<app-form-error-summary [controls]="controls">
    <app-form-error [control]="controls?.vinNumber" name='vinNumber' type='required'>
        Vehicle Identification Number is required.
    </app-form-error>
    <app-form-error [control]="controls?.vinNumber" name='vinNumber' type='pattern'>
        Vehicle Identification Number must be exactly 17 characters long.
    </app-form-error>
    <app-form-error [control]="controls?.mileage" name='mileage' type='required'>
        Mileage is required.
    </app-form-error>
    <app-form-error [control]="controls?.mileage" name='mileage' type='greaterThan'>
        Mileage must be greater than 0.
    </app-form-error>
</app-form-error-summary>
<div class="vin-mileage-credentials">
    <label for="vinNumber">Vehicle Identification Number</label>
    <input
        name="vinNumber"
        id="vinNumber"
        #vinNumber
        type="text"
        placeholder="Vehicle Identification Number"
        [textMask]="{ mask: vinMask, keepCharPositions: true }"
        [ngrxFormControlState]="controls.vinNumber"
        [tabIndex]="2" />
    <app-form-error [control]="controls?.vinNumber" name='vinNumber' type='required'>
        Vehicle Identification Number is required.
    </app-form-error>
    <app-form-error [control]="controls?.vinNumber" name='vinNumber' type='pattern'>
        Vehicle Identification Number must be exactly 17 characters long.
    </app-form-error>
    <label id="last-name-label" for="mileage">Mileage</label>
    <input
        name="mileage"
        id="mileage"
        #mileage
        type="number"
        placeholder="Mileage"
        [ngrxFormControlState]="controls.mileage"
        [tabIndex]="3" />
    <app-form-error [control]="controls?.mileage" name='mileage' type='required'>
        Mileage is required.
    </app-form-error>
    <app-form-error [control]="controls?.mileage" name='mileage' type='greaterThan'>
        Mileage must be greater than 0.
    </app-form-error>
</div>
