export function getMemberSinceYears(joinYearDate) {
  // backend can return the joinDate with two or four digits 😱
  const fullYear = new Date().getFullYear().toString()
  if (joinYearDate.length === 1 || joinYearDate.length === 2) {
    return Number(fullYear.substr(-2)) - Number(joinYearDate)
  } else if (joinYearDate.length === 4) {
    return Number(fullYear) - Number(joinYearDate)
  } else {
    return null
  }
}

export function getMemberGreetings(joinYearDate) {
  const years = getMemberSinceYears(joinYearDate)
  if (years === 0) {
    return 'Thank you for being a valued AAA member!'
  } else if (years > 0) {
    return `Thank you for being a valued AAA member for ${years} year${years === 1 ? '' : 's'}!`
  }
}
