import { AAAStore } from 'src/app/store/root-reducer'
import { TowLocationState } from './tow-location.reducer'
import { createSelector } from '@ngrx/store'
import { concatAddress } from 'src/app/shared/utils/concatAddress'
import { NON_AAR_TOWING_NAMES } from './tow-location.actions'
import {
  GenericCoordinates,
  GoogleLocationMarker,
  LocationWithMarker,
  TowLocation,
} from '../location.types'

export const selectTowLocationState = (store: AAAStore): TowLocationState =>
  store.towLocation

export const selectTowLocationAddress = createSelector(
  selectTowLocationState,
  (towLocationState): TowLocation => ({
    ...towLocationState,
    address: concatAddress(
      towLocationState,
      towLocationState.name ||
        towLocationState.facility ||
        towLocationState.location ||
        ''
    ),
    isAar: towLocationState.hasOwnProperty('isAar')
      ? towLocationState.isAar
      : towLocationState.name !== NON_AAR_TOWING_NAMES.CUSTOM &&
        towLocationState.name !== NON_AAR_TOWING_NAMES.HOME,
  })
)

export const selectIsTowLocationValid = createSelector(
  selectTowLocationState,
  (towLocation: TowLocationState): boolean =>
    Boolean(towLocation.latitude) && Boolean(towLocation.longitude)
)

export const selectTowLocation = createSelector(
  selectTowLocationState,
  selectIsTowLocationValid,
  (towLocationState, isValid): TowLocation | null =>
    isValid ? towLocationState : null
)

export const selectTowLocationMarker = createSelector(
  selectTowLocation,
  (towLocation): GoogleLocationMarker | null =>
    towLocation !== null
      ? {
          address: concatAddress(
            towLocation,
            towLocation.name !== NON_AAR_TOWING_NAMES.CUSTOM
              ? towLocation.name
              : ''
          ),
          name: towLocation.name,
          isAar: towLocation.isAar,
          lat: Number(towLocation.latitude),
          lng: Number(towLocation.longitude),
        }
      : null
)

export const selectTowLocationPreview = createSelector(
  selectTowLocationState,
  (towLocationState): LocationWithMarker | null => towLocationState?.preview
)

export const selectTowLocationCoordinates = createSelector(
  selectTowLocationPreview,
  (locationMarker: LocationWithMarker): GenericCoordinates => locationMarker ? {
    latitude: locationMarker.marker.lat,
    longitude: locationMarker.marker.lng,
  } : null
)

export const selectIsTowLocationPreviewValid = createSelector(
  selectTowLocationPreview,
  (towLocationPreview): boolean =>
    Boolean(towLocationPreview?.location?.latitude) &&
    Boolean(towLocationPreview?.location?.longitude)
)

export const selectTowLocationPreviewAddress = createSelector(
  selectTowLocationPreview,
  (preview): TowLocation | null =>
    preview?.location
      ? ({
          ...preview.location,
          address: concatAddress(preview.location, preview.location.name || ''),
          isAar:
            preview.location.isAar ||
            (preview.location.name !== NON_AAR_TOWING_NAMES.CUSTOM &&
              preview.location.name !== NON_AAR_TOWING_NAMES.HOME),
        } as TowLocation)
      : null
)

export const selectTowLocationPreviewMarker = createSelector(
  selectTowLocationPreview,
  (preview): GoogleLocationMarker | null =>
    preview?.marker
      ? {
          ...preview.marker,
          address:
            preview.marker.name !== NON_AAR_TOWING_NAMES.CUSTOM
              ? `${preview.marker.name} - ${preview.marker.address}`
              : preview.marker.address,
        }
      : null
)
