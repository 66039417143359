import {
  Component,
  Input,
  Output,
  EventEmitter,
  forwardRef,
} from '@angular/core'
import { NgForm, ControlContainer } from '@angular/forms'
import { FormViewAdapter, NGRX_FORM_VIEW_ADAPTER } from 'ngrx-forms'

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NGRX_FORM_VIEW_ADAPTER,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class CheckboxComponent implements FormViewAdapter {
  @Input() get checked(): boolean {
    return this.checkedValue
  }

  set checked(newValue) {
    this.checkedValue = newValue
    this.active = this.checkedValue ? 'active' : 'inactive'
  }

  constructor() {}

  @Input() name = ''
  @Input() id = ''
  @Input() value: any = null
  @Input() tabIndex = 0
  @Input() required = false

  setIsDisabled = null
  checkedValue = false
  active = 'inactive'
  focus = 'nofocus'

  @Output() checkedChange: EventEmitter<boolean> = new EventEmitter()

  setViewValue(value: any): void {
    this.checked = value
  }

  setOnChangeCallback(fn: (value: any) => void): void {
    this.checkedChange.subscribe(fn)
  }

  setOnTouchedCallback(fn: () => void): void {
    this.checkedChange.subscribe(fn)
  }

  handleChange($event) {
    const oldValue = this.checked
    this.checked = $event.target.checked

    if (this.checked !== oldValue) {
      this.checkedChange.emit(this.checked)
    }
  }

  // These weird callbacks are necessary in order to ensure focus is visible to
  // the user, since in order to style the checkbox the way we want to, the
  // real one is hidden off-screen.
  handleFocus() {
    this.focus = 'focus'
  }

  handleBlur() {
    this.focus = 'nofocus'
  }
}
