import { Pipe, PipeTransform } from '@angular/core'

export type ColorName =
  | 'primary'
  | 'success'
  | 'green'
  | 'red'
  | 'error'
  | 'gray'
  | 'white'

const COLORS = {
  primary: '#003893',
  success: '#009966',
  green: '#009966',
  error: '#d81e05',
  red: '#d81e05',
  gray: '#cccccc',
  white: '#fff',
}

@Pipe({ name: 'color' })
export class ColorPipe implements PipeTransform {
  transform(value: ColorName): string {
    return COLORS[value] || COLORS.primary
  }
}
