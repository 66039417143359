<div class="dialog not-a-member">
  <h1 mat-dialog-title>Not a Member?</h1>

  <div mat-dialog-content>
    <h3>We can still help.</h3>
    <p>
      Give us a call, and we’ll connect you to a customer service
      representative.
    </p>
  </div>
  <div class="not-a-member_secondary-content">
    <a 
      href="tel:+18005646222" 
      target="_blank" 
      tabIndex="0" 
      class="btn inverted show-mobile generic-dialog-link-button"
      title="CALL AAA"
      (click)="onClose()"
    >
      1-800-JOIN-AAA
    </a>


    <div class="show-desktop text-center">
      <span class="join"> 1-800-JOIN-AAA </span>
    </div>

  </div>
  <button mat-dialog-close class="mat-dialog-close" aria-label="dismiss this message">
    <icon-close></icon-close>
  </button>
</div>
