import { Component, OnInit, Inject, ViewChild } from '@angular/core'
import { Store, select } from '@ngrx/store'
import { AAAStore } from 'src/app/store/root-reducer'

import { AbstractComponent } from 'src/app/shared/abstract.component'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { DialogData, ErrorDialogTypes } from 'src/app/modules/ui/ui.types'
import {
  searchMembersReset,
  MEMBER_INFO,
  MEMBER_BASIC_INFO,
  MEMBERS_SEARCH,
} from 'src/app/modules/member/member.actions'
import { selectSearchMembersResults } from 'src/app/modules/member/member.selectors'
import { filter, map } from 'rxjs/operators'
import {
  authNameSearchRequest,
} from 'src/app/modules/auth/auth.actions'
import { ADD_CALL } from 'src/app/modules/dashboard/calls.actions'
import { selectIsLoading } from '../../../loading/loading.selectors'

import { combineLatest } from 'rxjs'
import { openErrorDialog } from '../../../ui.actions'
import { TaggingService } from 'src/app/modules/tagging/tagging.service'
import { NgForm } from '@angular/forms'
import events from 'src/app/modules/tagging/events'
import { MEMBERSHIP_ERRORS } from 'src/app/modules/member/member.types'

@Component({
  selector: 'app-auth-additional-data',
  templateUrl: './auth-additional-info.component.html',
  styleUrls: ['./auth-additional-info.component.scss'],
})
export class AuthAdditionalDataComponent
  extends AbstractComponent
  implements OnInit {
  isLoading$ = combineLatest([
    this.store$.pipe(select(selectIsLoading(MEMBER_INFO.ACTION))),
    this.store$.pipe(select(selectIsLoading(MEMBER_BASIC_INFO.ACTION))),
    this.store$.pipe(select(selectIsLoading(MEMBERS_SEARCH.ACTION))),
    this.store$.pipe(select(selectIsLoading(ADD_CALL.ACTION))),
  ]).pipe(map((areActionsLoading) => areActionsLoading.find(Boolean)))

  @ViewChild('f') streetForm: NgForm

  streetFormModel = ''

  searchResults$ = this.store$.pipe(
    select(selectSearchMembersResults),
    filter(
      (searchItems) =>
        searchItems['members'] && searchItems['members'].length >= 1
    )
  )

  searchMembersList: any[]
  searchId: string

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private store$: Store<AAAStore>,
    private taggingService: TaggingService
  ) {
    super()
  }

  ngOnInit() {
    this.searchMembersList = this.data.params?.members || []
    this.searchId = this.data.params?.searchId || null
  }

  // TODO: move handleStreetForm to effects
  /**
   * Handle modal form submission
   *
   * @param param0
   */
  handleStreetForm({ value }) {
    const streetNumber = value['streetNumber'].trim().toUpperCase() || ''
    const exists = this.searchMembersList.find(
      (member) => member['streetNumber'].toUpperCase() === streetNumber
    )

    if (!exists) {
      this.store$.dispatch(
        openErrorDialog({
          payload: {
            type: ErrorDialogTypes.MEMBER_NO_MATCHES,
          },
        })
      )

      this.taggingService.setAutomatedEvent(
        events.auth.FORM_NAME_AUTH_FAILURE,
        events.auth.PAGE_TYPE,
        null,
        { 'Event Detail': MEMBERSHIP_ERRORS.MEMBER_STREET_MISMATCH.reason }
      )

      this.resetMemberSearch()
    } else {
      const resultId = exists['resultId']
      this.triggerAuthBySearch(this.searchId, resultId)
    }
    this.data.close()
  }

  /**
   * Trigger reset search list
   */
  resetMemberSearch() {
    this.searchMembersList.length = 0
    this.store$.dispatch(searchMembersReset())
  }

  // TODO move to effects
  /**
   * Trigger login
   *
   * @param searchId
   * @param resultId
   */
  triggerAuthBySearch(searchId, resultId) {

    this.store$.dispatch(
      authNameSearchRequest({ payload: { searchId, resultId } as any })
    )
  }
}
