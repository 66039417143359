export const SCREEN_SIZE_XSMALL = 320
export const SCREEN_SIZE_SMALL = 640
export const SCREEN_SIZE_MEDIUM = 1024
export const SCREEN_SIZE_XLARGE = 1200

export function isMobileSize(): boolean {
  let match = false

  if (typeof window !== 'undefined') {
    match = window.matchMedia(`(max-width: ${SCREEN_SIZE_SMALL}px)`).matches
  }

  return match
}

export function isDesktopSize(): boolean {
  return !isMobileSize()
}
