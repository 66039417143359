import { object, string, array } from 'yup'
import { VehicleMakes, VehicleModels, VehicleYears } from './vehicle.types'

export const VehicleYearsSchema = object<VehicleYears>().shape({
  years: array().of(string()),
})

export const VehicleMakesSchema = object<VehicleMakes>().shape({
  makes: array().of(string()),
})

export const VehicleModelsSchema = object<VehicleModels>().shape({
  models: array().of(string()),
})
