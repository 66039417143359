<div class="dialog">
  <h1 mat-dialog-title>Call Completed </h1>

  <div mat-dialog-content>
    <p>
      Your call was completed. If you need additional assistance, please call:
    </p>
  </div>
  <div mat-dialog-actions>
    <app-call-aaa-button
      [clickEventPage]="pageType"
      [inverted]="true"
      (buttonClick)="onClose()">
    </app-call-aaa-button>
  </div>
  <button mat-dialog-close class="mat-dialog-close" aria-label="dismiss this message">
    <icon-close></icon-close>
  </button>
</div>
