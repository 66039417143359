import { RouterNavigatedAction, RouterRequestAction } from '@ngrx/router-store'

export const getParamsFromEvents = (
  action: RouterRequestAction | RouterNavigatedAction,
  query
) => {
  const nextStepUrl =
    new RegExp(`${query}=([\\w-]+)`).exec(action.payload.event.url) || []
  return nextStepUrl[1]
}
