<ng-container *ngIf="displayQuickAccess && !isPwa && (suppressPWA$ | async) && !(isRapUser)">
  <footer [@openClose]="closed ? 'closed' : 'open'" class="pwa-shortcut" role="contentinfo" >
    <div class="label" 
      aria-label="Install Progressive Web App"
      triggerable-element 
      (click)="install(prompt)" 
      [tabindex]="closed ? -1 : 0">
      Install Application
      <icon-aaa></icon-aaa>
    </div>
    <div class="close" aria-label="dismiss this message"  [tabindex]="closed ? -1 : 0" triggerable-element (click)="close()">
      &times;
    </div>
  </footer>
</ng-container>
