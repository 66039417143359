import {
  Component,
  Input,
  Output,
  EventEmitter,
  HostListener,
  ViewChild,
} from '@angular/core'
import { NgSelectComponent } from '@ng-select/ng-select'
import { Observable, EMPTY } from 'rxjs'
import { map } from 'rxjs/operators'
import { isDesktopSize } from '../../utils/windowSize'

const OTHER_VALUE = 'Other'

@Component({
  selector: 'app-select-or-text',
  templateUrl: './select-or-text.component.html',
  styleUrls: ['./select-or-text.component.scss'],
})
export class SelectOrTextComponent {
  private itemsValue$: Observable<string[]> = EMPTY

  @ViewChild('selectOrText', { static: false }) selectOrText: NgSelectComponent

  @Input()
  get items$(): Observable<string[]> {
    return this.itemsValue$
  }

  set items$(value) {
    this.itemsValue$ = value
    this.internalItems$ = this.items$.pipe(
      map((items) => [...items, OTHER_VALUE])
    )
  }

  @Input() fieldName: string
  @Input() maxLength = 250
  @Output() selectionMade: EventEmitter<string> = new EventEmitter<string>()
  @Input() keyupText: (event: any) => string

  internalItems$: Observable<string[]> = EMPTY
  otherSelected = false
  otherValue = ''
  isDesktopValue = isDesktopSize()
  get isDesktop() {
    return this.isDesktopValue
  }

  handleChange(selection) {
    if (selection === OTHER_VALUE) {
      this.otherSelected = true
      return
    }

    this.selectionMade.emit(selection)
  }

  handleKeyUpText(event) {
    if (this.keyupText) {
this.otherValue = this.keyupText(event)
}
  }

  submitOtherValue() {
    if (this.otherSelected) { // Blocks empty value on Other input
      if (!this.otherValue.trim()) {
        this.otherValue = ''

        return
      }
    }

    this.selectionMade.emit(this.otherValue)
  }

  @HostListener('window:resize', ['$event'])
  onResize($event) {
    this.isDesktopValue = isDesktopSize()
  }
}
