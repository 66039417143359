import { createSelector, defaultMemoize } from '@ngrx/store'

import { IndexedCollection } from 'src/app/shared/types'
import {
  concatAddress,
  concatAddressLine1,
  concatAddressLine2,
} from 'src/app/shared/utils/concatAddress'
import { AAAStore } from 'src/app/store/root-reducer'
import {
  PaceSetterCode,
  PACE_SETTER_SITUATION_CODES,
} from '../../issue/issue.types'
import { selectAARData } from '../../location/aar/aar.selectors'
import { GoogleCoordinates } from '../../location/google-geocode/types'
import {
  selectBreakdownLocation,
  selectIsBreakdownLocationValid,
  selectLocationClub,
} from '../../location/location.selectors'
import {
  BreakdownLocation,
  GoogleLocationMarker,
  TowLocation,
} from '../../location/location.types'
import { compareAddresses } from '../../location/location.utils'
import { NON_AAR_TOWING_NAMES } from '../../location/tow-location/tow-location.actions'
import {
  selectIsMemberVehicleValid,
  selectMemberActiveVehicle,
} from '../../member/member.selectors'
import { VehicleData } from '../../member/member.types'
import {
  AAACallStatus,
  BreakdownLocationParams,
  CALL_STATUS_CODES,
} from '../calls.types'
import { generateCallId } from '../calls.utils'
import { AAACallsStatusesState } from './call-status.reducer'
import { DriverInfo, EXTERNAL_SERVICES } from './call-status.types'

export const selectCallsStatusesState = (
  store: AAAStore
): AAACallsStatusesState => store.callsStatuses

export const selectActiveCallStatusId = createSelector(
  selectCallsStatusesState,
  (state: AAACallsStatusesState) => state.activeCallStatus
)

export const createSelectDefaultActiveCallStatusId = createSelector(
  selectActiveCallStatusId,
  (activeCallStatusId) =>
    defaultMemoize(
      (indexedCallStatus: IndexedCollection<AAACallStatus>): string | null =>
        getDefaultActiveCall(indexedCallStatus, activeCallStatusId)
    )
)

// FIXME: deprecated
export const selectCanceledCallStatus = createSelector(
  selectCallsStatusesState,
  (state: AAACallsStatusesState): Array<string> => state.canceledCalls
)

export const selectCallsStatusesData = createSelector(
  selectCallsStatusesState,
  (state: AAACallsStatusesState): IndexedCollection<AAACallStatus> => state.data
)

export const selectHasAvailableCall = createSelector(
  selectCallsStatusesData,
  // Need to verify if cancelled calls are excluded.
  (calls: IndexedCollection<AAACallStatus>): boolean =>
    Object.keys(calls).length > 0
)

export const selectFollowingCallsStatusId = createSelector(
  selectCallsStatusesData,
  selectActiveCallStatusId,
  (callStatuses, activeCallStatusId): string => {
    const remainingCalls = Object.values(callStatuses).filter(
      (call) =>
        generateCallId(call.callId, call.callDate) !== activeCallStatusId
    )

    return remainingCalls.length > 0
      ? generateCallId(remainingCalls[0].callId, remainingCalls[0].callDate)
      : null
  }
)

export const selectDrivers = createSelector(
  selectCallsStatusesState,
  (state: AAACallsStatusesState): IndexedCollection<DriverInfo> => state.drivers
)

export const selectActiveCallStatus = createSelector(
  selectCallsStatusesData,
  selectActiveCallStatusId,
  (
    callsStatuses: IndexedCollection<AAACallStatus>,
    activeCallStatusId: string
  ): AAACallStatus => {
    const activeCallStatus = callsStatuses[activeCallStatusId]
    if (!activeCallStatus) {
      return null
    }

    return { ...activeCallStatus }
  }
)

export const selectCanCancelActiveCall = createSelector(
  selectActiveCallStatus,
  (activeCallStatus: AAACallStatus): boolean => {
    if (activeCallStatus === null) {
      return false
    }

    switch (activeCallStatus.callStatus) {
      case CALL_STATUS_CODES.OL:
      case CALL_STATUS_CODES.OS:
      case CALL_STATUS_CODES.UT:
      case CALL_STATUS_CODES.TW:
        return false
      default:
        return true
    }
  }
)

export const selectDriverInfo = createSelector(
  selectActiveCallStatus,
  selectDrivers,
  (call, drivers): DriverInfo => drivers[call?.driverData?.id] || null
)

export const selectDriverLocation = createSelector(
  selectActiveCallStatus,
  (activeCallStatus): GoogleCoordinates => {
    if (!activeCallStatus) {
      return null
    }

    const { driverData } = activeCallStatus
    if (
      !driverData ||
      (driverData.latitude === '0' && driverData.longitude === '0')
    ) {
      return null
    }

    return {
      lat: Number(driverData.latitude),
      lng: Number(driverData.longitude),
    }
  }
)

export const selectCallPaceSetterCode = createSelector(
  selectActiveCallStatus,
  (activeCallStatus): PaceSetterCode['paceSetterCode'] =>
    activeCallStatus?.pacesetterCode
)

// TODO fix duplicate of selectTowLocationAddress in tow-location.selectors.ts
export const selectCallTowLocation = createSelector(
  selectActiveCallStatus,
  selectAARData,
  (activeCallStatus, aars): null | TowLocation => {
    // Undefined tow destination
    if (
      !activeCallStatus?.towDestination?.location &&
      !activeCallStatus?.towDestination?.facility
    ) {
      return null
    }

    const destination = activeCallStatus.towDestination
    // The server will sometimes return a different structure, so get the address from one type or the other.
    const address = concatAddress(
      destination,
      destination.name || destination.facility || destination.location || ''
    )

    const isAar =
      destination.isAar ||
      (destination.name !== NON_AAR_TOWING_NAMES.CUSTOM &&
        destination.name !== NON_AAR_TOWING_NAMES.HOME) ||
      Boolean(aars.find(compareAddresses(address)))

    return {
      ...destination,
      address,
      isAar,
    }
  }
)

export const selectCallVehicle = createSelector(
  selectActiveCallStatus,
  (activeCallStatus): null | VehicleData => activeCallStatus?.vehicle || null
)

export const selectActiveBreakdownMarkerData = createSelector(
  selectActiveCallStatus,
  (callState: AAACallStatus): GoogleLocationMarker => {
    if (!callState?.breakdownLocation) {
      return null
    }
    // TODO investigate this curveball.
    const streetName =
      (callState.breakdownLocation as BreakdownLocation).streetName ||
      (callState.breakdownLocation as BreakdownLocationParams).street
    const postalCode =
      (callState.breakdownLocation as BreakdownLocation).postalCode ||
      (callState.breakdownLocation as BreakdownLocationParams).zip

    const { latitude, longitude, state, city, streetNumber, location } =
      callState.breakdownLocation
    return {
      lat: Number(latitude),
      lng: Number(longitude),
      addressLine1: concatAddressLine1(streetNumber, streetName, location),
      addressLine2: concatAddressLine2(city, state, postalCode, true),
    }
  }
)

export const selectCallTowDestinationMarkerData = createSelector(
  selectCallTowLocation,
  (destination: TowLocation): GoogleLocationMarker => {
    if (!destination) {
      return null
    }
    return {
      lat: Number(destination.latitude),
      lng: Number(destination.longitude),
      address: destination.address,
    }
  }
)

const _validExternaProvider = (provider: string) => {
  if (!provider) {
    return false
  }

  const _provider = provider.toUpperCase()
  const externalProvider = Boolean(EXTERNAL_SERVICES[_provider]) || false
  return externalProvider
}

export const selectExternalServiceUrl = createSelector(
  selectActiveCallStatus,
  (activeCallStatus: AAACallStatus): null | string => {
    if (!_validExternaProvider(activeCallStatus?.tracking?.provider)) {
      return null
    }

    return activeCallStatus.tracking.url
  }
)

export const selectActiveCallServingClub = createSelector(
  selectActiveCallStatus,
  (activeCallStatus: AAACallStatus): null | string =>
    activeCallStatus?.servicingClub || null
)

export const selectIsActiveBatteryCall = createSelector(
  selectActiveCallStatus,
  (activeCallStatus: AAACallStatus): null | boolean =>
    activeCallStatus?.pacesetterCode ===
      PACE_SETTER_SITUATION_CODES.BATTERY_ISSUE || null
)

export const selectHasPostalCodeChanged = createSelector(
  selectBreakdownLocation,
  selectIsBreakdownLocationValid,
  selectActiveCallStatus,
  (breakdownLocation, isBreakdownLocationValid, activeCallStatus): boolean =>
    isBreakdownLocationValid &&
    // TODO investigate curveball.
    breakdownLocation?.postalCode !==
      ((activeCallStatus?.breakdownLocation as BreakdownLocation)?.postalCode ||
        (activeCallStatus?.breakdownLocation as BreakdownLocationParams)?.zip)
)

export const selectHasVehicleChanged = createSelector(
  selectMemberActiveVehicle,
  selectIsMemberVehicleValid,
  selectActiveCallStatus,
  (activeVehicle, isMemberVehicleValid, activeCallStatus): boolean =>
    isMemberVehicleValid &&
    (activeCallStatus?.vehicle?.year !== activeVehicle?.year ||
      activeCallStatus?.vehicle?.make !== activeVehicle?.make ||
      activeCallStatus?.vehicle?.model !== activeVehicle?.model)
)

export const selectHasClubChanged = createSelector(
  selectLocationClub,
  selectActiveCallStatus,
  (club, activeCallStatus): boolean =>
    Boolean(club) && club !== activeCallStatus?.servicingClub
)

export const selectActiveCallsCalled = createSelector(
  selectCallsStatusesState,
  (state: AAACallsStatusesState): boolean => state.activeCallCalled
)

function getDefaultActiveCall(
  indexedCallStatuses: IndexedCollection<AAACallStatus>,
  activeCallStatusId: string
): string | null {
  const isActiveCall = (
    indexedStatuses: IndexedCollection<AAACallStatus>,
    id: string
  ) =>
    indexedStatuses[id].callStatus !== CALL_STATUS_CODES.CL &&
    indexedStatuses[id].callStatus !== CALL_STATUS_CODES.CA &&
    indexedStatuses[id].callStatus !== CALL_STATUS_CODES.XX
  if (
    activeCallStatusId &&
    indexedCallStatuses[activeCallStatusId] &&
    isActiveCall(indexedCallStatuses, activeCallStatusId)
  ) {
    return activeCallStatusId
  } else {
    const activeCalls = Object.keys(indexedCallStatuses).filter((id) =>
      isActiveCall(indexedCallStatuses, id)
    )
    if (activeCalls?.length) {
      return activeCalls[0]
    } else {
      return null
    }
  }
}
