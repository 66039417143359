<div class="dialog not-a-member">
  <h4 mat-dialog-title>Are you struggling to log in using your name and zip?</h4>
  <div mat-dialog-content>
    <p>Please follow these steps to log in correctly:</p>
    <ol class="ordered-list">
      <li>Verify the full name and home zip code of the <strong>primary account holder</strong>;</li>
      <li>Enter the <strong>primary account holder's</strong> information into the first name, last name, and zip code fields;</li>
      <li>Click <strong>confirm</strong>.</li>
    </ol> 
  </div>
  <h4 mat-dialog-title>Forgot Your Member ID?</h4>
  <div mat-dialog-content>
    <p>We are sorry to hear you do not have your AAA Membership number. As a reminder, your member number can be located on the front of your AAA Membership Card.</p>
    <p>To continue your request for service, please call the number below.</p>
  </div>
  <app-call-aaa-button class="mt-20"></app-call-aaa-button>
  <button mat-dialog-close class="mat-dialog-close">
    <icon-close></icon-close>
  </button>
</div>
