export default {
  LOCATION_PAGE_TYPE: 'Breakdown Location',
  LOCATION_SEARCH_CLICK: 'Breakdown location search',
  LOCATION_DETAILS_SELECT: 'Set optional details',
  LOCATION_HOME_CLICK: 'Home breakdown location',
  LOCATION_CURRENT_CLICK: 'Current location',
  LOCATION_ADDITIONAL_INFO_PROMPT: 'Additional info required - Prompt',
  LOCATION_ADDITIONAL_INFO_CONFIRM_CLICK: 'Additional info required - Confirm',
  LOCATION_NEXT_CLICK: 'Set breakdown location',
  OPTIONS_PAGE_TYPE: 'Location Options',
  OPTIONS_CURRENT_CLICK: 'User selected find my location',
  OPTIONS_HOME_CLICK: 'User selected home location',
  OPTIONS_HOME_EDIT_CLICK: 'User selected edit home location',
  SERVICING_CLUB_PAGE_TYPE: 'Servicing Club Config',
  SERVICING_CLUB_DISABLED_PROMPT: 'DRR System Disabled'
}
