import { Collection } from '../../shared/types'

export interface VehicleData {
  color?: string
  driveType?: string
  imageUrl?: string | null
  make: string
  model: string
  notes?: string
  stockImage?: string
  vehicleType?: string
  vin?: string
  wheelDrive?: string
  year: number | string
}

export interface AddVehicleResponse {
  id: string
}

export type Vehicle = AddVehicleResponse & VehicleData

export interface MemberLocation {
  city: string
  state: string
  latitude: string
  longitude: string
  streetName: string
  streetNumber?: string
  locationName?: string
  shopId?: number
}
export interface ServiceItem {
  code: number
  callId: string
  callDate: string
  callStatus: string
  firstName: string
  lastName: string
  legacyTrouble1?: string
  numberOfPassengers?: number
  pacesetterCode1?: string
  receivedTime?: Date
  serviceProvider?: string
  breakdownLocation?: MemberLocation
  towDestination?: MemberLocation
  vehicle?: Vehicle
}

export interface MemberInfo {
  basicAddress: string
  city: string
  countryCode: string
  eligible: boolean
  ersAbuser: boolean
  expirationDate: string
  joinDate: string
  firstName: string
  lastName: string
  memberVehicles: VehicleData[]
  membershipNumber: string
  fullMembershipNumber?: string
  noteText: string
  plusIndicator: string
  postalCode: string
  preferredAARs?: string | null
  stateProvince: string
  statusCode: string
  supplementalAddress: string
  supplementalTelephoneNumber?: string | null
  telephoneNumber: string
  membershipLevel?: MembershipLevelsSettings
  callClubIndicator?: boolean
  cdxAuthorizationLevel?: string
  codIndicator?: boolean
}

export interface MemberBasicInfo {
  eligible: boolean
  statusCode: string
  fullMembershipNumber?: string
  membershipNumber: string
  plusIndicator: string
  expirationDate: string
  firstName?: string
  lastName?: string
  joinDate?: string
  ersAbuser: boolean
  membershipLevel?: string
}

export type MemberVehicles = Collection<'vehicles', Vehicle>

export interface SearchMembersParams {
  firstName: string
  lastName: string
  zipCode: string
  captchaToken: string
}

export interface SearchMemberResult {
  searchId: string
  members: MembersResult[]
}

export interface MembersResult {
  resultId: string
  streetNumber: string
}

export enum MemberStatusCodes {
  ACTIVE = 'A',
  CANCELLED = 'C',
  PENDING = 'P',
}

export enum MembershipLevelsSettings {
  CLASSIC = 'Basic',
  BASIC = 'Basic',
  PREMIER = 'Premier',
  PREMIERRV = 'PremierRV',
  RV = 'RV',
  PLUS = 'Plus',
  MOTORCYCLE = 'Motorcycle',
}

export interface MemberEligibility {
  currentCallCount: number
  maxCallsAllowed: number
}

export enum MembershipErrorTypes {
  ENTITLEMENT = 'entitlement',
  INCORRECT = 'incorrect'
}

export interface MemberShipError {
  [key: string]: {
    reason: string,
    action?: string
  }
}

export enum MembershipErrorActions {
  MEMBER_VALIDATION_INVALID_NUMBER = 'invalid member number',
  MEMBER_VALIDATION_INVALID_ZIP_CODE = 'invalid zip code',
  MEMBER_VALIDATION_INACTIVE_MEMBER = 'non active member',
}

export enum MemberTypesFromMessage {
  CAPTCHA_TOKEN = 'invalid captcha token',
  ETS_MISSING = 'missing member info: ets',
  ETS_EXPIRED = 'expired member info: ets',
  MEMBER_NOT_ZIP = 'no member info found',
  MEMBER_MORE_MATCHES_STREET = 'duplicate member info found',
  MEMBER_LOOKUP_ERROR = 'error validating member',
  MEMBER_LOOKUP_INVALID = 'invalid member info',
  MEMBER_NOT_ACTIVE = 'membership not active',
  VALIDATION_TOKEN = 'error getting authorization token',
  NOT_ELEGIBLE = 'member not elegible',
  ERS_ABUSER = 'ers abuser',
  INVALID_TOKEN = 'invalid user token'
}

// TODO need to match the other reasons with the right actions
export const MEMBERSHIP_ERRORS: MemberShipError = {
  CAPTCHA_TOKEN: {
    reason: 'Captcha Token Invalid'
  },
  MEMBER_NOT_ZIP: {
    reason: 'No matches found for that member name in any club that covers the given Zip code'
  },
  MEMBER_MORE_MATCHES_STREET: {
    reason: 'More than one member found for the same street number with the given name / Zip code'
  },
  MEMBER_STREET_MISMATCH: {
    reason: 'Street number verification failed'
  },
  ETS_MISSING: {
    reason: 'ets is missing'
  },
  ETS_EXPIRED: {
    reason: 'ets has expired'
  },
  MEMBER_LOOKUP_ERROR: {
    reason: 'Error during Member Lookup request',
    action: MembershipErrorActions.MEMBER_VALIDATION_INVALID_NUMBER
  },
  MEMBER_LOOKUP_INVALID: {
    reason: 'Member Lookup returns nothing, or request returns a different Zip code than what is passed in',
    action: MembershipErrorActions.MEMBER_VALIDATION_INVALID_ZIP_CODE
  },
  MEMBER_NOT_ACTIVE: {
    reason: 'Member\'s status is not Active or Pending',
    action: MembershipErrorActions.MEMBER_VALIDATION_INACTIVE_MEMBER
  },
  INVALID_TOKEN: {
    reason: 'Error occurred trying to create an authentication token'
  },
  NOT_ELEGIBLE: {
    reason: 'Member is not elegible'
  },
  ERS_ABUSER: {
    reason: 'Member marked as ERS'
  }
}
