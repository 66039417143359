import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'
import { from } from 'rxjs'
import {
  catchError,
  concatMap,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators'

import { AAAStore } from 'src/app/store/root-reducer'
import { ErrorReportingService } from '../../shared/services/error-reporting.service'
import { AuthSecurityWrapperService } from '../auth/auth-security-wrapper/auth-security-wrapper.service'
import { selectActiveCallStatus } from '../dashboard/calls-statuses/call-status.selectors'
import { selectNeedsTow } from '../issue/issue.selectors'
import {
  SET_LOCATION_CLUB,
  SKIP_SET_LOCATION_CLUB,
} from '../location/location.actions'
import events from '../tagging/events'
import { TaggingService } from '../tagging/tagging.service'
import { openMessageDialog, setSplashscreenStep, showSplashscreen } from '../ui/ui.actions'
import { MessageDialogTypes, StepTypes } from '../ui/ui.types'
import { currentEditStepLocation } from '../wizard/wizard.selectors'
import { WizardService } from '../wizard/wizard.service'
import {
  setServicingClubConfigsFailure,
  setServicingClubConfigsRequest,
  setServicingClubConfigsSuccess,
  SET_SERVICING_CLUB_CONFIGS,
} from './servicing-club.actions'
import {
  createSelectConfigByServicingClub,
  selectIsEnabled,
} from './servicing-club.selectors'
import { ServicingClubService } from './servicing-club.service'
import { ServicesConfiguration } from './servicing-club.types'

@Injectable()
export class ServicingClubEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<AAAStore>,
    private router: Router,
    private authWrapperService: AuthSecurityWrapperService,
    private errorReportingService: ErrorReportingService,
    private taggingService: TaggingService,
    private wizardService: WizardService,
    private servicingClubService: ServicingClubService
  ) {}

  handleServicingClubConfigRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof setServicingClubConfigsRequest>>(
        SET_SERVICING_CLUB_CONFIGS.REQUEST
      ),
      withLatestFrom(
        this.store$.pipe(select(createSelectConfigByServicingClub))
      ),
      switchMap(([action, selectConfigByServicingClub]) => {
        const clubConfig = selectConfigByServicingClub.memoized(
          action.payload.club
        )

        return clubConfig
          ? [
              setServicingClubConfigsSuccess({
                payload: {
                  club: action.payload.club,
                  config: clubConfig,
                },
              }),
            ]
          : from(
              this.servicingClubService.getServicingClubConfigs(
                action.payload.club
              )
            ).pipe(
              map((serviceConfiguration: ServicesConfiguration) =>
                setServicingClubConfigsSuccess({
                  payload: {
                    club: action.payload.club,
                    config: serviceConfiguration,
                  },
                })
              ),
              catchError((error) =>
                from(
                  this.errorReportingService.notifyErrorObservable(
                    error,
                    setServicingClubConfigsFailure
                  )
                )
              )
            )
      })
    )
  )

  handleDisabledDRR$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof setServicingClubConfigsSuccess>>(
        SET_SERVICING_CLUB_CONFIGS.SUCCESS
      ),
      withLatestFrom(this.store$.pipe(select(selectIsEnabled))),
      filter(([_, isEnabled]) => !isEnabled),
      concatMap(() => {
        this.authWrapperService.logout()
        this.taggingService.setAutomatedEvent(
          events.location.SERVICING_CLUB_DISABLED_PROMPT,
          events.location.SERVICING_CLUB_PAGE_TYPE
        )

        return [
          openMessageDialog({
            payload: {
              type: MessageDialogTypes.ADVISORY,
            },
          }),
          showSplashscreen(),
          setSplashscreenStep({ payload: 0 }),
        ]
      })
    )
  )

  handleNavigation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          SET_SERVICING_CLUB_CONFIGS.SUCCESS,
          SET_SERVICING_CLUB_CONFIGS.FAILURE,
          SET_LOCATION_CLUB.FAILURE,
          SKIP_SET_LOCATION_CLUB
        ),
        withLatestFrom(
          this.store$.pipe(select(currentEditStepLocation)),
          this.store$.pipe(select(selectNeedsTow)),
          this.store$.pipe(select(selectActiveCallStatus)),
          this.store$.pipe(select(selectIsEnabled))
        ),
        filter(
          ([_, _2, _3, activeCall, isEnabled]) =>
            !Boolean(activeCall) && isEnabled
        ),
        map(([_, currentStepUrl, needsTow]) => {
          if (currentStepUrl && !needsTow) {
            this.wizardService.backToEditUrl(currentStepUrl)
          } else {
            this.router.navigate(['steps'], {
              queryParams: { step: StepTypes.VEHICLE },
            })
          }
        })
      ),
    { dispatch: false }
  )
}
