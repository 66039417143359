export enum APIids {
  ERS = 'ERS',
}

export enum AppConfigParamsKeys {
  APP_ID = 'appid',
  DEFAULT_LATITUDE = 'lat',
  DEFAULT_LONGITUDE = 'lng',
  PREPROD = 'preprod',
  ZIP_CODE = 'memberzip',
  AGENT_ID = 'agentid',
  AGENT_VER = 'agentver',
  MAP_DEBUG = 'mapdebug',
  CALL_STATUS_INTERVAL = 'csi',
  TELEMATICS = 'telematics',
  MAKE = 'make',
  MODEL = 'model',
  YEAR = 'year',
  FIRST_TIME_ACCESS = 'ft',
}

// TODO remove all ABTest parameters from UIConfigParamsKeys after the single abTest parameter is in use
export enum UIConfigParamsKeys {
  PWA = 'pwa',
  MENU = 'menu',
  AB_TEST = 'abtest',
  SHOW_BACK = 'back',
  HIDE_NOTES_ON_NEED_TOW = 'hnt',
  SHOW_AUTH_OPTION_BUTTON = 'aob',
  SHOW_KEEP_ME_INFORMED = 'ki',
  SIMPLE_CAPTCHA = 'sc',
}

export enum ABTestParamsKeys {
  SHOW_BACK = 'back',
  SHOW_EXPANDED_DIALOG = 'sed',
  SHOW_BOTTOM_NAVIGATION = 'sbn',
  HIDE_NOTES_ON_NEED_TOW = 'hnt',
  TOW_REDESIGN = 'tr',
  SHOW_AUTH_OPTION_BUTTON = 'aob',
  SHOW_KEEP_ME_INFORMED = 'ki',
  SIMPLE_CAPTCHA = 'sc',
  APPLICATION_TEXT_UPDATES = 'atu',
  ISSUE_SELECTION_REVAMP = 'isr',
  NAME_ZIP_INSTRUCTIONS = 'nzi',
}

export enum AuthConfigParamsKeys {
  NAME_VALIDATE = 'namevalidate',
  MEM_ID = 'memid',
  ETS = 'ets',
  TOKEN = 'token',
  RAP_TOKEN = 'raptoken',
  CLUB = 'club',
}
