import { pipe } from 'rxjs'
import { Vehicle, VehicleData } from 'src/app/modules/member/member.types'
import { concatWith } from './concatWith'

export const generateVehicleSlug = (
  vehicle: Vehicle | VehicleData,
  postalCode?: string
) =>
  pipe(
    concatWith(vehicle?.year as string, '-'),
    concatWith(vehicle?.make || '', '-'),
    concatWith(vehicle?.model || '', '-'),
    concatWith(postalCode || '', '-')
  )('')
