import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { IndexedCollection } from 'src/app/shared/types'
import { AARData, AARDetail } from './aar.types'
import { setNoMakesFacilities } from './aar.actions'
import { XHRService } from 'src/app/shared/services/xhr.service'
import { indexCollection } from 'src/app/shared/utils'
import { AARDetailSchema, AARSchema } from './aar.model'
import { RapService } from '../../rap/rap.service'

@Injectable({
  providedIn: 'root',
})
export class AarService {
  private retries = 0

  constructor(
    private xhr: XHRService,
    private store$: Store,
    private rapService: RapService
  ) { }

  async getAARs({
    latitude,
    longitude,
    make,
  }): Promise<IndexedCollection<AARData>> {

    if (this.rapService.isRapUser()) {
      return this.rapService.getAarsFromTowDestination({
        latitude,
        longitude,
        make,
      })
    }

    try {
      const response = await this.xhr.request<{ aars: Array<AARData> }>({
        url: 'club/aars',
        params: {
          latitude,
          longitude,
          make,
        },
      })
      await AARSchema.validate(response)

      if (!response.aars?.length && this.retries <= 0) {
        this.retries++
        this.dispatchNoMakesFacilities()
        return this.getAARs({
          latitude,
          longitude,
          make: undefined,
        })
      }
      this.retries = 0

      return indexCollection<AARData, 'id'>(response.aars, 'id')
    } catch (error) {
      throw error
    }
  }

  private dispatchNoMakesFacilities() {
    this.store$.dispatch(
      setNoMakesFacilities({
        payload: {
          active: true,
        },
      })
    )
  }

  async getAARDetails(id: string): Promise<AARDetail> {
    try {
      const response = await this.xhr.request<AARDetail>({
        url: `club/aars/${id}`,
      })
      await AARDetailSchema.validate(response)
      return response
    } catch (error) {
      throw error
    }
  }
}
