import { Injectable } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { IndexedCollection } from 'src/app/shared/types'
import { indexCollection } from 'src/app/shared/utils'
import { AAAStore } from 'src/app/store/root-reducer'
import { XHRService } from '../../shared/services/xhr.service'
import { selectMaxTowMileLimit, selectModeConfiguration } from '../auth/auth.selectors'
import { VendorConfigurationMode } from '../auth/mode-configuration.types'
import { AARSchema } from '../location/aar/aar.model'
import { AARData } from '../location/aar/aar.types'


@Injectable({
  providedIn: 'root',
})
export class RapService {

  modeConfiguration: VendorConfigurationMode
  maxTowMileLimit: number

  constructor(
    private store$: Store<AAAStore>,
    private xhr: XHRService,
  ) {
    this.store$.pipe(select(selectModeConfiguration)).subscribe(modeConfiguration => this.modeConfiguration = modeConfiguration)
    this.store$.pipe(select(selectMaxTowMileLimit)).subscribe(maxTowMileLimit => this.maxTowMileLimit = maxTowMileLimit)
  }

  isRapUser(): boolean {
    return this.modeConfiguration !== null;
  }

  async getAarsFromTowDestination(aarRequestParams): Promise<IndexedCollection<AARData>> {
    try {
      const aars = await this.xhr.authRequest<Array<AARData>>({
        url: 'rap/tow-destinations',
        params: {
          ...aarRequestParams,
          limitBeyondRadius: this.modeConfiguration?.limitBeyondRadius,
          limitWithinRadius: this.modeConfiguration?.limitWithinRadius,
          radius: this.maxTowMileLimit,
        },
      })
      await AARSchema.validate({ aars })
      return indexCollection<AARData, 'id'>(aars, 'id')
    } catch (error) {
      throw error
    }
  }
}
