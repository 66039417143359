import { PayloadedAction } from '../../shared/types'
import {
  SET_SUBMIT_STEP,
  PASSENGERS_FORM,
  CONTACT_FORM,
  RESET_PASSENGERS,
} from './submit.actions'
import { Option } from '../ui/ui.types'
import { CANCEL_EDITING_REQUEST } from '../../shared/actions/shared.actions'
import {
  FormGroupState,
  validate,
  updateGroup,
  createFormGroupState,
  formGroupReducer,
  formStateReducer,
  SetValueAction,
} from 'ngrx-forms'
import { required, pattern, requiredTrue } from 'ngrx-forms/validation'

export interface Passengers {
  quantity: Option
  notes?: string
}

export interface UserContactInfo {
  contactNumber: string
  firstName: string
  lastName: string
  shouldTextUpdates?: boolean
  displayId?: boolean
}

export interface SubmitState {
  step: string
  contactInfo: FormGroupState<UserContactInfo>
  passengers: FormGroupState<Passengers>
}

export const validatePassengers = updateGroup<Passengers>({
  quantity: validate(required),
})

export const validateContactInfo = updateGroup<UserContactInfo>({
  contactNumber: validate(
    required,
    pattern(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/)
  ),
  firstName: validate(
    required,
    pattern(/[a-zA-Z]+/)
  ),
  lastName: validate(
    required,
    pattern(/[a-zA-Z]+/)
  ),
  displayId: validate(requiredTrue),
})

export const initialState: SubmitState = createInitialState()

function createInitialState(): SubmitState {
  return {
    step: '',
    contactInfo: createFormGroupState<UserContactInfo>(CONTACT_FORM, {
      contactNumber: '',
      firstName: '',
      lastName: '',
      shouldTextUpdates: true,
      displayId: false,
    }),
    passengers: createFormGroupState<Passengers>(PASSENGERS_FORM, {
      quantity: null,
      notes: '',
    }),
  }
}

export const passengerOptions: Option[] = [
  { label: '0' },
  { label: '1' },
  { label: '2' },
  { label: '3 OR MORE' },
]

export function reducer(
  state: SubmitState = initialState,
  action: PayloadedAction
) {
  const passengers = validatePassengers(
    formGroupReducer(state.passengers, action)
  )
  if (passengers !== state.passengers) {
    return {
      ...state,
      passengers,
    }
  }

  const contactInfo = validateContactInfo(
    formGroupReducer(state.contactInfo, action)
  )
  if (contactInfo !== state.contactInfo) {
    return {
      ...state,
      contactInfo,
    }
  }

  switch (action.type) {
    case SET_SUBMIT_STEP:
      return {
        ...state,
        step: action.payload.step,
      }
    case RESET_PASSENGERS:
      const resetControlViaAction = formStateReducer(
        passengers,
        new SetValueAction(passengers.id, {
          quantity: null,
          notes: '',
        })
      )
      return {
        ...state,
        passengers: resetControlViaAction,
      }
    case CANCEL_EDITING_REQUEST:
      // Allowing for a new initial state prevents corruption of the original initialState value
      return createInitialState()
    default:
      return state
  }
}
