import { Component } from '@angular/core'
import { LinkButtonComponent } from '../link-button/link-button.component';

@Component({
  selector: 'aria-link-button',
  templateUrl: './aria-link-button.component.html',
  styleUrls: ['./aria-link-button.component.scss'],
})
export class AriaLinkButtonComponent extends LinkButtonComponent {
}
