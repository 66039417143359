import {
  Address,
  Indicator,
  OperatingDay,
  Phone,
  BooleanAnswer,
} from 'src/app/shared/types'
import { LocationArea } from '../google-geocode/types'
import { GenericCoordinates } from '../location.types'

export interface DRRCoordinates {
  lat: string
  lon: string
}

export interface AARGenericData {
  type: string // TODO convert to enum
  content: string
}

export interface PublicAARAddress {
  stateProv: {
    code: string
  }
  cityName: string
  cityCode: string
  postalCode: string
  countryName: {
    code: string
    content: string
  }
  type: string
  addressLine: string
}

export interface PublicAARData {
  loccode: string
  images: Object
  addresses: {
    address: PublicAARAddress
  }
  sortName: string
  brands: {}
  serviceProviderCode: string
  operatingDays: {
    operatingDay: OperatingDay[]
  }
  phones: {
    phone: AARGenericData
  }
  vehicles: {
    vehicle: AARGenericData[]
  }
  services: {
    service: AARGenericData[]
  }
  type: string
  indicators: {
    napaInd: BooleanAnswer
    sycsStatusInd: BooleanAnswer
    onlineAppointmentsInd: BooleanAnswer
  }
  distanceTo: string
  discountLabor: string
  activityid: string
  emailAddress: string
  serviceOffers: {
    serviceOffer: string[] // TODO convert to enum
  }
  name: string
  links: {
    urlAddress: string
  }
  id: string
  position: GenericCoordinates
}

export interface AARData extends GenericCoordinates {
  id: number
  type: string
  name: string
  serviceProviderCode: string
  distanceTo: number
  indicators: Indicator
  address: Address
  phone: Phone
  operatingDays: OperatingDay[]
  serviceOffers: string[]
  vehicles: AARVehicle[]
  activityid: string
  loccode: string
  services?: AARGenericData[]
}

export interface AARDetail extends AARData {
  emailAddress: string
  webAddress: string
  discount: {
    offerDescription: string
    offerRestriction: string
  }
  compressedHours?: any[]
}

export interface AARVehicle {
  type: string
  value: string
}

export interface AARRequestParams extends LocationArea {
  make: string
  vehicleSlug: string
}

export const AAR_TYPES = ['AAR', 'COR', 'SPC']
export const COR_TYPE = 'COR'
