import { createAction, props } from '@ngrx/store'
import { AAACallStatus } from '../calls.types'
import { createAsyncConstants } from 'src/app/shared/utils'
import { ActionRequestParams, IndexedCollection } from 'src/app/shared/types'
import { DriverInfo, DriverInfoParams } from './call-status.types'

export const ACTIVE_CALL_STATUS = createAsyncConstants('ACTIVE_CALL_STATUS')
export const ACTIVE_CALL_DETAILS = createAsyncConstants('ACTIVE_CALL_DETAILS')
export const CALL_STATUS = createAsyncConstants('CALL_STATUS')
export const DRIVER_INFO = createAsyncConstants('DRIVER_INFO')
export const SET_ACTIVE_CALL_STATUS = 'SET_ACTIVE_CALL_STATUS'
export const CALL_STATUS_RESET = 'CALL_STATUS_RESET'

export const driverInfoSuccess = createAction(
  DRIVER_INFO.SUCCESS,
  props<{ payload: DriverInfo }>()
)

export const driverInfoRequest = createAction(
  DRIVER_INFO.REQUEST,
  props<{ payload: DriverInfoParams }>()
)

export const driverInfoFailure = createAction(DRIVER_INFO.FAILURE)

export const callStatusRequest = createAction(
  CALL_STATUS.REQUEST,
  props<{ payload: ActionRequestParams }>()
)

export const callStatusSuccess = createAction(
  CALL_STATUS.SUCCESS,
  props<{ payload: { data: IndexedCollection<AAACallStatus> } }>()
)

export const notifyCallStatusFailure = createAction(CALL_STATUS.FAILURE)

export const activeCallStatusRequest = createAction(
  ACTIVE_CALL_STATUS.REQUEST,
  props<{ payload: ActionRequestParams }>()
)

export const activeCallStatusSuccess = createAction(
  ACTIVE_CALL_STATUS.SUCCESS,
  props<{
    payload: {
      data: AAACallStatus[],
      params: ActionRequestParams
    }
  }>()
);

export const notifyActiveCallStatusFailure = createAction(ACTIVE_CALL_STATUS.FAILURE)

export const activeCallDetailsRequest = createAction(
  ACTIVE_CALL_DETAILS.REQUEST,
  props<{ payload: ActionRequestParams }>()
)

export const activeCallDetailsSuccess = createAction(
  ACTIVE_CALL_DETAILS.SUCCESS,
  props<{ payload: { data: IndexedCollection<AAACallStatus> } }>()
)

export const notifyActiveCallDetailsFailure = createAction(ACTIVE_CALL_DETAILS.FAILURE)

export const setActiveCallStatus = createAction(
  SET_ACTIVE_CALL_STATUS,
  props<{ payload: { id: string } }>()
)

export const resetCallStatus = createAction(CALL_STATUS_RESET)
