import {
  Component,
  HostBinding,
  Output,
  EventEmitter,
  Input,
} from '@angular/core'

@Component({
  selector: 'app-expanding-input',
  templateUrl: './expanding-input.component.html',
  styleUrls: ['./expanding-input.component.scss'],
})
export class ExpandingInputComponent {
  @HostBinding('class.active')
  active = false

  @Input() text = ''
  @Input() maxChars
  @Output() textChange: EventEmitter<string> = new EventEmitter<string>()

  toggleText() {
    this.active = !this.active
    return this.active
  }

  textInput($event) {
    this.text = $event.target.value
    this.textChange.emit($event.target.value)
  }
}
