<div class="dialog">
  <h1 mat-dialog-title id="dialog-title">Invalid membership</h1>
  <div id="dialog-content" mat-dialog-content>
    <p *ngIf="!isRapUser">
      We are having trouble verifying your membership, please try again. If the problem persists please contact us:
    </p>
    <p class="font-size-large" *ngIf="isRapUser">
      There seems to be a problem processing your request, for assistance please contact us at
    </p>
  </div>
  <div mat-dialog-actions>
    <app-call-aaa-button
      [clickEventPage]="pageType"
      [inverted]="true"
      (buttonClick)="onCallAAAClick()">
    </app-call-aaa-button>
  </div>
  <button mat-dialog-close class="mat-dialog-close" aria-label="dismiss this message">
    <icon-close></icon-close>
  </button>
</div>
