import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { filter, switchMap, take, tap, withLatestFrom } from 'rxjs/operators'
import { Store } from '@ngrx/store'
import { AAAStore } from 'src/app/store/root-reducer'
import { SessionService } from './session.service'
import { CHECK_SESSION_TIMEOUT, START_SESSION_TIMEOUT } from './session.actions'
import { selectLastInteraction } from './session.selectors'
import { selectIsSecure, selectIsSignedIn } from '../auth/auth.selectors'
import { IndexedCollection, PayloadedAction } from '../../shared/types'
import { openMessageDialog } from '../ui/ui.actions'
import { Dialog, MessageDialogTypes } from '../ui/ui.types'
import { selectActiveDialogs } from '../ui/ui.selectors'
import { MatDialog } from '@angular/material/dialog'
import { AuthSecurityWrapperService } from '../auth/auth-security-wrapper/auth-security-wrapper.service'

const TIMEOUT_LIMIT = 10 * 60 * 1000
const TIMEOUT_ALERT = 9 * 60 * 1000

@Injectable()
export class SessionEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<AAAStore>,
    private sessionService: SessionService,
    private authSecurityService: AuthSecurityWrapperService,
    private dialogService: MatDialog
  ) {}

  handleRefresh$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType('@ngrx/effects/init'),
        withLatestFrom(
          this.store$.select(selectIsSignedIn),
          this.store$.select(selectIsSecure)
        ),
        take(1),
        filter(
          ([_, signedIn, isSecure]: [PayloadedAction, boolean, boolean]) =>
            signedIn && !isSecure
        ),
        tap(() => this.sessionService.startSessionTimeout())
      ),
    { dispatch: false }
  )

  handleStartSessionTimeout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(START_SESSION_TIMEOUT),
      take(1),
      switchMap(() => {
        this.sessionService.startSessionTimeout()
        return []
      })
    )
  )

  handleCheckSessionTimeout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CHECK_SESSION_TIMEOUT),
      withLatestFrom(
        this.store$.select(selectLastInteraction),
        this.store$.select(selectActiveDialogs)
      ),
      switchMap(
        ([_, lastInteraction, dialogs]: [
          PayloadedAction,
          number,
          IndexedCollection<Dialog>
        ]) => {
          const now = new Date()
          const lastOpened = new Date(lastInteraction)
          const actions = []
          if (now.getTime() - lastOpened.getTime() >= TIMEOUT_LIMIT) {
            this.sessionService.stopSessionTimeout()
            this.dialogService.closeAll()
            // https://github.com/clevertech/aaa-drr-v2/pull/1869/files
            this.authSecurityService.forceLogout()
          } else if (
            !dialogs[MessageDialogTypes.SESSION_TIMEOUT] &&
            now.getTime() - lastOpened.getTime() >= TIMEOUT_ALERT
          ) {
            actions.push(
              openMessageDialog({
                payload: {
                  type: MessageDialogTypes.SESSION_TIMEOUT,
                  disableClose: true,
                },
              })
            )
          }
          return actions
        }
      )
    )
  )
}
