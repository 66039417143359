<div class="dialog dialog-error">
  <h1 mat-dialog-title>Notice</h1>
  <div id="dialog-content" mat-dialog-content>
    <p class="font-size-large">
      We are having trouble verifying your VIN number, please try again. If the problem persists please contact us:
    </p>
  </div>
  <div *ngIf="!data.params.hideActions" mat-dialog-actions>
    <app-call-aaa-button
      [clickEventPage]="pageType"
      (buttonClick)="onCallAAAClick()"
      >
    </app-call-aaa-button>
    <button 
      *ngIf="!!data.params.showDismissButton" 
      id="dialog-dismiss-submit" 
      name="dialogGenericErrorDismiss"
      class="btn primary btn-sm" 
      (click)="onClose()"
    >
      Dismiss
    </button>
  </div>
  <button mat-dialog-close class="mat-dialog-close" aria-label="dismiss this message">
    <icon-close></icon-close>
  </button>
</div>
