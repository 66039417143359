import { AbstractIconComponent } from './abstract.icon'
import { Component } from '@angular/core'

@Component({
  selector: 'icon-close',
  templateUrl: './close.icon.html',
})
export class CloseIconComponent extends AbstractIconComponent {
  static icon = 'close'
}
