<div class="loading-wrapper" aria-live="polite" aria-busy="true">
  <svg class="lds-spin" [attr.width]="width" [attr.height]="height" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid"
    style="background: none;">
    <g transform="translate(80,50)">
      <g transform="rotate(0)">
        <circle cx="0" cy="0" r="4" [attr.fill]="(colorName ? (colorName | color) : color)" fill-opacity="1"
          transform="scale(1.15121 1.15121)">
          <animateTransform attributeName="transform" type="scale" begin="-0.63s" values="2 2;1 1" keyTimes="0;1"
            dur="0.7s" repeatCount="indefinite"></animateTransform>
          <animate attributeName="fill-opacity" keyTimes="0;1" dur="0.7s" repeatCount="indefinite" values="1;0"
            begin="-0.63s"></animate>
        </circle>
      </g>
    </g>
    <g transform="translate(74.27050983124843,67.6335575687742)">
      <g transform="rotate(36)">
        <circle cx="0" cy="0" r="4" [attr.fill]="(colorName ? (colorName | color) : color)" fill-opacity="0.9"
          transform="scale(1.25121 1.25121)">
          <animateTransform attributeName="transform" type="scale" begin="-0.5599999999999999s" values="2 2;1 1"
            keyTimes="0;1" dur="0.7s" repeatCount="indefinite"></animateTransform>
          <animate attributeName="fill-opacity" keyTimes="0;1" dur="0.7s" repeatCount="indefinite" values="1;0"
            begin="-0.5599999999999999s"></animate>
        </circle>
      </g>
    </g>
    <g transform="translate(59.270509831248425,78.53169548885461)">
      <g transform="rotate(72)">
        <circle cx="0" cy="0" r="4" [attr.fill]="(colorName ? (colorName | color) : color)" fill-opacity="0.8"
          transform="scale(1.35121 1.35121)">
          <animateTransform attributeName="transform" type="scale" begin="-0.48999999999999994s" values="2 2;1 1"
            keyTimes="0;1" dur="0.7s" repeatCount="indefinite"></animateTransform>
          <animate attributeName="fill-opacity" keyTimes="0;1" dur="0.7s" repeatCount="indefinite" values="1;0"
            begin="-0.48999999999999994s"></animate>
        </circle>
      </g>
    </g>
    <g transform="translate(40.72949016875158,78.53169548885461)">
      <g transform="rotate(108)">
        <circle cx="0" cy="0" r="4" [attr.fill]="(colorName ? (colorName | color) : color)" fill-opacity="0.7"
          transform="scale(1.45121 1.45121)">
          <animateTransform attributeName="transform" type="scale" begin="-0.41999999999999993s" values="2 2;1 1"
            keyTimes="0;1" dur="0.7s" repeatCount="indefinite"></animateTransform>
          <animate attributeName="fill-opacity" keyTimes="0;1" dur="0.7s" repeatCount="indefinite" values="1;0"
            begin="-0.41999999999999993s"></animate>
        </circle>
      </g>
    </g>
    <g transform="translate(25.72949016875158,67.6335575687742)">
      <g transform="rotate(144)">
        <circle cx="0" cy="0" r="4" [attr.fill]="(colorName ? (colorName | color) : color)" fill-opacity="0.6"
          transform="scale(1.55121 1.55121)">
          <animateTransform attributeName="transform" type="scale" begin="-0.35s" values="2 2;1 1" keyTimes="0;1"
            dur="0.7s" repeatCount="indefinite"></animateTransform>
          <animate attributeName="fill-opacity" keyTimes="0;1" dur="0.7s" repeatCount="indefinite" values="1;0"
            begin="-0.35s"></animate>
        </circle>
      </g>
    </g>
    <g transform="translate(20,50.00000000000001)">
      <g transform="rotate(180)">
        <circle cx="0" cy="0" r="4" [attr.fill]="(colorName ? (colorName | color) : color)" fill-opacity="0.5"
          transform="scale(1.65121 1.65121)">
          <animateTransform attributeName="transform" type="scale" begin="-0.27999999999999997s" values="2 2;1 1"
            keyTimes="0;1" dur="0.7s" repeatCount="indefinite"></animateTransform>
          <animate attributeName="fill-opacity" keyTimes="0;1" dur="0.7s" repeatCount="indefinite" values="1;0"
            begin="-0.27999999999999997s"></animate>
        </circle>
      </g>
    </g>
    <g transform="translate(25.729490168751575,32.366442431225806)">
      <g transform="rotate(216)">
        <circle cx="0" cy="0" r="4" [attr.fill]="(colorName ? (colorName | color) : color)" fill-opacity="0.4"
          transform="scale(1.75121 1.75121)">
          <animateTransform attributeName="transform" type="scale" begin="-0.20999999999999996s" values="2 2;1 1"
            keyTimes="0;1" dur="0.7s" repeatCount="indefinite"></animateTransform>
          <animate attributeName="fill-opacity" keyTimes="0;1" dur="0.7s" repeatCount="indefinite" values="1;0"
            begin="-0.20999999999999996s"></animate>
        </circle>
      </g>
    </g>
    <g transform="translate(40.729490168751575,21.468304511145394)">
      <g transform="rotate(252)">
        <circle cx="0" cy="0" r="4" [attr.fill]="(colorName ? (colorName | color) : color)" fill-opacity="0.3"
          transform="scale(1.85121 1.85121)">
          <animateTransform attributeName="transform" type="scale" begin="-0.13999999999999999s" values="2 2;1 1"
            keyTimes="0;1" dur="0.7s" repeatCount="indefinite"></animateTransform>
          <animate attributeName="fill-opacity" keyTimes="0;1" dur="0.7s" repeatCount="indefinite" values="1;0"
            begin="-0.13999999999999999s"></animate>
        </circle>
      </g>
    </g>
    <g transform="translate(59.27050983124842,21.46830451114539)">
      <g transform="rotate(288)">
        <circle cx="0" cy="0" r="4" [attr.fill]="(colorName ? (colorName | color) : color)" fill-opacity="0.2"
          transform="scale(1.95121 1.95121)">
          <animateTransform attributeName="transform" type="scale" begin="-0.06999999999999999s" values="2 2;1 1"
            keyTimes="0;1" dur="0.7s" repeatCount="indefinite"></animateTransform>
          <animate attributeName="fill-opacity" keyTimes="0;1" dur="0.7s" repeatCount="indefinite" values="1;0"
            begin="-0.06999999999999999s"></animate>
        </circle>
      </g>
    </g>
    <g transform="translate(74.27050983124842,32.3664424312258)">
      <g transform="rotate(324)">
        <circle cx="0" cy="0" r="4" [attr.fill]="(colorName ? (colorName | color) : color)" fill-opacity="0.1"
          transform="scale(1.05121 1.05121)">
          <animateTransform attributeName="transform" type="scale" begin="0s" values="2 2;1 1" keyTimes="0;1" dur="0.7s"
            repeatCount="indefinite"></animateTransform>
          <animate attributeName="fill-opacity" keyTimes="0;1" dur="0.7s" repeatCount="indefinite" values="1;0"
            begin="0s"></animate>
        </circle>
      </g>
    </g>
  </svg>
</div>
