import { createAction, props } from '@ngrx/store'
import { createAsyncConstants } from '../../shared/utils/createAsyncConstants'
import {
  AgentSettingsParams,
  AuthMethods,
  AuthRequestParams,
  AuthResponse,
  RapAuthFailure,
  SearchSigninParams,
  SecureAuthRequestParams,
  SetAuthParams,
  SetRapAuthParams,
} from './auth.types'

export const AUTH = createAsyncConstants('AUTH')
export const AUTH_VAS = createAsyncConstants('AUTH_VAS')
export const AUTH_RENTAL = createAsyncConstants('AUTH_RENTAL')
export const AUTH_OEM = createAsyncConstants('AUTH_OEM')
export const AUTH_RESET = 'AUTH_RESET'
export const SET_AUTH = 'SET_AUTH'
export const SET_RAP_AUTH = 'SET_RAP_AUTH'
export const SET_AUTH_METHOD = 'SET_AUTH_METHOD'
export const SET_SECURE_PARAMS = 'SET_SECURE_PARAMS'
export const AUTH_NAME_SEARCH = createAsyncConstants('AUTH_NAME_SEARCH')
export const SET_IS_AGENT = 'SET_IS_AGENT'
export const SET_AGENT_SETTINGS = 'SET_AGENT_SETTINGS'

// start ARR:POC - Agent settings actions commits the agent settings to state.
export const setAgent = createAction(
  SET_IS_AGENT,
  props<{ payload: boolean }>()
)

export const setAgentSettings = createAction(
  SET_AGENT_SETTINGS,
  props<{ payload: AgentSettingsParams }>()
)
// end ARR:POC

export const assignSecureParams = createAction(
  SET_SECURE_PARAMS,
  props<{ payload: SecureAuthRequestParams }>()
)

export const requestAuth = createAction(
  AUTH.REQUEST,
  props<{ payload: AuthRequestParams }>()
)

export const authSuccess = createAction(
  AUTH.SUCCESS,
  props<{ payload: AuthResponse }>()
)

export const notifyAuthFailure = createAction(AUTH.FAILURE)
export const notifyOemAuthFailure = createAction(AUTH_OEM.FAILURE)
export const notifyRentalAuthFailure = createAction(AUTH_RENTAL.FAILURE)
export const notifyVasAuthFailure = createAction(AUTH_VAS.FAILURE)

export const setAuth = createAction(
  SET_AUTH,
  props<{ payload: SetAuthParams }>()
)

export const setRapAuth = createAction(
  SET_RAP_AUTH,
  props<{ payload: SetRapAuthParams }>()
)

export const setAuthMethod = createAction(
  SET_AUTH_METHOD,
  props<{ payload: AuthMethods }>()
)

export const resetAuth = createAction(AUTH_RESET)

export const authNameSearchRequest = createAction(
  AUTH_NAME_SEARCH.REQUEST,
  props<{ payload: SearchSigninParams }>()
)





export const authVasRequest = createAction(
  AUTH_VAS.REQUEST,
  props<{ payload: AuthRequestParams }>()
)
export const authVasSuccess = createAction(
  AUTH_VAS.SUCCESS,
  props<{ payload: AuthResponse }>()
)
export const notifyAuthVasFailure = createAction(
  AUTH_VAS.FAILURE
)


export const authRentalRequest = createAction(
  AUTH_RENTAL.REQUEST,
  props<{ payload: AuthRequestParams }>()
)
export const authRentalSuccess = createAction(
  AUTH_RENTAL.SUCCESS,
  props<{ payload: AuthResponse }>()
)
export const notifyAuthRentalFailure = createAction(
  AUTH_RENTAL.FAILURE,
)


export const authOemRequest = createAction(
  AUTH_OEM.REQUEST,
  props<{ payload: AuthRequestParams }>()
)
export const authOemSuccess = createAction(
  AUTH_OEM.SUCCESS,
  props<{ payload: AuthResponse }>()
)
export const notifyRapAuthFailure = createAction(
  AUTH_OEM.FAILURE,
  props<{ payload: RapAuthFailure }>()
)





export const authNameSearchSuccess = createAction(
  AUTH_NAME_SEARCH.SUCCESS,
  props<{ payload: AuthResponse }>()
)

export const notifyAuthNameSearchFailure = createAction(
  AUTH_NAME_SEARCH.FAILURE
)
