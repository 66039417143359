<section
  id="credentials-entry"
  class="minimal-credentials"
  [ngClass]="{
    entry: !(isSecure$ | async),
    placeholder: isSecure$ | async
  }"
>
<div class="tab-container" *ngIf="showAuthOptionButton$ | async">
  <app-button
    [ngClass]="{selected: isNumberTabActive}"
    class="flex-grow"
    classNames="tab"
    [active]="isNameTabActive"
    id="tab-use-member-name"
    (buttonClick)="useMemberName()"
    type="button"
    [tabIndex]="(13 + tabIndexPosition)">
    Member Name
  </app-button>
  <app-button
    [ngClass]="{selected: isNumberTabActive}"
    class="flex-grow"
    classNames="tab"
    [active]="isNumberTabActive"
    id="tab-use-member-number"
    (buttonClick)="useMemberNumber()"
    type="button"
    [tabIndex]="(13 + tabIndexPosition)"
  >
     Member Number
  </app-button>
</div>
  <form
    id="signon-form"
    (ngSubmit)="formState.isValid && handleSubmit()"
    class="minimal-credentials_form"
    novalidate
    [ngrxFormState]="formState"
  >
    <ng-container *ngIf="!(isSecure$ | async)">
      <app-form-error-summary [controls]="controls">
        <app-form-error
          [control]="controls?.membershipNumber"
          name='membershipNumber'
          type='required'
        >
          Membership number is required.
        </app-form-error>
        <app-form-error
          [control]="controls?.membershipNumber"
          name='membershipNumber'
          type='pattern'
        >
          Membership number must be exactly 16 characters long.
        </app-form-error>
        <app-form-error
          [control]="controls?.zipCode"
          name='zipCode'
          type='required'
        >
          Zip Code is required.
        </app-form-error>
        <app-form-error
          [control]="controls?.zipCode"
          name='zipCode'
          type='minLength'
        >
          Zip Code must be 5 characters long.
        </app-form-error>
        <app-form-error
          [control]="controls?.zipCode"
          name='zipCode'
          type='maxLength'
        >
          Zip Code must be 5 characters long.
        </app-form-error>

        <div
        class="form-error text-error small-text"
        *ngIf="captchaError"
        >
        You must confirm you are not a robot.
        </div>
      </app-form-error-summary>
      <ng-container *ngIf="allowNameAuth; else membershipLines">
        <div class="minimal-credentials_membership-line" role="navigation" aria-labelledby="membership-name">
          <label for="firstName" id="membership-name">First Name *</label>
          <aria-link-button
            elementId="use-member-number"
            class="minimal-credentials_no-membership color-black  link-use-member-number"
            (clickLink)="useMemberNumber($event)"
            [tabIndex]="(1 + tabIndexPosition)"
            *ngIf="!(showAuthOptionButton$ | async)">
            Use Member Number
          </aria-link-button>
        </div>
        <input
          name="firstName"
          id="firstName"
          type="text"
          [ngrxFormControlState]="controls.firstName"
          placeholder="First name"
          [tabIndex]="(2 + tabIndexPosition)"
          aria-required="true"
          required
        />
        <label id="last-name-label" for="lastName">Last Name *</label>
        <input
          name="lastName"
          id="lastName"
          type="text"
          [ngrxFormControlState]="controls.lastName"
          placeholder="Last name"
          [tabIndex]="(3 + tabIndexPosition)"
          aria-required="true"
          required
        />
      </ng-container>

      <ng-template #membershipLines>
        <div class="minimal-credentials_membership-line"  role="navigation" aria-labelledby="membership-number">
          <label for="membershipNumber" id="membership-number">Membership Number *</label>
          <aria-link-button 
          elementId="use-member-name"
          class="minimal-credentials_no-membership color-black  link-use-member-name"
          (clickLink)="useMemberName($event)"
          [tabIndex]="(9 + tabIndexPosition)"
          *ngIf="!(showAuthOptionButton$ | async)">
            Use Member Name
          </aria-link-button>
        </div>
        <input
          name="membershipNumber"
          id="membershipNumber"
          type="tel"
          [textMask]="{ mask: mask, keepCharPositions: true }"
          [ngrxFormControlState]="controls.membershipNumber"
          placeholder="16 digit number on your AAA card"
          pattern="^\d{3}\s{2}\d{3}\s{2}\d{9}\s{2}\d$"
          [tabIndex]="(10 + tabIndexPosition)"
          aria-required="true"
          required
        />
        <app-form-error
          [control]="controls?.membershipNumber"
          name='membershipNumber'
          type='required'
        >
          Membership number is required.
        </app-form-error>
        <app-form-error
          [control]="controls?.membershipNumber"
          name='membershipNumber'
          type='pattern'
        >
          Membership number must be exactly 16 characters long.
        </app-form-error>
      </ng-template>

      <div class="minimal-credentials_postal-code-line">
        <label for="zipCode">Home Zip Code *</label>
        <input
          name="zipCode"
          id="zipCode"
          type="tel"
          placeholder="Enter your code"
          minLength="5"
          maxlength="7"
          [tabIndex]="(11 + tabIndexPosition)"
          [ngrxFormControlState]="controls.zipCode"
          aria-required="true"
          required
        />

        <app-form-error
          [control]="controls?.zipCode"
          name='zipCode'
          type='required'
        >
          Zip Code is required.
        </app-form-error>
        <app-form-error
          [control]="controls?.zipCode"
          name='zipCode'
          type='minLength'
        >
          Zip Code must be 5 characters long.
        </app-form-error>
        <app-form-error
          [control]="controls?.zipCode"
          name='zipCode'
          type='maxLength'
        >
          Zip Code must be 5 characters long.
        </app-form-error>
      </div>

      <ng-container *ngIf="displayCaptcha && !showInvisibleCaptcha">
        <ngx-recaptcha2
          #captchaElementSimple
          [siteKey]="siteKey"
          (ready)="handleCaptchaReady()"
          (success)="handleCaptchaSuccessSimple($event)"
          (error)="handleCaptchaError()"
          [useGlobalDomain]="false"
          type="checkbox"
          [ngModel]="captcha"
          [ngModelOptions]="{ standalone: true }"
        >
        </ngx-recaptcha2>
      </ng-container>
      <div
      class="form-error text-error small-text"
      *ngIf="captchaError"
      >
      You must confirm you are not a robot.
      </div>
      <app-button
        [disabled]="(isSigningIn$ | async)"
        type="submit"
        [loading]="(isSigningIn$ | async) === true"
        [tabIndex]="(12 + tabIndexPosition)"
      >
        Confirm
      </app-button>
      <section class="instructions-container" *ngIf="nameZipInstructions$ | async">
        <app-button
        [disabled]="(isSigningIn$ | async)"
        [loading]="(isSigningIn$ | async) === true"
        [tabIndex]="(12 + tabIndexPosition)"
        [size]="'condensed'"
        [color]="'success'"
        (buttonClick)="openNotMember($event)"
        >
        NOT A MEMBER?
      </app-button>
        <app-button
        [disabled]="(isSigningIn$ | async)"
        [loading]="(isSigningIn$ | async) === true"
        [tabIndex]="(12 + tabIndexPosition)"
        [size]="'condensed'"
        [color]="'success'"
        (buttonClick)="openCannotLogin($event)"
        >
        CAN'T LOGIN?
      </app-button>
    </section>
      <ng-container *ngIf="previousShowAuthOptionButton">
        <div class="auth-divider">
          <div class="line"><mat-divider></mat-divider></div>
          <div class="text mat-typography">Or</div>
          <div class="line"><mat-divider></mat-divider></div>
        </div>
        <ng-container *ngIf="allowNameAuth; else numberAuth">
          <app-button
            class="button-use-member-number"
            (buttonClick)="useMemberNumber()"
            type="submit"
            [tabIndex]="(13 + tabIndexPosition)"
          >
            Use Membership Number
          </app-button>
        </ng-container>
        <ng-template #numberAuth>
          <app-button
            class="button-use-member-name"
            (buttonClick)="useMemberName()"
            type="submit"
            [tabIndex]="(13 + tabIndexPosition)"
          >
            Use Membership Name
          </app-button>
        </ng-template>
      </ng-container>
      <ng-container *ngIf="displayCaptcha && showInvisibleCaptcha">
        <ngx-invisible-recaptcha
          #captchaElement
          [siteKey]="siteKey"
          (ready)="handleCaptchaReady()"
          (success)="handleCaptchaSuccess($event)"
          (error)="handleCaptchaError()"
          [useGlobalDomain]="false"
          type="image"
          [badge]="'bottomright'"
          [ngModel]="captcha"
          [ngModelOptions]="{ standalone: true }"
        >
        </ngx-invisible-recaptcha>
      </ng-container>
    </ng-container>
  </form>

<ng-container *ngIf="!(nameZipInstructions$ | async)">
  <ng-container *ngIf="displayNotMember">
    <div
      *ngIf="!(isSecure$ | async)"
      class="minimal-credentials_not-a-member color-black "
      role="navigation"
      aria-labelledby="login-help"
    >
      <div id="login-help">
        <ng-container *ngIf="!allowNameAuth">
          <aria-link-button
            class="link-forgot-member-number"
            (clickLink)="openCannotRemember($event)"
            elementId="forgot-member-number"
            [tabIndex]="(23 + tabIndexPosition)">
            Forgot Member Number
          </aria-link-button>
          |
        </ng-container>
        <aria-link-button
          (clickLink)="openNotMember($event)"
          class="minimal-credentials_not-a-member-dialog"
          elementId="not-a-member"
          [tabIndex]="(24 + tabIndexPosition)">
          Not a Member?
        </aria-link-button>
      </div>
    </div>

  </ng-container>
</ng-container>

  <div class="minimal-credentials_terms">
    By selecting Confirm, you accept the
  </div>

  <div class="minimal-credentials_terms" role="navigation" aria-labelledby="terms">
    <aria-link-button
      elementId="terms"
      (clickLink)="openTerms()"
      class="terms-and-conditions__link"
      [tabIndex]="(24 + tabIndexPosition)">
      Terms and Conditions
    </aria-link-button>
  </div>

  <ng-container *ngIf="!(isSecure$ | async) && isWebApp()">
    <div class="privacy-policy" id="privacy-policy"  role="navigation" aria-labelledby="privacy-policy">
      <aria-link-button
        (clickLink)="openPrivacyPolicy()"
        class="privacy-policy__link"
        [tabIndex]="(25 + tabIndexPosition)"
        elementId="privacy-policy-link"
      >
        Privacy Policy
      </aria-link-button>
    </div>
  </ng-container>
</section>
