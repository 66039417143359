import { createAsyncConstants } from 'src/app/shared/utils'
import { createAction, props } from '@ngrx/store'
import { AARData, AARRequestParams } from './aar.types'
import { IndexedCollection } from 'src/app/shared/types'

export const AAR_LOAD = createAsyncConstants('AAR_LOAD')
export const AAR_LOAD_OPEN_AARS = 'AAR_LOAD_OPEN_AARS'
export const AAR_RESET = 'AAR_RESET'
export const AAR_DETAIL = createAsyncConstants('AAR_DETAIL')
export const INCREMENT_AARS_SHOWN = 'INCREMENT_AARS_SHOWN'
export const SHOW_OPEN_AARS_ONLY = 'SHOW_OPEN_AARS_ONLY'
export const SHOW_ALL_AARS = 'SHOW_ALL_AARS'
export const NO_MAKE_FACILITIES = 'NO_MAKE_FACILITIES'

export const requestMinimumAars = createAction(AAR_LOAD_OPEN_AARS)

export const requestAars = createAction(
  AAR_LOAD.REQUEST,
  props<{ payload: AARRequestParams }>()
)

export const completeAarLoad = createAction(
  AAR_LOAD.SUCCESS,
  props<{
    payload: {
      [id: string]: IndexedCollection<AARData>
    }
  }>()
)

export const notifyAarLoadFailure = createAction(AAR_LOAD.FAILURE)

export const requestAarDetail = createAction(
  AAR_DETAIL.REQUEST,
  props<{ payload: number | string }>()
)

export const completeAarDetail = createAction(
  AAR_DETAIL.SUCCESS,
  props<{ payload: AARData }>()
)

export const notifyAarDetailFailure = createAction(AAR_DETAIL.FAILURE)

export const resetAar = createAction(AAR_RESET)

export const incrementAarsShown = createAction(INCREMENT_AARS_SHOWN)

export const showOpenAARsOnly = createAction(SHOW_OPEN_AARS_ONLY)

export const showAllAARs = createAction(SHOW_ALL_AARS)

export const setNoMakesFacilities = createAction(
  NO_MAKE_FACILITIES,
  props<{ payload: { active: boolean } }>()
)
