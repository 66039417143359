export default {
  MENU_PAGE_TYPE: 'Top Menu',
  PRIVACY_POLICY_CLICK: 'Privacy Policy',
  TERMS_AND_CONDITIONS_CLICK: 'Terms and Conditions',
  CALL_AAA_CLICK: 'Call AAA',
  APP_INSTALL_CLICK: 'App Install',
  BACK_BUTTON_PAGE_TYPE:  'Back button',
  BACK_FROM_ISSUE_PAGE: 'Back Issue',
  BACK_FROM_LOCATION_PAGE: 'Back Location',
  BACK_FROM_VEHICLE_PAGE: 'Back Vehicle',
  BACK_FROM_VEHICLE_MAKES_PAGE: 'Back Vehicle - Section Makes',
  BACK_FROM_VEHICLE_MODELS_PAGE: 'Back Vehicle - Section Models',
  BACK_FROM_VEHICLE_DETAILS_PAGE: 'Back Vehicle - Section Details',
  BACK_FROM_TOWTO_PAGE: 'Back Tow to',
  SESSION_EXPIRED_PAGE_TYPE: 'Session Expired Issue',
  SESSION_EXPIRED_PROMPT: 'Session Expired Issue Prompt',
  CALL_AAA_PAGE_TYPE: 'Call AAA Error Popup',
  CALL_AAA_PROMPT: 'Call AAA Error Popup Prompt',
  NOT_A_MEMBER_PAGE_TYPE: 'Not a Member',
  LOST_MEMBERSHIP_PAGE_TYPE: 'Don\'t Have Membership Number',
  CALL_CANCEL_CONFIRM_PAGE_TYPE: 'Call Cancelled',
  CALL_COMPLETE_CONFIRM_PAGE_TYPE: 'Call Completed',
  UNSUPPORTED_BROWSER_PAGE_TYPE: 'Unsupported Browser Error - Prompt'
}
