import {
  AUTH,
  AUTH_RESET,
  SET_AUTH,
  SET_SECURE_PARAMS,
  AUTH_NAME_SEARCH,
  SET_AUTH_METHOD,
  SET_IS_AGENT,
  SET_AGENT_SETTINGS,
  AUTH_OEM,
  AUTH_VAS,
  AUTH_RENTAL,
  SET_RAP_AUTH,
} from './auth.actions'
import { PayloadedAction } from '../../shared/types'
import { AuthMethods, AgentSettingsParams } from './auth.types'
import { LOAD_MODE_CONFIGURATION } from './mode-configuration.actions'
import { VendorConfigurationMode } from './mode-configuration.types'
import { Eligibility } from './eligibility.types'

export interface AuthState {
  club: string
  accessToken: string
  expiresIn: number
  isSecure: boolean
  method: AuthMethods
  memberNumber?: string
  memId?: string
  ets?: string
  // start ARR:POC - The agent state
  isAgent: boolean
  agentSettings?: AgentSettingsParams
  // end ARR:POC
  modeConfig: VendorConfigurationMode
  eligibility: Eligibility
}

export const initialState = {
  club: '',
  accessToken: '',
  expiresIn: 0,
  isSecure: false,
  isAgent: false,
  agentSettings: null,
  method: AuthMethods.MEMBER_NAME,
  modeConfig: null,
}

export function reducer(state = initialState, action: PayloadedAction) {
  switch (action.type) {
    case SET_SECURE_PARAMS:
      return {
        ...state,
        memId: action.payload.memId,
        ets: action.payload.ets,
        isSecure: true,
        method: AuthMethods.AAA_NATIONAL,
      }
    case SET_AUTH:
      return {
        ...state,
        club: action.payload.club,
        accessToken: action.payload.access_token,
        isSecure: true,
        method: AuthMethods.AAA_TOKEN,
      }
    case SET_RAP_AUTH:
      return {
        ...state,
        accessToken: action.payload.access_token,
        isSecure: action.payload.isSecure,
        method: action.payload.method,
      }
    case SET_AUTH_METHOD:
      return {
        ...state,
        method: action.payload,
      }

    case LOAD_MODE_CONFIGURATION.SUCCESS:
      return {
        ...state,
        modeConfig: action.payload,
      }

    case AUTH_OEM.SUCCESS:
    case AUTH_RENTAL.SUCCESS:
    case AUTH_VAS.SUCCESS:
      return {
        ...state,
        accessToken: action.payload.access_token,
        expiresIn: action.payload.expires_in,
        isSecure: action.payload.isSecure,
        method: action.payload.method || state.method,
        eligibility: action.payload.eligibility,
        club: action.payload.club,
      }

    case AUTH.SUCCESS:
    case AUTH_NAME_SEARCH.SUCCESS:
      return {
        ...state,
        club: action.payload.club,
        accessToken: action.payload.access_token,
        expiresIn: action.payload.expires_in,
        isSecure: action.payload.isSecure,
        method: action.payload.method || state.method,
      }
    case AUTH.REQUEST:
      return {
        ...state,
        isSecure: action.payload.isSecure,
        memberNumber: action.payload.memberNumber,
        method: action.payload.method || state.method,
      }
    case AUTH.FAILURE:
      return {
        ...state,
        isSecure: false,
      }
    case AUTH_RESET:
      return {
        ...initialState,
        modeConfig: state.modeConfig,
        method: state.method,
      }
    // start ARR:POC - The agent state
    case SET_IS_AGENT:
      return {
        ...state,
        isAgent: true,
      }
    case SET_AGENT_SETTINGS:
      return {
        ...state,
        agentSettings: action.payload,
      }
    // end ARR:POC
    default:
      return state
  }
}
