import {
  MemberState,
  reducer as member,
} from '../modules/member/member.reducer'
import { reducer as auth, AuthState } from '../modules/auth/auth.reducer'
import {
  reducer as paceSetter,
  PaceSetterState,
} from '../modules/issue/issue.reducer'
import { reducer as loading } from '../modules/ui/loading/loading.reducer'
import {
  reducer as location,
  LocationState,
} from '../modules/location/location.reducer'
import {
  reducer as vehicle,
  VehicleState,
} from '../modules/vehicle/vehicle.reducer'
import {
  reducer as submit,
  SubmitState,
} from '../modules/submit/submit.reducer'
import {
  reducer as towLocation,
  TowLocationState,
} from '../modules/location/tow-location/tow-location.reducer'
import { reducer as call } from '../modules/dashboard/calls.reducer'
import {
  reducer as callsStatuses,
  AAACallsStatusesState,
} from '../modules/dashboard/calls-statuses/call-status.reducer'
import { reducer as ui, UIState } from '../modules/ui/ui.reducer'
import { reducer as forms, FormsState } from '../modules/ui/forms/forms.reducer'
import { reducer as lastInteraction } from './last-interaction.reducer'
import * as fromRouter from '@ngrx/router-store'
import { routerReducer } from '@ngrx/router-store'
import { reducer as aar, AARState } from '../modules/location/aar/aar.reducer'
import {
  reducer as clubs,
  ClubState,
} from '../modules/dashboard/clubs/clubs.reducer'
import { AAACallState } from '../modules/dashboard/calls.reducer'
import {
  reducer as wizard,
  WizardState,
} from '../modules/wizard/wizard.reducer'
import {
  reducer as tagging,
  TaggingState,
} from '../modules/tagging/tagging.reducer'
import {
  reducer as config,
  ConfigState,
} from '../modules/config/config.reducer'
import {
  reducer as quotes,
  AAAQuotesServicesState,
} from '../modules/quotes/quotes.reducer'
import {
  reducer as servicingClub,
  ServicingClubState,
} from '../modules/servicing-club/servicing-club.reducer'
import {
  reducer as advisories,
  AdvisoriesState,
} from '../modules/advisories/advisories.reducer'
import {
  reducer as routesDistance,
  RouteDistancesState,
} from '../modules/route-distance/route-distance.reducer'

import { environment } from '../../environments/environment'
import {
  reducer as session,
  SessionState,
} from '../modules/session/session.reducer'

const rootReducers = {
  aar,
  auth,
  clubs,
  member,
  paceSetter,
  loading,
  location,
  ui,
  forms,
  submit,
  towLocation,
  vehicle,
  lastInteraction,
  call,
  callsStatuses,
  router: routerReducer,
  tagging,
  wizard,
  quotes,
  servicingClub,
  session,
  advisories,
  routesDistance,
}

export const reducers = {
  ...(environment.configTools
    ? {
        ...rootReducers,
        config,
      }
    : rootReducers),
}

export interface AAAStore {
  store: ConfigState
  aar: AARState
  auth: AuthState
  clubs: ClubState
  member: MemberState
  paceSetter: PaceSetterState
  loading: any
  location: LocationState
  ui: UIState
  forms: FormsState
  submit: SubmitState
  towLocation: TowLocationState
  vehicle: VehicleState
  session: SessionState
  router: fromRouter.RouterReducerState<any>
  call: AAACallState
  callsStatuses: AAACallsStatusesState
  tagging: TaggingState
  wizard: WizardState
  config?: ConfigState
  quotes?: AAAQuotesServicesState
  servicingClub?: ServicingClubState
  advisories?: AdvisoriesState
  routesDistance: RouteDistancesState
}
