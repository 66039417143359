import { createAction, props } from '@ngrx/store'

import {
  Step,
  Dialog,
  MessageDialog,
  ErrorDialog,
  PromptDialog,
} from './ui.types'
import { createAsyncConstants } from 'src/app/shared/utils'
import { TransitionEvent, NavigationEvent } from 'src/app/shared/types'
import { GoogleAddress } from '../location/google-geocode/types'
import { Vehicle } from '../member/member.types'

export enum DIALOG_OPEN {
  MESSAGE = 'DIALOG_OPEN.MESSAGE',
  ERROR = 'DIALOG_OPEN.ERROR',
  PROMPT = 'DIALOG_OPEN.PROMPT',
}

export const DIALOG_CLOSE = 'DIALOG_CLOSE'
export const DIALOG_RESET = 'DIALOG_RESET'

export const PENDING_STEP = 'PENDING_STEP'
export const EDIT_STEP = 'EDIT_STEP'
export const ACTIVE_STEP = 'ACTIVE_STEP'
export const DONE_STEP = 'DONE_STEP'

export const SET_SPLASHSCREEN_STEP = 'SET_SPLASHSCREEN_STEP'
export const HIDE_SPLASHSCREEN = 'HIDE_SPLASHSCREEN'
export const SHOW_SPLASHSCREEN = 'SHOW_SPLASHSCREEN'
export const ANIMATE_SPLASHSCREEN_HIDE = 'ANIMATE_SPLASHSCREEN_HIDE'

export const SET_SHOW_PWA = 'SET_SHOW_PWA'
export const SET_SHOW_MENU = 'SET_SHOW_MENU'
export const SET_TRANSITIONING = 'SET_TRANSITIONING'
export const SET_ALLOW_NAME_AUTH = 'SET_ALLOW_NAME_AUTH'
export const SET_SHOW_BACK = 'SET_SHOW_BACK'
export const SET_SHOW_EXPANDED_DIALOG = 'SET_SHOW_EXPANDED_DIALOG'
export const SET_ISSUE_SELECTION_REVAMP = 'SET_ISSUE_SELECTION_REVAMP'
export const SET_SHOW_BOTTOM_NAVIGATION = 'SET_SHOW_BOTTOM_NAVIGATION'
export const SET_APPLICATION_TEXT_UPDATES = 'SET_APPLICATION_TEXT_UPDATES'
export const SET_TOW_REDESIGN = 'SET_TOW_REDESIGN'
export const SET_HIDE_NOTES_ON_NEED_TOW = 'SET_HIDE_NOTES_ON_NEED_TOW'
export const SET_SHOW_AUTH_OPTION_BUTTON = 'SET_SHOW_AUTH_OPTION_BUTTON'
export const SET_SHOW_KEEP_ME_INFORMED = 'SET_SHOW_KEEP_ME_INFORMED'
export const SET_SIMPLE_CAPTCHA = 'SET_SIMPLE_CAPTCHA'
export const SET_IS_CAPTCHA_REQUIRED = 'SET_IS_CAPTCHA_REQUIRED'
export const SET_NAME_ZIP_INSTRUCTIONS = 'SET_NAME_ZIP_INSTRUCTIONS'

export const TRANSITION = createAsyncConstants('TRANSITION_START')
export const REQUEST_ROUTER_NAVIGATE = 'REQUEST_ROUTER_NAVIGATE'
export const SET_USER_DEFAULT_COORDS = 'SET_USER_DEFAULT_COORDS'
export const SET_USER_DEFAULT_ZIPCODE = 'SET_USER_DEFAULT_ZIPCODE'
export const SET_SKIP_ISSUE = 'SET_SKIP_ISSUE'
export const RESET_SKIP_ISSUE = 'RESET_SKIP_ISSUE'

export const ADD_TOWING_STEP = 'ADD_TOWING_STEP'

export const SET_PREPROD_ENV = 'SET_PREPROD_ENV'

export const SET_MAP_DEBUG = 'SET_MAP_DEBUG'
export const SET_SHOW_SERVICE_HISTORY = 'SET_SHOW_SERVICE_HISTORY'

export const SET_CALL_STATUS_INTERVAL = 'SET_CALL_STATUS_INTERVAL'

export const SET_TELEMATICS = 'SET_TELEMATICS'
export const SET_QUERY_PARAMS_VEHICLE_DATA = 'SET_QUERY_PARAMS_VEHICLE_DATA'

export const SET_FIRST_TIME_ACCESS = 'SET_FIRST_TIME_ACCESS'

export enum STEP_CHANGES {
  PENDING_STEP,
  EDIT_STEP,
  ACTIVE_STEP,
  DONE_STEP,
}

export const openMessageDialog = createAction(
  DIALOG_OPEN.MESSAGE,
  props<{ payload: MessageDialog }>()
)

export const openErrorDialog = createAction(
  DIALOG_OPEN.ERROR,
  props<{ payload: ErrorDialog }>()
)

export const openPromptDialog = createAction(
  DIALOG_OPEN.PROMPT,
  props<{ payload: PromptDialog }>()
)

export const closeDialog = createAction(
  DIALOG_CLOSE,
  props<{ payload: { type: Dialog['type'] } }>()
)

export const resetDialogs = createAction(DIALOG_RESET)

export const pendingStep = createAction(
  PENDING_STEP,
  props<{ payload: Step }>()
)

export const editStep = createAction(EDIT_STEP, props<{ payload: Step }>())

export const activeStep = createAction(ACTIVE_STEP, props<{ payload: Step }>())

export const doneStep = createAction(DONE_STEP, props<{ payload: Step }>())

export const showSplashscreen = createAction(SHOW_SPLASHSCREEN)

export const hideSplashscreen = createAction(HIDE_SPLASHSCREEN)

export const animateSplashscreenHide = createAction(ANIMATE_SPLASHSCREEN_HIDE)

export const setSplashscreenStep = createAction(
  SET_SPLASHSCREEN_STEP,
  props<{ payload: 0 | 1 | 2 | 3 }>()
)

export const setShowPwa = createAction(
  SET_SHOW_PWA,
  props<{ payload: boolean }>()
)

export const setShowBack = createAction(
  SET_SHOW_BACK,
  props<{ payload: boolean }>()
)

export const setShowExpandedDialog = createAction(
  SET_SHOW_EXPANDED_DIALOG,
  props<{ payload: boolean }>()
)

export const setIssueSelectionRevamp = createAction(
  SET_ISSUE_SELECTION_REVAMP,
  props<{ payload: boolean }>()
)

export const setShowBottomNavigation = createAction(
  SET_SHOW_BOTTOM_NAVIGATION,
  props<{ payload: boolean }>()
)

export const setTowRedesign = createAction(
  SET_TOW_REDESIGN,
  props<{ payload: boolean }>()
)

export const setHideNotesOnNeedTow = createAction(
  SET_HIDE_NOTES_ON_NEED_TOW,
  props<{ payload: boolean }>()
)

export const setShowAuthOptionButton = createAction(
  SET_SHOW_AUTH_OPTION_BUTTON,
  props<{ payload: boolean }>()
)

export const setShowKeepMeInformed = createAction(
  SET_SHOW_KEEP_ME_INFORMED,
  props<{ payload: boolean }>()
)

export const setApplicationTextUpdates = createAction(
  SET_APPLICATION_TEXT_UPDATES,
  props<{ payload: boolean }>()
)

export const setSimpleCaptcha = createAction(
  SET_SIMPLE_CAPTCHA,
  props<{ payload: boolean }>()
)


export const setIsCaptchaRequired = createAction(
  SET_IS_CAPTCHA_REQUIRED,
  props<{ payload: boolean }>()
)

export const setNameZipInstructions = createAction(
  SET_NAME_ZIP_INSTRUCTIONS,
  props<{ payload: boolean }>()
)

export const transitionStart = createAction(
  TRANSITION.REQUEST,
  props<{ payload: TransitionEvent }>()
)

export const notifyTransitionEnded = createAction(TRANSITION.SUCCESS)

export const requestRouterNavigate = createAction(
  REQUEST_ROUTER_NAVIGATE,
  props<{ payload: NavigationEvent }>()
)

export const setShowMenu = createAction(
  SET_SHOW_MENU,
  props<{ payload: boolean }>()
)

export const setUserDefaultCoords = createAction(
  SET_USER_DEFAULT_COORDS,
  props<{ payload: GoogleAddress }>()
)

export const setSkipIssue = createAction(
  SET_SKIP_ISSUE,
  props<{ payload: boolean }>()
)

export const resetSkipIssue = createAction(RESET_SKIP_ISSUE)

export const addTowingStep = createAction(
  ADD_TOWING_STEP,
  props<{ payload: boolean }>()
)

export const setPreProdEnv = createAction(SET_PREPROD_ENV)

export const setUserDefaultZipCode = createAction(
  SET_USER_DEFAULT_ZIPCODE,
  props<{ payload: string }>()
)

export const setMapDebug = createAction(
  SET_MAP_DEBUG,
  props<{ payload: boolean }>()
)

export const setShowServiceHistory = createAction(
  SET_SHOW_SERVICE_HISTORY,
  props<{ payload: boolean }>()
)

export const setCallStatusInterval = createAction(
  SET_CALL_STATUS_INTERVAL,
  props<{ payload: number }>()
)

export const setTelematics = createAction(
  SET_TELEMATICS,
  props<{ payload: string }>()
)

export const setQueryParamsVehicleData = createAction(
  SET_QUERY_PARAMS_VEHICLE_DATA,
  props<{ payload: Vehicle }>()
)

export const setFirstTimeAccess = createAction(
  SET_FIRST_TIME_ACCESS,
  props<{ payload: boolean }>()
)
