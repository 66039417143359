import { StepperComponent } from './stepper/stepper.component'
import { StepComponent } from './stepper/step.component'
import { AuthSecurityWrapperComponent } from '../modules/auth/auth-security-wrapper/auth-security-wrapper.component'

import { PanelComponent } from './layouts/panel/panel.component'
import { SplitPanelComponent } from './layouts/split-panel/split-panel.component'
import { ExpandingPanelComponent } from './layouts/expanding-panel/expanding-panel.component'
import { SummaryComponent } from './summary/summary.component'
import { SplashscreenComponent } from './splashscreen/splashscreen.component'

import { AgentLoginComponent } from './layouts/agent-login/agent-login.component'

import { MinimalCredentialsComponent } from '../modules/auth/minimal-credentials/minimal-credentials.component'
import { EntitlementInfoComponent } from './entitlement-info/entitlement-info.component'
import { FormErrorComponent } from './controls/form-error/form-error.component'
import { FormErrorSummaryComponent } from './controls/form-error-summary/form-error-summary.component'
import { BannerComponent } from './banner/banner.component'
import { ButtonComponent } from './controls/button/button.component'
import { CancelRequestButtonComponent } from './controls/cancel-request/cancel-request.component'
import { CheckboxComponent } from './controls/checkbox/checkbox.component'
import { ClickableLinkComponent } from './controls/clickable-link/clickable-link.component'
import { DraggerComponent } from './controls/dragger/dragger.component'
import { ExpandingInputComponent } from './controls/expanding-input/expanding-input.component'
import { HomeLocationButtonComponent } from './controls/home-location-button/home-location-button.component'
import { ImageComponent } from './controls/image/image.component'
import { LinkButtonComponent } from './controls/link-button/link-button.component'
import { RadioSquareComponent } from './controls/radio-square/radio-square.component'
import { SelectOrTextComponent } from './controls/select-or-text/select-or-text.component'
import { WhiteLabelCredentialsComponent } from './layouts/white-label-credentials/white-label-credentials.component'
import { VinMileageCredentialsComponent } from './whitelabel-credentials-modes/vin-mileage-credentials/vin-mileage-credentials.component'
import { CarRentalCredentialsComponent } from './whitelabel-credentials-modes/car-rental-credentials/car-rental-credentials.component'
import { SubscriberNumberCredentialsComponent } from './whitelabel-credentials-modes/subscriber-number-credentials/subscriber-number-credentials.component'
import { SelectComponent } from './controls/select/select.component'
import { AriaLinkButtonComponent } from './controls/aria-link/aria-link-button.component'
import { SkipToContentComponent } from './controls/skip-to-content/skip-to-content.component';

export function sharedComponents() {
  return [
    StepperComponent,
    StepComponent,
    AuthSecurityWrapperComponent,
    ExpandingPanelComponent,
    PanelComponent,
    SplitPanelComponent,
    SummaryComponent,
    SplashscreenComponent,
    MinimalCredentialsComponent,
    EntitlementInfoComponent,
    FormErrorSummaryComponent,
    FormErrorComponent,
    BannerComponent,
    ButtonComponent,
    ImageComponent,
    DraggerComponent,
    ExpandingInputComponent,
    LinkButtonComponent,
    ClickableLinkComponent,
    HomeLocationButtonComponent,
    RadioSquareComponent,
    CheckboxComponent,
    SelectOrTextComponent,
    CancelRequestButtonComponent,
    AgentLoginComponent,
    WhiteLabelCredentialsComponent,
    VinMileageCredentialsComponent,
    CarRentalCredentialsComponent,
    SubscriberNumberCredentialsComponent,
    SelectComponent,
    AriaLinkButtonComponent,
    SkipToContentComponent
  ]
}

export function sharedProviders() {
  return []
}
