import { IndexedCollection } from 'src/app/shared/types'
import { hourToDate } from 'src/app/shared/utils/datetime'
import { PaceSetterCode } from '../../issue/issue.types'
import { AARData } from './aar.types'

const FLAT_TIRE_NO_SPARE_CODE = 'T180'
const TIRE_WHEEL_SERVICE = 'Tire/Wheel Service'
export const MINIMUM_AARS = 5

export function isAAROpen(aar: AARData) {
  let result

  try {
    const now = new Date()
    const today = new Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(
      now
    )
    const operatingDay = aar.operatingDays.find(
      (day) => day.day.toUpperCase() === today.toUpperCase()
    )
    const from = hourToDate(operatingDay.open)
    const to = hourToDate(operatingDay.close)

    result = from <= now && to >= now
  } catch (error) {
    // If e.g. the open/close times are malformatted, we want to avoid falling apart.
    result = false
  }

  return result
}

export function filterAarByServiceOffers(
  activePaceCode: PaceSetterCode,
  res: IndexedCollection<AARData>,
  isRapUser: boolean,
): IndexedCollection<AARData> {
  // If isRapUser we don't want to apply this filter
  // When a member selects Flat Tire and no spare (code T180),
  // only AAR facilities that have 'Tire/Wheel Service' listed as a service should appear in the results.
  if (!isRapUser && activePaceCode?.paceSetterCode === FLAT_TIRE_NO_SPARE_CODE) {
    Object.keys(res).forEach((id) => {
      if (
        !res[id] ||
        !res[id].serviceOffers ||
        res[id].serviceOffers.length === 0 ||
        res[id].serviceOffers.indexOf(TIRE_WHEEL_SERVICE) === -1
      ) {
        delete res[id]
      }
    })
  }
  return res
}

export function objectToArray(data) {
  const convert = (Array.isArray(data) ? data : [data]) || []
  return convert
}
