import { Component, OnInit, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { DialogData } from '../../ui.types'

@Component({
  selector: 'app-iframe-dialog',
  templateUrl: './iframe-dialog.component.html',
  styleUrls: ['./iframe-dialog.component.scss'],
})
export class IframeDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  close() {
    this.data.close()
  }
}
