<div class="dialog">
  <h1 mat-dialog-title>Warning</h1>

  <div id="dialog-content" mat-dialog-content>
    <p>Your session is about to time out!</p> 
    <p>Press the button below "Continue Session" to remain logged in.</p>
  </div>

  <div mat-dialog-actions>
    <app-button
      type="button"
      (click)="onSessionContinue()"
    >
      Continue Session
    </app-button>
  </div>
</div>
