import { Component, Input } from '@angular/core'

import { select, Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import {
  selectIsSecure,
  selectAgentSettings,
} from 'src/app/modules/auth/auth.selectors'
import { AAAStore } from 'src/app/store/root-reducer'

import { AgentSettingsParams } from 'src/app/modules/auth/auth.types'

import { setAgentSettings } from 'src/app/modules/auth/auth.actions'

import { Router } from '@angular/router'

// ARR:POC - the component handles the login for the agent
@Component({
  selector: 'app-agent-login',
  templateUrl: './agent-login.component.html',
  styleUrls: ['./agent-login.component.scss'],
})
export class AgentLoginComponent {
  @Input() tabIndexPosition = 0

  loading = false

  agentSettings: AgentSettingsParams = {}

  isSecure$: Observable<boolean> = this.store$.pipe(select(selectIsSecure))

  constructor(private store$: Store<AAAStore>, private router: Router) {}

  handleSubmit() {
    this.loading = true

    this.store$.dispatch(
      setAgentSettings({
        payload: this.agentSettings,
      })
    )

    this.loading = false

    this.router.navigate(['/auth/signin'])
  }
}
