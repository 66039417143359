import { Store, select } from '@ngrx/store'
import { Component, OnInit } from '@angular/core'
import { AAAStore } from '../../../store/root-reducer'
import { combineLatest, Observable } from 'rxjs'
import { AbstractComponent } from 'src/app/shared/abstract.component'
import {
  selectShowMenu,
} from 'src/app/modules/ui/ui.selectors'
import { selectIsSignedIn } from 'src/app/modules/auth/auth.selectors'
import { selectRouterStep } from 'src/app/store/router.selectors'
import { selectCanCancelActiveCall } from 'src/app/modules/dashboard/calls-statuses/call-status.selectors'
import { StepTypes } from 'src/app/modules/ui/ui.types'
import { filter, map, withLatestFrom } from 'rxjs/operators'
import { OEMBranding } from '../../../modules/auth/auth.types'
import { getCookie } from '../../utils/cookies'
import { Event, NavigationEnd, Router } from '@angular/router'
import { OEMLogoData } from '../../utils/branding'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends AbstractComponent implements OnInit {
  toggleOpenClass = false

  isVisible = true
  canCancelLiveCall = false

  showLogo = false
  appIdLogo = {
    width: '47',
    height: '30',
    className: 'aaa-logo',
    fileName: 'logo.png',
    alt: 'AAA'
  };

  constructor(private store$: Store<AAAStore>, private router: Router) {
    super()
  }

  navigationEndEvent$: Observable<Event> = this.router.events.pipe(
    filter(e => e instanceof NavigationEnd)
  )

  ngOnInit(): void {
    this.navigationEndEvent$.subscribe(() => {
      this.showLogo = true;
      //OEM Branding after cookie is set with current appId
      const appId = getCookie('AAA_AppId');
      if (appId in OEMBranding) {
        this.appIdLogo = OEMLogoData[appId];
      }
    });
  }

  showMenu$: Observable<any> = this.store$.pipe(select(selectShowMenu))

  isSignedIn$: Observable<boolean> = this.store$.pipe(select(selectIsSignedIn))

  canCancel$: Observable<boolean> = combineLatest([
    this.isSignedIn$,
    this.store$.select(selectRouterStep),
    this.store$.select(selectCanCancelActiveCall),
  ]).pipe(
    map(([isSignedIn, step, canCancelActiveCall]) => {
      if (!isSignedIn) {
        return false
      }
      if (step) {
        return step.toLowerCase() !== StepTypes.ISSUE
      } else {
        this.canCancelLiveCall = false
        this.canCancelLiveCall = canCancelActiveCall

        return this.canCancelLiveCall
      }
    })
  )
}
