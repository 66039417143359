<div class="dialog">
  <h1 mat-dialog-title>Ongoing Request</h1>

  <div id="dialog-content" mat-dialog-content>
    <p>A call request is already ongoing for this member.</p>
    <p *ngIf="!isRapUser">If you did not submit a call, please call AAA:</p>
    <p *ngIf="isRapUser">If you did not submit a call, please call:</p>
    <div>
      <app-call-aaa-button
        [clickEventPage]="pageType"
        [inverted]="true"
        (buttonClick)="onCallAAAClick()">
      </app-call-aaa-button>
    </div>
  </div>

  <div mat-dialog-actions>
    <app-button
      id="dialog-dismiss-submit"
      name="dialogConfirmCancelSubmit"
      type="button"
      inverted="true"
      (click)="onClose()"
      aria-label="dismiss this message"
    >
      Dismiss
    </app-button>
    <app-button
      id="dialog-confirm-submit"
      name="dialogConfirmConfirmSubmit"
      type="button"
      (click)="onSubmit()"
    >
      Load Request
    </app-button>
  </div>
  <button mat-dialog-close class="mat-dialog-close" aria-label="dismiss this message">
    <icon-close></icon-close>
  </button>
</div>
