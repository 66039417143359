import { createSelector, defaultMemoize } from '@ngrx/store'
import * as haversine from 'haversine'

import { BreakdownLocation } from 'src/app/modules/location/location.types'
import { generateVehicleSlug } from 'src/app/shared/utils/generateVehicleSlug'
import { AAAStore } from 'src/app/store/root-reducer'
import { selectMemberActiveVehicle } from '../../member/member.selectors'
import { Vehicle } from '../../member/member.types'
import { selectBreakdownLocation } from '../location.selectors'
import { GenericCoordinates } from '../location.types'
import { AARState } from './aar.reducer'
import { AARData, AARRequestParams, AAR_TYPES, COR_TYPE } from './aar.types'
import { isAAROpen, objectToArray } from './aar.utils'

const MAXIMUM_MILES_DISTANCE = 10

export const selectAARState = (store: AAAStore): AARState => store.aar

export const selectCurrentVehicleSlug = createSelector(
  selectMemberActiveVehicle,
  selectBreakdownLocation,
  (activeVehicle: Vehicle, coords: BreakdownLocation) =>
    generateVehicleSlug(activeVehicle, coords ? coords['zip'] : '--')
)

export const selectAARData = createSelector(
  selectAARState,
  selectCurrentVehicleSlug,
  (state: AARState, activeVehicleSlug: string): AARData[] => {
    const _data = state.data || {}
    return _data[activeVehicleSlug]
      ? Object.values(_data[activeVehicleSlug])
      : []
  }
)

export const selectAAROpenOnly = createSelector(
  selectAARState,
  (state: AARState): boolean => state.openOnly
)

export const selectMaximumAarsShown = createSelector(
  selectAARState,
  (state: AARState): number => state.maximumAarsShown
)

export const selectAARAvailableCount = createSelector(
  selectAARData,
  selectAAROpenOnly,
  (data: AARData[], isOpenOnly: boolean): number =>
    isOpenOnly ? data.filter(isAAROpen).length : data.length
)

const handleCORfacilities = (serviceTypes, facilityInfo, breakdownLocation) => {
  const isCOR = Boolean(
    serviceTypes.filter((service) => service.type.toUpperCase() === COR_TYPE)
      .length
  )
  const locationCoords = {
    latitude: +breakdownLocation.latitude,
    longitude: +breakdownLocation.longitude,
  }
  const distance: any = haversine(
    {
      latitude: Number(facilityInfo.latitude),
      longitude: Number(facilityInfo.longitude),
    },
    locationCoords,
    { unit: 'mile' }
  )

  const inRange = isCOR && distance <= MAXIMUM_MILES_DISTANCE
  return inRange
}

const handlePriorityEnabledFacilities = (facilityInfo, breakdownLocation) => {
  const locationCoords = {
    latitude: +breakdownLocation.latitude,
    longitude: +breakdownLocation.longitude,
  }
  const distance: any = haversine(
    {
      latitude: Number(facilityInfo.latitude),
      longitude: Number(facilityInfo.longitude),
    },
    locationCoords,
    { unit: 'mile' }
  )

  const isPriorityInRange =
    facilityInfo['priority'] && distance <= MAXIMUM_MILES_DISTANCE

  return isPriorityInRange
}

export const selectNearbyAARs = createSelector(
  selectAARData,
  selectBreakdownLocation,
  (data: AARData[], breakdownLocation: BreakdownLocation) => {
    const corFacilities = []
    const rspFacilities = []

    let facilities = (data || [])
      .filter((item) => {
        const services = objectToArray(item?.services)
        const serviceTypes = services.find(
          (service) => AAR_TYPES.indexOf(service.type.toUpperCase()) !== -1
        )
        const isCORinRange = handleCORfacilities(
          services,
          item,
          breakdownLocation
        )
        const isPriorityInRange = handlePriorityEnabledFacilities(
          item,
          breakdownLocation
        )

        /// if is a COR facility and is within 10 miles range, exclude it from source
        // to be used later in the top of the list
        if (isCORinRange) {
          corFacilities.push(item)
          return null
        }

        // if priority is enabled, exclude from source and push it to a new array
        // to be used later on the list, also if facility is COR type avoid to be pushed
        // this will avoid to create a duplicated record
        if (isPriorityInRange && !isCORinRange) {
          rspFacilities.push(item)
          return null
        }

        return serviceTypes ? item : null
      })
      .filter((item) => item)

    // In case there is no facilities, display default list
    if (!facilities.length) {
      facilities = data
    }

    const sortedFacilities = facilities.sort(
      (a: AARData, b: AARData) =>
        +haversine(
          { latitude: Number(a.latitude), longitude: Number(a.longitude) },
          {
            latitude: Number(breakdownLocation.latitude),
            longitude: Number(breakdownLocation.longitude),
          },
          { unit: 'mile' }
        ) -
        +haversine(
          { latitude: Number(b.latitude), longitude: Number(b.longitude) },
          {
            latitude: Number(breakdownLocation.latitude),
            longitude: Number(breakdownLocation.longitude),
          },
          { unit: 'mile' }
        )
    )
    const response = [...corFacilities, ...rspFacilities, ...sortedFacilities]
    return response
  }
)

export const selectShownAARs = createSelector(
  selectNearbyAARs,
  selectAAROpenOnly,
  selectMaximumAarsShown,
  (
    nearbyAars: AARData[],
    isOpenOnly: boolean,
    maximumAarsShown: number
  ): AARData[] => {
    const aars = (isOpenOnly ? nearbyAars.filter(isAAROpen) : nearbyAars).slice(
      0,
      maximumAarsShown
    )

    return aars
  }
)

export const selectShownAARsCount = createSelector(
  selectNearbyAARs,
  selectAAROpenOnly,
  (nearbyAars: AARData[], isOpenOnly: boolean): number =>
    isOpenOnly ? nearbyAars.filter(isAAROpen).length : nearbyAars.length
)

export const selectAARCoordinates = createSelector(
  selectAARState,
  (state: AARState): AARRequestParams => state.aarParams
)

export const selectAARDetails = createSelector(
  selectAARState,
  (state: AARState): AARData[] => state.details && Object.values(state.details)
)

export const createAARDetailSelector = createSelector(
  selectAARState,
  selectBreakdownLocation,
  (state: AARState) => (id: number | string) =>
    state?.details[id] || state?.data[id]
)

// TODO check if it's still required, no usage found
export const selectIsAarFactory = createSelector(
  selectAARData,
  (data: AARData[]) =>
    defaultMemoize(
      (location: GenericCoordinates) =>
        !!data.find((aar) =>
          haversine(
            {
              lat: Number(aar.latitude),
              lng: Number(aar.longitude),
            },
            {
              lat: Number(location.latitude),
              lng: Number(location.longitude),
            },
            {
              threshold: 100,
              unit: 'meter',
            }
          )
        )
    )
)

export const selectAARsCustomMessage = createSelector(
  selectAARState,
  (state: AARState) => state.noMakeFacilities
)

export const selectExistsAarForVehicle = createSelector(
  selectAARState,
  selectCurrentVehicleSlug,
  (state: AARState, activeVehicleSlug: string) => {
    const _data = state.data || {}
    return Boolean(_data[activeVehicleSlug])
  }
)
