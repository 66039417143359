import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'
import { from, Observable } from 'rxjs'
import {
  catchError,
  concatMap,
  filter,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators'

import { ErrorReportingService } from 'src/app/shared/services/error-reporting.service'
import { PayloadedAction } from '../../shared/types'
import { dispatchErrorAction } from '../../shared/utils'
import { AuthSecurityWrapperService } from '../auth/auth-security-wrapper/auth-security-wrapper.service'
import { authNameSearchRequest,} from '../auth/auth.actions'
import { selectAuthMethod, selectIsAgent } from '../auth/auth.selectors'
import { AuthMethods } from '../auth/auth.types'
import { CHARACTER_LIMIT_VEHICLE_COLOR } from '../location/location.constants'
import events from '../tagging/events'
import { TaggingService } from '../tagging/tagging.service'
import { handleAuthAnalytics } from '../tagging/tagging.utils'
import {
  openErrorDialog,
  openMessageDialog,
  openPromptDialog,
  setSplashscreenStep,
  showSplashscreen,
} from '../ui/ui.actions'
import { selectQueryParamsVehicleData } from '../ui/ui.selectors'
import {
  ErrorDialogTypes,
  MessageDialogTypes,
  PromptDialogTypes,
} from '../ui/ui.types'
import { AAAStore } from './../../store/root-reducer'
import * as memberActions from './member.actions'
import { MemberService } from './member.service'
import {
  AddVehicleResponse,
  MemberBasicInfo,
  MemberEligibility,
  MemberInfo,
  MEMBERSHIP_ERRORS,
  MemberVehicles,
  SearchMemberResult,
  ServiceItem,
  Vehicle,
} from './member.types'
import { getAutomatedEventDetails } from './member.utils'
import { RapService } from '../rap/rap.service'

@Injectable()
export class MemberEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<AAAStore>,
    private _memberService: MemberService,
    private authSecurityWrapperService: AuthSecurityWrapperService,
    private taggingService: TaggingService,
    private errorReportingService: ErrorReportingService,
    private rapService: RapService
  ) { }

  loadMemberInfo$ = createEffect(
    (): Observable<
      | ReturnType<typeof memberActions.memberInfoSuccess>
      | ReturnType<typeof memberActions.memberInfoFailure>
    > =>
      this.actions$.pipe(
        ofType(memberActions.MEMBER_INFO.REQUEST),
        withLatestFrom(this.store$.select(selectAuthMethod)),
        switchMap(([_, authMethod]) =>
          from(this._memberService.getInfo()).pipe(
            map((memberData: MemberInfo) =>
              memberActions.memberInfoSuccess({ payload: memberData })
            ),
            catchError((error) => {
              const { reason } = getAutomatedEventDetails(error)

              handleAuthAnalytics(this.taggingService, {
                reason,
                method: authMethod,
                isValid: false,
              })

              return this.errorReportingService.notifyErrorObservable(
                error,
                memberActions.memberInfoFailure
              )
            })
          )
        )
      )
  )

  loadMemberBasicInfo$ = createEffect(
    (): Observable<
      | ReturnType<typeof memberActions.memberBasicInfoSuccess>
      | ReturnType<typeof memberActions.memberBasicInfoFailure>
    > =>
      this.actions$.pipe(
        ofType(memberActions.MEMBER_BASIC_INFO.REQUEST),
        withLatestFrom(this.store$.select(selectAuthMethod)),
        switchMap(([_, authMethod]) =>
          from(this._memberService.getBasicInfo()).pipe(
            map((memberData: MemberBasicInfo) =>
              memberActions.memberBasicInfoSuccess({ payload: memberData })
            ),
            catchError((error) => {
              const { reason } = getAutomatedEventDetails(error)
              handleAuthAnalytics(this.taggingService, {
                reason,
                method: authMethod,
                isValid: false,
              })
              this.authSecurityWrapperService.forceLogout()
              return this.errorReportingService.notifyErrorObservable(
                error,
                memberActions.memberBasicInfoFailure
              )
            })
          )
        )
      )
  )

  // start ARR:POC - Effects for firing the services.
  loadTowUsage$ = createEffect(
    (): Observable<
      | ReturnType<typeof memberActions.memberEligibilitySuccess>
      | ReturnType<typeof memberActions.memberEligibilityFailure>
    > =>
      this.actions$.pipe(
        ofType(
          memberActions.MEMBER_INFO.SUCCESS,
          memberActions.MEMBER_BASIC_INFO.SUCCESS
        ),
        withLatestFrom(this.store$.select(selectIsAgent)),
        filter(([_, isAgent]) => isAgent),
        switchMap(
          ([memberData]: [PayloadedAction<MemberBasicInfo>, boolean]) => {
            const memberNumber = memberData.payload?.fullMembershipNumber
            return from(
              this._memberService.getMemberEligibility(memberNumber)
            ).pipe(
              map((data: MemberEligibility) =>
                memberActions.memberEligibilitySuccess({ payload: data })
              ),
              catchError((error) =>
                this.errorReportingService.notifyErrorObservable(
                  error,
                  memberActions.memberEligibilityFailure
                )
              )
            )
          }
        )
      )
  )

  getServiceHistory$ = createEffect(
    (): Observable<
      | ReturnType<typeof memberActions.memberServiceHistorySuccess>
      | ReturnType<typeof memberActions.memberServiceHistoryFailure>
    > =>
      this.actions$.pipe(
        ofType(
          memberActions.MEMBER_INFO.SUCCESS,
          memberActions.MEMBER_BASIC_INFO.SUCCESS
        ),
        withLatestFrom(this.store$.select(selectIsAgent)),
        filter(([_, isAgent]) => isAgent),
        switchMap(
          ([memberData]: [PayloadedAction<MemberBasicInfo>, boolean]) => {
            const memberNumber = memberData.payload?.fullMembershipNumber
            return from(
              this._memberService.getServiceHistory(memberNumber)
            ).pipe(
              map((data: Array<ServiceItem>) =>
                memberActions.memberServiceHistorySuccess({ payload: data })
              ),
              catchError((error) =>
                this.errorReportingService.notifyErrorObservable(
                  error,
                  memberActions.memberServiceHistoryFailure
                )
              )
            )
          }
        )
      )
  )
  // end ARR:POC

  handleIneligibility$ = createEffect(
    (): Observable<
      | ReturnType<typeof openMessageDialog>
      | ReturnType<typeof showSplashscreen>
      | ReturnType<typeof setSplashscreenStep>
    > =>
      this.actions$.pipe(
        ofType(
          memberActions.MEMBER_ELIGIBILITY_RESULT
        ),
        withLatestFrom(this.store$.select(selectAuthMethod)),
        filter(
          ([action, _]: [PayloadedAction<MemberBasicInfo | MemberInfo>, AuthMethods]) =>
            !action.payload.eligible || action.payload.ersAbuser
        ),
        concatMap(([action, authMethod]: [PayloadedAction<MemberBasicInfo | MemberInfo>, AuthMethods]) => {
          const eligible = action.payload.eligible
          const ersAbuser = action.payload.ersAbuser

          const reason = eligible ? MEMBERSHIP_ERRORS.NOT_ELEGIBLE.reason :
          ersAbuser ? MEMBERSHIP_ERRORS.ERS_ABUSER.reason : 'Members without entitlements'

          handleAuthAnalytics(this.taggingService, {
            reason,
            method: authMethod,
            isValid: false,
          })
          return [
            openMessageDialog({
              payload: {
                type: MessageDialogTypes.INVALID_MEMBERSHIP,
              },
            }),
            showSplashscreen(),
            setSplashscreenStep({ payload: 0 }),
          ]
        })
      )
  )

  loadMemberVehicles$ = createEffect(
    (): Observable<
      | ReturnType<typeof memberActions.vehicleLoadSuccess>
      | ReturnType<typeof memberActions.notifyVehicleLoadFailure>
    > =>
      this.actions$.pipe(
        ofType(memberActions.VEHICLE_LOAD.REQUEST),
        withLatestFrom(
          this.store$.pipe(select(selectQueryParamsVehicleData)),
        ),
        switchMap(([action, queryParamsVehicle]) =>
          from(this._memberService.getVehicles()).pipe(
            mergeMap((vehiclesData: MemberVehicles) => {
              const actions: any[] = []
              if (vehiclesData?.vehicles?.length > 0) {
                actions.push(memberActions.vehicleLoadSuccess({ payload: vehiclesData }))
              } else if (queryParamsVehicle) {
                let vehicle: Vehicle = {...queryParamsVehicle}
                const payload = { vehicles: [vehicle] }
                actions.push(memberActions.vehicleLoadSuccess({ payload }))
                actions.push(memberActions.assignExistingVehicle({ payload: vehicle }))
              }
              return actions
            }),
            catchError((error) =>
              this.errorReportingService.notifyErrorObservable(
                error,
                memberActions.notifyVehicleLoadFailure
              )
            )
          )
        )
      )
  )

  storeUpdatedVehicle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(memberActions.SET_VEHICLE),
      map((action: PayloadedAction) => {
        action.payload.color = action.payload.color.substr(
          0,
          CHARACTER_LIMIT_VEHICLE_COLOR
          )
          if (action.payload.id) {
          return memberActions.requestVehicleUpdate({ payload: action.payload })
        } else {
          return memberActions.requestVehicleAdd({ payload: action.payload })
        }
      })
    )
  )

  addVehicle$ = createEffect(
    (): Observable<
      | ReturnType<typeof memberActions.vehicleAddSuccess>
      | ReturnType<typeof memberActions.notifyVehicleAddFailure>
    > =>
      this.actions$.pipe(
        ofType(memberActions.VEHICLE_ADD.REQUEST),
        mergeMap(
          (action: ReturnType<typeof memberActions.requestVehicleAdd>) => from(this._memberService.addVehicle(action.payload)).pipe(
              map((response: AddVehicleResponse) =>
                memberActions.vehicleAddSuccess({
                  payload: {
                    ...action.payload,
                    id: response.id,
                  },
                })
              ),
              catchError((error) =>
                this.errorReportingService.notifyErrorObservable(
                  error,
                  memberActions.notifyVehicleAddFailure
                )
              )
            )
        )
      )
  )

  updateMemberVehicle$ = createEffect(
    (): Observable<
      | ReturnType<typeof memberActions.vehicleUpdateSuccess>
      | ReturnType<typeof memberActions.notifyVehicleUpdateFailure>
    > =>
      this.actions$.pipe(
        ofType(memberActions.VEHICLE_UPDATE.REQUEST),
        switchMap((action: PayloadedAction) => {
          this.taggingService.setAutomatedEvent(
            events.vehicle.VEHICLE_EDIT,
            events.vehicle.VEHICLE_PAGE_TYPE
          )

          return from(!this.rapService.isRapUser() ? this._memberService.updateVehicle(action.payload) : Promise.resolve()).pipe(
            map(() =>
              memberActions.vehicleUpdateSuccess({ payload: action.payload })
            ),
            catchError((error) =>
              this.errorReportingService.notifyErrorObservable(
                error,
                memberActions.notifyVehicleUpdateFailure
              )
            )
          )
        })
      )
  )

  deleteMemberVehicle$ = createEffect(
    (): Observable<
      | ReturnType<typeof memberActions.vehicleDeleteSuccess>
      | ReturnType<typeof memberActions.notifyVehicleDeleteFailure>
    > =>
      this.actions$.pipe(
        ofType(memberActions.VEHICLE_DELETE.REQUEST),
        switchMap((action: PayloadedAction) => {
          this.taggingService.setAutomatedEvent(
            events.vehicle.VEHICLE_DELETE,
            events.vehicle.VEHICLE_PAGE_TYPE
          )

          return from(!this.rapService.isRapUser() ? this._memberService.deleteVehicle(action.payload) : Promise.resolve()).pipe(
            map(() =>
              memberActions.vehicleDeleteSuccess({ payload: action.payload })
            ),
            catchError((error) =>
              this.errorReportingService.notifyErrorObservable(
                error,
                memberActions.notifyVehicleDeleteFailure
              )
            )
          )
        })
      )
  )

  handleMemberSearch$ = createEffect(
    (): Observable<
      | ReturnType<typeof memberActions.searchMembersSuccess>
      | ReturnType<typeof memberActions.searchMembersFailure>
    > =>
      this.actions$.pipe(
        ofType(memberActions.MEMBERS_SEARCH.REQUEST),
        switchMap((action: PayloadedAction) =>
          from(this._memberService.memberSearch(action.payload)).pipe(
            map((response: SearchMemberResult) =>
              memberActions.searchMembersSuccess({ payload: response })
            ),
            catchError((error) => {
              const { reason } = getAutomatedEventDetails(error)

              if (reason) {
                this.taggingService.setAutomatedEvent(events.auth.FORM_NAME_AUTH_FAILURE, events.auth.PAGE_TYPE, null, {
                  'Event Detail': reason
                })
              } else {
                this.taggingService.setAutomatedEvent(events.auth.FORM_NAME_AUTH_FAILURE, events.auth.PAGE_TYPE)
              }

              return this.errorReportingService.notifyErrorObservable(error, [
                openErrorDialog({
                  payload: { type: ErrorDialogTypes.MEMBER_NO_MATCHES },
                }),
                dispatchErrorAction(memberActions.searchMembersFailure, error),
              ])
            })
          )
        )
      )
  )

  handleMemberSearchSuccess$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(memberActions.MEMBERS_SEARCH.SUCCESS),
      map(
        (action: ReturnType<typeof memberActions.searchMembersSuccess>) => {
          const { members, searchId } = action.payload
          const resultId = members[0]['resultId']

          return members.length > 1
            ? openPromptDialog({
              payload: {
                type: PromptDialogTypes.ADDITIONAL_AUTH_INFO,
                params: {
                  members,
                  searchId,
                },
              },
            })
            : authNameSearchRequest({ payload: { searchId, resultId } })
        }
      )
    )
  )
}
