import {
  createSelector,
  defaultMemoize,
  MemoizedProjection,
  Selector,
} from '@ngrx/store'

import { AAAStore } from '../../store/root-reducer'
import { PaceSetterState } from './issue.reducer'
import {
  PaceSetterCode,
  PaceSetterSituation,
  PaceSetterDetails,
  PaceSetterGroup,
  PaceSetterOptions,
  PaceSetterList,
} from './issue.types'
import { deepCopy } from 'src/app/shared/utils/deepCopy'
import { selectClubOverridePaceSetterCode } from '../servicing-club/servicing-club.selectors'
import { PACE_SETTER_OPTION_TEMPLATES } from './issue.utils'

export const selectPaceSetterState: Selector<AAAStore, PaceSetterState> = (
  store
) => store.paceSetter

export const selectActivePaceSetterSituation = createSelector(
  selectPaceSetterState,
  (state: PaceSetterState): PaceSetterSituation | null => state.activeSituation
)

export const selectActivePaceSetterSituationName = createSelector(
  selectPaceSetterState,
  (state: PaceSetterState): string | null =>
    state.activeSituation && state.activeSituation.name
)

export const selectActiveSituationTemplate = createSelector(
  selectActivePaceSetterSituationName,
  (name: string): PaceSetterOptions | null =>
    name && PACE_SETTER_OPTION_TEMPLATES[name.toUpperCase()]
)

export const selectActivePaceSetterStep = createSelector(
  selectPaceSetterState,
  (state: PaceSetterState): number => state.stepIndex
)

function findActiveDetails(state: PaceSetterState): PaceSetterDetails | null {
  if (!state.activeSituation) {
    return null
  }

  return state.details[state.activeSituation.name]
}

export const selectActivePaceSetterDetails = createSelector(
  selectPaceSetterState,
  (state: PaceSetterState): PaceSetterDetails | null => findActiveDetails(state)
)

export const selectActivePaceSetterSelection = createSelector(
  selectPaceSetterState,
  (state: PaceSetterState): PaceSetterList[] => {
    const details = findActiveDetails(state)
    if (!details || !state.stepIndex) {
      return []
    }

    return (
      (details.selectedGroups.length &&
        details.selectedGroups[state.stepIndex - 1].lists) ||
      []
    )
  }
)

export const selectPaceSetterNotes = createSelector(
  selectActivePaceSetterDetails,
  (details: PaceSetterDetails): string => (details ? details.notes || '' : '')
)

export const selectUserActiveCode = createSelector(
  selectPaceSetterState,
  (state: PaceSetterState): PaceSetterCode | null => state.activeCode
)

export const selectActivePaceSetterCode = createSelector(
  selectPaceSetterState,
  selectClubOverridePaceSetterCode,
  (
    state: PaceSetterState,
    getOverride: MemoizedProjection
  ): PaceSetterCode | null => {
    const override = getOverride.memoized(state.activeCode)
    return override ? { ...state.activeCode, ...override } : state.activeCode
  }
)

export const selectIsActivePaceSetter = (
  code: PaceSetterCode['paceSetterCode']
) =>
  createSelector(
    selectActivePaceSetterCode,
    (activeSituation: PaceSetterCode): boolean =>
      activeSituation?.paceSetterCode === code
  )

export const selectPaceSetterDetailSelections = createSelector(
  selectPaceSetterState,
  (state: PaceSetterState): PaceSetterGroup[] => {
    if (!state.activeSituation || !state.details[state.activeSituation.name]) {
      return null
    }

    return state.details[state.activeSituation.name].selectedGroups
  }
)

// There is no more tamples with options.
// It will always return []
// REMOVE?
export const selectTemplateOptions = createSelector(
  selectPaceSetterState,
  (state: PaceSetterState): any => {
    if (!state.activeSituation) {
      return []
    }

    const template = deepCopy(
      PACE_SETTER_OPTION_TEMPLATES[state.activeSituation.name.toUpperCase()]
    )
    const _index = state.stepIndex - 1
    const group = template.detailGroups[_index]
    const options =
      group && group.options ? group.options[_index] : { values: [] }
    return options.values || []
  }
)

// There is no more tires to select.
// It will always return []
// REMOVE?
export const selectTires = createSelector(
  selectPaceSetterState,
  (state: PaceSetterState): Array<any> => state['selectedTires'] || []
)

// There is no more damaged parts to select.
// It will always return null
// REMOVE?
export const selectDamagedParts = createSelector(
  selectPaceSetterState,
  (state: PaceSetterState): any => state.damagedParts
)

export const selectNeedsTow = createSelector(
  selectActivePaceSetterCode,
  (activeCode: any): boolean => {
    if (!activeCode) {
      return false
    }

    return activeCode.requiresTowing
  }
)

function flattenPaceSetterCodes(details: PaceSetterDetails): PaceSetterCode[] {
  if (!details || !details.selectedGroups) {
    return []
  }

  const flatLists: PaceSetterList[] = details.selectedGroups.reduce(
    (output, group) => output.concat(group.lists),
    []
  )

  return flatLists.reduce((output, list) => output.concat(list.members), [])
}

export const selectPaceSetterNotesAndComments = createSelector(
  selectActivePaceSetterDetails,
  (details: PaceSetterDetails) => {
    if (!details) {
      return ''
    }

    const codes = flattenPaceSetterCodes(details)
    const comments = codes
      .filter((code) => !!code.comment)
      .map((code) => code.comment)
      .join('\n')

    const result = []
    if (comments) {
      result.push(comments)
    }

    if (details.notes) {
      result.push(details.notes)
    }

    return result.join('\n')
  }
)

// TODO migrate to ngrx-forms post-pilot
// TODO validate pace-setter details
export const selectIsPaceSetterValid = createSelector(
  selectActivePaceSetterCode,
  (paceSetter: PaceSetterCode): boolean =>
    Boolean(paceSetter && paceSetter.paceSetterCode)
)
