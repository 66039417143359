import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../../ui.types';
import {
  isChromeDesktop,
  isChromeMobile,
  isSafariDesktop,
  isSafariMobile
} from '../../../../shared/utils/browser-detect';
import { isNativeAndroid, isNativeIOS } from '../../../../shared/utils/app-detect';

@Component({
  selector: 'app-location-services-required',
  templateUrl: './location-services-required.component.html',
  styleUrls: ['./location-services-required.component.scss']
})
export class LocationServicesRequiredComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }

  hasInstructions = () =>
    isChromeMobile()
    || isSafariMobile()
    || isChromeDesktop()
    || isSafariDesktop()
    || isNativeAndroid()
    || isNativeIOS()

  isSafariMobile() {
    return isSafariMobile();
  }

  isChromeMobile() {
    return isChromeMobile();
  }

  isSafariDesktop() {
    return isSafariDesktop();
  }

  isChromeDesktop() {
    return isChromeDesktop();
  }

  isNativeIOS() {
    return isNativeIOS();
  }

  isNativeAndroid() {
    return isNativeAndroid();
  }

  getTitle() {
    if (this.hasInstructions()) {
      return 'How to turn Location Services for your browser';
    } else {
      return 'Location Services Required';
    }
  }
}
