import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'

import { DialogData } from '../../ui.types'
import { TaggingService } from 'src/app/modules/tagging/tagging.service'
import events from 'src/app/modules/tagging/events'
import { RapService } from 'src/app/modules/rap/rap.service'

@Component({
  selector: 'app-generic-error-dialog-component',
  templateUrl: './generic-error-dialog.component.html',
  styleUrls: ['../shared-dialog.component.scss'],
})
export class GenericErrorDialogComponent {
  pageType = events.shared.CALL_AAA_PAGE_TYPE
  isRapUser = this.rapService.isRapUser()

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private taggingService: TaggingService,
    private rapService: RapService,
  ) {
    this.data.title = this.data.title || `We're Sorry!`
    this.data.content = this.data.content || ((): string => {
      if (this.rapService.isRapUser()) {
        return `
          <p>An error has occurred at this time.<br/>
          For additional assistance, please contact us at
          </p>
        `
      }

      const _defaultText = `
        <p>An error has occurred at this time.<br/>
        For additional assistance, please call AAA
        </p>
      `

      return _defaultText
    })()

    this.taggingService.setPageEvent(
      events.shared.CALL_AAA_PROMPT,
      events.shared.CALL_AAA_PAGE_TYPE
    )
  }

  onClose() {
    this.data.close()
  }

  onCallAAAClick() {
    this.onClose()
  }
}
