<img
  id="icon-aaa"
  src="assets/images/icons/img-club-brand-copy.png"
  srcset="
    assets/images/icons/img-club-brand-copy@2x.png 2x,
    assets/images/icons/img-club-brand-copy@3x.png 3x
  "
  class="img_club_brand-copy"
  alt="AAA"
/>
