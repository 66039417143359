import { OEMBranding } from 'src/app/modules/auth/auth.types';
import { getCookie } from './cookies';

export const OEMLogoData = {
  [OEMBranding.HYUNDAI]: {
    width: '120',
    className: `${OEMBranding.HYUNDAI.toLowerCase()}-logo`,
    fileName: 'hyundai_logo.png',
    alt: OEMBranding.HYUNDAI
  }
}

export const getOemBrandingId = (url: string): string => {
  let oemBrandingAppId: string

  const appId = getCookie('AAA_AppId');
  if (appId && appId.toUpperCase() in OEMBranding) {
    oemBrandingAppId = appId
  }

  if (!oemBrandingAppId) {
    for (let key in OEMBranding) {
      if (url.toUpperCase().indexOf(key) > -1) {
        oemBrandingAppId = key
      }
    }
  }

  return oemBrandingAppId;
}
