import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { select, Store } from '@ngrx/store'
import { FormGroupState } from 'ngrx-forms'
import { InvisibleReCaptchaComponent, ReCaptcha2Component } from 'ngx-captcha'
import { BehaviorSubject, combineLatest, Observable } from 'rxjs'
import { filter, map } from 'rxjs/operators'

import { selectSearchMembersResults } from 'src/app/modules/member/member.selectors'
import { TaggingService } from 'src/app/modules/tagging/tagging.service'
import {
  MembershipNumberAuthFormState,
  NameAuthFormState,
} from 'src/app/modules/ui/forms/forms.reducer'
import {
  selectAuthForm,
  selectNameAuthForm,
} from 'src/app/modules/ui/forms/forms.selectors'
import {
  selectDefaultZipCode,
  selectNameZipInstructions,
  selectShowAuthOptionButton,
  selectShowSimpleCaptcha,
} from 'src/app/modules/ui/ui.selectors'
import { environment as config } from 'src/environments/environment'
import { AbstractComponent } from '../../../shared/abstract.component'
import { AAAStore } from 'src/app/store/root-reducer'
import {
  AUTH,
  AUTH_RESET,
  notifyAuthFailure,
  requestAuth,
  setAuthMethod,
} from 'src/app/modules/auth/auth.actions'
import {
  selectAuthMethod,
  selectAuthState,
  selectIsAgent,
  selectIsSecure,
} from 'src/app/modules/auth/auth.selectors'
import {
  MEMBER_BASIC_INFO,
  MEMBER_INFO,
  MEMBERS_SEARCH,
  searchMembersRequest,
} from 'src/app/modules/member/member.actions'
import { selectIsLoading } from 'src/app/modules/ui/loading/loading.selectors'
import {
  animateSplashscreenHide,
  openMessageDialog,
  openPromptDialog,
} from 'src/app/modules/ui/ui.actions'
import {
  MessageDialogTypes,
  PromptDialogTypes,
} from 'src/app/modules/ui/ui.types'
import {
  AuthMethods,
  BaseAuthRequestParams,
} from 'src/app/modules/auth/auth.types'
import { AuthState } from 'src/app/modules/auth/auth.reducer'
import { isMobileApp } from '../../../shared/utils/app-detect'
import { CALL_STATUS } from 'src/app/modules/dashboard/calls-statuses/call-status.actions'
import { Actions, ofType } from '@ngrx/effects'
import events from '../../tagging/events'

@Component({
  selector: 'app-minimal-credentials',
  templateUrl: './minimal-credentials.component.html',
  styleUrls: ['./minimal-credentials.component.scss'],
})
export class MinimalCredentialsComponent
  extends AbstractComponent
  implements OnInit {
  @Input() displayNotMember = true
  @Input() displayCaptcha
  @Input() tabIndexPosition = 0

  @ViewChild('dialogContent', { static: true }) dialogContent: TemplateRef<any>
  @ViewChild('captchaElement') captchaElement: InvisibleReCaptchaComponent
  @ViewChild('captchaElementSimple') captchaElementSimple: ReCaptcha2Component

  authParams = new BehaviorSubject<any>({
    accessToken: null,
    memId: '',
    ets: '',
    isSecure: false,
  })
  captcha // Required for an Angular reason
  captchaBadge: 'bottomright' | 'bottomleft' | 'inline' = 'inline'
  captchaToken: string
  captchaError = false
  siteKey = config.googleCaptchaKey
  mask = [
    /\d/,
    /\d/,
    /\d/,
    ' ',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    ' ',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    ' ',
    /\d/,
  ]
  authForm: FormGroupState<MembershipNumberAuthFormState> = null
  nameAuthForm: FormGroupState<NameAuthFormState> = null
  allowNameAuth: boolean
  showInvisibleCaptcha: boolean
  isNameTabActive = true
  isNumberTabActive = false
  previousShowAuthOptionButton = false //This can return after the next test results
  isAgent = false
  streetForm: any = {
    value: '',
  }

  showInvisibleCaptcha$ = this.store$.pipe(
    select(selectShowSimpleCaptcha)
  )
  isSecure$ = this.store$.pipe(
    select(selectIsSecure)
  )
  nameZipInstructions$: Observable<boolean> = this.store$.pipe(
    select(selectNameZipInstructions)
  )
  searchResults$ = this.store$.pipe(
    select(selectSearchMembersResults),
    filter((searchItems) => searchItems?.members?.length >= 1)
  )
  authForm$: Observable<FormGroupState<MembershipNumberAuthFormState>> =
    this.store$.pipe(select(selectAuthForm))
  nameAuthForm$: Observable<FormGroupState<NameAuthFormState>> =
    this.store$.pipe(select(selectNameAuthForm))
  allowNameAuth$: Observable<AuthMethods> = this.store$.pipe(
    select(selectAuthMethod)
  )
  showAuthOptionButton$: Observable<boolean> = this.store$.pipe(
    select(selectShowAuthOptionButton)
  )
  defaultUserZipCode$: Observable<string> = this.store$.pipe(
    select(selectDefaultZipCode)
  )
  isSigningIn$: Observable<boolean> = combineLatest([
    this.store$.pipe(select(selectIsLoading(AUTH.ACTION))),
    this.store$.pipe(select(selectIsLoading(MEMBER_INFO.ACTION))),
    this.store$.pipe(select(selectIsLoading(MEMBER_BASIC_INFO.ACTION))),
    this.store$.pipe(select(selectIsLoading(MEMBERS_SEARCH.ACTION))),
    this.store$.pipe(select(selectIsLoading(CALL_STATUS.ACTION))),
  ]).pipe(map((areActionsLoading) => areActionsLoading.some(Boolean)))
  authState$: Observable<[AuthState, boolean]> = combineLatest([
    this.store$.pipe(select(selectAuthState)),
    this.isSigningIn$,
  ])
  isAgent$: Observable<boolean> = this.store$.pipe(select(selectIsAgent))
  isResetNeeded$: Observable<boolean> = this.actions$.pipe(
    ofType<ReturnType<typeof notifyAuthFailure>>(AUTH.FAILURE, AUTH_RESET),
    map(() => true)
  )

  get formState(): FormGroupState<any> {
    return this.allowNameAuth ? this.nameAuthForm : this.authForm
  }

  get controls(): any {
    return this.formState && this.formState.controls
  }

  private captchaInterval = null

  constructor(
    private actions$: Actions,
    private store$: Store<AAAStore>,
    private taggingService: TaggingService,
    public dialog: MatDialog
  ) {
    super()
  }

  ngOnInit() {
    this.subscriptions.push(
      // TODO investigate if it's still required
      this.authState$.subscribe(([authState]) => {
        this.authParams.next(authState)
      }),
      // TODO move to effects (unrelated to forms and captcha)
      this.isSecure$.pipe(filter((isSecure) => !isSecure)).subscribe((_) => {
        this.store$.dispatch(animateSplashscreenHide())
      }),
      this.authForm$.subscribe((form) => {
        this.authForm = form
      }),
      this.nameAuthForm$.subscribe((form) => {
        this.nameAuthForm = form
      }),
      this.allowNameAuth$.subscribe((authMethod) => {
        this.allowNameAuth = authMethod === AuthMethods.MEMBER_NAME
      }),
      this.defaultUserZipCode$.subscribe((zipCode) => {
        ;(this.authForm.controls.zipCode as any).value =
          zipCode || (null as any)
        ;(this.nameAuthForm.controls.zipCode as any).value =
          zipCode || (null as any)
      }),
      this.showInvisibleCaptcha$.subscribe((showInvisibleCaptcha) => {
        this.showInvisibleCaptcha = showInvisibleCaptcha
      }),
      this.isAgent$.subscribe((agent) => {
        this.isAgent = agent
      }),
      this.isResetNeeded$
        .pipe(filter((isResetNeeded) => isResetNeeded))
        .subscribe(() => {
          if (this.captchaElementSimple) {
            this.captchaElementSimple.reCaptchaApi.reset()
          }
          if (this.captchaElement) {
            this.captchaElement.resetCaptcha()
          }
        })
    )

    if (this.allowNameAuth) {
      this.taggingService.setAutomatedEvent(
        events.auth.FORM_NAME_AUTH_PROMPT,
        events.auth.PAGE_TYPE
      )
    }

    if (!this.allowNameAuth) {
      this.taggingService.setAutomatedEvent(
        events.auth.FORM_MEMBERSHIP_AUTH_PROMPT,
        events.auth.PAGE_TYPE
      )
    }
  }

  openPrivacyPolicy() {
    this.taggingService.setClickEvent(
      events.shared.PRIVACY_POLICY_CLICK,
      events.auth.PAGE_TYPE
    )

    window.open('https://www.aaa.com/privacy')
  }

  openTerms() {
    this.taggingService.setClickEvent(
      events.shared.TERMS_AND_CONDITIONS_CLICK,
      events.auth.PAGE_TYPE
    )

    this.store$.dispatch(
      openPromptDialog({
        payload: {
          type: PromptDialogTypes.IFRAME_DIALOG,
          panelClass: 'iframe-dialog',
          params: {
            ariaLabel: 'Terms and conditions content',
            url: 'https://www.aaa.com/AAA/common/drrsmsterms/terms.html',
          },
        },
      })
    )
  }

  openCannotRemember($event) {
    $event.stopPropagation()
    $event.preventDefault()

    this.taggingService.setClickEvent(
      events.auth.LOST_MEMBERSHIP_CLICK,
      events.auth.PAGE_TYPE
    )

    this.store$.dispatch(
      openMessageDialog({
        payload: {
          type: MessageDialogTypes.CANT_REMEMBER_MY_ID,
          title: 'Cannot Remember',
        },
      })
    )
  }

  openNotMember($event) {
    $event?.stopPropagation()
    $event?.preventDefault()

    this.taggingService.setClickEvent(
      events.auth.NOT_A_MEMBER_CLICK,
      events.auth.PAGE_TYPE
    )
    this.store$.dispatch(
      openMessageDialog({
        payload: {
          type: MessageDialogTypes.NOT_MEMBER,
          title: 'Not a Member?',
        },
      })
    )
  }

  openCannotLogin($event) {
    $event?.stopPropagation()
    $event?.preventDefault()

    this.taggingService.setClickEvent(
      events.auth.CANNOT_LOGIN_CLICK,
      events.auth.PAGE_TYPE
    )
    this.store$.dispatch(
      openMessageDialog({
        payload: {
          type: MessageDialogTypes.CANNOT_LOGIN,
          title: 'Can\'t login?',
        },
      })
    )
  }

  doAuth(captchaToken) {
    const { method } = this.authParams.getValue()
    if (this.allowNameAuth) {
      this.searchForMembers(captchaToken)
    } else {
      this.signOn({ captchaToken, method })
    }
  }

  handleSubmit() {
    if (!this.captchaElement && !this.captchaElementSimple) {
      this.handleCaptchaSuccess(null)
      return
    }
    if (this.captchaElement || this.captchaElementSimple) {
      if (!this.showInvisibleCaptcha) {
        if (this.captchaToken === undefined) {
          this.captchaError = true
          return
        }

        this.captchaError = false
        this.doAuth(this.captchaToken)
        return
      } else {
        // to enforce the correct captcha position, sometimes the iframe is loaded after the (ready) events is called
        this.watchRecapchaPosition()

        this.captchaElement.execute()
      }
    }
  }

  handleCaptchaReady() {
    this.watchRecapchaPosition()
  }

  handleCaptchaError() {
    this.showInvisibleCaptcha
      ? this.captchaElementSimple.resetCaptcha()
      : this.captchaElement.resetCaptcha()
  }

  handleCaptchaSuccess(captchaToken: string) {
    this.doAuth(captchaToken)
  }

  handleCaptchaSuccessSimple(captchaToken: string) {
    this.captchaToken = captchaToken
    this.captchaError = false
  }

  signOn({ captchaToken = null }: BaseAuthRequestParams) {
    const zipCode = this.formState.value.zipCode
    const firstName = this.formState.value.firstName
    const lastName = this.formState.value.lastName
    const memberNumber =
      this.formState.value.membershipNumber &&
      this.formState.value.membershipNumber.replace(/\s/g, '')

    if (
      Boolean(zipCode) &&
      (Boolean(memberNumber) || (Boolean(firstName) && Boolean(lastName)))
    ) {
      this.taggingService.setClickEvent(
        events.auth.FORM_MEMBERSHIP_AUTH_REQUEST,
        events.auth.PAGE_TYPE
      )

      const authMethod = this.allowNameAuth
        ? AuthMethods.MEMBER_NAME
        : AuthMethods.MEMBERSHIP_NUMBER
      const identifier = this.allowNameAuth
        ? { firstName, lastName }
        : { memberNumber }
      const token = captchaToken !== null ? { captchaToken } : {}

      const payload = {
        zipCode: this.formState.value.zipCode,
        isSecure: false,
        method: authMethod,
        isAgent: this.isAgent,
        ...identifier,
        ...token,
      }

      this.store$.dispatch(
        requestAuth({
          payload,
        })
      )
    }
  }

  useMemberNumber($event?) {
    if ($event) {
      $event.stopPropagation()
      $event.preventDefault()
    }

    this.subscriptions.push(
      this.showAuthOptionButton$.subscribe((showAuthOptionButton) => {
        if(showAuthOptionButton) {
          this.isNameTabActive = false
          this.isNumberTabActive = true
        }
      }),
    )

    this.store$.dispatch(
      setAuthMethod({ payload: AuthMethods.MEMBERSHIP_NUMBER })
    )

    this.taggingService.setClickEvent(
      events.auth.USE_FORM_MEMBERSHIP_CLICK,
      events.auth.PAGE_TYPE
    )
  }

  useMemberName($event?) {
    if ($event) {
      $event.stopPropagation()
      $event.preventDefault()
    }

    this.subscriptions.push(
      this.showAuthOptionButton$.subscribe((showAuthOptionButton) => {
        if(showAuthOptionButton) {
          this.isNumberTabActive = false
          this.isNameTabActive = true
        }
      }),
    )

    this.store$.dispatch(setAuthMethod({ payload: AuthMethods.MEMBER_NAME }))

    this.taggingService.setClickEvent(
      events.auth.USE_FORM_NAME_CLICK,
      events.auth.PAGE_TYPE
    )
  }

  // continuously check captcha position and place it on
  // the middle of the screen
  watchRecapchaPosition() {
    this.captchaInterval = setInterval(() => {
      const iframes: any = document.getElementsByTagName('iframe')
      let parent = null
      for (let i = 0; i < iframes.length; i++) {
        const title = iframes[i].getAttribute('title')
        if (title === 'recaptcha challenge') {
          parent = iframes[i].parentNode
          const recaptchaWindow = parent.parentNode
          // repositioning only happens if the challenge is visible
          if (recaptchaWindow.style.opacity === '1') {
            recaptchaWindow.style.position = 'fixed'
            recaptchaWindow.style.top = '36px'
            if (window.innerHeight < 640) {
              recaptchaWindow.style.bottom = '50px'
            } else {
              recaptchaWindow.style.bottom = ''
            }
            const captchaWidth = recaptchaWindow.style.width || 400
            const catpchaHeight = recaptchaWindow.style.height || 580
            recaptchaWindow.style.left = `${
              window.innerWidth / 2 - captchaWidth / 2
            }px`

            if (window.innerWidth < captchaWidth) {
              parent.style.width = `${window.innerWidth - 10}px`
            }
            if (window.innerHeight < catpchaHeight) {
              parent.style.height = `${window.innerHeight - 10}px`
              parent.style.overflowY = 'scroll'
            }

            recaptchaWindow.style.right = ''
          }
          recaptchaWindow.style.overflow = 'visible'
        }
      }
      // stop listening when the challenge is solved and the captchaToken is defined
      if (this.captchaToken) {
        clearInterval(this.captchaInterval)
        return
      }
    }, 300)
  }

  /**
   * dispatch action to request members info
   *
   * @param captchaToken
   */
  searchForMembers(captchaToken = null) {
    this.taggingService.setClickEvent(
      events.auth.FORM_NAME_AUTH_REQUEST,
      events.auth.PAGE_TYPE
    )

    const firstName = this.formState.value.firstName
    const lastName = this.formState.value.lastName
    const zipCode = this.formState.value.zipCode
    const payload = {
      captchaToken,
      zipCode,
      firstName,
      lastName,
    }

    this.store$.dispatch(searchMembersRequest({ payload }))
  }

  isWebApp() {
    return !isMobileApp()
  }
}
