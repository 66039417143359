import { Component, Input, EventEmitter, Output } from '@angular/core'
import { NgForm, ControlContainer } from '@angular/forms'

export class CheckEvent {
  checked: boolean
  value: any
}

@Component({
  selector: 'app-radio-square',
  templateUrl: './radio-square.component.html',
  styleUrls: ['./radio-square.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class RadioSquareComponent {
  @Input() name = ''

  @Input() required = false

  @Input() id = ''

  @Input() value: any = null

  @Input() tabIndex = 0

  checkedValue = false
  @Input() get checked(): boolean {
    return this.checkedValue
  }

  set checked(newValue) {
    this.checkedValue = newValue
    this.active = this.checkedValue ? 'active' : 'inactive'
  }

  @Output() checkedChange: EventEmitter<boolean> = new EventEmitter()

  active = 'inactive'

  focus = 'nofocus'

  handleChange() {
    this.checked = !this.checked
    this.checkedChange.emit(this.checked)
  }

  // These weird callbacks are necessary in order to ensure focus is visible to
  // the user, since in order to style the radio button the way we want to, the
  // real one is hidden off-screen.
  handleFocus() {
    this.focus = 'focus'
  }

  handleBlur() {
    this.focus = 'nofocus'
  }
}
