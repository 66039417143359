import { Component, OnInit, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { DialogData } from '../../ui.types'

@Component({
  selector: 'app-pwa-services',
  templateUrl: './pwa-services.component.html',
  styleUrls: ['./pwa-services.component.scss'],
})
export class PWAServicesComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  ngOnInit() {}
}
