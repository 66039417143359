<div class="dialog">
  <h1 mat-dialog-title class="location-services_title">
    {{getTitle()}}
  </h1>
  <div mat-dialog-content class="tall">
    <ng-container
      *ngIf="hasInstructions(); else genericHelp">
      <!--Safari Mobile-->
      <ng-container *ngIf="isSafariMobile()">
        <div class="location-services-help_instructions">
          <ol>
            <li>
              Click aA on Address Bar > Website settings > Location > Allow
            </li>
            <li>
              Refresh the page
            </li>
          </ol>
        </div>
        <div class="location-services-help_instructions_image">
          <app-img
            src="assets/images/location/safari-mobile-1.jpg"
            alt="Instructions for safari mobile step 1"
            width="100%"
          >
          </app-img>
        </div>
        <div class="location-services-help_title">
          If this doesn't work, make sure that Location Services is on
        </div>
        <div class="location-services-help_instructions">
          <ol>
            <li>
              Go to Settings > Privacy > Location Services.
            </li>
            <li>
              Make sure that Location Services is on.
            </li>
          </ol>
        </div>
        <div class="location-services-help_instructions_image">
          <app-img
            src="assets/images/location/safari-mobile-2.jpg"
            alt="Instructions for safari mobile step 2"
            width="100%"
          >
          </app-img>
        </div>
      </ng-container>
      <!--Chrome Mobile-->
      <ng-container *ngIf="isChromeMobile()">
        <div class="location-services-help_instructions">
          <ol>
            <li>
              Click <span [innerHTML]="'&#8942;'"></span> on Address Bar > Site Settings > Location > Allow
            </li>
            <li>
              Refresh the page
            </li>
          </ol>
        </div>
        <div class="location-services-help_instructions_image">
          <app-img
            src="assets/images/location/chrome-mobile-1.jpg"
            alt="Instructions for chrome mobile step 1"
            width="100%"
          >
          </app-img>
        </div>
        <div class="location-services-help_title">
          If this doesn't work, make sure that Location Services is on
        </div>
        <div class="location-services-help_instructions">
          <ol>
            <li>
              Go to Settings > Privacy > Location.
            </li>
            <li>
              Make sure that Google Chrome has access to Location.
            </li>
          </ol>
        </div>
        <div class="location-services-help_instructions_image">
          <app-img
            src="assets/images/location/chrome-mobile-2.jpg"
            alt="Instructions for chrome mobile step 2"
            width="100%"
          >
          </app-img>
        </div>
      </ng-container>
      <!--Safari Desktop-->
      <ng-container *ngIf="isSafariDesktop()">
        <div class="location-services-help_instructions">
          <ol>
            <li>
              Click on Safari > Settings For This Website > Location > Allow
            </li>
            <div class="location-services-help_instructions_image">
              <app-img
                src="assets/images/location/safari-desktop.jpg"
                alt="Instructions for safari desktop"
                width="100%"
              >
              </app-img>
            </div>
            <li>
              Refresh the page
            </li>
          </ol>
        </div>
      </ng-container>
      <!--Chrome Desktop-->
      <ng-container *ngIf="isChromeDesktop()">
        <div class="location-services-help_instructions">
          <ol>
            <li>
              Click
              <mat-icon aria-hidden="false"
                        aria-label="lock icon"
                        [inline]="true">
                lock
              </mat-icon>
              (Lock Icon) > Location > Allow
            </li>
            <div class="location-services-help_instructions_image">
              <app-img
                src="assets/images/location/chrome-desktop.jpg"
                alt="Instructions for chrome desktop"
                width="100%"
              >
              </app-img>
            </div>
            <li>
              Refresh the page
            </li>
          </ol>
        </div>
      </ng-container>
    </ng-container>
    <!--Native iOS-->
    <ng-container *ngIf="isNativeIOS()">
      <div class="location-services-help_instructions">
        <ol>
          <li>
            Go to Settings > Privacy > Location Services.
          </li>
          <li>
            Make sure that Location Services is on.
          </li>
          <li>
            Select "Always" options in AAA app.
          </li>
          <div class="location-services-help_instructions_image">
            <app-img
              src="assets/images/location/ios-native.jpg"
              alt="Instructions for IOS devices"
              width="100%"
            >
            </app-img>
          </div>
          <li>
            Restart the AAA application.
          </li>
        </ol>
      </div>
    </ng-container>
    <!--Native Android-->
    <ng-container *ngIf="isNativeAndroid()">
      <div class="location-services-help_instructions">
        <ol>
          <li>
            Go to Settings > Privacy > Location.
          </li>
          <li>
            Make sure that AAA application has access to Location.
          </li>
          <div class="location-services-help_instructions_image">
            <app-img
              src="assets/images/location/android-native.jpg"
              alt="Instructions for Android devices"
              width="100%"
            >
            </app-img>
          </div>
          <li>
            Restart the AAA application.
          </li>
        </ol>
      </div>
    </ng-container>
    <ng-template #genericHelp>
      <!--Others-->
      <div class="intro-block">
        <p>
          To help us locate your vehicle, please share your location. Click the
          information icon to the left of your browser's address bar and set
          Location to "Allow."
        </p>
        <p>
          If you can't see an option to share your location, your device may not
          have location services enabled. In this case, please follow the
          instructions for your device and operating system, as shown below:
        </p>
      </div>
      <div class="instruction-links">
        <div class="instruction-header">Desktop</div>
        <div>
          <a
            target="_blank"
            href="https://support.microsoft.com/en-us/help/4468240/windows-10-location-service-and-privacy"
          >Windows PC</a
          >
        </div>
        <div>
          <a target="_blank" href="https://support.apple.com/en-us/HT204690"
          >Mac/OSX</a
          >
        </div>
      </div>
      <div class="instruction-links">
        <div class="instruction-header">Mobile</div>
        <div>
          <a target="_blank" href="https://support.apple.com/en-ca/HT207092"
          >iOS</a
          >
        </div>
        <div>
          <a
            target="_blank"
            href="https://support.google.com/accounts/answer/3467281?hl=en"
          >Android</a
          >
        </div>
      </div>
      <p>
        Once you have enabled location services for this site, please reload the
        page to continue.
      </p>
    </ng-template>
  </div>
  <button mat-dialog-close class="mat-dialog-close" aria-label="dismiss this message">
    <icon-close></icon-close>
  </button>
</div>
