import { Component, HostListener } from '@angular/core'
import { AbstractComponent } from './abstract.component'

@Component({
  template: ''
})
export class AbstractResponsiveComponent extends AbstractComponent {
  mobileWidth = 640
  isMobile: boolean

  constructor(){
    super()
    this.isMobile = window.innerWidth <= this.mobileWidth
  }

  @HostListener('window:resize', ['$event'])
  onResize($event: { target: { innerWidth: number } }) {
    this.isMobile = $event.target.innerWidth <= this.mobileWidth
  }
}
