<app-select
  [elementId]="fieldName + '-select'"
  [placeholder]="'See other ' + fieldName + 's'"
  [items$]="internalItems$ "
  [searchable]="isDesktop"
  (selectionMade)="handleChange($event)">
</app-select>

<div *ngIf="otherSelected" class="select-or-text_other">
  <label [for]="fieldName + '-input'">Enter your {{ fieldName }}</label>
  <input
    [id]="fieldName + '-input'"
    type="text"
    [placeholder]="'Type ' + fieldName + '...'"
    [(ngModel)]="otherValue"
    [maxLength]="maxLength"
    (keyup)="handleKeyUpText($event)"
  />
  <app-button
    type="button"
    [disabled]="!otherValue"
    [inverted]="true"
    (buttonClick)="submitOtherValue()"
    >DONE
  </app-button>
</div>
