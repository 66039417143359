import { Component, Input, ElementRef } from '@angular/core'

@Component({
  selector: 'app-dragger',
  templateUrl: './dragger.component.html',
  styleUrls: ['./dragger.component.scss'],
})
export class DraggerComponent {
  @Input() target: ElementRef
  @Input() inverted = false
  active = false
  start = 0
  startHeight = 0

  touchDown($event) {
    this.active = true
    this.start = $event.touches.item(0).screenY
    this.startHeight = this.target.nativeElement.offsetHeight
    $event.preventDefault()
    $event.stopPropagation()
  }

  touchMove($event) {
    if (!this.active) {
      return
    }

    $event.preventDefault()
    $event.stopPropagation()

    const y = $event.touches.item(0).screenY

    const difference = y - this.start
    this.target.nativeElement.style.height =
      this.startHeight + difference + 'px'
  }

  touchUp($event) {
    $event.preventDefault()
    $event.stopPropagation()
    this.active = false
  }
}
