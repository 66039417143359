import { isMobileApp } from './app-detect';

export function isMobileBrowser() {
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    return true
  } else {
    return false
  }
}

export function isSafari() {
  if (/Safari/i.test(navigator.userAgent)) {
    if (isChrome() || isChromeIOS()) { // Chrome have both Chrome and Safari userAgents
      return false;
    } else {
      return true;
    }
  }
  return false;
}

export function isChrome() {
  return /Chrome/i.test(navigator.userAgent)
}

export function isChromeIOS() {
  return navigator.userAgent.match('CriOS')
}

export function isSafariMobile() {
  return isSafari() && isMobileBrowser() && !isMobileApp()
}

export function isChromeMobile() {
  return (isChrome() || isChromeIOS()) && isMobileBrowser() && !isMobileApp()
}

export function isSafariDesktop() {
  return isSafari() && !isMobileBrowser() && !isMobileApp()
}

export function isChromeDesktop() {
  return isChrome() && !isMobileBrowser() && !isMobileApp()
}
