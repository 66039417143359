export default {
  PAGE_TYPE: 'Verification Home',
  LOST_MEMBERSHIP_CLICK: 'Don\'t Have Membership Number',
  NOT_A_MEMBER_CLICK: 'Not a Member',
  CANNOT_LOGIN_CLICK: 'Can\'t Login',
  AAA_NATIONAL_AUTH_REQUEST: 'Membership Validation Request - Secure',
  AAA_NATIONAL_AUTH_SUCCESS: 'Membership Validation Success - Secure',
  AAA_NATIONAL_AUTH_FAILURE: 'Membership Validation Failed - Secure',
  AAA_MOBILE_AUTH_REQUEST: 'Membership Validation Request - Token',
  AAA_MOBILE_AUTH_SUCCESS: 'Membership Validation Success - Token',
  AAA_MOBILE_AUTH_FAILURE: 'Membership Validation Failed - Token',
  FORM_MEMBERSHIP_AUTH_PROMPT: 'Membership Validation Prompt',
  FORM_MEMBERSHIP_AUTH_REQUEST: 'Membership Validation Request',
  FORM_MEMBERSHIP_AUTH_SUCCESS: 'Membership Validation Success',
  FORM_MEMBERSHIP_AUTH_FAILURE: 'Membership Validation Failed',
  FORM_NAME_AUTH_PROMPT: 'Membership Validation Prompt - Name',
  FORM_NAME_AUTH_REQUEST: 'Membership Validation Request - Name',
  FORM_NAME_AUTH_SUCCESS: 'Membership Validation Success - Name',
  FORM_NAME_AUTH_FAILURE: 'Membership Validation Failed - Name',
  MEMBER_VALIDATION_INVALID_ZIP_CODE: 'Invalid member info',
  MEMBER_VALIDATION_INVALID_NUMBER: 'Error validating member',
  MEMBER_VALIDATION_INACTIVE_MEMBER: 'Membership not active',
  FORM_RAP_VALIDATION_SUCCESS: 'RAP Validation Success',
  FORM_RAP_VALIDATION_FAILURE: 'RAP Validation Failed',
  MEMBER_NO_MATCHES_PROMPT: 'Verification Membership Issue Prompt',
  USE_FORM_NAME_CLICK: 'Used Member Name',
  USE_FORM_MEMBERSHIP_CLICK: 'Used Member Number',
  MEMBER_ISSUE_PAGE_TYPE: 'Verification Membership Issue',
  MEMBER_ISSUE_PROMPT: 'Verification Membership Issue Prompt'
}
