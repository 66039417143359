import { Injectable } from '@angular/core'
import { ofType, createEffect, Actions } from '@ngrx/effects'
import { ROUTER_NAVIGATED } from '@ngrx/router-store'
import {
  map,
  filter,
  catchError,
  withLatestFrom,
  tap,
  mergeMap,
  switchMap,
} from 'rxjs/operators'
import { IndexedCollection, PayloadedAction } from 'src/app/shared/types'
import {
  setVehicleStep,
  VEHICLE_YEARS,
  VEHICLE_MAKES,
  VEHICLE_MODELS,
  SET_VEHICLE_YEAR,
  SET_VEHICLE_MAKE,
  SET_VEHICLE_DETAILS,
  EDIT_VEHICLE,
  clearEditMetadata,
  CREATE_NEW_VEHICLE,
  notifyVehicleYearsFailure,
  vehicleYearsSuccess,
  vehicleMakesSuccess,
  notifyVehicleMakesFailure,
  vehicleModelsSuccess,
  notifyVehicleModelsFailure,
  notifySetVehicleDataFailure,
  SET_VEHICLE_MODEL_AND_TYPE,
  notifyVehicleModelTypesFailure,
  vehicleModelTypesSuccess,
  VEHICLE_MODEL_TYPES,
} from './vehicle.actions'
import { Params, Router } from '@angular/router'
import {
  setVehicle,
  ASSIGN_EXISTING_VEHICLE,
  SET_VEHICLE,
} from '../member/member.actions'
import { VehicleService } from './vehicle.service'
import { from, of } from 'rxjs'
import {
  VehicleYears,
  VehicleMakes,
  VehicleModels,
  VehicleEditMetadata,
  ModelTypesResponse,
  ModelType,
} from './vehicle.types'
import { AAAStore } from 'src/app/store/root-reducer'
import { Store, select } from '@ngrx/store'
import { selectWorkingVehicle, selectEditMetadata } from './vehicle.selectors'
import { VehicleEditState } from './vehicle.reducer'
import { VehicleData } from '../member/member.types'
import { selectUrl } from 'src/app/store/router.selectors'
import { WizardService } from '../wizard/wizard.service'
import { currentEditStepLocation } from '../wizard/wizard.selectors'
import { selectNeedsTow } from '../issue/issue.selectors'
import { StepTypes, SubmitSections } from '../ui/ui.types'
import { ErrorReportingService } from 'src/app/shared/services/error-reporting.service'
import { selectActiveCallStatus } from '../dashboard/calls-statuses/call-status.selectors'

const NEXT_DESTINATIONS = {
  [CREATE_NEW_VEHICLE]: { step: 'vehicle', section: 'makes' },
  [SET_VEHICLE_YEAR]: { step: 'vehicle', section: 'makes' },
  [SET_VEHICLE_MAKE]: { step: 'vehicle', section: 'models' },
  [SET_VEHICLE_MODEL_AND_TYPE]: { step: 'vehicle', section: 'details' },
  [SET_VEHICLE_DETAILS]: { step: 'submit' },
}

@Injectable()
export class VehicleEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<AAAStore>,
    private router: Router,
    private _vehicleService: VehicleService,
    private errorReportingService: ErrorReportingService,
    private wizardService: WizardService
  ) {}

  handleNavigation = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      map(
        (action: PayloadedAction) => action.payload.routerState.root.queryParams
      ),
      filter((params: Params) => params.step === 'vehicle'),
      map(({ section }) => setVehicleStep({ payload: { step: section || '' } }))
    )
  )

  handleVehicleEdit = createEffect(() =>
    this.actions$.pipe(
      ofType(EDIT_VEHICLE),
      switchMap((action: PayloadedAction) => {
        this.router.navigate(['steps'], {
          queryParams: {
            step: StepTypes.VEHICLE,
            section: action.payload.section,
          },
        })
        return []
      })
    )
  )

  loadVehicleYears$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VEHICLE_YEARS.REQUEST),
      switchMap(() =>
        from(this._vehicleService.getYears()).pipe(
          map((years: VehicleYears) => vehicleYearsSuccess({ payload: years })),
          catchError((error) =>
            this.errorReportingService.notifyErrorObservable(
              error,
              notifyVehicleYearsFailure
            )
          )
        )
      )
    )
  )

  loadVehicleMakes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VEHICLE_MAKES.REQUEST),
      withLatestFrom(this.store$.pipe(select(selectWorkingVehicle))),
      switchMap(
        ([action, workingVehicle]: [PayloadedAction, VehicleEditState]) =>
          from(
            this._vehicleService.getMakes(
              +(action.payload || workingVehicle.year)
            )
          ).pipe(
            map((makes: VehicleMakes) =>
              vehicleMakesSuccess({ payload: makes })
            ),
            catchError((error) =>
              this.errorReportingService.notifyErrorObservable(
                error,
                notifyVehicleMakesFailure
              )
            )
          )
      )
    )
  )

  loadVehicleModels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VEHICLE_MODELS.REQUEST),
      withLatestFrom(this.store$.pipe(select(selectWorkingVehicle))),
      switchMap(
        ([action, workingVehicle]: [PayloadedAction, VehicleEditState]) => {
          const payload = {
            ...workingVehicle,
            ...action.payload,
          }

          return from(
            this._vehicleService.getModels(+payload.year, payload.make)
          ).pipe(
            map((models: VehicleModels) =>
              vehicleModelsSuccess({ payload: models })
            ),
            catchError((error) =>
              this.errorReportingService.notifyErrorObservable(
                error,
                notifyVehicleModelsFailure
              )
            )
          )
        }
      )
    )
  )

  loadVehicleTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VEHICLE_MODEL_TYPES.REQUEST),
      switchMap((action: PayloadedAction) =>
        from(
          this._vehicleService.getModelTypes(action.payload)
        ).pipe(
          map((modelTypes: IndexedCollection<ModelType[]>) =>
            vehicleModelTypesSuccess({ payload: modelTypes })
          ),
          catchError((error) =>
          this.errorReportingService.notifyErrorObservable(
            error,
            notifyVehicleModelTypesFailure
          )
        )
      ))
    )
  )

  // We have an existing vehicle to assign to the call.  This just tags it in and moves on.
  assignExistingVehicle$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ASSIGN_EXISTING_VEHICLE),
        switchMap((action) =>
          of(action).pipe(
            withLatestFrom(
              this.store$.pipe(select(selectActiveCallStatus)),
              this.store$.pipe(select(currentEditStepLocation)),
              this.store$.pipe(select(selectNeedsTow))
            ),
            filter(([_, activeCallStatus]) => activeCallStatus === null),
            map(([_, activeCallStatus, currentStepUrl, needsTow]) => {
              // if we came from any edition (right panel) we will redirect the user
              // to the page whom triggered the edition
              if (currentStepUrl && !needsTow) {
                return this.wizardService.backToEditUrl(currentStepUrl)
              }

              // If we didn't trigger the edition, but instead we manually select something
              // from the wizard, we will go to towing only when is needed if not, will go back to summary
              const stepsection = needsTow ? StepTypes.TOWING : StepTypes.SUBMIT
              const params = {
                step: stepsection,
                section: SubmitSections.SUMMARY,
              }

              if (needsTow) {
                delete params['section']
              }

              this.router.navigate([], { queryParams: params })
            }),
            catchError((error) =>
              from(this.errorReportingService.notifyError(error))
            )
          )
        )
      ),
    { dispatch: false }
  )

  // This effect handles edits - once data is stored, this determines whether we are in the middle
  // of editing a vehicle, and if so, where to go when done.
  setVehicleData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CREATE_NEW_VEHICLE,
        SET_VEHICLE_YEAR,
        SET_VEHICLE_MAKE,
        SET_VEHICLE_MODEL_AND_TYPE,
        SET_VEHICLE_DETAILS
      ),
      switchMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store$.pipe(select(selectEditMetadata)),
            this.store$.pipe(select(selectUrl))
          ),
          filter(
            ([payloadedAction, metadata, currentUrl]: [
              PayloadedAction,
              VehicleEditMetadata,
              string
            ]) =>
              this.navigationNextLocation(
                payloadedAction.type,
                metadata.completionUrl,
                currentUrl
              )
          ),
          withLatestFrom(this.store$.pipe(select(selectWorkingVehicle))),
          mergeMap(([_, vehicleEditState]: [never, VehicleEditState]) => {
            const actions: Array<any> = []

            // Sometimes we are editing an existing vehicle with an ID.  At other times we are "editing"
            // an earlier stage of a vehicle which is still new.
            if (vehicleEditState && vehicleEditState.id) {
              actions.push(
                setVehicle({ payload: vehicleEditState as VehicleData })
              )
            }

            return actions
          }),
          catchError((error) =>
            this.errorReportingService.notifyErrorObservable(
              error,
              notifySetVehicleDataFailure
            )
          )
        )
      )
    )
  )

  // This effect complements the above setVehicleData$, which takes care of most saving/routing -
  // this effect is needed if setVehicleDetails is carried out in main flow, rather than by a vehicle edit.
  setVehicleDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SET_VEHICLE_DETAILS),
      withLatestFrom(
        this.store$.pipe(select(selectWorkingVehicle)),
        this.store$.pipe(select(selectEditMetadata))
      ),
      // If a return URL is set, SetVehicle is taken care of by the main flow
      filter(
        ([_, _2, metadata]: [never, VehicleEditState, VehicleEditMetadata]) =>
          !metadata.completionUrl
      ),
      map(([_, workingVehicle, _2]: [never, VehicleEditState, never]) =>
        setVehicle({ payload: workingVehicle as VehicleData })
      )
    )
  )

  /**
   * We need to check if the vehicle information has been set before
   * redirecting the user to the towing view, this will avoid
   * a conflict between the steps state
   */
  handleSetVehicle$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SET_VEHICLE),
        withLatestFrom(this.store$.pipe(select(selectEditMetadata))),
        // If a return URL is set, SetVehicle is taken care of by the main flow
        filter(
          ([_, metadata]: [PayloadedAction, VehicleEditMetadata]) =>
            !metadata.completionUrl
        ),
        withLatestFrom(this.store$.pipe(select(selectNeedsTow))),
        tap(([_, needsTow]) => {
          const queryParams = {
            step: needsTow ? StepTypes.TOWING : StepTypes.SUBMIT,
          }
          this.router.navigate([], { queryParams })
        })
      ),
    { dispatch: false }
  )

  // If the user navigates away, forget about editing - don't want later vehicle changes to
  // navigate weirdly
  clearEditOnCancel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      map(
        (action: PayloadedAction) => action.payload.routerState.root.queryParams
      ),
      filter(
        (params: Params) => params.step !== StepTypes.VEHICLE || !params.section
      ),
      withLatestFrom(this.store$.pipe(select(selectEditMetadata))),
      filter(
        ([action, metadata]: [PayloadedAction, VehicleEditMetadata]) =>
          !!metadata.completionUrl
      ),
      map(clearEditMetadata)
    )
  )

  navigationNextLocation(type, completionUrl, currentUrl = null): boolean {
    // Ugly special case for makes...
    // 1. Don't navigate "back" if back is the same as the current location...
    if (
      completionUrl &&
      completionUrl !== currentUrl &&
      type !== SET_VEHICLE_MAKE
    ) {
      this.router.navigateByUrl(completionUrl)
      return true
    }

    const queryParams = NEXT_DESTINATIONS[type]

    this.router.navigate([], { queryParams })
    // 2. But if we are finishing the edit, still tell the caller
    return completionUrl === currentUrl
  }
}
