<input #target type="text"
       [class]="active ? 'active' : 'inactive'"
       (change)="textInput($event)"
       [attr.maxlength]="maxChars"
       [attr.tabIndex]="active ? undefined : -1" />
<button type="button"
        aria-label="Accept"
        [class]="active ? 'active' : 'inactive'"
        (click)="toggleText() && target.focus()">
    <div></div>
</button>
<ng-content></ng-content>
