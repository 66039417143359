import { Input, Component } from '@angular/core'
import { ColorName } from '../pipes'

@Component({
  template: ''
})
export class AbstractIconComponent {
  @Input() color = '#003893'
  @Input() colorName: ColorName = 'primary'
}
