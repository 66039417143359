import { XHRService, RequestOptions } from 'src/app/shared/services/xhr.service'
import { Injectable } from '@angular/core'
import {
  BatteryQuote,
  BatteryQuotePayload,
  BatteryQuoteResponse,
} from './quotes.types'
import { environment } from 'src/environments/environment'
import { IndexedCollection } from 'src/app/shared/types'
import { generateVehicleSlug } from 'src/app/shared/utils/generateVehicleSlug'
import { TaggingService } from 'src/app/modules/tagging/tagging.service'
import events from '../tagging/events'

@Injectable({
  providedIn: 'root',
})
export class QuotesService {
  private requestOptions: RequestOptions = {
    withCredentials: false,
    overrideBaseURL: environment.aaaQuoteServicesUrl,
    useCustomAAAheaders: false,
  }

  constructor(
    private xhr: XHRService,
    private tagging: TaggingService
  ) { }

  checkNotApplicableBatteryQuotes(quotes) {
    let failure = false;
    for (let index = 0; index < quotes.length; index++) {
      const element = quotes[index];
      if (!element?.MemberPrice || element?.MemberPrice === 'N/A') {
        failure = true
      }
    }

    return failure;
  }

  tagBatteryQuotes(quotePayload, batteryQuotes) {
    const failedQuotes = this.checkNotApplicableBatteryQuotes(batteryQuotes)
    if (failedQuotes) {
      this.tagging.setAutomatedEvent(
        events.dashboard.BATTERY_QUOTES_NO_PRICE,
        events.dashboard.SERVICE_TRACKING_PAGE_TYPE,
        null,
        {
          vehicleYear: quotePayload.imCarYear,
          vehicleMake: quotePayload.smCarMake,
          vehicleModel: quotePayload.smCarModel,
          zipCode: quotePayload.imZipCode
        }
      )
    } else {
      this.tagging.setAutomatedEvent(
        events.dashboard.BATTERY_QUOTES_SUCCESS,
        events.dashboard.SERVICE_TRACKING_PAGE_TYPE
      )
    }
  }

  async getBatteryQuotes(
    data: BatteryQuotePayload
  ): Promise<IndexedCollection<Array<BatteryQuote>>> {
    try {
      const response = await this.xhr.request<BatteryQuoteResponse>(
        {
          url: 'batteryinfo/getBatteryQuoteDetail',
          params: data,
          method: 'GET',
          headers: {
            Accept: 'application/json',
          },
        },
        {...this.requestOptions, retryAll: false}
      )

      // TODO how to handle multiple quotes[] entries?
      const batteryQuotes = response.services.batteryinfo.quotes[0].quote || []
      const id = generateVehicleSlug(
        {
          year: data.imCarYear,
          make: data.smCarMake,
          model: data.smCarModel,
        },
        data.imZipCode
      )

      this.tagBatteryQuotes(data, batteryQuotes);

      return {
        [id]: batteryQuotes,
      }
    } catch(error) {
      console.log('No battery quote was found.', data)
      throw new Error(error)
    }
  }
}
