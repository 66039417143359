import { createAction, props } from '@ngrx/store'
import { IndexedCollection } from 'src/app/shared/types'
import { createAsyncConstants } from 'src/app/shared/utils'
import {
  GetVehicleModelsParams,
  EditVehicleParams,
  VehicleEditMetadata,
  VehicleYears,
  VehicleMakes,
  VehicleModels,
  ModelTypesResponse,
  ModelType,
} from './vehicle.types'

export const SET_VEHICLE_STEP = 'SET_VEHICLE_STEP'
export const EDIT_VEHICLE = 'EDIT_VEHICLE'
export const SET_EDIT_METADATA = 'SET_EDIT_METADATA'
export const CLEAR_EDIT_METADATA = 'CLEAR_EDIT_METADATA'

export const VEHICLE_YEARS = createAsyncConstants('VEHICLE_YEARS')
export const VEHICLE_MAKES = createAsyncConstants('VEHICLE_MAKES')
export const VEHICLE_MODELS = createAsyncConstants('VEHICLE_MODELS')
export const VEHICLE_MODEL_TYPES = createAsyncConstants('VEHICLE_MODEL_TYPES')

export const CREATE_NEW_VEHICLE = 'CREATE_NEW_VEHICLE'
export const SET_VEHICLE_YEAR = 'SET_VEHICLE_YEAR'
export const SET_VEHICLE_MAKE = 'SET_VEHICLE_MAKE'
export const SET_VEHICLE_MODEL_AND_TYPE = 'SET_VEHICLE_MODEL_AND_TYPE'
export const SET_VEHICLE_DATA_FAILURE = 'SET_VEHICLE_DATA_FAILURE'
export const SET_VEHICLE_DETAILS = 'SET_VEHICLE_DETAILS'

export const setVehicleStep = createAction(
  SET_VEHICLE_STEP,
  props<{ payload: { step: string } }>()
)

export const editVehicle = createAction(
  EDIT_VEHICLE,
  props<{ payload: EditVehicleParams }>()
)

export const setEditMetadata = createAction(
  SET_EDIT_METADATA,
  props<{ payload: VehicleEditMetadata }>()
)

export const clearEditMetadata = createAction(CLEAR_EDIT_METADATA)

export const requestVehicleYears = createAction(VEHICLE_YEARS.REQUEST)

export const vehicleYearsSuccess = createAction(
  VEHICLE_YEARS.SUCCESS,
  props<{ payload: VehicleYears }>()
)
export const notifyVehicleYearsFailure = createAction(VEHICLE_YEARS.FAILURE)

export const requestVehicleMakes = createAction(
  VEHICLE_MAKES.REQUEST,
  props<{ payload: string | number }>()
)
export const vehicleMakesSuccess = createAction(
  VEHICLE_MAKES.SUCCESS,
  props<{ payload: VehicleMakes }>()
)
export const notifyVehicleMakesFailure = createAction(VEHICLE_MAKES.FAILURE)

export const requestVehicleModels = createAction(
  VEHICLE_MODELS.REQUEST,
  props<{ payload: GetVehicleModelsParams }>()
)
export const vehicleModelsSuccess = createAction(
  VEHICLE_MODELS.SUCCESS,
  props<{ payload: VehicleModels }>()
)
export const notifyVehicleModelsFailure = createAction(VEHICLE_MODELS.FAILURE)

export const requestVehicleModelTypes = createAction(
  VEHICLE_MODEL_TYPES.REQUEST,
  props<{ payload: {
    year: number,
    make: string,
    model: string
  } }>()
)

export const vehicleModelTypesSuccess = createAction(
  VEHICLE_MODEL_TYPES.SUCCESS,
  props<{ payload: IndexedCollection<ModelType[]> }>()
)

export const notifyVehicleModelTypesFailure = createAction(VEHICLE_MODEL_TYPES.FAILURE)

export const createNewVehicle = createAction(
  CREATE_NEW_VEHICLE,
  // FIXME add type
  props<{ payload: any }>()
)

export const setVehicleYear = createAction(
  SET_VEHICLE_YEAR,
  props<{ payload: string | number }>()
)

export const setVehicleMake = createAction(
  SET_VEHICLE_MAKE,
  props<{ payload: string }>()
)

export const setVehicleModelAndType = createAction(
  SET_VEHICLE_MODEL_AND_TYPE,
  props<{ payload: {
    driveType: string | null,
    model: string,
  } }>()
)

export const setVehicleDetails = createAction(
  SET_VEHICLE_DETAILS,
  // FIXME add type
  props<{ payload: any }>()
)

export const notifySetVehicleDataFailure = createAction(
  SET_VEHICLE_DATA_FAILURE
)
