import { AARData } from './aar/aar.types'
import {
  AAALocation,
  GoogleLocation,
  GoogleLocationMarker,
  LocationWithMarker,
  TowLocation,
} from './location.types'
import { concatAddress } from '../../shared/utils'
import { GOOGLE_LOCATION_TYPES } from './google-geocode/types'
import { hasHighwayName } from './google-geocode/google-geocode.utils'
import { MemberInfo } from '../member/member.types'

const PO_BOX_REGEX = /\b((P(ost|ostal)?([ \.]*o(ffice)?))([ \.]*Box))\b/i

export function aarAppendLocationMarker(aar: AARData): {
  location: TowLocation
  marker: GoogleLocationMarker
} {
  const streetName = aar.address.addressLine.substr(
    aar.address.addressLine.indexOf(' ')
  )
  const streetNumber = aar.address.addressLine.substr(
    0,
    aar.address.addressLine.indexOf(' ')
  )

  return {
    location: {
      id: aar.id,
      isAar: true,
      name: aar.name,
      serviceProviderCode: aar.serviceProviderCode,
      latitude: aar.latitude,
      longitude: aar.longitude,
      streetName,
      streetNumber,
      city: aar.address.cityName,
      state: aar.address.stateProv.code,
      postalCode: aar.address.postalCode,
      address: concatAddress({
        postalCode: aar.address.postalCode,
        state: aar.address.stateProv.code,
        city: aar.address.cityName,
        streetName: aar.address.addressLine,
      }),
    },
    marker: {
      lat: Number(aar.latitude),
      lng: Number(aar.longitude),
      isAar: true,
      serviceProviderCode: aar.serviceProviderCode,
      address: concatAddress({
        postalCode: aar.address.postalCode,
        state: aar.address.stateProv.code,
        city: aar.address.cityName,
        streetName: aar.address.addressLine,
      }),
      name: aar.name,
    },
  }
}

export function hasHighwayNameAndGeometricCenter(
  address: string,
  googleLocation: GoogleLocation
): boolean {
  return (
    hasHighwayName.test(address) &&
    googleLocation.geometry.location_type ===
      GOOGLE_LOCATION_TYPES.GEOMETRIC_CENTER
  )
}

export function isAddressComplete(address: AAALocation): boolean {
  return Boolean(
    address &&
      address.longitude &&
      address.latitude &&
      address.state &&
      address.state.trim().length !== 0 &&
      address.city &&
      address.city.trim().length !== 0 &&
      address.streetName &&
      address.streetName.trim().length !== 0
  )
}

export function isHomeAddressComplete(memberInfo: MemberInfo): boolean {
  return Boolean(
    memberInfo &&
      isBasicAddressComplete(memberInfo.basicAddress) &&
      memberInfo.city &&
      memberInfo.city.trim().length !== 0 &&
      memberInfo.stateProvince &&
      memberInfo.stateProvince.trim().length !== 0 &&
      memberInfo.postalCode &&
      memberInfo.postalCode.trim().length !== 0
  )
}

export function isBasicAddressComplete(basicAddress: string): boolean {
  return Boolean(
    basicAddress &&
      basicAddress.trim().length !== 0 &&
      isNaN(+basicAddress.trim()) &&
      basicAddress.toUpperCase() !== 'SECURED' &&
      !PO_BOX_REGEX.test(basicAddress)
  )
}

export function mapDebugMarkers(
  addresses: GoogleLocation[]
): LocationWithMarker[] {
  return addresses
    .filter(
      (address) =>
        !address.types.includes('plus_code') &&
        !address.types.includes('political')
    )
    .map(
      (address) =>
        ({
          marker: {
            lat: address.geometry.location.lat(),
            lng: address.geometry.location.lng(),
            address: `${address.formatted_address} (${address.geometry.location_type})`,
          },
          location: {
            latitude: address.geometry.location.lat(),
            longitude: address.geometry.location.lng(),
          },
        } as LocationWithMarker)
    )
}

export function compareAddresses(raw) {
  const lineRemove = /\n/g
  const original = raw.replace(lineRemove, ' ').toLowerCase().trim()

  return (aar) => {
    const aarAddress = aar.address.addressLine
      .replace(lineRemove, ' ')
      .toLowerCase()
      .trim()

    // strcmp style because e.g. Country can sometimes be included in one and not the other
    return (
      aarAddress.indexOf(original) === 0 || original.indexOf(aarAddress) === 0
    )
  }
}
