import 'hammerjs'
import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import { AppModule } from './app/app.module'
import { environment } from './environments/environment'

if (environment.optimize) {
  enableProdMode()
}

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then(() => {
      loadServiceWorker()
      // setTimeout(() => {
      loadGA()

      // }, 500);
    })
    .catch((err) => console.error(err))
})

function loadServiceWorker() {
  const enabled = environment.serviceWorker || false
  if (enabled && 'serviceWorker' in navigator) {
    navigator.serviceWorker
      .register('service-worker.js')
      .catch((err) =>
        console.error('Service worker registration failed with:', err)
      )
  }
}

function loadGA() {
  const dataLayer = window['dataLayer']
  if (dataLayer && dataLayer.length) {
    const loaded = dataLayer.find((events) => events.event === 'gtm.load')
    if (loaded) {
      dataLayer.push({ event: 'optimize.activate' })
    } else {
      sleep(loadGA, 0)
    }
  } else {
    sleep(loadGA, 0)
  }
}

function sleep(action, time) {
  setTimeout(() => action(), time)
}

// keep it as helper
function unregisterServiceWorkers() {
  if ('serviceWorker' in navigator) {
    // navigator.serviceWorker.register('/ngsw-worker.js')
    navigator.serviceWorker.getRegistrations().then(function(registrations) {
      for (let i = 0; i < registrations.length; i++) {
        registrations[i].unregister()
      }
    })
  }
}
