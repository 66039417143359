import { createSelector } from '@ngrx/store'
import { AAAStore } from '../../store/root-reducer'
import { Passengers, SubmitState, UserContactInfo } from './submit.reducer'
import { Option } from '../ui/ui.types'
import { selectIsTowLocationValid } from '../location/tow-location/tow-location.selectors'
import { FormGroupState, unbox } from 'ngrx-forms'

export const selectSubmitState = (store: AAAStore): SubmitState => store.submit

export const selectSubmitStep = createSelector(
  selectSubmitState,
  (state: SubmitState) => state.step
)

export const selectContactForm = createSelector(
  selectSubmitState,
  (state: SubmitState): FormGroupState<UserContactInfo> =>
    state ? state.contactInfo : null
)

export const selectUserContactInfo = createSelector(
  selectContactForm,
  (form: FormGroupState<UserContactInfo>): UserContactInfo =>
    form ? form.value : null
)

export const selectPassengersForm = createSelector(
  selectSubmitState,
  (state: SubmitState) => (state ? state.passengers : null)
)

export const selectPassengers = createSelector(
  selectPassengersForm,
  (form: FormGroupState<Passengers>): Passengers =>
    form ? unbox(form.value) : null
)

export const selectPassengersQuantity = createSelector(
  selectPassengers,
  (state: Passengers): Option => (state ? state.quantity : null)
)

export const selectIsSubmitValid = createSelector(
  selectPassengersQuantity,
  selectUserContactInfo,
  selectIsTowLocationValid,
  (
    passengers: Option,
    contactInfo: UserContactInfo,
    isTowLocationValid
  ): boolean =>
    Boolean(passengers?.label) &&
    Boolean(contactInfo.contactNumber) &&
    Boolean(contactInfo.firstName) &&
    Boolean(contactInfo.lastName) &&
    isTowLocationValid
)

export const selectMergedPassengersComments = createSelector(
  selectPassengers,
  (passengers: Passengers): string => {
    if (!passengers) {
      return ''
    }

    const result = []
    if (passengers.quantity) {
      result.push(`PASSENGERS: ${passengers.quantity.label}`)
    }
    if (passengers.notes && passengers.notes.trim()) {
      result.push(passengers.notes)
    }
    return result.join(' ')
  }
)

export const selectPassengersNotes = createSelector(
  selectPassengers,
  (passengers: Passengers): string => passengers.notes
)
