import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'

import { DialogData } from '../../ui.types'
import { select, Store } from '@ngrx/store'
import { AAAStore } from 'src/app/store/root-reducer'
import { confirmTowDistance } from 'src/app/modules/location/tow-location/tow-location.actions'
import events from 'src/app/modules/tagging/events'
import { map } from 'rxjs/operators'
import { selectModeConfiguration } from 'src/app/modules/auth/auth.selectors'
import { Observable } from 'rxjs'

@Component({
  selector: 'rap-tow-mileage-message-dialog-component',
  templateUrl: './rap-tow-mileage-message-dialog.component.html',
  styleUrls: ['./rap-tow-mileage-message-dialog.component.scss'],
})
export class RapTowMileageMessageDialogComponent {
  pageType = events.towTo.DESTINATION_PAGE_TYPE

  contactPhoneNumber$: Observable<string> = this.store$.pipe(
    select(selectModeConfiguration),
    map(configData => {
      if (configData) {
        return configData?.contactPhone;
      }
    })
  )

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private store$: Store<AAAStore>
  ) {}

  onSubmit() {
    this.onClose()
  }

  onClose() {
    this.data.close()
  }
}
