<ng-select
  #appSelect
  [attr.id]="elementId"
  [searchable]="searchable"
  [clearable]="clearable"
  (change)="handleChange($event)"
  [placeholder]="placeholder"
  [items]="internalItems$ | async"
  [dropdownPosition]="dropdownPosition"
>
</ng-select>
