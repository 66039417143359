import { GoogleLocation } from '../location/location.types'

export enum StepTypes {
  ISSUE = 'issue',
  BREAKDOWN_LOCATION = 'location',
  VEHICLE = 'vehicle',
  TOWING = 'tow-location',
  SUBMIT = 'submit',
}

export type Section = VehicleSections

export enum VehicleSections {
  MAKES = 'makes',
  MODELS = 'models',
  DETAILS = 'details',
}

// TODO; change to Tow + passengers. Leave submit alone.
export enum SubmitSections {
  TOW_LOCATION = 'tow-location',
  PASSENGERS = 'passengers',
  SUMMARY = 'summary',
}

export enum StepStatus {
  PENDING = 'PENDING',
  EDITING = 'EDITING',
  DONE = 'DONE',
  ACTIVE = 'ACTIVE',
}

export interface BaseStep {
  id: number
  status: StepStatus
}

export interface Step extends BaseStep {
  url: StepTypes
  title: string
  sections?: Array<Section>
}

export interface MarkerDetails {
  label: string
  draggable: boolean
  iconurl: string
  active?: boolean
  showAddress?: boolean
  offsetY?: number
  offsetX?: number
  type?: MarkerTypes
  className?: string
}

export enum MarkerTypes {
  BREAKDOWN = 'BREAKDOWN',
  TOWING = 'TOWING',
  TRUCK = 'TRUCK',
  SHOP = 'SHOP',
  LOCATION = 'LOCATION',
}

export enum ErrorDialogTypes {
  AUTH = 'AUTH',
  AUTH_OEM = 'AUTH_OEM',
  AUTH_VAS = 'AUTH_VAS',
  AUTH_RENTAL = 'AUTH_RENTAL',
  CALL_STATUS = 'CALL_STATUS',
  GENERIC = 'GENERIC',
  MEMBER_NO_MATCHES = 'MEMBER_NO_MATCHES',
  SESSION_EXPIRED = 'SESSION_EXPIRED',
  NOT_ELIGIBLE = 'NOT_ELIGIBLE',
  CALL_CANCEL = 'CALL_CANCEL'
}

export enum PromptDialogTypes {
  AAR_DETAILS = 'AAR_DETAILS',
  AUTHENTICATE_DIALOG = 'AUTHENTICATE_DIALOG',
  CONFIRM_CANCEL_CALL = 'CONFIRM_CANCEL_CALL',
  CONFIRM_VEHICLE_DELETE = 'CONFIRM_VEHICLE_DELETE',
  ADDITIONAL_AUTH_INFO = 'ADDITIONAL_AUTH_INFO',
  TOWING_DISTANCE_WARN = 'TOWING_DISTANCE_WARN',
  BATTERY_QUOTES = 'BATTERY_QUOTES',
  SHOW_BATTERY_QUOTES = 'SHOW_BATTERY_QUOTES',
  IFRAME_DIALOG = 'IFRAME_DIALOG',
  ADDITIONAL_LOCATION_INFO = 'ADDITIONAL_LOCATION_INFO',
}

export enum MessageDialogTypes {
  CUSTOM = 'CUSTOM',
  CANT_REMEMBER_MY_ID = 'CANT_REMEMBER_MY_ID',
  NOT_MEMBER = 'NOT_MEMBER',
  CANNOT_LOGIN = 'CANNOT_LOGIN',
  NOTIFY_POLICE = 'NOTIFY_POLICE',
  LOCATION_SERVICES_REQUIRED = 'LOCATION_SERVICES_REQUIRED',
  PWA_INSTALL = 'PWA_INSTALL',
  IS_NOT_WORKING = 'IS_NOT_WORKING',
  CALL_CANCELLED = 'CALL_CANCELLED',
  CALL_COMPLETED = 'CALL_COMPLETED',
  CALL_DUPLICATE = 'CALL_DUPLICATE',
  TOWING_DISTANCE_LIMIT = 'TOWING_DISTANCE_LIMIT',
  TOW_LOCATION_ADDRESS = 'TOW_LOCATION_ADDRESS',
  TOW_BOUNDARY_MESSAGE = 'TOW_BOUNDARY_MESSAGE',
  RAP_TOW_MILEAGE_MESSAGE = 'RAP_TOW_MILEAGE_MESSAGE',
  SESSION_TIMEOUT = 'SESSION_TIMEOUT',
  SAME_ADDRESS_TOW_BL = 'SAME_ADDRESS_TOW_BL',
  INVALID_MEMBERSHIP = 'INVALID_MEMBERSHIP',
  ADVISORY = 'ADVISORY',
}

export type ErrorDialog = DialogData & {
  type: ErrorDialogTypes
}

export type MessageDialog = DialogData & {
  type: MessageDialogTypes
}

export type PromptDialog = DialogData & {
  type: PromptDialogTypes
}

export type Dialog = DialogData & {
  type: ErrorDialogTypes | MessageDialogTypes | PromptDialogTypes
}

export interface DialogData {
  title?: string
  content?: string
  params?: any
  submit?: () => void
  close?: () => void
  disableClose?: boolean
  panelClass?: string
  displayedAt?: Date // TODO check this type, the value being set is a number from Date.now()
}

export interface Option {
  label: string
  value?: any
}

export const DEFAULT_TRUCK_MARKER: MarkerDetails = {
  label: 'Service Vehicle',
  draggable: false,
  iconurl: 'assets/images/tow-truck-pin.svg',
  offsetY: 60,
}

export const DEFAULT_BREAKDOWN_MARKER: MarkerDetails = {
  label: 'Current Location',
  draggable: false,
  iconurl: 'assets/images/breakdown-location-pin.svg',
  offsetY: 69,
  type: MarkerTypes.BREAKDOWN,
}

export const DEFAULT_TOW_MARKER: MarkerDetails = {
  label: 'Towing Destination',
  draggable: false,
  iconurl: 'assets/images/tow-location-pin.svg',
  offsetY: 72,
}

export const DEFAULT_TOW_DESTINATION_MARKER: MarkerDetails = {
  label: 'Towing Destination',
  draggable: false,
  iconurl: 'assets/images/tow-destination-pin.svg',
  offsetY: 72,
  offsetX: 25,
}

export const DEFAULT_MARKER_DETAILS: MarkerDetails = {
  label: 'Location',
  draggable: false,
  iconurl: 'assets/images/breakdown-location-pin.svg',
  offsetY: 69,
}

export const USER_LOCATION_MARKER: MarkerDetails = {
  label: 'User Current Location',
  draggable: false,
  iconurl: 'assets/images/blue-dot.jpg',
  offsetY: 0,
  className: 'default-user-location-marker',
  type: MarkerTypes.LOCATION,
}

export interface LatLng {
  lat: number
  lng: number
  address: GoogleLocation
}

export interface MarkerLocationInfo {
  lat: number | string
  lng: number | string
  isAar?: boolean
  accuracy?: number
  address?: string
  notes?: string
  name?: string
}
