<span
  class="form-error text-error small-text"
  [attr.aria-labelledby]="name"
  (click)="onControlClick($event, name)"
  *ngIf="
    control?.errors[type]
    && control.isTouched
  "
>
  <span class="material-icons-sharp icon">close</span>
  <ng-content></ng-content>
</span>