export default {
  DESTINATION_PAGE_TYPE: 'Tow destination',
  DESTINATION_HOME_CLICK: 'Home tow location',
  DESTINATION_MORE_AARS_CLICK: 'Request more AAA',
  DESTINATION_MANUAL_INPUT: 'Tow location manually entered',
  DESTINATION_AAR_SELECT: 'Tow to this location',
  DESTINATION_DISTANCE_WARNING: 'tow over 50 miles',
  DESTINATION_DISTANCE_ALERT: 'tow over 100 miles',
  DESTINATION_DISTANCE_LIMIT: 'tow over 100 miles call AAA',
  DESTINATION_NEXT_CLICK: 'Tow Location next'
}
