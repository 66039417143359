import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Params } from '@angular/router'
import { ROUTER_NAVIGATION, RouterNavigatedAction } from '@ngrx/router-store'
import {
  getRouterActionQueryParams,
  getURLQueryParams,
} from 'src/app/shared/utils/query-params'
import { Action } from '@ngrx/store'
import { setCookie } from 'src/app/shared/utils/cookies'
import { AppId, AppWhiteLabelId, AuthMethods } from '../auth/auth.types'
import { from, of } from 'rxjs'
import { ErrorReportingService } from 'src/app/shared/services/error-reporting.service'
import { PayloadedAction } from 'src/app/shared/types'
import { catchError, concatMap, map, switchMap, take } from 'rxjs/operators'
import {
  setCallStatusInterval,
  setMapDebug,
  setPreProdEnv,
  setShowAuthOptionButton,
  setShowBack,
  setShowExpandedDialog,
  setShowBottomNavigation,
  setTowRedesign,
  setShowKeepMeInformed,
  setShowMenu,
  setShowPwa,
  setSimpleCaptcha,
  setUserDefaultCoords,
  setUserDefaultZipCode,
  setHideNotesOnNeedTow,
  setApplicationTextUpdates,
  setIssueSelectionRevamp,
  setNameZipInstructions,
  setTelematics,
  setQueryParamsVehicleData,
  setFirstTimeAccess,
} from '../ui/ui.actions'
import {
  assignSecureParams,
  setAgent,
  setAgentSettings,
  setAuth,
  setAuthMethod,
  setRapAuth,
} from '../auth/auth.actions'
import {
  ABTestParamsKeys,
  AppConfigParamsKeys,
  AuthConfigParamsKeys,
  UIConfigParamsKeys,
} from './config.types'
import { SET_APP_ID, setAppId } from './config.actions'
import {
  setLocationStep,
  setShowMapOptions,
} from '../location/location.actions'
import { LOCATION_STEPS } from '../location/location.types'
import { Vehicle } from '../member/member.types'

@Injectable()
export class ConfigEffects {
  // note: to test locally change it to 'local.cleverbuild.biz:3000'
  preProdUrl = 'drrweb-preprod.national.aaa.com'

  private defaultTruthyValues = ['yes', 'y']
  private defaultFalsyValues = ['no', 'n']

  defaultQueryParams = {
    [AppConfigParamsKeys.APP_ID]: '',
    [AppConfigParamsKeys.DEFAULT_LATITUDE]: '',
    [AppConfigParamsKeys.DEFAULT_LONGITUDE]: '',
    [AppConfigParamsKeys.PREPROD]: '',
    [AppConfigParamsKeys.ZIP_CODE]: '',
    [AppConfigParamsKeys.AGENT_ID]: '',
    [AppConfigParamsKeys.AGENT_VER]: '',
    [AppConfigParamsKeys.MAP_DEBUG]: '',
    [AppConfigParamsKeys.CALL_STATUS_INTERVAL]: '',
    [AppConfigParamsKeys.TELEMATICS]: '',
    [AppConfigParamsKeys.MAKE]: '',
    [AppConfigParamsKeys.MODEL]: '',
    [AppConfigParamsKeys.YEAR]: '',
    [AppConfigParamsKeys.FIRST_TIME_ACCESS]: '',
    [UIConfigParamsKeys.PWA]: '',
    [UIConfigParamsKeys.MENU]: '',
    [UIConfigParamsKeys.SHOW_BACK]: '',
    [UIConfigParamsKeys.HIDE_NOTES_ON_NEED_TOW]: '',
    [UIConfigParamsKeys.SHOW_AUTH_OPTION_BUTTON]: '',
    [UIConfigParamsKeys.SHOW_KEEP_ME_INFORMED]: '',
    [UIConfigParamsKeys.SIMPLE_CAPTCHA]: '',
    [UIConfigParamsKeys.AB_TEST]: '',
    [AuthConfigParamsKeys.NAME_VALIDATE]: '',
    [AuthConfigParamsKeys.MEM_ID]: '',
    [AuthConfigParamsKeys.ETS]: '',
    [AuthConfigParamsKeys.TOKEN]: '',
    [AuthConfigParamsKeys.RAP_TOKEN]: '',
    [AuthConfigParamsKeys.CLUB]: '',
  }

  // Grab all query params and map it into a single object
  checkQueryParams$ = createEffect(() =>
    this.actions$.pipe(
      ofType<RouterNavigatedAction>(ROUTER_NAVIGATION),
      take(1),
      map((action) => getRouterActionQueryParams(action)),
      map(getURLQueryParams(this.defaultQueryParams)),
      switchMap((params) =>
        of(params).pipe(
          concatMap(() => {
            const stream = []
            stream.push(
              ...this.AppConfigParamsActions(params),
              ...this.UiConfigParamsActions(params).reduce(
                (previousValue, currentValue) => {
                  if (Array.isArray(currentValue)) {
                    currentValue = currentValue.reduce(
                      (previousValue1, currentValue1) =>
                        previousValue1.concat(currentValue1),
                      []
                    )
                  }

                  return previousValue.concat(currentValue)
                },
                []
              ),
              ...this.AuthConfigParamsActions(params)
            )

            return stream
          }),
          catchError((error) => from(this.errorReportingService.notifyError(error))
          )
        )
      )
    )
  )

  setAppId$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SET_APP_ID),
        map((action: PayloadedAction) => {
          const appId = action.payload
          setCookie('AAA_AppId', appId.toUpperCase())
        })
      ),
    { dispatch: false }
  )

  /**
   * Map each key and handle all params related to auth configs
   *
   * @param params Query params object
   */
  AuthConfigParamsActions(
    params: Params
  ): Array<PayloadedAction | Action | void> {
    return Object.keys(params)
      .filter((paramKey) => {
        switch (paramKey) {
          case AuthConfigParamsKeys.NAME_VALIDATE:
            const nameValidateValue =
              params[AuthConfigParamsKeys.NAME_VALIDATE].toLowerCase()
            return this.defaultFalsyValues.indexOf(nameValidateValue) !== -1

          case AuthConfigParamsKeys.MEM_ID:
            return (
              Boolean(params[AuthConfigParamsKeys.MEM_ID]) &&
              Boolean(params[AuthConfigParamsKeys.ETS])
            )

          case AuthConfigParamsKeys.TOKEN:
            return (
              Boolean(params[AuthConfigParamsKeys.TOKEN]) &&
              Boolean(params[AuthConfigParamsKeys.CLUB])
            )

          case AuthConfigParamsKeys.RAP_TOKEN:
            return (
              Boolean(params[AuthConfigParamsKeys.RAP_TOKEN])
            )
        }
      })
      .map((paramKey) => this.executeActions(paramKey, params))
  }

  /**
   * Map each key and handle all params related to ui configs
   *
   * @param params Query params object
   */
  UiConfigParamsActions(
    params: Params
  ): Array<PayloadedAction | Action | void> {
    return Object.keys(params)
      .filter((paramKey) => {
        switch (paramKey) {
          case UIConfigParamsKeys.PWA:
            const pwaValue = params[UIConfigParamsKeys.PWA].toLowerCase()
            return this.defaultFalsyValues.indexOf(pwaValue) !== -1

          case UIConfigParamsKeys.MENU:
            const menuValue = params[UIConfigParamsKeys.MENU].toLowerCase()
            return this.defaultFalsyValues.indexOf(menuValue) !== -1

          case UIConfigParamsKeys.SHOW_BACK:
            const backValue = params[UIConfigParamsKeys.SHOW_BACK].toLowerCase()
            return this.defaultTruthyValues.indexOf(backValue) !== -1

          case UIConfigParamsKeys.HIDE_NOTES_ON_NEED_TOW:
            const noteValue =
              params[UIConfigParamsKeys.HIDE_NOTES_ON_NEED_TOW].toLowerCase()
            return this.defaultTruthyValues.indexOf(noteValue) !== -1

          case UIConfigParamsKeys.SHOW_AUTH_OPTION_BUTTON:
            const authOptValue =
              params[UIConfigParamsKeys.SHOW_AUTH_OPTION_BUTTON].toLowerCase()
            return this.defaultTruthyValues.indexOf(authOptValue) !== -1

          case UIConfigParamsKeys.SHOW_KEEP_ME_INFORMED:
            const keepMeInformed =
              params[UIConfigParamsKeys.SHOW_KEEP_ME_INFORMED].toLowerCase()
            return this.defaultTruthyValues.indexOf(keepMeInformed) !== -1

          case UIConfigParamsKeys.SIMPLE_CAPTCHA:
            const scValue =
              params[UIConfigParamsKeys.SIMPLE_CAPTCHA].toLowerCase()
            return this.defaultFalsyValues.indexOf(scValue) !== -1

          case UIConfigParamsKeys.AB_TEST:
            const abTestValues = params[UIConfigParamsKeys.AB_TEST]
              .toLowerCase()
              .split(':')
            return Boolean(
              abTestValues.find(
                (value) => Object.values(ABTestParamsKeys).indexOf(value) >= 0
              )
            )
        }
      })
      .map((paramKey) => this.executeActions(paramKey, params))
  }

  /**
   * Map each key and handle all params related to custom app configs
   *
   * @param params Query params object
   */
  AppConfigParamsActions(
    params: Params
  ): Array<PayloadedAction | Action | void> {
    return Object.keys(params)
      .filter((paramKey) => {
        switch (paramKey) {
          case AppConfigParamsKeys.APP_ID:
            const appId = params[AppConfigParamsKeys.APP_ID]

            return Boolean(
              appId && (
                Object.values(AppId).includes(appId.toUpperCase())
                || Object.values(AppWhiteLabelId).includes(appId.toUpperCase())
              )
            )

          case AppConfigParamsKeys.AGENT_ID:
            const agentid = params[AppConfigParamsKeys.AGENT_ID]
            return Boolean(agentid)

          case AppConfigParamsKeys.AGENT_VER:
            const agentver = params[AppConfigParamsKeys.AGENT_VER]
            return Boolean(agentver)

          case AppConfigParamsKeys.DEFAULT_LATITUDE:
            const lat = params[AppConfigParamsKeys.DEFAULT_LATITUDE]
            const lng = params[AppConfigParamsKeys.DEFAULT_LONGITUDE]

            return Boolean(lat) && Boolean(lng)

          case AppConfigParamsKeys.PREPROD:
            const preProdValue =
              params[AppConfigParamsKeys.PREPROD].toLowerCase()
            const isValid =
              this.defaultTruthyValues.indexOf(preProdValue) !== -1
            return window.location.host === this.preProdUrl && isValid

          case AppConfigParamsKeys.ZIP_CODE:
            const zipCode = params[AppConfigParamsKeys.ZIP_CODE].toLowerCase()
            return Boolean(zipCode)

          case AppConfigParamsKeys.MAP_DEBUG:
            const mapDebugValue =
              params[AppConfigParamsKeys.MAP_DEBUG].toLowerCase()
            return this.defaultTruthyValues.indexOf(mapDebugValue) !== -1

          case AppConfigParamsKeys.CALL_STATUS_INTERVAL:
            const callStatusValue =
              params[AppConfigParamsKeys.CALL_STATUS_INTERVAL]
            return Boolean(callStatusValue) && callStatusValue > 0

          case AppConfigParamsKeys.TELEMATICS:
            const telematicsValue =
              params[AppConfigParamsKeys.TELEMATICS]
            return Boolean(telematicsValue)

          case AppConfigParamsKeys.MAKE:
            const makeValue = params[AppConfigParamsKeys.MAKE]
            return Boolean(makeValue)

          case AppConfigParamsKeys.FIRST_TIME_ACCESS:
            const ftValue = params[AppConfigParamsKeys.FIRST_TIME_ACCESS]
            return this.defaultTruthyValues.indexOf(ftValue) !== -1
        }
      })
      .map((paramKey) => this.executeActions(paramKey, params))
  }

  /**
   * Return each action depending on passed query param key
   *
   * @param paramKey query param key
   * @param params query params object
   */
  executeActions(
    paramKey: string,
    params: object
  ): PayloadedAction | Action | void {
    switch (paramKey) {
      case AuthConfigParamsKeys.NAME_VALIDATE:
        return setAuthMethod({ payload: AuthMethods.MEMBERSHIP_NUMBER })

      case AuthConfigParamsKeys.MEM_ID:
        const memId = decodeURI(
          encodeURI(params[AuthConfigParamsKeys.MEM_ID])
        ).replace(/\s/g, '+')
        const ets = decodeURI(
          encodeURI(params[AuthConfigParamsKeys.ETS])
        ).replace(/\s/g, '+')

        return assignSecureParams({ payload: { memId, ets } })

      case AuthConfigParamsKeys.TOKEN:
        const token = params[AuthConfigParamsKeys.TOKEN]
        const club = params[AuthConfigParamsKeys.CLUB]

        return setAuth({ payload: { access_token: token, club } })

      case AuthConfigParamsKeys.RAP_TOKEN:
        const rapToken = params[AuthConfigParamsKeys.RAP_TOKEN]
        return setRapAuth({ payload: { access_token: rapToken } })

      case UIConfigParamsKeys.PWA:
        return setShowPwa({ payload: false })

      case UIConfigParamsKeys.MENU:
        return setShowMenu({ payload: false })

      case UIConfigParamsKeys.SHOW_BACK:
        return setShowBack({ payload: true })

      case UIConfigParamsKeys.HIDE_NOTES_ON_NEED_TOW:
        return setHideNotesOnNeedTow({ payload: true })

      case UIConfigParamsKeys.SHOW_AUTH_OPTION_BUTTON:
        return setShowAuthOptionButton({ payload: true })

      case UIConfigParamsKeys.SHOW_KEEP_ME_INFORMED:
        return setShowKeepMeInformed({ payload: true })

      case UIConfigParamsKeys.SIMPLE_CAPTCHA:
        return setSimpleCaptcha({ payload: true })

      case UIConfigParamsKeys.AB_TEST:
        const abTests = params[UIConfigParamsKeys.AB_TEST]
          .toLowerCase()
          .split(':')
        const abTestActions = abTests.map((abTest: string) => {
          switch (abTest) {
            case ABTestParamsKeys.SIMPLE_CAPTCHA:
              return setSimpleCaptcha({ payload: true })
            case ABTestParamsKeys.SHOW_BACK:
              return setShowBack({ payload: true })
            case ABTestParamsKeys.SHOW_BOTTOM_NAVIGATION:
              return setShowBottomNavigation({ payload: true })
            case ABTestParamsKeys.SHOW_EXPANDED_DIALOG:
              return setShowExpandedDialog({ payload: true })
            case ABTestParamsKeys.TOW_REDESIGN:
              return setTowRedesign({ payload: true })
            case ABTestParamsKeys.HIDE_NOTES_ON_NEED_TOW:
              return setHideNotesOnNeedTow({ payload: true })
            case ABTestParamsKeys.SHOW_AUTH_OPTION_BUTTON:
              return setShowAuthOptionButton({ payload: true })
            case ABTestParamsKeys.SHOW_KEEP_ME_INFORMED:
              return setShowKeepMeInformed({ payload: true })
            case ABTestParamsKeys.ISSUE_SELECTION_REVAMP:
              return setIssueSelectionRevamp({ payload: true })
            case ABTestParamsKeys.APPLICATION_TEXT_UPDATES:
              return setApplicationTextUpdates({ payload: true })
            case ABTestParamsKeys.NAME_ZIP_INSTRUCTIONS:
              return setNameZipInstructions({ payload: true})
          }
        })
        return abTestActions

      case AppConfigParamsKeys.APP_ID:
        const appId = params[AppConfigParamsKeys.APP_ID].toUpperCase()
        return setAppId({ payload: appId.toUpperCase() })

      case AppConfigParamsKeys.PREPROD:
        return setPreProdEnv()

      case AppConfigParamsKeys.DEFAULT_LATITUDE:
        const lat = params[AppConfigParamsKeys.DEFAULT_LATITUDE]
        const lng = params[AppConfigParamsKeys.DEFAULT_LONGITUDE]

        return setUserDefaultCoords({
          payload: {
            lat: Number(lat),
            lng: Number(lng),
            address: {},
          },
        })

      case AppConfigParamsKeys.ZIP_CODE:
        return setUserDefaultZipCode({
          payload: params[AppConfigParamsKeys.ZIP_CODE],
        })

      case AppConfigParamsKeys.AGENT_ID:
        const agentName = params[paramKey]
        return setAgentSettings({ payload: { agentName } })

      case AppConfigParamsKeys.AGENT_VER:
        const isAgent = params[paramKey].toUpperCase() === 'V2'
        return setAgent({
          payload: isAgent,
        })

      case AppConfigParamsKeys.MAP_DEBUG:
        return setMapDebug({ payload: true })

      case AppConfigParamsKeys.CALL_STATUS_INTERVAL:
        return setCallStatusInterval({
          payload: Number(params[AppConfigParamsKeys.CALL_STATUS_INTERVAL]),
        })

      case AppConfigParamsKeys.TELEMATICS:
        return setTelematics({
          payload: params[AppConfigParamsKeys.TELEMATICS],
        })

      case AppConfigParamsKeys.MAKE:
        const make = params[AppConfigParamsKeys.MAKE]
        const model = params[AppConfigParamsKeys.MODEL]
        const year = params[AppConfigParamsKeys.YEAR]

        // Required fields
        if (make && model && year) {
          const vehicle = {
            make,
            model,
            year,
          } as Vehicle
          return setQueryParamsVehicleData({
            payload: vehicle,
          })
        }
        break;

      case AppConfigParamsKeys.FIRST_TIME_ACCESS:
        return setFirstTimeAccess({ payload: true })
    }
  }

  constructor(
    private actions$: Actions,
    private errorReportingService: ErrorReportingService
  ) { }
}
