import { GenericCoordinates, GoogleLocation } from '../location.types'

export interface LocationArea extends GenericCoordinates {
  radius?: number
}

export interface GoogleCoordinates {
  lat: number
  lng: number
  accuracy?: number
}

export interface GoogleAddress extends GoogleCoordinates {
  address: GoogleLocation
}

export enum GOOGLE_LOCATION_TYPES {
  APPROXIMATE = 'APPROXIMATE',
  GEOMETRIC_CENTER = 'GEOMETRIC_CENTER',
  RANGE_INTERPOLATED = 'RANGE_INTERPOLATED',
  ROOFTOP = 'ROOFTOP',
}

export enum GOOGLE_ADDRESS_COMPONENT_TYPES {
  STREET_NUMBER = 'street_number',
  ROUTE = 'route',
  INTERSECTION = 'intersection',
  NEIGHBORHOOD = 'neighborhood',
  POSTAL_CODE = 'postal_code',
  LOCALITY = 'locality',
  POLITICAL = 'political',
  STATE = 'administrative_area_level_1',
  COUNTRY = 'country',
  ESTABLISHMENT = 'establishment',
  POINT_OF_INTEREST = 'point_of_interest',
  NATURAL_FEATURE = 'natural_feature',
  PARK = 'park',
  AIRPORT = 'airport',
  PREMISE = 'premise',
}

export interface GeoLocationOptions {
  preferredType?: GOOGLE_LOCATION_TYPES
}
