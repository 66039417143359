import {
  Component,
  Input,
  Output,
  EventEmitter,
  HostListener,
  ViewChild,
} from '@angular/core'
import { NgSelectComponent } from '@ng-select/ng-select'
import { Observable, EMPTY } from 'rxjs'
import { map } from 'rxjs/operators'

const OTHER_VALUE = 'Other'

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent {
  private itemsValue$: Observable<string[]> = EMPTY

  @ViewChild('appSelect', { static: false }) appSelect: NgSelectComponent

  @Input()
  get items$(): Observable<string[]> {
    return this.itemsValue$
  }

  set items$(value) {
    this.itemsValue$ = value
    this.internalItems$ = this.items$.pipe(
      map((items) => [...items, ...(this.addOtherValue ? OTHER_VALUE : []) ])
    )
  }

  @Input() fieldName: string
  @Input() placeholder: string
  @Input() elementId: string
  @Input() dropdownPosition = 'auto'
  @Input() searchable = false
  @Input() clearable = false
  @Input() addOtherValue = false;

  @Output() selectionMade: EventEmitter<string> = new EventEmitter<string>()

  internalItems$: Observable<string[]> = EMPTY
  otherSelected = false
  otherValue = ''

  handleChange(selection) {
    this.selectionMade.emit(selection)
  }

  ngAfterViewInit() {
    this.ariaElementUpdates();
  }

  submitOtherValue() {
    this.selectionMade.emit(this.otherValue)
  }

  // IBM Equal Access Accessibility Checker - complains about this attribute
  // "Form control with "combobox" role has no associated label"
  private ariaElementUpdates() {
    const makesSelect = this.appSelect?.element?.getElementsByTagName('input')[0] || null

    if (makesSelect) {
      makesSelect.removeAttribute('autocomplete')
    }
  }
}
