<div class="dialog dialog-error">
  <h1 *ngIf="!data.params.hideTitle" mat-dialog-title>{{ data.title }}</h1>

  <app-safehtml id="dialog-content" mat-dialog-content [content]="data.content"></app-safehtml>

  <div *ngIf="!data.params.hideActions" mat-dialog-actions>
    <app-call-aaa-button
      [clickEventPage]="pageType"
      [inverted]="true"
      (buttonClick)="onCallAAAClick()">
    </app-call-aaa-button>

    <button
      *ngIf="!!data.params.showDismissButton"
      id="dialog-dismiss-submit"
      name="dialogGenericErrorDismiss"
      class="btn primary btn-sm"
      aria-label="dismiss this message"
      (click)="onClose()">
      Dismiss
    </button>
  </div>
  <button mat-dialog-close class="mat-dialog-close" aria-label="dismiss this message">
    <icon-close></icon-close>
  </button>
</div>
