import { GoogleCoordinates } from './google-geocode/types'
import { PaceSetterCode } from '../issue/issue.types'
import { BasicAddressInfo } from '../../shared/types'
import { AARData } from './aar/aar.types'
import { NON_AAR_TOWING_NAMES } from './tow-location/tow-location.actions'

export interface GoogleLocationMarker extends GoogleCoordinates {
  isAar?: boolean
  serviceProviderCode?: string
  accuracy?: number
  address?: string
  addressLine1?: string
  addressLine2?: string
  notes?: string
  name?: string
  locationType?: string
}

export interface GoogleLocation {
  address_components?: Array<{
    long_name: string
    short_name: string
    types: string[]
  }>
  formatted_address?: string
  geometry?: {
    location: {
      lat: any
      lng: any
      accuracy?: number
    }
    location_type: string
  }
  place_id?: string
  plus_code?: Object
  types?: string[]
}

export interface GenericCoordinates {
  latitude: string | number
  longitude: string | number
  accuracy?: number
}

export type AAALocation = {
  address?: string
  landmark?: string
  locationType?: string
} & BasicAddressInfo &
  GenericCoordinates

export interface BreakdownLocation extends GenericCoordinates {
  postalCode: string
  state: string
  city: string
  streetName: string
  streetNumber: string
  zip?: string
  location?: string
  landmark?: string
  locationType?: string
}

export interface BreakdownLocationDetails {
  options: PaceSetterCode[]
  notes?: string
  references?: LocationWithMarker[]
}

export interface TowLocation extends AAALocation {
  id?: number
  serviceProviderCode?: string
  name: NON_AAR_TOWING_NAMES | string
  facility?: string
  location?: string
  isAar?: boolean
}

export interface LocationWithMarker {
  location: TowLocation
  marker: GoogleLocationMarker
}

export enum DIRECTIONAL_NAMES {
  W = 'Westbound',
  E = 'Eastbound',
  S = 'Southbound',
  N = 'Northbound',
  WE = 'WestEastbound',
  NE = 'NorthEastBound',
  SE = 'NorthEastBound',
}

export interface HighwayExits {
  highwayLongName: string
  highwayShortName: string
  exitBehind?: HighwayExitInfo
  exitAhead?: HighwayExitInfo
}

export interface HighwayExitInfo {
  latitude: string | number
  longitude: string | number
  exitNumber?: string | number
  exitName?: string
  direction?: string
}

export interface LocationClubResponse {
  servicingClub: string
}

export enum TOW_DISTANCE {
  LIMIT = 100,
  WARNING = 50,
}

export enum TOWING_ALERT_TYPES {
  DEFAULT = 'default',
  CUSTOM = 'custom',
  RAP = 'rap',
}

export interface TowingDistanceCoords {
  breakdownCoordinates: GenericCoordinates
  towLocation: GoogleCoordinates
}

export enum LOCATION_STEPS {
  MAP = 'MAP',
  OPTIONS = 'OPTIONS',
  MAP_OPTIONS = 'MAP_OPTIONS',
}
