import { Action, ActionReducer } from '@ngrx/store'
import { AAAStore } from '../root-reducer'
import { AUTH, AUTH_OEM, AUTH_RENTAL, AUTH_VAS } from '../../modules/auth/auth.actions'
import { ErrorDialogTypes } from '../../modules/ui/ui.types'
import { getActionErrorMessage } from './errors.messages'
import {
  SET_HIGHWAY_EXITS,
  SET_LOCATION_CLUB,
} from '../../modules/location/location.actions'
import { BATTERY_QUOTES } from 'src/app/modules/quotes/quotes.action'
import { SET_SERVICING_CLUB_CONFIGS } from '../../modules/servicing-club/servicing-club.actions'
import { ACTIVE_CALL_STATUS, CALL_STATUS } from 'src/app/modules/dashboard/calls-statuses/call-status.actions';
import { ADD_PARTIAL_CALL, CALL_CANCEL } from 'src/app/modules/dashboard/calls.actions'
import { RapAuthFailure } from 'src/app/modules/auth/auth.types'

const ignoreList = [
  ADD_PARTIAL_CALL.FAILURE,
  SET_HIGHWAY_EXITS.FAILURE,
  SET_LOCATION_CLUB.FAILURE,
  SET_SERVICING_CLUB_CONFIGS.FAILURE,
  BATTERY_QUOTES.FAILURE,
  CALL_STATUS.FAILURE,
  ACTIVE_CALL_STATUS.FAILURE,
]

export function errorMiddleware(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return function newReducer(state: AAAStore, action: Action & { payload: RapAuthFailure }) {
    const isDialogActive = state && Object.keys(state.ui.dialog).length > 0

    if (
      !isDialogActive &&
      action.type.includes('FAILURE') &&
      !ignoreList.includes(action.type)
    ) {
      const failureContent = getActionErrorMessage(action)

      switch (action.type) {
        case AUTH_OEM.FAILURE:
          const dialogComponent = action.payload === RapAuthFailure.NOT_ELIGIBLE ? ErrorDialogTypes.NOT_ELIGIBLE : ErrorDialogTypes.AUTH_OEM;

          state = {
            ...state,
            ui: {
              ...state.ui,
              dialog: {
                ...state.ui.dialog,
                [dialogComponent]: { type: dialogComponent },
              },
            },
          }
          break
        case AUTH_VAS.FAILURE:
          state = {
            ...state,
            ui: {
              ...state.ui,
              dialog: {
                ...state.ui.dialog,
                [ErrorDialogTypes.AUTH_VAS]: { type: ErrorDialogTypes.AUTH_VAS },
              },
            },
          }
          break
        case AUTH_RENTAL.FAILURE:
          state = {
            ...state,
            ui: {
              ...state.ui,
              dialog: {
                ...state.ui.dialog,
                [ErrorDialogTypes.AUTH_RENTAL]: { type: ErrorDialogTypes.AUTH_RENTAL },
              },
            },
          }
          break
        case AUTH.FAILURE:
          state = {
            ...state,
            ui: {
              ...state.ui,
              dialog: {
                ...state.ui.dialog,
                [ErrorDialogTypes.AUTH]: { type: ErrorDialogTypes.AUTH },
              },
            },
          }
          break
        case CALL_CANCEL.FAILURE:
          state = {
            ...state,
            ui: {
              ...state.ui,
              dialog: {
                ...state.ui.dialog,
                [ErrorDialogTypes.CALL_CANCEL]: { type: ErrorDialogTypes.CALL_CANCEL },
              },
            },
          }
          break
        default:
          state = {
            ...state,
            ui: {
              ...state.ui,
              dialog: {
                ...state.ui.dialog,
                [ErrorDialogTypes.GENERIC]: {
                  type: ErrorDialogTypes.GENERIC,
                  content: failureContent,
                },
              },
            },
          }
      }
    }

    const nextState = reducer(state, action)

    return nextState
  }
}
