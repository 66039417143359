import { Injectable, PLATFORM_ID, Inject, OnDestroy } from '@angular/core'
import { SwUpdate } from '@angular/service-worker'
import { BehaviorSubject } from 'rxjs'
import { environment } from 'src/environments/environment'

const ONE_DAY = 24 * 3600 * 1000
@Injectable({
  providedIn: 'root',
})
export class PwaService implements OnDestroy {
  promptEvent: BehaviorSubject<any> = new BehaviorSubject<any>(null)
  isBrowse: any
  private updateInterval = null

  constructor(
    @Inject(PLATFORM_ID) private platformId // public swUpdate: SwUpdate
  ) {}

  get enabled(): any {
    return (async () => await this.serviceWorkerRegistered())()
  }

  ngOnDestroy(): void {
    if (this.updateInterval) {
      clearInterval(this.updateInterval)
    }
  }

  /**
   * Listen for service worker updates
   */
  async swUpdateListener() {
    const enabled = await this.enabled
    if (!enabled) {
      return
    }

    navigator.serviceWorker.addEventListener('message', async (event) => {
      if (event.data.meta === 'workbox-broadcast-update') {
        const { cacheName, updatedUrl } = event.data.payload
        const cache = await caches.open(cacheName)
        const updatedResponse = await cache.match(updatedUrl)
        await updatedResponse?.text()
      }
    })

    if (this.updateInterval) {
      clearInterval(this.updateInterval)
    }

    this.updateInterval = setInterval(() => this.checkForUpdate(), ONE_DAY)
    setTimeout(() => this.checkForUpdate(), 0)

    if (this.platformId === 'browser') {
      window.addEventListener('beforeinstallprompt', (event) => {
        this.promptEvent.next(event)
      })
    }
  }

  reload() {
    window.location.reload()
  }

  private async checkForUpdate() {
    try {
      return true
      // await this.swUpdate.checkForUpdate()
    } catch (error) {
      console.error('Update check failed.  Details below:')
      console.error(error)
    }
  }

  private async serviceWorkerRegistered() {
    const _env = environment.serviceWorker || false
    const _exists = 'serviceWorker' in navigator

    if (!_exists || !_env) {
      return false
    }

    const registered = await navigator.serviceWorker.getRegistrations()

    return registered ? true : false
  }
}
