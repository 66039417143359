import { Injectable } from '@angular/core'
import {
  clickEventAction,
  pageViewAction,
  automatedEventAction,
  AutomatedEventMeta,
} from './tagging.actions'
import { Store } from '@ngrx/store'
import { AAAStore } from 'src/app/store/root-reducer'

@Injectable({
  providedIn: 'root',
})
export class TaggingService {
  constructor(private store$: Store<AAAStore>) {}

  setClickEvent(action: String, pageType: String, extras?: any) {
    const _clickEvent = clickEventAction({
      payload: { action, pageType, ...extras },
    })
    this.store$.dispatch(_clickEvent)
  }

  setPageEvent(action, pageType) {
    const _pageEvent = pageViewAction({ payload: { action, pageType } })
    this.store$.dispatch(_pageEvent)
  }

  setAutomatedEvent(
    action,
    pageType,
    meta?: AutomatedEventMeta,
    params?: object
  ) {
    const _automatedEvent = automatedEventAction({
      payload: { action, pageType, params },
      meta,
    })

    this.store$.dispatch(_automatedEvent)
  }
}
