import { AAAStore } from 'src/app/store/root-reducer'
import { LocationState } from './location.reducer'
import { createSelector } from '@ngrx/store'
import {
  BreakdownLocation,
  BreakdownLocationDetails,
  DIRECTIONAL_NAMES,
} from 'src/app/modules/location/location.types'
import {
  GenericCoordinates,
  GoogleLocationMarker,
} from 'src/app/modules/location/location.types'
import { concatAddress } from 'src/app/shared/utils/concatAddress'
import { BreakdownLocationParams } from '../dashboard/calls.types'
import { LOCATION_TYPE } from './location.actions'
import { selectAdditionalLocationInfoForm } from '../ui/forms/forms.selectors'
import { AdditionalLocationInfoFormState } from '../ui/forms/forms.reducer'
import { FormGroupState } from 'ngrx-forms'
import { selectInitialMapLocation, selectUserDefaultCoords } from '../ui/ui.selectors'
import { isMobileApp } from 'src/app/shared/utils/app-detect'

export const selectLocationState = (store: AAAStore): LocationState =>
  store.location

export const selectLocationServicesAvailable = createSelector(
  selectLocationState,
  (state: LocationState) => state.servicesAvailable
)

export const selectBreakdownLocation = createSelector(
  selectLocationState,
  // TODO remove unnecessary null check, improve affected unit tests instead.
  (state: LocationState): BreakdownLocation => state && state.breakdownLocation
)

export const selectBreakdownLocationAddress = createSelector(
  selectLocationState,
  (state: LocationState): string => state.address
)

export const selectBreakdownLocationCoordinates = createSelector(
  selectLocationState,
  (state: LocationState): GenericCoordinates => ({
    latitude: state.breakdownLocation?.latitude,
    longitude: state.breakdownLocation?.longitude,
  })
)

export const selectIsBreakdownLocationHome = createSelector(
  selectBreakdownLocation,
  (breakdownLocation: BreakdownLocation): boolean =>
    breakdownLocation.locationType === LOCATION_TYPE.HOME ||
    breakdownLocation.locationType === LOCATION_TYPE.HOME_LINK
)

export const selectLocationStep = createSelector(
  selectLocationState,
  (state: LocationState): string => state.step
)

export const selectCurrentGeoLocation = createSelector(
  selectLocationState,
  (state: LocationState): GenericCoordinates => state?.geoLocationCoordinates
)

export const selectBreakdownLocationDetails = createSelector(
  selectLocationState,
  selectAdditionalLocationInfoForm,
  (
    state: LocationState,
    additionalInfoForm: FormGroupState<AdditionalLocationInfoFormState>
  ): BreakdownLocationDetails => ({
    // FIXME: Momentary hack due to Angular Template form + ngrx-forms mix in app.
    // Remove when using ngrx-forms in BreakdownLocationDetailsComponent.
    // It should reuse the same AdditionalLocationInfoFormState. (LocationDetailsFormState rename adviced).
    ...state.breakdownLocationDetails,
    notes:
      state.breakdownLocationDetails.notes ||
      additionalInfoForm.controls.breakdownComment.value,
  })
)

export const selectBreakdownLocationNotes = createSelector(
  selectBreakdownLocationDetails,
  (state: BreakdownLocationDetails) => state && state.notes
)

export const selectBreakdownReferencesMarkers = createSelector(
  selectBreakdownLocationDetails,
  (state: BreakdownLocationDetails) => state && state.references
)

export const selectBreakdownMarker = createSelector(
  selectLocationState,
  (locationState: LocationState): GoogleLocationMarker => {
    if (!locationState.address) {
      return null
    }

    const {
      location,
      latitude,
      longitude,
      postalCode,
      state,
      city,
      streetName,
      streetNumber,
      accuracy,
      locationType,
    } = locationState.breakdownLocation

    return {
      lat: Number(latitude),
      lng: Number(longitude),
      accuracy,
      locationType,
      address: concatAddress(
        {
          postalCode,
          state,
          city,
          streetName,
          streetNumber: String(streetNumber),
        },
        location
      ),
      notes: locationState.breakdownLocationDetails.notes,
    }
  }
)

export const selectIsBreakdownLocationValid = createSelector(
  selectBreakdownLocationCoordinates,
  selectBreakdownLocationAddress,
  (coordinates: GenericCoordinates, address: string): boolean =>
    Boolean(address) &&
    Boolean(coordinates.latitude) &&
    Boolean(coordinates.longitude)
)

export const selectHasBreakdownLocationPostalCode = createSelector(
  selectBreakdownLocation,
  (breakdownLocation: BreakdownLocation): boolean =>
    (breakdownLocation?.postalCode || breakdownLocation?.zip || '') !== ''
)

export const selectLandMarkLocationName = createSelector(
  selectLocationState,
  (state: LocationState): string => state.landmark
)

export const displayLandmarkAddress = createSelector(
  selectLocationState,
  (state: LocationState): boolean => state.displayLandmark
)

export const selectLandMarkAndAddress = createSelector(
  selectLocationState,
  (state: LocationState): string => {
    let address = state.address
    const landmark = state.landmark || ''
    if (landmark && state.displayLandmark) {
      address = `${state.landmark}, ${address.replace(state.landmark, '')}`
    }
    return address?.trim()
  }
)

export const selectLocationClub = createSelector(
  selectLocationState,
  (state: LocationState): string =>
    state.servicingClub || state.tempServicingClub
)

export const selectIsHighway = createSelector(
  selectLocationState,
  (state: LocationState): boolean => state.highway
)

export const selectHighwayExit = createSelector(
  selectLocationState,
  (state: LocationState): string | null => {
    let description = null

    const highwayExits: any = state.highwayExits
    const exitAhead = highwayExits?.exitAhead || null
    const exitBehind = highwayExits?.exitBehind || null
    const direction = exitAhead?.direction
      ? DIRECTIONAL_NAMES[exitAhead['direction']]
      : null

    const ahead = removeFromString(
      `${exitAhead?.exitNumber || exitAhead?.exitName || ''}`,
      '/<wbr/>'
    )
    const behind = removeFromString(
      `${exitBehind?.exitNumber || exitBehind?.exitName || ''}`,
      '/<wbr/>'
    )

    if (direction && ahead && behind) {
      description = `${direction} between exits ${ahead} and ${behind}`
    }

    if (direction && ahead && !behind) {
      description = `${direction} near exit ${ahead}`
    }

    if (direction && !ahead && !behind) {
      description = direction
    }

    return description
  }
)

export const selectBreakdownLocationParams = createSelector(
  selectBreakdownLocation,
  (breakdownLocation): BreakdownLocationParams =>
    breakdownLocation && {
      latitude: Number(breakdownLocation.latitude),
      longitude: Number(breakdownLocation.longitude),
      location: breakdownLocation.location,
      streetNumber: breakdownLocation.streetNumber,
      street: breakdownLocation.streetName,
      city: breakdownLocation.city,
      state: breakdownLocation.state,
      zip: breakdownLocation.postalCode,
      landmark: breakdownLocation.landmark,
    }
)

export const selectHasGPSAccess = createSelector(
  selectLocationServicesAvailable,
  selectUserDefaultCoords,
  (areLocationServicesAvailable, userDefaultCoords): boolean => isMobileApp() ?
    (Boolean(areLocationServicesAvailable
      || (
        userDefaultCoords
        && userDefaultCoords.lat
        && userDefaultCoords.lng
      ))
    ) :
    areLocationServicesAvailable
)

/**
 * Clean undesired content from giving string
 *
 * @param baseString base string value
 * @param value value you want to remove
 */
function removeFromString(baseString = '', replace = '') {
  return baseString.replace(replace, '')
}
