import { Injectable } from '@angular/core'
import { Store, select } from '@ngrx/store'
import { AAAStore } from 'src/app/store/root-reducer'
import { setEditStepLocation } from 'src/app/modules/wizard/wizard.actions'
import { ActivatedRoute, Router } from '@angular/router'
import { selectNeedsTow } from 'src/app/modules/issue/issue.selectors'
import { take } from 'rxjs/operators'
import { VehicleData } from 'src/app/modules/member/member.types'
import { editVehicle } from 'src/app/modules/vehicle/vehicle.actions'
import { clearActiveVehicle } from '../../modules/member/member.actions'

@Injectable({
  providedIn: 'root',
})
export class EditService {
  constructor(
    private store$: Store<AAAStore>,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  // always get current active step
  private get activeStep() {
    return this.route.snapshot.queryParams['step']
  }

  async editVehicleSection(active, vehicle, section) {
    this.store$.dispatch(clearActiveVehicle())
    this.store$.dispatch(
      editVehicle({
        payload: {
          vehicle: vehicle as VehicleData,
          isActive: active,
          section,
        },
      })
    )
  }

  async doEdit(step, section?) {
    // Don't set a return URL if you start from the same step you are going to.
    const editionStepLocation =
      step === this.activeStep ? null : this.router.url

    // check if towing is needed always taking the last dispached value
    const needTowing = await this.store$
      .pipe(select(selectNeedsTow), take(1))
      .toPromise()

    if (!needTowing) {
      this.store$.dispatch(
        setEditStepLocation({
          payload: {
            editionStepLocation,
            step,
          },
        })
      )
    }

    const queryParams = { step }

    if (section) {
      queryParams['section'] = section
    }

    this.router.navigate(['steps'], { queryParams })
  }
}
