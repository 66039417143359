<div class="white-label-credentials entry">
    <form
        id="signon-form"
        (ngSubmit)="checkFieldsAndSubmit()"
        class="white-label-credentials_form"
        [ngrxFormState]="formState"
    >

        <ng-template formMode></ng-template>


        <ng-container *ngIf="displayCaptcha">
            <ngx-recaptcha2
                #captchaElementSimple
                [siteKey]="siteKey"
                (ready)="handleCaptchaReady()"
                (success)="handleCaptchaSuccessSimple($event)"
                (error)="handleCaptchaError()"
                (expire)="handleExpire()"
                [useGlobalDomain]="false"
                type="checkbox"
                [ngModel]="captcha"
                [ngModelOptions]="{ standalone: true }">
            </ngx-recaptcha2>
        </ng-container>
        <div class="form-error text-error small-text" *ngIf="captchaError">
            You must confirm you are not a robot.
        </div>

        <app-button [disabled]="(isSigningIn$ | async)" type="submit" [loading]="(isSigningIn$ | async) === true"
            [tabIndex]="(12 + tabIndexPosition)">
            Confirm
        </app-button>


    </form>

    <div class="white-label-credentials_terms">
        By selecting Confirm, you accept the
        <app-clickable-link (clickLink)="openTerms()" class="terms-and-conditions__link"
            [tabIndex]="(24 + tabIndexPosition)">
            Terms and Conditions
        </app-clickable-link>
    </div>

    <ng-container *ngIf="!(isSecure$ | async) && isWebApp()">
        <div class="privacy-policy">
            <app-clickable-link (clickLink)="openPrivacyPolicy()" class="privacy-policy__link"
                [tabIndex]="(25 + tabIndexPosition)">
                Privacy Policy
            </app-clickable-link>
        </div>
    </ng-container>
</div>
