import { Injectable } from '@angular/core'
import { Observable, Subscription, combineLatest } from 'rxjs'
import { Store, select } from '@ngrx/store'
import axios, { AxiosResponse, AxiosRequestConfig } from 'axios'
import debounce from 'p-debounce'

import { AAAStore } from '../../store/root-reducer'
import { AuthState } from '../../modules/auth/auth.reducer'
import { environment } from '../../../environments/environment'
import { AAACallStatus } from '../../modules/dashboard/calls.types'
import { selectActiveCallStatus } from '../../modules/dashboard/calls-statuses/call-status.selectors'
import { ConfigService } from 'src/app/modules/config/config.service'
import { getCookie, setCookie } from '../utils/cookies'
import { AuthSecurityWrapperService } from 'src/app/modules/auth/auth-security-wrapper/auth-security-wrapper.service'

type RequestArgs = string | AxiosRequestConfig

const RETRY_INTERVAL = 20000
const TIMEOUT_INTERVAL = 30000

const MAXIMUM_TIMEOUTS = 5
const MAXIMUM_RETRIES = 3

export interface RequestOptions {
  retryAll?: boolean | number
  overrideBaseURL?: string
  overrideAuthHeader?: string
  withCredentials?: boolean
  useCustomAAAheaders?: boolean
}

interface URLMapping {
  [url: string]: number
}

export enum HeaderFooter {
  HEADER = 'HEADER',
  FOOTER = 'FOOTER',
}

export enum DefaultClubs {
  AAA = 'RSOAAA',
  CAA = 'RSOCAA',
}

function clubFromMembership(auth: AuthState) {
  return auth.memberNumber ? auth.memberNumber.substring(3, 6) : auth.club
}

const IDEMPOTENT_VERBS = ['GET', 'HEAD', 'PUT', 'DELETE', 'OPTIONS', 'TRACE']

const APP_ID_DRR_WEB = 'DRRWEB'

function canRetryRequest(verb) {
  return IDEMPOTENT_VERBS.indexOf((verb || '').trim().toUpperCase()) >= 0
}

const createHeaders = ({
  auth,
  call,
}: {
  auth?: AuthState
  call?: AAACallStatus
}) => {
  const club = {}
  const aaa = {}
  const authTokens = {}

  aaa['x-aaa-client-id'] = environment.clientId
  aaa['x-aaa-app-id'] = getCookie('AAA_AppId') || APP_ID_DRR_WEB

  if (auth) {
    club['x-aaa-restws-club'] = auth.club
    aaa['x-aaa-rsoweb-secure'] = auth.isSecure
    aaa['x-aaa-rsoweb-club'] = clubFromMembership(auth)

    authTokens['authorization'] = `Bearer ${auth.accessToken}`
  }

  if (call) {
    aaa['x-aaa-rsoweb-callid'] = call.callId
  }

  return {
    aaa,
    club,
    auth: authTokens,
  }
}

@Injectable({
  providedIn: 'root',
})
export class XHRService {
  constructor(
    private store$: Store<AAAStore>,
    private configService: ConfigService,
    private authSecurityWrapperService: AuthSecurityWrapperService
  ) {
    this.authCall$.subscribe(([auth, call]) => {
      this.headers = createHeaders({ auth, call })
    })
  }
  public headers = createHeaders({})
  public defaultConfig = {
    timeout: TIMEOUT_INTERVAL,
    responseType: 'json',
    method: 'GET',
    retryAll: true,
    withCredentials: true,
    useCustomAAAheaders: true,
  }

  authCall$: Observable<[AuthState, AAACallStatus]> = combineLatest([
    this.store$.pipe(select((state) => state.auth)),
    this.store$.pipe(select(selectActiveCallStatus)),
  ])

  timedOuts: URLMapping = {}
  retries: URLMapping = {}
  authSubscription: Subscription = null

  constructUrl(args: RequestArgs) {
    const baseURL = typeof args === 'string' ? args : args.url

    try {
      if (getCookie('dtCookie')) {
        const aditionalDomain = environment.additionalCookieDomain
          ? 'domain=national.aaa.com'
          : ''
        setCookie('dtCookie', `${getCookie('dtCookie')};${aditionalDomain}`)
        setCookie('rxVisitor', `${getCookie('rxVisitor')};${aditionalDomain}`)
      }
    } catch (error) {
      return baseURL
    }

    return `${baseURL}`
  }

  async clubRequest<T>(args: RequestArgs, options: RequestOptions = {}) {
    if (typeof args === 'string') {
      return this.authRequest<T>(
        {
          url: args,
          headers: this.headers.club,
        },
        options
      )
    }
    return this.authRequest<T>(
      {
        ...args,
        headers: this.headers.club,
      },
      options
    )
  }

  async authRequest<T>(args: RequestArgs, options: RequestOptions = {}) {
    if (typeof args === 'string') {
      return this.request<T>(
        {
          url: args,
          headers: {
            ...(options.overrideAuthHeader
              ? { authorization: options.overrideAuthHeader }
              : this.headers.auth),
          },
        },
        options
      )
    }

    return this.request<T>(
      {
        ...args,
        headers: {
          ...args.headers,
          ...(options.overrideAuthHeader
            ? { authorization: options.overrideAuthHeader }
            : this.headers.auth),
        },
      },
      options
    )
  }

  async request<T>(
    args: RequestArgs,
    options: RequestOptions = {}
  ): Promise<T> {
    let response: AxiosResponse<T>
    let requestObject: AxiosRequestConfig = {}
    const { overrideBaseURL, useCustomAAAheaders } = options
    const requestConfig = {
      ...this.defaultConfig,
      retryAll:
        options.retryAll === undefined
          ? this.defaultConfig.retryAll
          : options.retryAll,
      withCredentials:
        options.withCredentials === undefined
          ? this.defaultConfig.withCredentials
          : options.withCredentials,
      baseURL: overrideBaseURL || this.configService.getConfig().baseURL,
    }
    const requestUrl = this.constructUrl(args)
    const headers =
      useCustomAAAheaders === undefined || useCustomAAAheaders
        ? this.headers.aaa
        : {}

    if (typeof args === 'string') {
      // @ts-ignore
      requestObject = {
        ...requestConfig,
        url: requestUrl,
        headers: { ...headers },
      }
    } else {
      // @ts-ignore
      requestObject = {
        ...requestConfig,
        ...args,
        url: requestUrl,
        headers: {
          ...headers,
          ...(args.headers as any),
        },
      }
    }

    try {
      if (environment.configTools) {
        console.log('Network: ', JSON.stringify(requestObject))
      }

      response = await axios.request(requestObject)

      if (response.status >= 200 && response.status < 300) {
        const { url } = args as AxiosRequestConfig

        // Reset for this URL
        this.resetURLTimeout(url)

        // Return the response
        return response.data
      } else if (
        canRetryRequest(requestObject.method) &&
        response.status === 408
      ) {
        return this.handleRequestTimeout<T>(args as AxiosRequestConfig, options)
      }

      throw response
    } catch (error) {
      if (error?.response?.status === 401) {
        this.authSecurityWrapperService.forceLogout()
      }
      if (requestConfig.retryAll && canRetryRequest(requestObject.method)) {
        if (error?.code === 'ECONNABORTED') {
          return this.handleRequestTimeout<T>(
            args as AxiosRequestConfig,
            options
          )
        } else if (error?.response?.status !== 401) {
          return this.handleRequestRetry(options)(args as AxiosRequestConfig)
        }
      }
      throw error
    }
  }
  getURLRetry(url: string): number {
    return this.retries[url] || 0
  }
  addURLRetry(url: string): void {
    this.retries = {
      ...this.retries,
      [url]: this.getURLRetry(url) + 1,
    }
  }
  resetURLRetry(url: string): void {
    this.retries = {
      ...this.retries,
      [url]: 0,
    }
  }
  getURLTimeout(url: string): number {
    return this.timedOuts[url] || 0
  }
  addURLTimeout(url: string): void {
    this.timedOuts = {
      ...this.timedOuts,
      [url]: this.getURLTimeout(url) + 1,
    }
  }
  resetURLTimeout(url: string): void {
    this.timedOuts = {
      ...this.timedOuts,
      [url]: 0,
    }
  }
  handleRequestTimeout<T>(
    args: AxiosRequestConfig,
    options: RequestOptions
  ): Promise<T> {
    const { url } = args

    this.addURLTimeout(url)

    if (this.getURLTimeout(url) <= MAXIMUM_TIMEOUTS) {
      return this.request<T>(args, options)
    } else if (this.getURLTimeout(url) > MAXIMUM_TIMEOUTS) {
      this.resetURLTimeout(url)
    }
  }

  handleRequestRetry: Function = (options: RequestOptions) => {
    const interval =
      typeof options.retryAll === 'number' ? options.retryAll : RETRY_INTERVAL

    return debounce((args: AxiosRequestConfig) => {
      const { url } = args

      this.addURLRetry(url)

      if (this.getURLRetry(url) <= MAXIMUM_RETRIES) {
        return this.request(args, options)
      } else {
        this.resetURLRetry(url)
      }
    }, interval)
  }
}

export const __TEST__ = {
  MAXIMUM_RETRIES,
  MAXIMUM_TIMEOUTS,
}
