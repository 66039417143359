<div class="dialog">
  <button mat-dialog-close class="mat-dialog-close" aria-label="dismiss this message">
    <icon-close></icon-close>
  </button>
  <div class="details-container tall" mat-dialog-content>
    <app-img
      classNames="logo"
      src="assets/images/AAA Approved.svg"
      alt="AAA Approved"
    ></app-img>
    <ng-container *ngIf="details$ | async as details; else loading">
      <div class="name-title">{{ details.name }}</div>
      <div class="location-group">
        <div class="normal-text" *ngIf="details.address as address">
          <div>{{ address.addressLine }}</div>
          <div>
            {{ address.cityName }},&nbsp;{{ address.stateProv.code }}
            {{ address.postalCode }}
          </div>
        </div>
        <div class="distance small-text" *ngIf="distanceTo">
          {{ distanceTo | number:'1.0-1' }} Miles
        </div>
      </div>

      <div class="phone-container">
        <a
          class="contact show-mobile"
          href="tel:{{ details.phone.value | phone }}"
          target="_blank"
        >
          <div class="call"></div>
          {{ details.phone.value }}
        </a>
        <div class="contact show-desktop bold">{{ details.phone.value }}</div>
      </div>

      <a
        *ngIf="details.webAddress"
        class="contact"
        [attr.href]="details.webAddress"
        target="_blank"
      >
        {{ details.webAddress }}
      </a>

      <div>
        <div class="button-wrapper">
          <app-button id="aar-details-tow-to-button" (click)="setLocation(details)"
            >TOW TO THIS LOCATION</app-button
          >
          <div class="small-text" *ngIf="distanceTo > maxTowMileLimit && distanceTo < callCenterTowMileage "> 
            It seems that you selected a tow destination that exceeds typical tow limitations, but another dealership is nearby to assist. 
            Please choose from the list of dealerships provided so that we can quickly assist you.
          </div>
          <div class="small-text" *ngIf="distanceTo > callCenterTowMileage ">
            It seems that the dealership selected as your tow destination exceeds typical tow limitations. 
            Please contact us at <a href="tel:{{contactPhone}}">{{contactPhone}}</a> so that we may assist you.
          </div>
        </div>
      </div>

      <!-- Special offers -->
      <div class="offers-hours">
        <ng-container *ngIf="!(isRapUser$ | async)">
          <div class="offers" *ngIf="details.discount as discount">
            <app-img
              classNames="logo-discounts"
              src="assets/images/discount-awards.svg"
              alt="AAA Members discount awards"
            ></app-img>
            <div class="large-text offer-title">
              This Facility Offers AAA Members:
            </div>
            <div class="small-text offer-description">
              {{ discount.offerDescription }}
            </div>
            <div class="small-text offer-restriction">
              {{ discount.offerRestriction }}
            </div>
          </div>
        </ng-container>
        <div class="hours">
          <div class="large-text hours-title">Hours</div>
          <div class="day-hours" *ngFor="let day of details.compressedHours">
            <div class="small-text day">
              {{ day.days | titlecase }}
            </div>
            <div class="small-text hours">
              {{ day.hours }}
            </div>
          </div>
        </div>
      </div>

      <div class="services-details">
        <div class="repair-services">
          <div class="large-text">Repair Services</div>
          <div
            class="repair-service-item small-text"
            *ngFor="let offer of details.serviceOffers"
          >
            {{ offer | titlecase }}
          </div>
        </div>
        <div class="vehicles-serviced">
          <div class="large-text">Vehicles Serviced</div>
          <ul class="vehicles-list">
            <li
              class="vehicle-list-item small-text"
              *ngFor="let vehicle of details.vehicles"
            >
              {{ vehicle.value | titlecase }}
            </li>
          </ul>
        </div>
      </div>
    </ng-container>
    <ng-template #loading>
      <div class="loading">
        <app-loading [width]="64" [height]="64"></app-loading>
      </div>
    </ng-template>
  </div>
</div>
