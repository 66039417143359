import { ofType, Actions, createEffect } from '@ngrx/effects'
import { Injectable } from '@angular/core'
import { Store, select, Action } from '@ngrx/store'
import { from, Observable, of } from 'rxjs'
import {
  map,
  catchError,
  withLatestFrom,
  concatMap,
  filter,
  switchMap,
  tap,
  mergeMap,
} from 'rxjs/operators'

import {
  AAACallStatus,
  AAACallData,
  CallResponse,
  ServiceDeliveryCallStatus,
  CALL_STATUS_CODES,
} from './calls.types'
import { AAAStore } from '../../store/root-reducer'
import {
  ADD_CALL,
  addCallSuccess,
  CALL_CANCEL,
  callCancelRequest,
  callCancelSuccess,
  setCallTowing,
  notifyCallCancelFailure,
  resetCallStatusError,
  ADD_PARTIAL_CALL,
  notifyPartialCallFailure,
  addPartialCallSuccess,
  setServiceDeliveryCallStatusSuccess,
  setServiceDeliveryCallStatusFailure,
  addCallRequest,
  resetActiveCallsCalled
} from './calls.actions';
import { CallsService } from './calls.service'
import { IndexedCollection, PayloadedAction } from '../../shared/types'
import { MessageDialogTypes, PromptDialogTypes } from '../ui/ui.types'
import { openMessageDialog, openPromptDialog } from '../ui/ui.actions'
import {
  callStatusRequest,
  SET_ACTIVE_CALL_STATUS,
  setActiveCallStatus
} from './calls-statuses/call-status.actions';
import { selectCallData } from './calls.selectors'
import {
  selectCallsStatusesData,
  selectActiveCallStatus,
  selectFollowingCallsStatusId,
  selectCanCancelActiveCall,
  selectActiveCallStatusId,
  selectIsActiveBatteryCall,
} from './calls-statuses/call-status.selectors'
import {
  cancelEditingRequest,
  CANCEL_REQUEST,
} from 'src/app/shared/actions/shared.actions'
import { Router } from '@angular/router'
import { TaggingService } from '../tagging/tagging.service'
import { ErrorReportingService } from 'src/app/shared/services/error-reporting.service'
import { selectServicingClubHighwayPriorityCode } from '../servicing-club/servicing-club.selectors'
import { selectIsHighway } from '../location/location.selectors'
import { generateCallId } from './calls.utils'

import { selectAgentSettings, selectIsAgent, selectIsRapUser } from '../auth/auth.selectors'
import { DateTime } from 'luxon'
import { CallStatusService } from './calls-statuses/call-status.service'
import { selectHasBatteryQuotes } from '../quotes/quotes.selectors'
import { PACE_SETTER_SITUATION_CODES } from '../issue/issue.types'
import events from '../tagging/events'
import { RouteTypes } from '../main-router.module'

@Injectable()
export class CallEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<AAAStore>,
    private _callService: CallsService,
    private _callStatusService: CallStatusService,
    private errorReportingService: ErrorReportingService,
    private router: Router,
    private tagging: TaggingService
  ) {}

  addCall$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof addCallRequest>>(ADD_CALL.REQUEST),
      withLatestFrom(
        this.store$.pipe(select(selectAgentSettings)),
        this.store$.pipe(select(selectCallData)),
        this.store$.pipe(select(selectIsHighway)),
        this.store$.pipe(select(selectServicingClubHighwayPriorityCode)),
        this.store$.pipe(select(selectIsAgent))
      ),
      map(([_, agentSettings, callData, isHighway, priorityCode, isAgent]) => {
        if (isHighway && priorityCode) {
          callData.priorityCode = priorityCode
        }
        const { agentName, agentUsername, cashCall, vehicleType } =
          agentSettings || {}
        return isAgent
          ? {
              ...callData,
              callTaker: agentName || agentUsername,
              priorityCode: agentSettings.priorityCode,
              cashRequired: cashCall,
              vehicle: { ...callData.vehicle, vehicleType },
            }
          : callData
      }),
      switchMap((callData: AAACallData) =>
        from(this._callService.addCall(callData)).pipe(
          filter((call) => Boolean(call)),
          concatMap((call: CallResponse) => {
            this.router.navigate([RouteTypes.DASHBOARD])
            // TODO improve function call signature
            this.tagging.setAutomatedEvent(
              events.submit.SUBMIT_SUCCESS,
              events.submit.SUBMIT_PAGE_TYPE, null,
              {
                callId: call.callId,
                servicingClub: call.servicingClub,
              }
            )
            this._callStatusService.startCallStatusesUpdater({ retry: true })

            return [
              addCallSuccess({
                payload: {
                  response: {
                    ...call,
                    callStatus: CALL_STATUS_CODES.NEW,
                  },
                  data: callData,
                },
              }),
              setActiveCallStatus({
                payload: { id: generateCallId(call.callId, call.callDate) },
              }),
            ]
          }),
          catchError((error) =>
          this.errorReportingService.notifyErrorObservable(
            error,
            [
              resetActiveCallsCalled(),
              callStatusRequest({
                payload: { retry: false, addCallFailure: true },
              })
            ]
          ))
        )
      ),
      catchError((error) =>
        this.errorReportingService.notifyErrorObservable(
          error,
          [
            resetActiveCallsCalled(),
            callStatusRequest({
              payload: { retry: false, addCallFailure: true },
            })
          ]
        )
      )
    )
  )

  // start ARR:POC - The effect for calling the service delivery service
  setServiceDeliveryStatus$ = createEffect(
    (): Observable<
      | ReturnType<typeof setServiceDeliveryCallStatusSuccess>
      | ReturnType<typeof setServiceDeliveryCallStatusFailure>
    > =>
      this.actions$.pipe(
        ofType<ReturnType<typeof addCallSuccess>>(ADD_CALL.SUCCESS),
        withLatestFrom(this.store$.pipe(select(selectIsAgent))),
        filter(([_, isAgent]) => isAgent),
        switchMap((params) =>
          of(params).pipe(
            mergeMap(([callSubmission]) => {
              const id = callSubmission.payload.response.callId
              const date = DateTime.fromISO(
                callSubmission.payload.response.callDate
              ).toISODate()

              return from(
                this._callService.getServiceDeliveryStatus(id, date)
              ).pipe(
                map((data: ServiceDeliveryCallStatus) =>
                  setServiceDeliveryCallStatusSuccess({ payload: data })
                ),
                catchError((error) =>
                  this.errorReportingService.notifyErrorObservable(
                    error,
                    setServiceDeliveryCallStatusFailure
                  )
                )
              )
            }),
            catchError((error) =>
              this.errorReportingService.notifyErrorObservable(
                error,
                setServiceDeliveryCallStatusFailure
              )
            )
          )
        )
      )
  )
  // end ARR:POC

  addPartialCall$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ADD_PARTIAL_CALL.REQUEST),
      withLatestFrom(
        this.store$.pipe(select(selectCallData)),
        this.store$.pipe(select(selectIsHighway)),
        this.store$.pipe(select(selectServicingClubHighwayPriorityCode)),
      ),
      map(([_, callData, isHighway, priorityCode]) => {
        if (isHighway && priorityCode) {
          callData.priorityCode = priorityCode
        }
        return callData
      }),
      switchMap((callData: AAACallData) =>
        from(this._callService.addPartialCall(callData)).pipe(
          map(() => addPartialCallSuccess()),
          catchError((error) => this.errorReportingService.notifyErrorObservable(
            error,
            notifyPartialCallFailure
          ))
        )
      )
    )
  )

  cancelRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CANCEL_REQUEST),
      switchMap((action) =>
        of(action).pipe(
          tap(() => {
            // reset call status error
            this.store$.dispatch(resetCallStatusError())
          }),
          withLatestFrom(
            this.store$.pipe(select(selectActiveCallStatus)),
            this.store$.pipe(select(selectCanCancelActiveCall))
          ),
          switchMap(([_, activeCallStatus, canCancel]) => {
            if (canCancel) {
              const { updateToken, callDate, callId, servicingClub } =
                activeCallStatus

              return [
                cancelEditingRequest(),
                callCancelRequest({
                  payload: {
                    updateToken,
                    callDate,
                    callId,
                    servicingClub,
                  },
                }),
              ]
            } else {
              return [cancelEditingRequest()]
            }
          }),
          catchError((error) =>
            this.errorReportingService.notifyErrorObservable(
              error,
              notifyCallCancelFailure
            )
          )
        )
      )
    )
  )

  // Switch calls when the user wants to cancel the active one
  changeCallOnCancel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CALL_CANCEL.REQUEST),
      switchMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store$.pipe(select(selectFollowingCallsStatusId))
          ),
          filter(([_, nextAvailableId]: [Action, string]) => !!nextAvailableId),
          tap(() => {
            // reset call status error
            this.store$.dispatch(resetCallStatusError())
          }),
          map(([_, nextAvailableId]: [Action, string]) =>
            setActiveCallStatus({
              payload: {
                id: nextAvailableId,
              },
            })
          )
        )
      )
    )
  )

  cancelCall$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CALL_CANCEL.REQUEST),
      switchMap((action: PayloadedAction) =>
        from(this._callService.cancelCall(action.payload)).pipe(
          switchMap((response) => {
            this.tagging.setAutomatedEvent(
              events.dashboard.CALL_CANCEL_SUCCESS,
              events.dashboard.CALL_STATUS_PAGE_TYPE
            )
            this._callStatusService.startCallStatusesUpdater({ retry: true })

            return [
              callCancelSuccess({
                payload: {
                  ...response,
                  callDate: action.payload.callDate,
                },
              }),
              resetCallStatusError(),
            ]
          }),
          catchError((error) =>
            this._automatedEventAndNotifyFailure(
              error,
              events.dashboard.CALL_CANCEL_FAILURE,
              notifyCallCancelFailure,
              { callId: selectActiveCallStatusId }
            )
          )
        )
      )
    )
  )

  cancelCallSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CALL_CANCEL.SUCCESS),
      map(() =>
        openMessageDialog({
          payload: {
            type: MessageDialogTypes.CALL_CANCELLED,
          },
        })
      )
    )
  )

  updateCallTowDestination$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SET_ACTIVE_CALL_STATUS),
      withLatestFrom(this.store$.pipe(select(selectCallsStatusesData))),
      filter(
        ([action, data]: [PayloadedAction, IndexedCollection<AAACallStatus>]) =>
          !!data[action.payload.id]
      ),
      map(
        ([action, data]: [
          PayloadedAction,
          IndexedCollection<AAACallStatus>
        ]) => {
          const activeCallStatus: AAACallStatus = data[action.payload.id]

          return setCallTowing({
            payload: activeCallStatus.towDestination
              ? {
                  ...activeCallStatus.towDestination,
                  name: activeCallStatus.towDestination.location,
                  latitude: activeCallStatus.towDestination.latitude,
                  longitude: activeCallStatus.towDestination.longitude,
                }
              : null,
          })
        }
      )
    )
  )

  // Show welcome once for each call submitted.
  handleShowWelcome$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ADD_CALL.SUCCESS),
      withLatestFrom(
        this.store$.pipe(select(selectCallData)),
        this.store$.select(selectIsActiveBatteryCall),
        this.store$.select(selectHasBatteryQuotes),
        this.store$.select(selectIsRapUser)
      ),
      map(([_, callData, isActiveBatteryCall, hasBatteryQuotes, isRapUser]) => {
        const textDialog = isRapUser
          ? 'Please be with the vehicle when assistance arrives.'
          : 'The AAA member is required to be with the vehicle when assistance arrives.<br> Please be prepared to show Photo ID.';

        const isBatteryCall =
          isActiveBatteryCall ||
          callData.situationCodes.pacesetterCode ===
            PACE_SETTER_SITUATION_CODES.BATTERY_ISSUE

        if (isBatteryCall && !hasBatteryQuotes) {
          return openPromptDialog({
            payload: {
              type: PromptDialogTypes.SHOW_BATTERY_QUOTES,
            },
          })
        } else {
          return openMessageDialog({
            payload: {
              type: MessageDialogTypes.CUSTOM,
              title: 'On Our Way!',
              content: textDialog,
            },
          })
        }
      })
    )
  )

  _automatedEventAndNotifyFailure = (
    error,
    eventAction,
    notifyAction,
    notifyPayload?
  ) => {
    const payload = !notifyPayload ? {} : notifyPayload
    const observableAction = this.errorReportingService.notifyErrorObservable(
      error,
      notifyAction,
      payload
    )
    try {
      this.tagging.setAutomatedEvent(eventAction, events.submit.SUBMIT_PAGE_TYPE)
    } catch (e) {
      console.error('Failure tagging automated event: ', e)
    }
    return observableAction
  }
}
