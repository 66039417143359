import {
  ROUTER_NAVIGATED,
  RouterNavigatedAction,
  ROUTER_REQUEST,
  RouterRequestAction,
} from '@ngrx/router-store'
import { createEffect, Actions, ofType } from '@ngrx/effects'
import { Injectable } from '@angular/core'
import { Store, select } from '@ngrx/store'
import {
  withLatestFrom,
  mergeMap,
  filter,
  map,
  catchError,
  switchMap,
} from 'rxjs/operators'
import { createGetStepByUrl, selectUserDefaultCoords } from '../ui/ui.selectors'
import { AAAStore } from '../../store/root-reducer'
import { editStep, activeStep, doneStep } from '../ui/ui.actions'
import { getRouterActionQueryParam } from 'src/app/shared/utils/query-params'
import { ensureLocationServices } from '../location/location.actions'
import { selectRouterStep } from '../../store/router.selectors'
import { createSelectIsCurrentStepValid } from './wizard.selectors'
import { StepTypes } from '../ui/ui.types'
import { from, of } from 'rxjs'
import { ErrorReportingService } from '../../shared/services/error-reporting.service'
import { getParamsFromEvents } from '../ui/ui.utils'
import { selectLocationStep } from '../location/location.selectors'
import { LOCATION_STEPS } from '../location/location.types'

@Injectable()
export class WizardEffects {
  beforeStepChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType<RouterRequestAction>(ROUTER_REQUEST),
      filter((action) => {
        const event = action.payload.event

        if (!event) {
          return false
        }

        return !/cancel=true/i.test(event.url)
      }),
      withLatestFrom(
        this.store$.pipe(select(createGetStepByUrl)),
        this.store$.pipe(select(selectRouterStep)),
        this.store$.pipe(select(createSelectIsCurrentStepValid))
      ),
      filter(([action, getStepByUrl, currentStepUrl]) => {
        const nextStepUrl = getParamsFromEvents(action, 'step')
        return (
          currentStepUrl &&
          getStepByUrl.memoized(currentStepUrl) !== undefined &&
          currentStepUrl !== nextStepUrl
        )
      }),
      map(([_, getStepByUrl, currentStepUrl, selectIsCurrentStepValid]) => {
        const currentStep = getStepByUrl.memoized(currentStepUrl)
        const isCurrentStepValid = selectIsCurrentStepValid(currentStepUrl)
        return isCurrentStepValid
          ? doneStep({ payload: currentStep })
          : activeStep({ payload: currentStep })
      })
    )
  )

  stepChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType<RouterNavigatedAction>(ROUTER_NAVIGATED),
      switchMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store$.pipe(select(createGetStepByUrl)),
            this.store$.pipe(select(selectUserDefaultCoords)),
            this.store$.pipe(select(selectLocationStep))
          ),
          filter(([payloadedAction, getStepByUrl]) => {
            const nextStepUrl = getRouterActionQueryParam(
              payloadedAction,
              'step'
            )
            const nextStep = getStepByUrl.memoized(nextStepUrl)
            return nextStep !== undefined
          }),
          mergeMap(
            ([
              payloadedAction,
              getStepByUrl,
              defaultLocation,
              locationStep,
            ]) => {
              const dispatchedActions = []
              const nextStepUrl = getRouterActionQueryParam(
                payloadedAction,
                'step'
              )
              const nextStep = getStepByUrl.memoized(nextStepUrl)

              dispatchedActions.push(
                editStep({
                  payload: nextStep,
                })
              )

              switch (nextStepUrl) {
                case StepTypes.BREAKDOWN_LOCATION:
                  if (!defaultLocation && locationStep === LOCATION_STEPS.MAP) {
                    dispatchedActions.push(ensureLocationServices())
                  }
                  break
              }
              window.scroll({ top: 0, left: 0, behavior: 'smooth' })

              return dispatchedActions
            }
          ),
          catchError((error) =>
            from(this.errorReportingService.notifyError(error))
          )
        )
      )
    )
  )

  constructor(
    private store$: Store<AAAStore>,
    private actions$: Actions,
    private errorReportingService: ErrorReportingService
  ) {}
}
