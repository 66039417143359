import { Component, HostListener, Input } from '@angular/core'
import { ALLOWED_KEYBOARD_EVENTS } from '../../types';

@Component({
  selector: 'app-skip-to-content',
  templateUrl: './skip-to-content.component.html',
  styleUrls: ['./skip-to-content.component.scss'],
})
export class SkipToContentComponent {

  @Input() elementId = ''
  @Input() tabIndex = 1
  @Input() title = 'Skip to content'

  onAnchorClick(event) {
    event.preventDefault()
    event.stopPropagation()
    this.scrollTo()
  }

  @HostListener('keydown', ['$event'])
  keyboardListener(event) {
    const keyCode = event.keyCode || null;

    event.preventDefault()
    event.stopPropagation()

    if (keyCode === ALLOWED_KEYBOARD_EVENTS.SPACE || keyCode === ALLOWED_KEYBOARD_EVENTS.ENTER) {
      this.scrollTo()
    }
  }

  private scrollTo() {
    const _elementId = this.elementId.replace('#', '');
    const offset = document.getElementById(_elementId).offsetTop;
    window.scrollTo({
      top: offset,
      behavior: 'smooth'
    });
    // remove focus from element
    const link = document.getElementById('skipcontent');
    link.blur()
  }
}
