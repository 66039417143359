import { createAction, props } from '@ngrx/store'
import { CALL_STATUS_CODES } from '../dashboard/calls.types'

export const NOTIFY_CLICK_ACTION = 'NOTIFY_CLICK_ACTION'
export const NOTIFY_NAVIGATION_ACTION = 'NOTIFY_NAVIGATION_ACTION'
export const NOTIFY_AUTOMATED_ACTION = 'NOTIFY_AUTOMATED_ACTION'
export const NOTIFY_ACTION_FAILURE = 'NOTIFY_ACTION_FAILURE'

export interface EventProps {
  payload: {
    action: string
    pageType: string
    params?: object
  }
}

export interface AutomatedEventProps extends EventProps {
  meta?: AutomatedEventMeta
}

export interface AutomatedEventMeta {
  callIdentifier: string
  callStatus: CALL_STATUS_CODES
}

export const clickEventAction = createAction(
  NOTIFY_CLICK_ACTION,
  props<EventProps>()
)

export const pageViewAction = createAction(
  NOTIFY_NAVIGATION_ACTION,
  props<EventProps>()
)

export const automatedEventAction = createAction(
  NOTIFY_AUTOMATED_ACTION,
  props<AutomatedEventProps>()
)

export const notifyEventActionFailure = createAction(NOTIFY_ACTION_FAILURE)
