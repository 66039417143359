import { Injectable } from '@angular/core'

import {
  MemberInfo,
  MemberVehicles,
  VehicleData,
  Vehicle,
  SearchMemberResult,
  AddVehicleResponse,
  MemberEligibility,
  ServiceItem,
} from './member.types'
import {
  MemberVehiclesSchema,
  MemberSchema,
  MemberBasicSchema,
  SearchMemberResultSchema,
  MemberEligibilitySchema,
  ServiceListSchema,
} from './members.model'
import { XHRService } from '../../shared/services/xhr.service'
import {
  BaseAuthRequestParams,
  NameAuthRequestParams,
} from '../auth/auth.types'
import { RapService } from '../rap/rap.service'
import { v4 as uuidv4 } from 'uuid';

const MEMBER_RETRIES = 3
const MEMBER_RETRY_INTERVAL = 15000

@Injectable({
  providedIn: 'root',
})
export class MemberService {
  constructor(private xhr: XHRService, private rapService: RapService) {}

  async getInfo(): Promise<MemberInfo> {
    try {
      const response = await this.xhr.clubRequest<MemberInfo>(
        {
          url: 'v2/member',
          timeout: MEMBER_RETRY_INTERVAL
        },
        {
          retryAll: MEMBER_RETRIES
        })
      const validData = await MemberSchema.validate(response)

      return validData
    } catch (error) {
      throw error
    }
  }

  async getBasicInfo(): Promise<MemberInfo> {
    try {
      const response = await this.xhr.clubRequest<MemberInfo>(
        {
          url: 'v2/member',
          timeout: MEMBER_RETRY_INTERVAL
        },
        {
          retryAll: MEMBER_RETRIES
        })
      const validData = await MemberBasicSchema.validate(response)

      return validData
    } catch (error) {
      throw error
    }
  }

  // start ARR:POC - Services for retrieving service history and tow usage data
  async getMemberEligibility(memberNumber: string): Promise<MemberEligibility> {
    try {
      const response = await this.xhr.clubRequest<MemberEligibility>(
        `member/limits/${memberNumber}`
      )
      const validatedData = await MemberEligibilitySchema.validate(response)

      return validatedData
    } catch (err) {
      throw err
    }
  }

  async getServiceHistory(memberNumber: string): Promise<ServiceItem[]> {
    try {
      const response = await this.xhr.clubRequest<MemberEligibility>(
        `roadservice/member-calls/${memberNumber}`
      )
      const validatedData = await ServiceListSchema.validate(response)

      return validatedData
    } catch (err) {
      throw err
    }
  }
  // end ARR:POC

  async getVehicles(): Promise<MemberVehicles> {
    try {
      const response = await this.xhr.clubRequest<MemberVehicles>(
        'v2/member/profile/vehicles'
      )
      const validData = await MemberVehiclesSchema.validate(response)

      return validData
    } catch (error) {
      throw error
    }
  }

  async addVehicle(data: VehicleData): Promise<AddVehicleResponse> {
    if(this.rapService.isRapUser()) { // RAP's backend doesn't have capability to store a vehicle.
      return { id: uuidv4() }
    }

    try {
      const response = await this.xhr.clubRequest<AddVehicleResponse>({
        url: 'v2/member/profile/vehicles',
        method: 'POST',
        data,
      })
      return response
    } catch (error) {
      throw error
    }
  }

  async updateVehicle(data: Vehicle): Promise<void> {
    const response = await this.xhr.clubRequest<void>({
      url: `v2/member/profile/vehicles/${data.id}`,
      method: 'PUT',
      data,
    })
    return response
  }

  async deleteVehicle(id: string): Promise<void> {
    const response = await this.xhr.clubRequest<void>({
      url: `v2/member/profile/vehicles/${id}`,
      method: 'DELETE',
    })
    return response
  }

  async memberSearch({
    captchaToken,
    firstName,
    lastName,
    zipCode,
  }: BaseAuthRequestParams & NameAuthRequestParams) {
    const response = await this.xhr.request<SearchMemberResult>({
      url: 'v2/member/search',
      data: {
        captchaToken,
        firstName,
        lastName,
        zipCode,
      },
      method: 'POST',
    })
    await SearchMemberResultSchema.validate(response)
    return response
  }
}
