import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { select, Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { CallStatusService } from '../../dashboard/calls-statuses/call-status.service'
import { selectIsAnyRequestLoading } from '../../ui/loading/loading.selectors'
import { openErrorDialog } from '../../ui/ui.actions'
import { selectIsAnyDialogActive } from '../../ui/ui.selectors'
import { ErrorDialogTypes } from '../../ui/ui.types'
import { resetAuth } from '../auth.actions'
import { selectIsSignedIn } from '../auth.selectors'

@Injectable({
  providedIn: 'root',
})
export class AuthSecurityWrapperService {
  isSignedIn: boolean
  isAnyDialogActive: boolean
  isAnyRequestLoading: boolean

  selectisSignedIn$: Observable<boolean> = this.store$.pipe(
    select(selectIsSignedIn)
  )
  selectIsAnyDialogActive$: Observable<boolean> = this.store$.pipe(
    select(selectIsAnyDialogActive)
  )
  selectIsAnyRequestLoading$: Observable<boolean> = this.store$.pipe(
    select(selectIsAnyRequestLoading)
  )

  constructor(
    private store$: Store,
    private router: Router,
    private callStatusService: CallStatusService
  ) {
    this.selectisSignedIn$.subscribe(
      (isSignedIn) => (this.isSignedIn = isSignedIn)
    )
    this.selectIsAnyDialogActive$.subscribe(
      (isAnyDialogActive) => (this.isAnyDialogActive = isAnyDialogActive)
    )
    this.selectIsAnyRequestLoading$.subscribe(
      (isAnyRequestLoading) => (this.isAnyRequestLoading = isAnyRequestLoading)
    )
  }

  logout(): void {
    this.callStatusService.stopCallStatusesUpdater()
    this.store$.dispatch(resetAuth())
    this.router.navigate(['auth/signin'], { queryParams: {} })
  }

  forceLogout(): void {
    this.logout()

    if (this.isSignedIn) {
      this.store$.dispatch(
        openErrorDialog({
          payload: {
            type: ErrorDialogTypes.GENERIC,
            content: 'We are having trouble verifying your account, please attempt to re-verify. If the problem persists please contact us:',
          },
        })
      )
    } else if (!this.isAnyDialogActive && !this.isAnyRequestLoading) {
      this.store$.dispatch(
        openErrorDialog({ payload: { type: ErrorDialogTypes.AUTH } })
      )
    }
  }
}
