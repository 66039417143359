import {
  PACE_SETTER_SITUATION_CODES,
  PACE_SETTER_SITUATION_TYPES,
  PaceSetterDetails,
  PaceSetterOptions,
} from './issue.types'
import { IndexedCollection } from '../../shared/types'

export function constructDetailsSummary(details: PaceSetterDetails) {
  if (!details) {
    return ''
  }

  let additionalDetails = ''
  const selectedGroups = details.selectedGroups
  if (selectedGroups.length && selectedGroups[0].lists.length) {
    const firstList = selectedGroups[0].lists[0]
    if (firstList.members.length) {
      additionalDetails = firstList.members
        .map((detail) => detail.alternateLabel || detail.name)
        .join(', ')
    }
  }

  if (additionalDetails) {
    return [details.name, additionalDetails].join(': ')
  } else {
    return details.name
  }
}

// TODO temporary hack, since API returns CODES in service tracker, and frontend handles NAMES
const paceSetterMap = {
  [PACE_SETTER_SITUATION_CODES.ACCIDENT]: PACE_SETTER_SITUATION_TYPES.ACCIDENT,
  [PACE_SETTER_SITUATION_CODES.STUCK]: PACE_SETTER_SITUATION_TYPES.STUCK,
  // note that BATTERY_ISSUE and CAR_WONT_START_NO_IGNITION have the same pace setter code = L301,
  // this collision is handled using the Car Won't Start type for both
  [PACE_SETTER_SITUATION_CODES.BATTERY_ISSUE]:
    PACE_SETTER_SITUATION_TYPES.CAR_WONT_START,
  [PACE_SETTER_SITUATION_CODES.WONT_START]:
    PACE_SETTER_SITUATION_TYPES.CAR_WONT_START,
  [PACE_SETTER_SITUATION_CODES.WONT_START_CLICK]:
    PACE_SETTER_SITUATION_TYPES.CAR_WONT_START,
  [PACE_SETTER_SITUATION_CODES.WONT_START_DIED]:
    PACE_SETTER_SITUATION_TYPES.CAR_WONT_START,
  [PACE_SETTER_SITUATION_CODES.LOCK_KEY_INSIDE]:
    PACE_SETTER_SITUATION_TYPES.LOCKED_OUT,
  [PACE_SETTER_SITUATION_CODES.LOCK_KEY_LOST]:
    PACE_SETTER_SITUATION_TYPES.LOCKED_OUT,
  [PACE_SETTER_SITUATION_CODES.LOCK_KEY_NO_TURN]:
    PACE_SETTER_SITUATION_TYPES.LOCKED_OUT,
  [PACE_SETTER_SITUATION_CODES.FUEL_GAS]:
    PACE_SETTER_SITUATION_TYPES.OUT_OF_FUEL,
  [PACE_SETTER_SITUATION_CODES.FUEL_DIESEL]:
    PACE_SETTER_SITUATION_TYPES.OUT_OF_FUEL,
  [PACE_SETTER_SITUATION_CODES.FUEL_ELECTRIC]:
    PACE_SETTER_SITUATION_TYPES.OUT_OF_FUEL,
  [PACE_SETTER_SITUATION_CODES.NEED_TOW]:
    PACE_SETTER_SITUATION_TYPES.NEED_A_TOW,
  [PACE_SETTER_SITUATION_CODES.TIRE_NO_SPARE]:
    PACE_SETTER_SITUATION_TYPES.FLAT_TIRE,
  [PACE_SETTER_SITUATION_CODES.TIRE_SPARE]:
    PACE_SETTER_SITUATION_TYPES.FLAT_TIRE,
  [PACE_SETTER_SITUATION_CODES.FLAT_TIRE_NO_SPARE_TROUBLE_CODE]:
    PACE_SETTER_SITUATION_TYPES.FLAT_TIRE,
  [PACE_SETTER_SITUATION_CODES.FLAT_TIRE_SPARE_TROUBLE_CODE]:
    PACE_SETTER_SITUATION_TYPES.FLAT_TIRE,
  [PACE_SETTER_SITUATION_CODES.BATTERY_TROUBLE_CODE]:
    PACE_SETTER_SITUATION_TYPES.BATTERY_ISSUE,
  [PACE_SETTER_SITUATION_CODES.CAR_WONT_START_TROUBLE_CODE]:
    PACE_SETTER_SITUATION_TYPES.CAR_WONT_START,
  [PACE_SETTER_SITUATION_CODES.FUEL_TROUBLE_CODE]:
    PACE_SETTER_SITUATION_TYPES.OUT_OF_FUEL,
  [PACE_SETTER_SITUATION_CODES.TOW_TROUBLE_CODE]:
    PACE_SETTER_SITUATION_TYPES.NEED_A_TOW,
  [PACE_SETTER_SITUATION_CODES.LOCKED_OUT_KEY_DELIVERY_TROUBLE_CODE]:
    PACE_SETTER_SITUATION_TYPES.LOCKED_OUT,
  [PACE_SETTER_SITUATION_CODES.LOCKED_OUT_LOCKSMITH_TROUBLE_CODE]:
    PACE_SETTER_SITUATION_TYPES.LOCKED_OUT,
  [PACE_SETTER_SITUATION_CODES.LOCKED_OUT_TROUBLE_CODE]:
    PACE_SETTER_SITUATION_TYPES.LOCKED_OUT,
  [PACE_SETTER_SITUATION_CODES.STUCK_TROUBLE_CODE]:
    PACE_SETTER_SITUATION_TYPES.STUCK
}

export function getPaceSetterNameByCode(code: PACE_SETTER_SITUATION_CODES) {
  return paceSetterMap[code] || ''
}

// TODO move to actual .html templates. Add regular sections.
export const PACE_SETTER_OPTION_TEMPLATES: IndexedCollection<PaceSetterOptions> =
  {
    [PACE_SETTER_SITUATION_TYPES.ACCIDENT]: {
      name: 'Accident',
      defaultCode: {
        name: 'Accident',
        paceSetterCode: 'T906',
        requiresTowing: true,
      },
      detailGroups: [],
    },
    [PACE_SETTER_SITUATION_TYPES.STUCK]: {
      name: 'Vehicle Stuck',
      defaultCode: {
        name: 'Vehicle Stuck',
        paceSetterCode: 'T931',
        requiresTowing: true,
      },
      detailGroups: [],
    },
    [PACE_SETTER_SITUATION_TYPES.BATTERY_ISSUE]: {
      name: 'Battery Issue',
      defaultCode: {
        name: 'Battery Issue',
        paceSetterCode: 'L301',
        requiresTowing: false,
      },
      detailGroups: [],
    },
    [PACE_SETTER_SITUATION_TYPES.BATTERY_ISSUE_CAR_WONT_START]: {
      name: 'Battery Issue/Car won\'t start',
      defaultCode: {
        name: 'Battery Issue/Car won\'t start',
        paceSetterCode: 'L301',
        requiresTowing: false,
      },
      detailGroups: [
        {
          heading: `What's happening?`,
          allowNotes: true,
          noteInstructions:
            'Is the check engine light on?  Can you see anything else that might help us diagnose the issue?',
          lists: [
            {
              name: `Car Won't Start`,
              mandatory: true,
              multiSelect: false,
              values: [
                {
                  name: 'I turn the key and nothing happens',
                  paceSetterCode: 'L301',
                  comment: 'I turn the key and nothing happens',
                },
                {
                  name: 'I turn the key and hear a clicking sound',
                  paceSetterCode: 'L401',
                  comment: 'I turn the key and hear a clicking sound',
                },
                {
                  name: 'Vehicle died while driving',
                  paceSetterCode: 'T680',
                  comment: 'Vehicle died while driving',
                  requiresTowing: true,
                },
                {
                  name: 'Battery issue',
                  paceSetterCode: 'L301',
                  comment: 'Battery issue',
                },
                {
                  name: 'I need a tow',
                  paceSetterCode: 'T480',
                  comment: 'Need a tow',
                  requiresTowing: true,
                },
              ],
            },
          ],
        },
      ],
    },
    [PACE_SETTER_SITUATION_TYPES.FLAT_TIRE]: {
      name: 'Flat Tire',
      detailGroups: [
        {
          heading: 'Do you have spares for each flat tire?',
          allowNotes: true,
          noteInstructions: `Tell us about your issue, and we'll be better prepared when we arrive.`,
          lists: [
            {
              name: 'spares',
              mandatory: true,
              values: [
                {
                  name: 'Yes',
                  tagLabel: 'Spare Tires?',
                  alternateLabel: 'I have a spare',
                  paceSetterCode: 'L101',
                  requiresTowing: false,
                  comment: 'Have spare for each flat tire',
                },
                {
                  name: 'No',
                  tagLabel: 'Spare Tires?',
                  alternateLabel: `I don't have a spare`,
                  paceSetterCode: 'T180',
                  requiresTowing: true,
                  comment: 'Don\'t have spare for each flat tire',
                },
              ],
            },
          ],
        },
      ],
    },
    [PACE_SETTER_SITUATION_TYPES.LOCKED_OUT]: {
      name: 'Locked Out',
      defaultCode: {
        name: 'Locked Out',
        paceSetterCode: 'L701',
      },
      detailGroups: [
        {
          heading: 'Where are the keys?',
          isOptional: true,
          lists: [
            {
              name: 'key location',
              mandatory: true,
              multiSelect: false,
              values: [
                {
                  name: 'Locked in the vehicle',
                  paceSetterCode: 'L701',
                  requiresTowing: false,
                  comment: 'Locked in the vehicle',
                },
                {
                  name: 'My key is broken or lost',
                  paceSetterCode: 'L890',
                  requiresTowing: false,
                  comment: 'My key is broken or lost',
                },
                {
                  name: `My key won't turn in the ignition`,
                  paceSetterCode: 'L801',
                  requiresTowing: false,
                  comment: `My key won't turn in the ignition`,
                },
                {
                  name: `My key won't unlock the door`,
                  paceSetterCode: 'L890',
                  requiresTowing: false,
                  comment: `My key won't unlock the door`,
                },
                {
                  name: `I don't know`,
                  paceSetterCode: 'L701',
                  requiresTowing: false,
                },
              ],
            },
          ],
        },
      ],
    },
    [PACE_SETTER_SITUATION_TYPES.OUT_OF_FUEL]: {
      name: 'Fuel/EV Charge',
      detailGroups: [
        {
          heading: 'Please select the fuel type for your vehicle.',
          subHeading:
            'Additional fees may apply depending on membership level.',
          lists: [
            {
              name: 'fuelType',
              mandatory: true,
              values: [
                {
                  name: 'Gasoline',
                  paceSetterCode: 'L402',
                  requiresTowing: false,
                  comment: 'Gas Vehicle',
                },
                {
                  name: 'Diesel Fuel',
                  paceSetterCode: 'L403',
                  requiresTowing: false,
                  comment: 'Diesel Vehicle',
                },
                {
                  name: 'Electric Vehicle',
                  paceSetterCode: 'T483',
                  requiresTowing: true,
                  comment: 'Electric Vehicle',
                },
              ],
            },
          ],
        },
      ],
    },
    [PACE_SETTER_SITUATION_TYPES.NEED_A_TOW]: {
      name: 'Need a tow',
      defaultCode: {
        name: 'Need a tow',
        paceSetterCode: 'T480',
        requiresTowing: true,
      },
      detailGroups: [
        {
          heading: `What's happening?`,
          subHeading: `Let us know what's going on.`,
          allowNotes: true,
          noteInstructions: `Tell us about your issue, and we'll be better prepared when we arrive.`,
          lists: [],
        },
      ],
    },
    [PACE_SETTER_SITUATION_TYPES.CAR_WONT_START]: {
      name: `My Car Won't Start`,
      detailGroups: [
        {
          heading: `What's happening?`,
          allowNotes: true,
          noteInstructions:
            'Is the check engine light on?  Can you see anything else that might help us diagnose the issue?',
          lists: [
            {
              name: `Car Won't Start`,
              mandatory: true,
              multiSelect: false,
              values: [
                {
                  name: 'I turn the key and nothing happens',
                  paceSetterCode: 'L301',
                  comment: 'I turn the key and nothing happens',
                },
                {
                  name: 'I turn the key and hear a clicking sound',
                  paceSetterCode: 'L401',
                  comment: 'I turn the key and hear a clicking sound',
                },
                {
                  name: 'Vehicle died while driving',
                  paceSetterCode: 'T680',
                  comment: 'Vehicle died while driving',
                  requiresTowing: true,
                },
                {
                  name: 'Battery issue',
                  paceSetterCode: 'L301',
                  comment: 'Battery issue',
                },
              ],
            },
          ],
        },
      ],
    },
  }
export const defaultOptions = {
  name: 'Service Request',
  detailGroups: [] as any,
  defaultCode: undefined,
}
