import { createSelector } from '@ngrx/store'
import { AAAStore } from 'src/app/store/root-reducer'
import { AdvisoriesState } from './advisories.reducer'
import { Advisory } from './advisories.types'

export const selectAdvisoriesState = (store: AAAStore): AdvisoriesState =>
  store.advisories

export const selectHasSeenClubAdvisory = createSelector(
  selectAdvisoriesState,
  (state): boolean => state.hasSeenAdvisories
)

export const selectAdvisories = createSelector(
  selectAdvisoriesState,
  (state): Array<Advisory> => state.advisories
)
