import { createSelector } from '@ngrx/store'

import { AAACallState } from './calls.reducer'
import { AAAStore } from '../../store/root-reducer'
import { BasicAddressInfo, UserInfo } from './../../shared/types'
import {
  BreakdownLocation,
  GenericCoordinates,
  TowLocation,
} from '../location/location.types'
import {
  AAACallData,
  AgentSettings,
  ServiceDeliveryCallStatus,
} from './calls.types'
import { selectAgentSettings, selectAuthClub, selectEligibility } from '../auth/auth.selectors'

import {
  selectMemberActiveVehicle,
  selectMemberActiveVehicleNotes,
  selectMemberData,
} from '../member/member.selectors'
import { concatAddress } from '../../shared/utils/concatAddress'
import {
  selectActivePaceSetterCode,
  selectPaceSetterNotesAndComments,
} from '../issue/issue.selectors'
import { selectTowLocation } from '../location/tow-location/tow-location.selectors'
import {
  selectBreakdownLocationDetails,
  selectBreakdownLocationParams,
  selectHighwayExit,
} from '../location/location.selectors'
import {
  selectMergedPassengersComments,
  selectUserContactInfo,
} from '../submit/submit.selectors'
import {
  CHARACTER_LIMIT_FACILITY,
  CHARACTER_LIMIT_VEHICLE_COLOR,
} from '../location/location.constants'
import { VehicleDriveTypes } from '../vehicle/vehicle.types'

export const selectCallState = (store: AAAStore): AAACallState => store.call

export const selectCallBreakdownLocationCoordinates = createSelector(
  selectCallState,
  (state: AAACallState): GenericCoordinates => ({
    latitude: state.breakdownLocation.latitude,
    longitude: state.breakdownLocation.longitude,
  })
)

export const selectCallBreakdownLocation = createSelector(
  selectCallState,
  (state: AAACallState): BreakdownLocation => state.breakdownLocation
)

export const selectCallBreakdownLocationAddress = createSelector(
  selectCallState,
  (state: AAACallState): string => state.breakdownLocationAddress
)

export const selectCallTowing = createSelector(
  selectCallState,
  (state: AAACallState): TowLocation => state.towing
)

export const selectTowingDescription = createSelector(
  selectCallTowing,
  (towing: TowLocation): string => {
    if (!towing) {
      return ''
    }

    return concatAddress(towing as BasicAddressInfo, towing.name, true)
  }
)

export const selectCallComments = createSelector(
  selectCallState,
  (state: AAACallState): string => state.comments
)

// start ARR:POC - A selector for selecting the service delivery data
export const selectServiceDeliveryStatus = createSelector(
  selectCallState,
  (state: AAACallState): ServiceDeliveryCallStatus => state.callStatus
)
// end ARR:POC

const selectMergedComments = createSelector(
  selectPaceSetterNotesAndComments,
  selectBreakdownLocationDetails,
  selectMemberActiveVehicleNotes,
  selectMergedPassengersComments,
  selectHighwayExit,
  (
    paceSetterComments,
    locationDetails,
    vehicleNotes,
    passengerComments,
    highwayExit
  ): string => {
    const result = []

    // first place pace setter comments, if it exists
    if (paceSetterComments) {
      result.push(paceSetterComments)
    }

    // second place breakdown location name, if it exists
    result.push(...locationDetails.options.map((detail) => detail.name))

    // third place any breakdown location notes, if it exists
    if (locationDetails.notes) {
      result.push(locationDetails.notes)
    }

    // fourth place any vehicles notes, if it exists
    if (vehicleNotes) {
      result.push(vehicleNotes)
    }

    // fifth place any vehicles comments, if it exists
    if (passengerComments) {
      result.push(passengerComments)
    }

    // Sixth highway exit information
    if (highwayExit) {
      result.push(`LOCATION: ${highwayExit}`)
    }

    return result.join(' ')
  }
)

// Compacted club + agent selectors due to a limitation of 9 params in createSelectors.
export const selectAgentAndClub = createSelector(
  selectAuthClub,
  selectAgentSettings,
  (club, agentSettings): AgentSettings => {
    if (!agentSettings) {
      return { club }
    }

    return {
      club,
      agentName: agentSettings.agentName,
      agentUsername: agentSettings.agentUsername,
      cashCall: agentSettings.cashCall,
      priorityCode: agentSettings.priorityCode,
      vehicleType: agentSettings.vehicleType,
    }
  }
)

export const selectUserInfo = createSelector(
  selectMemberData,
  selectUserContactInfo,
  selectEligibility,
  (
    memberData,
    userContactInfo,
    eligibility,
  ): UserInfo => {
    if (eligibility) {
      return ({
        ...memberData,
        ...userContactInfo,
      })
    }
    return ({
      ...memberData
    })
  }
)

export const selectCallData = createSelector(
  selectUserInfo,
  selectMemberActiveVehicle,
  selectActivePaceSetterCode,
  selectUserContactInfo,
  selectMergedComments,
  selectBreakdownLocationParams,
  selectTowLocation,
  selectAgentAndClub,
  (
    userInfo,
    activeMemberVehicle,
    activePaceSetter,
    userContactInfo,
    mergedComments,
    breakdownLocationParams,
    towLocation,
    agentSettings
  ): AAACallData => {
    const address = towLocation ? concatAddress(towLocation) : ''

    return {
      club: agentSettings.club,
      contactInfo: {
        enableEmail: false,
        enablePushNotifications: false,
        enableSMS: !!userContactInfo.shouldTextUpdates,
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        phone: userContactInfo.contactNumber,
      },
      vehicle: {
        ...activeMemberVehicle,
        vehicleType: agentSettings?.vehicleType,
        color: activeMemberVehicle?.color?.substr(
          0,
          CHARACTER_LIMIT_VEHICLE_COLOR
        ),
        driveType: activeMemberVehicle?.driveType
        // TODO: The API only recognizes either AWD or 4WD, despite UI/UX decision to combine them
          ? VehicleDriveTypes.ALL_WHEEL_DRIVE
          : null
      },
      callTaker: agentSettings.agentName || agentSettings.agentUsername,
      cashRequired: agentSettings.cashCall,
      priorityCode: agentSettings.priorityCode,
      situationCodes: {
        pacesetterCode: activePaceSetter?.paceSetterCode,
      },
      comments: mergedComments,
      breakdownLocation: breakdownLocationParams,
      towing: towLocation
        ? {
            facility: towLocation.name.substr(0, CHARACTER_LIMIT_FACILITY),
            serviceProviderCode: towLocation.serviceProviderCode,
            address,
            name: towLocation.name.substr(0, CHARACTER_LIMIT_FACILITY),
            streetNumber: towLocation.streetNumber,
            streetName: towLocation.streetName,
            city: towLocation.city,
            state: towLocation.state,
            latitude: towLocation.latitude,
            longitude: towLocation.longitude,
          }
        : null,
    }
  }
)

export const selectCallStatusError = createSelector(
  selectCallState,
  (state: AAACallState): boolean => state.callWithError
)

export const __TEST__ = {
  selectMergedComments,
}
