import { MemoizedProjection, select, Store } from '@ngrx/store'
import { Actions, ofType, createEffect } from '@ngrx/effects'
import { Injectable } from '@angular/core'
import { from, iif, of } from 'rxjs'
import {
  catchError,
  withLatestFrom,
  concatMap,
  filter,
  switchMap,
  map,
  tap,
} from 'rxjs/operators'

import { AAACallStatus, BreakdownLocationParams, CALL_STATUS_CODES } from '../calls.types'
import { AAAStore } from './../../../store/root-reducer'
import { setCallStatusError, resetCallStatusError, notifyCallFailure } from '../calls.actions';
import {
  callStatusSuccess,
  setActiveCallStatus,
  CALL_STATUS,
  DRIVER_INFO,
  driverInfoSuccess,
  driverInfoFailure,
  notifyCallStatusFailure,
  callStatusRequest,
  activeCallStatusRequest,
  activeCallStatusSuccess,
  ACTIVE_CALL_STATUS,
  notifyActiveCallStatusFailure
} from './call-status.actions';
import { CallsService } from '../calls.service'
import { DriverInfo } from './call-status.types'
import { IndexedCollection, PayloadedAction } from '../../../shared/types'
import { MessageDialogTypes } from '../../ui/ui.types'
import {
  selectDriverInfo,
  selectCallsStatusesData,
  selectActiveCallStatusId,
  selectCanceledCallStatus,
  createSelectDefaultActiveCallStatusId,
  selectActiveCallsCalled
} from './call-status.selectors';
import { TaggingService } from '../../tagging/tagging.service'
import { AutomatedEventMeta } from '../../tagging/tagging.actions'
import { concatAddress, dispatchErrorAction } from 'src/app/shared/utils'
import { Title } from '@angular/platform-browser'
import { buildTitle } from 'src/app/shared/utils/title'
import { createSelectIsCallStatusTagged } from '../../tagging/tagging.selectors'
import { ErrorReportingService } from 'src/app/shared/services/error-reporting.service'
import { ConfigService } from '../../config/config.service'
import { CallStatusService } from './call-status.service'
import {
  setBreakdownLocationSuccess,
  setLocationClubSuccess,
} from '../../location/location.actions'
import { BreakdownLocation } from '../../location/location.types'
import { assignExistingVehicle, memberEligibilityResult } from '../../member/member.actions'
import { completeSetTowDestination } from '../../location/tow-location/tow-location.actions'
import { setPaceSetterSituation } from '../../issue/issue.actions'
import { getPaceSetterNameByCode } from '../../issue/issue.utils'
import { generateCallId, indexCalls } from '../calls.utils'
import { RouteGuardService } from 'src/app/shared/route-guard/route-guard.service'
import { advisoriesRequest } from '../../advisories/advisories.actions'
import { RouteTypes } from '../../main-router.module'
import { selectCurrentUrl } from '../../wizard/wizard.selectors'
import { selectActiveHasTracking } from '../clubs/clubs.selectors'
import { selectMemberData } from '../../member/member.selectors'
import events from '../../tagging/events'
import { selectAuthMethod, selectEligibility } from '../../auth/auth.selectors'

const MAX_CONFIRMATION_RETRIES = 3
const _submitPageType = 'Submit DRR Request'

function tagEvent(tagging: TaggingService, meta: AutomatedEventMeta) {
  switch (meta.callStatus) {
    case CALL_STATUS_CODES.RE:
      tagging.setAutomatedEvent(
        events.dashboard.CALL_STATUS_RECEIVED_SUCCESS,
        events.dashboard.CALL_STATUS_PAGE_TYPE,
        meta
      )
      break

    case CALL_STATUS_CODES.ER:
      tagging.setAutomatedEvent(
        events.dashboard.CALL_STATUS_ENROUTE_SUCCESS,
        events.dashboard.CALL_STATUS_PAGE_TYPE,
        meta
      )
      break

    case CALL_STATUS_CODES.OL:
      tagging.setAutomatedEvent(
        events.dashboard.CALL_STATUS_ONLOCATION_SUCCESS,
        events.dashboard.CALL_STATUS_PAGE_TYPE,
        meta
      )
      break
  }
}

function isCallCancelled(
  cancelledCalls: Array<string>,
  call: AAACallStatus
): boolean {
  return (
    cancelledCalls.indexOf(generateCallId(call.callId, call.callDate)) !== -1 ||
    call.callStatus === CALL_STATUS_CODES.CA ||
    call.callStatus === CALL_STATUS_CODES.CL ||
    call.callStatus === CALL_STATUS_CODES.XX
  )
}

function validateCallStatus(titleService, _callStatusService, maxConfirmationRetries) {
  return function([
    callStatusesRemote,
    callStatusesLocal,
    selectDefaultActiveCallStatusId,
  ]: [
    IndexedCollection<AAACallStatus>,
    IndexedCollection<AAACallStatus>,
    MemoizedProjection,
    any,
    any
  ]) {
    const activeCallStatusId =
      selectDefaultActiveCallStatusId.memoized(callStatusesRemote)

    Object.keys(callStatusesLocal).forEach((callId) => {
      if (callStatusesLocal[callId].callStatus !== CALL_STATUS_CODES.NEW) {
        // TODO: Move. It's here due to out of sync issues when present in component (title should render before component)
        titleService.setTitle(buildTitle('Service Tracking'))

        // get the active call status from the remote status, if not present (already canceled or completed) map it from the local status
        const activeCallStatus =
          callStatusesRemote[activeCallStatusId]?.callStatus ||
          callStatusesRemote[callId]?.callStatus

        switch (activeCallStatus) {
          case CALL_STATUS_CODES.CL:
            _callStatusService.resetCallStatus(
              MessageDialogTypes.CALL_COMPLETED
            )
            break
          case CALL_STATUS_CODES.CA:
            _callStatusService.resetCallStatus(
              MessageDialogTypes.CALL_CANCELLED
            )
            break
        }
      } else if (
        callStatusesLocal[callId].callStatus === CALL_STATUS_CODES.NEW &&
        !callStatusesRemote[callId]
      ) {
        if (!callStatusesLocal[callId]._retries) {
          callStatusesLocal[callId]._retries = 0
        }
        callStatusesLocal[callId]._retries++

        if (callStatusesLocal[callId]._retries >= maxConfirmationRetries) {
          throw new Error(
            `Could not verify call status for call id: ${callId} after ${maxConfirmationRetries} attempts.`
          )
        }
      }
    })

    return Object.values(callStatusesRemote).length > 0
  }
}

function syncCallStatus(taggingService) {
  return function([
    callStatusesRemote,
    callStatusesLocal,
    selectDefaultActiveCallStatusId,
    cancelledCalls,
    selectIsCallStatusTagged,
  ]: [
    IndexedCollection<AAACallStatus>,
    IndexedCollection<AAACallStatus>,
    MemoizedProjection,
    string[],
    any
  ]): [IndexedCollection<AAACallStatus>, string] {
    Object.values(callStatusesRemote).forEach((call) => {
      const callIdentifier = generateCallId(call.callId, call.callDate)
      const isTagged = selectIsCallStatusTagged(callIdentifier, call.callStatus)
      if (!isTagged) {
        tagEvent(taggingService, {
          callIdentifier,
          callStatus: call.callStatus,
        })
      }
    })

    const callStatuses = [...Object.values(callStatusesRemote)]
    Object.values(callStatusesLocal).forEach((callStatusLocal) => {
      const callIdentifierLocal = generateCallId(
        callStatusLocal.callId,
        callStatusLocal.callDate
      )
      if (
        callStatusLocal.callStatus === CALL_STATUS_CODES.NEW &&
        !callStatusesRemote[callIdentifierLocal]
      ) {
        callStatuses.push(callStatusLocal)
      }
    })

    const filteredCallStatuses = indexCalls(
      callStatuses.filter((call) => !isCallCancelled(cancelledCalls, call))
    )
    const activeCallStatusId =
      selectDefaultActiveCallStatusId.memoized(filteredCallStatuses)
    return [filteredCallStatuses, activeCallStatusId]
  }
}

function handleActiveCallStatus(configService: ConfigService) {
  return function([indexedCallStatuses, activeCallStatusId]: [
    IndexedCollection<AAACallStatus>,
    string
  ]) {
    const callStatus = indexedCallStatuses?.[activeCallStatusId]
    const dispatchedActions: Array<PayloadedAction> = [
      setActiveCallStatus({
        payload: { id: activeCallStatusId },
      }),
      setLocationClubSuccess({
        payload: {
          association: configService.getConfig().association,
          club: callStatus.servicingClub,
          zipcode: (callStatus.breakdownLocation as BreakdownLocationParams).zip ||
            (callStatus.breakdownLocation as BreakdownLocation).postalCode
        },
      }),
      setPaceSetterSituation({
        payload: {
          name: getPaceSetterNameByCode(callStatus.pacesetterCode),
          // TODO refactor
          icon: '',
        },
      }),
      setBreakdownLocationSuccess({
        payload: {
          ...(callStatus.breakdownLocation as BreakdownLocation),
          address: concatAddress(
            callStatus.breakdownLocation as BreakdownLocation
          ),
        },
      }),
      assignExistingVehicle({
        payload: callStatus.vehicle,
      }),
    ]

    if (callStatus.towDestination) {
      dispatchedActions.push(
        completeSetTowDestination({
          payload: callStatus.towDestination,
        })
      )
    }

    return dispatchedActions
  }
}

@Injectable()
export class CallsStatusesEffect {
  constructor(
    private actions$: Actions,
    private _callsService: CallsService,
    private _callStatusService: CallStatusService,
    private store$: Store<AAAStore>,
    private taggingService: TaggingService,
    private errorReportingService: ErrorReportingService,
    private configService: ConfigService,
    private routeGuardService: RouteGuardService,
    protected titleService: Title
  ) {}
  maxConfirmationRetries = MAX_CONFIRMATION_RETRIES

  handleCallsStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof callStatusRequest>>(CALL_STATUS.REQUEST),
      withLatestFrom(
        this.store$.select(selectMemberData),
        this.store$.select(selectEligibility),
      ),
      filter(
        ([action, memberData, eligibility]) => {
          if (!action.payload.eligible || action.payload.ersAbuser) {
            return true // see DRRWEB-305
          }
          const checkCallStatusRap = eligibility?.eligible
          const checkCallStatusMember = !this._callsService.isCallStatusRequestPending &&
            memberData?.eligible &&
            !memberData.ersAbuser
          return checkCallStatusMember || checkCallStatusRap
        }),
        switchMap(([action]) => [activeCallStatusRequest({ payload: action.payload })])
    )
  )

  handleActiveCallStatusRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof activeCallStatusRequest>>(ACTIVE_CALL_STATUS.REQUEST),
      withLatestFrom(
        this.store$.pipe(select(selectActiveCallsCalled))
      ),
      switchMap(([action, activeCallCalled]: [PayloadedAction, boolean]) =>
        from(activeCallCalled ? Promise.resolve([])
          : this._callsService.getActiveCalls(action.payload.timeout)).pipe(
          withLatestFrom(
            this.store$.pipe(select(selectCallsStatusesData))
          ),
          map(
            ([response, localCalls]): any => {
              const calls = response.filter(Boolean)
              if (action.payload.addCallFailure && calls.length === 0) {
                this.taggingService.setAutomatedEvent(
                  events.submit.SUBMIT_FAILURE,
                  events.submit.SUBMIT_PAGE_TYPE
                )
                return notifyCallFailure();
              } else if (!action.payload.retry && (!action.payload.eligible || action.payload.ersAbuser) && calls.length === 0) {
                return memberEligibilityResult({ payload: action.payload })
              }
              const remoteCallIds = response.map((call) => generateCallId(call.callId, call.callDate))
              Object.keys(localCalls).forEach((localCallId) => {
                if (!remoteCallIds.includes(localCallId)) {
                  calls.push(localCalls[localCallId])
                }
              })
              return activeCallStatusSuccess({
                payload: {
                  data: calls,
                  params: action.payload
                },
              })
            }
          ),
          catchError((error) => {
            if (action.payload.addCallFailure) {
              this.taggingService.setAutomatedEvent(
                events.submit.SUBMIT_FAILURE,
                events.submit.SUBMIT_PAGE_TYPE
              )
              return this.errorReportingService.notifyErrorObservable(
                error,
                notifyCallFailure
              )
            } else {
              return this.errorReportingService.notifyErrorObservable(
                error,
                notifyActiveCallStatusFailure
              )
            }
          }
          )
        )
      )
    )
  )

  handleActiveCallStatusSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof activeCallStatusSuccess>>(ACTIVE_CALL_STATUS.SUCCESS),
      switchMap((action: PayloadedAction) =>
        from(Promise.all(action.payload.data
          .map(activeCall => this._callsService.getCallDetails(activeCall, action.payload.params.retry))))
          .pipe(
            map((activeCalls) => indexCalls(activeCalls)),
            withLatestFrom(
              this.store$.pipe(select(selectCallsStatusesData)),
              this.store$.pipe(select(createSelectDefaultActiveCallStatusId)),
              this.store$.pipe(select(selectCanceledCallStatus)),
              this.store$.pipe(select(createSelectIsCallStatusTagged)),
              this.store$.pipe(select(selectActiveHasTracking))
            ),
            switchMap(
              ([
                 callsStatusesRemote,
                 callsStatusesLocal,
                 selectDefaultActiveCallStatusId,
                 cancelledCalls,
                 selectIsCallStatusTagged,
                 hasTracking
               ]) =>
                iif(
                  () =>
                    Object.keys(callsStatusesLocal).length === 0 &&
                    Object.keys(callsStatusesRemote).length === 0,
                  of(callStatusSuccess({ payload: { data: {} } })).pipe(
                    tap(() => {
                      if (!(action.payload?.params?.retry)) {
                        this._callStatusService.stopCallStatusesUpdater();
                      }
                    })
                  ),
                  of([
                    callsStatusesRemote,
                    callsStatusesLocal,
                    selectDefaultActiveCallStatusId,
                    cancelledCalls,
                    selectIsCallStatusTagged
                  ]).pipe(
                    tap(() => {
                      if (hasTracking) {
                        this._callStatusService.startCallStatusesUpdater({
                          retry: true
                        });
                      } else {
                        this._callStatusService.stopCallStatusesUpdater();
                      }
                      // reset call status error
                      this.store$.dispatch(resetCallStatusError());
                    }),
                    filter(
                      validateCallStatus(
                        this.titleService,
                        this._callStatusService,
                        this.maxConfirmationRetries
                      )
                    ),
                    map(syncCallStatus(this.taggingService)),
                    concatMap(([indexedCallStatuses, activeCallStatusId]) => {
                      const stream = [];

                      if (
                        activeCallStatusId &&
                        indexedCallStatuses[activeCallStatusId]
                      ) {
                        stream.push(
                          advisoriesRequest({
                            payload: {
                              association:
                              this.configService.getConfig().association,
                              club: indexedCallStatuses[activeCallStatusId]
                                .servicingClub
                            }
                          })
                        );
                      }

                      return [
                        ...stream,
                        callStatusSuccess({
                          payload: { data: indexedCallStatuses }
                        })
                      ];
                    }),
                    catchError((error) =>
                      this.errorReportingService.notifyErrorObservable(
                        error,
                        [
                          setCallStatusError(),
                          dispatchErrorAction(notifyCallStatusFailure, error)
                        ],
                        { callId: selectActiveCallStatusId }
                      )
                    )
                  )
                )
            ),
            catchError((error) =>
              this.errorReportingService.notifyErrorObservable(
                error,
                [
                  setCallStatusError(),
                  dispatchErrorAction(notifyCallStatusFailure, error)
                ],
                { callId: selectActiveCallStatusId }
              )
            )
          )
      )
    )
  )

  handleActiveCallStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof callStatusSuccess>>(CALL_STATUS.SUCCESS),
      map((action) => action.payload.data),
      filter(
        (indexedCallStatuses) => Object.keys(indexedCallStatuses).length > 0
      ),
      withLatestFrom(
        this.store$.pipe(select(createSelectDefaultActiveCallStatusId))
      ),
      concatMap(([indexedCallStatuses, selectDefaultActiveCallStatusId]) => {
        const activeCallStatusId =
          selectDefaultActiveCallStatusId.memoized(indexedCallStatuses)

        return handleActiveCallStatus(this.configService)([
          indexedCallStatuses,
          activeCallStatusId,
        ])
      })
    )
  )

  handleNoActiveCallNavigation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<
          | ReturnType<typeof callStatusSuccess>
          | ReturnType<typeof notifyCallStatusFailure>
        >(CALL_STATUS.SUCCESS, CALL_STATUS.FAILURE),
        withLatestFrom(this.store$.select(selectCurrentUrl)),
        map(([action, url]) => [
          (action as ReturnType<typeof callStatusSuccess>).payload?.data,
          url,
        ]),
        filter(
          ([indexedCallStatuses, url]: [
            IndexedCollection<AAACallStatus>,
            string
          ]) =>
            (!indexedCallStatuses && url.startsWith(`/${RouteTypes.SIGNIN}`)) ||
            (indexedCallStatuses &&
              Object.keys(indexedCallStatuses).length === 0)
        ),
        tap(() => this._callStatusService.stopCallStatusesUpdater()),
        map(() => this.routeGuardService.redirectToFirstStep())
      ),
    { dispatch: false }
  )

  handleDriverInfo = createEffect(() =>
    this.actions$.pipe(
      ofType(DRIVER_INFO.REQUEST),
      withLatestFrom(this.store$.pipe(select(selectDriverInfo))),
      switchMap(([action, driverInfoLocal]: [PayloadedAction, DriverInfo]) => {
        if (!driverInfoLocal) {
          return from(this._callsService.getDriverInfo(action.payload)).pipe(
            map((driverInfoRemote) =>
              driverInfoSuccess({
                payload: {
                  driverId: action.payload.driverId,
                  ...driverInfoRemote,
                },
              })
            )
          )
        }
        return [driverInfoSuccess({ payload: driverInfoLocal })]
      }),
      catchError((error) =>
        this.errorReportingService.notifyErrorObservable(
          error,
          driverInfoFailure,
          { callId: selectActiveCallStatusId }
        )
      )
    )
  )
}

export const __TEST__ = {
  MAX_CONFIRMATION_RETRIES,
  isCallCancelled,
  validateCallStatus,
  syncCallStatus,
  handleActiveCallStatus
}
