export default {
  PASSENGERS_PAGE_TYPE: 'Passengers',
  PASSENGERS_COUNT_SELECT: 'Passengers count',
  PASSENGERS_NEXT_CLICK: 'Passengers next',
  SUMMARY_PAGE_TYPE: 'Summary',
  SUBMIT_PAGE_TYPE: 'Submit DRR Request',
  SUBMIT_SUCCESS: 'RSO Send Request Response',
  SUBMIT_FAILURE: 'RSO Send Request Failed',
  SUBMIT_DUPLICATE_PROMPT: 'Call Duplicate Prompt',
  SUMMARY_SUBMIT_CLICK: 'RSO Send Request'
}
