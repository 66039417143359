import { errorMiddleware } from './errors.meta'
import { MetaReducer } from '@ngrx/store'
import { localStorageSyncReducer } from './localStorage.meta'
import { sessionStorageSyncReducer } from './sessionStorage.meta'
import { lastInteractionMiddleware } from './lastInteraction.meta'
import { loadingMiddleware } from './loading.meta'

export const metaReducers: MetaReducer<any>[] = [
  localStorageSyncReducer,
  sessionStorageSyncReducer,
  lastInteractionMiddleware,
  errorMiddleware,
  loadingMiddleware,
]
