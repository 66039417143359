import { AgmCoreModule } from '@agm/core'
import { CommonModule } from '@angular/common'
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { MatDialogModule } from '@angular/material/dialog'
import { MatDividerModule } from '@angular/material/divider'
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router'
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap'
import { TextMaskModule } from 'angular2-text-mask'
import { NgrxFormsModule } from 'ngrx-forms'
import { NgxCaptchaModule } from 'ngx-captcha'
import { ResponsiveModule } from 'ngx-responsive'

import { MapTouchLayerComponent } from '../modules/location/map-touch-layer/map-touch-layer.component'
import { MapComponent } from '../modules/location/map/map.component'
// UI
import { UiComponents } from '../modules/ui'
import { SharedDirectives } from './directives/index'
// Icons
import { icons } from './icons'
import { AgentLoginComponent } from './layouts/agent-login/agent-login.component'
import { MemberEligibilityComponent } from './member-eligibility/member-eligibility.component'
import { SharedPipes } from './pipes'
// Shared declarationss
import { FormModules } from './shared-bootstraps'
// Components and providers
import {
  sharedComponents,
  sharedProviders
} from './shared.components.declarations'
import { FormModeDirective } from './layouts/white-label-credentials/form-mode.directive'

@NgModule({
  imports: [
    AgmCoreModule,
    CommonModule,
    RouterModule,
    ResponsiveModule.forRoot(),
    MatDialogModule,
    FormModules(),
    NgbTypeaheadModule,
    NgrxFormsModule,
    NgxCaptchaModule,
    TextMaskModule,
    MatDividerModule,
    MatIconModule
  ],
  declarations: [
    sharedComponents(),
    SharedDirectives(),
    SharedPipes(),
    UiComponents(),
    MapComponent,
    MapTouchLayerComponent,
    icons,
    AgentLoginComponent,
    MemberEligibilityComponent,
    FormModeDirective,
  ],
  exports: [
    CommonModule,
    RouterModule,
    ResponsiveModule,
    MatDialogModule,
    sharedComponents(),
    FormModules(),
    SharedDirectives(),
    SharedPipes(),
    UiComponents(),
    MapComponent,
    MapTouchLayerComponent,
    icons,
    NgrxFormsModule,
    NgxCaptchaModule,
    TextMaskModule,
    MatDividerModule,
  ],
  providers: [sharedProviders()],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class SharedModule { }
