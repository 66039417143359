import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'
import { from, Observable, of } from 'rxjs'
import {
  catchError,
  concatMap,
  filter,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators'
import { v4 as uuidv4 } from 'uuid';

import { dispatchErrorAction } from 'src/app/shared/utils'
import { AAAStore } from 'src/app/store/root-reducer'
import { ErrorReportingService } from '../../shared/services/error-reporting.service'
import { PayloadedAction } from '../../shared/types'
import { ConfigService } from '../config/config.service'
import { callStatusRequest } from '../dashboard/calls-statuses/call-status.actions'
import {
  memberBasicInfoRequest,
  memberBasicInfoSuccess,
  memberInfoRequest,
  memberInfoSuccess,
  MEMBER_BASIC_INFO,
  MEMBER_INFO,
  requestVehicleLoad,
  searchMembersReset,
  vehicleLoadSuccess,
  setEligibilityVehicle,
} from '../member/member.actions'
import { MemberBasicInfo, MemberInfo, Vehicle } from '../member/member.types'
import { startSessionTimeout } from '../session/session.actions'
import { TaggingService } from '../tagging/tagging.service'
import { handleAuthAnalytics } from '../tagging/tagging.utils'
import {
  openErrorDialog,
  resetSkipIssue,
  setSplashscreenStep,
} from '../ui/ui.actions'
import { selectQueryParamsVehicleData, selectSkipIssue } from '../ui/ui.selectors'
import { ErrorDialogTypes } from '../ui/ui.types'
import { selectVehicleYears } from '../vehicle/vehicle.selectors'
import { WizardService } from '../wizard/wizard.service'
import {
  assignSecureParams,
  AUTH,
  authNameSearchSuccess,
  authOemSuccess,
  authRentalSuccess,
  authSuccess,
  authVasSuccess,
  AUTH_NAME_SEARCH,
  AUTH_OEM,
  AUTH_RENTAL,
  AUTH_VAS,
  notifyAuthFailure,
  notifyAuthNameSearchFailure,
  notifyRapAuthFailure,
  notifyAuthRentalFailure,
  notifyAuthVasFailure,
  notifyOemAuthFailure,
  notifyRentalAuthFailure,
  notifyVasAuthFailure,
  SET_AUTH,
  SET_RAP_AUTH,
  SET_SECURE_PARAMS,
} from './auth.actions'
import { selectAuthMethod, selectIsSecure, selectModeConfiguration } from './auth.selectors'
import { AuthService } from './auth.service'
import { AuthMethods, AuthResponse, RapAuthFailure } from './auth.types'
import { getAutomatedEventDetails } from '../member/member.utils'
import { RouteTypes } from '../main-router.module'
import events from '../tagging/events'

const FIRST_CALL_STATUS_TIMEOUT = 15000

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<AAAStore>,
    private _authService: AuthService,
    private errorReportingService: ErrorReportingService,
    private router: Router,
    private taggingService: TaggingService,
    private configService: ConfigService,
    private wizardService: WizardService,
  ) { }

  handleEncryptedLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SET_SECURE_PARAMS),
      tap(() => {
        this.taggingService.setAutomatedEvent(
          events.auth.AAA_NATIONAL_AUTH_REQUEST,
          events.auth.PAGE_TYPE
        )
      }),
      switchMap((action: ReturnType<typeof assignSecureParams>) =>
        from(this._authService.secureSignin(action.payload)).pipe(
          switchMap((data: AuthResponse) => [
            authSuccess({
              payload: {
                ...data,
                club: this.configService.getConfig().unsecureClub,
                method: AuthMethods.AAA_NATIONAL,
                isSecure: true,
              },
            }),
            resetSkipIssue(),
            memberInfoRequest(),
          ]),
          catchError((error) => {
            const { reason } = getAutomatedEventDetails(error)

            handleAuthAnalytics(this.taggingService, {
              reason,
              method: AuthMethods.AAA_NATIONAL,
              isValid: false,
            })
            return this.errorReportingService.notifyErrorObservable(
              error,
              notifyAuthFailure
            )
          })
        )
      ),
      catchError((error) => {
        const { reason } = getAutomatedEventDetails(error)

        handleAuthAnalytics(this.taggingService, {
          reason,
          method: AuthMethods.AAA_NATIONAL,
          isValid: false,
        })

        return this.errorReportingService.notifyErrorObservable(
          error,
          notifyAuthFailure
        )
      })
    )
  )

  handleLogin$: Observable<
    | ReturnType<typeof authSuccess>
    | ReturnType<typeof memberBasicInfoRequest>
    | ReturnType<typeof requestVehicleLoad>
    | ReturnType<typeof notifyAuthFailure>
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(AUTH.REQUEST),
      switchMap((action: PayloadedAction) =>
        from(this._authService.unsecureSignin(action.payload)).pipe(
          mergeMap((data: AuthResponse) => [
            authSuccess({
              payload: {
                ...data,
                club: this.configService.getConfig().unsecureClub,
                method: action.payload.method,
                isSecure: false,
              },
            }),
            resetSkipIssue(),
            memberBasicInfoRequest(),
          ]),
          catchError((error) => {
            const { errorEventAction, reason } = getAutomatedEventDetails(error)

            if (errorEventAction) {
              this.taggingService.setAutomatedEvent(errorEventAction, events.auth.PAGE_TYPE)
            }

            handleAuthAnalytics(this.taggingService, {
              reason,
              method: action.payload.method,
              isValid: false,
            })
            return this.errorReportingService.notifyErrorObservable(
              error,
              notifyAuthFailure
            )
          })
        )
      ),
      catchError((error) =>
        this.errorReportingService.notifyErrorObservable(
          error,
          notifyAuthFailure
        )
      )
    )
  )

  handleLoginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<
        | ReturnType<typeof memberInfoSuccess>
        | ReturnType<typeof memberBasicInfoSuccess>
      >(MEMBER_INFO.SUCCESS, MEMBER_BASIC_INFO.SUCCESS),
      withLatestFrom(
        this.store$.pipe(select(selectAuthMethod)),
        this.store$.pipe(select(selectIsSecure))
      ),
      switchMap((inputs) =>
        of(inputs).pipe(
          tap(([action, authMethod]) => {
            if (action.payload.eligible && !action.payload.ersAbuser) {
              handleAuthAnalytics(this.taggingService, {
                method: authMethod as AuthMethods,
                isValid: true,
              })
            }
          }),
          mergeMap(([action, _, isSecure]) => {
            const actions = [
              callStatusRequest({
                payload: {
                  retry: false,
                  timeout: FIRST_CALL_STATUS_TIMEOUT,
                  ...(action.payload.eligible !== undefined ? {eligible: action.payload.eligible} : {}),
                  ...(action.payload.ersAbuser !== undefined ? {ersAbuser: action.payload.ersAbuser} : {}),
                },
              }),
              requestVehicleLoad(),
            ]
            if (isSecure) {
              actions.push(setSplashscreenStep({ payload: 2 }))
            }
            return actions
          }),
          catchError((error) => {
            const { reason } = getAutomatedEventDetails(error)
            handleAuthAnalytics(this.taggingService, {
              reason,
              method: inputs[1],
              isValid: false,
            })

            return this.errorReportingService.notifyErrorObservable(
              error,
              notifyAuthFailure
            )
          })
        )
      )
    )
  )

  handleLoginBySearch$ = createEffect(
    (): Observable<
      | ReturnType<typeof authNameSearchSuccess>
      | ReturnType<typeof notifyAuthFailure>
    > =>
      this.actions$.pipe(
        ofType(AUTH_NAME_SEARCH.REQUEST),
        switchMap((action: PayloadedAction) =>
          from(this._authService.searchSignin(action.payload)).pipe(
            concatMap((response) => [
              searchMembersReset(),
              authNameSearchSuccess({
                payload: {
                  ...response,
                  club: this.configService.getConfig().unsecureClub,
                  isSecure: false,
                },
              }),
              resetSkipIssue(),
              memberBasicInfoRequest(),
            ]),
            catchError((error) => {
              const { reason } = getAutomatedEventDetails(error)

              handleAuthAnalytics(this.taggingService, {
                reason,
                method: AuthMethods.MEMBER_NAME,
                isValid: false,
              })
              return this.errorReportingService.notifyErrorObservable(error, [
                openErrorDialog({
                  payload: { type: ErrorDialogTypes.MEMBER_NO_MATCHES },
                }),
                dispatchErrorAction(notifyAuthNameSearchFailure, error),
              ])
            })
          )
        )
      )
  )

  handleLoginBypass$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SET_AUTH),
      switchMap((action) =>
        of(action).pipe(
          concatMap(() => {
            this.taggingService.setAutomatedEvent(
              events.auth.AAA_MOBILE_AUTH_REQUEST,
              events.auth.PAGE_TYPE
            )

            return [memberInfoRequest(), requestVehicleLoad()]
          }),
          catchError((error) => this.errorReportingService.notifyErrorObservable(
            error,
            notifyAuthFailure
          )
          )
        )
      )
    )
  )

  // TODO: investigate deprecated areas (skip issue), move to handleLoginSuccess
  handleLoginNavigation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MEMBER_INFO.SUCCESS, MEMBER_BASIC_INFO.SUCCESS),
      switchMap((action: PayloadedAction<MemberBasicInfo | MemberInfo>) =>
        of(action).pipe(
          withLatestFrom(
            this.store$.pipe(select(selectSkipIssue)),
            this.store$.select(selectIsSecure)
          ),
          filter(
            ([_, skipIssue]) => !skipIssue && action.payload.eligible && !action.payload.ersAbuser
          ),
          mergeMap(([_, __, isSecure]) => {
            const actions = []
            if (!isSecure) {
              actions.push(startSessionTimeout())
            }
            return actions
          }),
          catchError((error) => this.errorReportingService.notifyErrorObservable(
            error,
            notifyAuthFailure
          )
          )
        )
      )
    )
  )

  // FIXME: This shouldn't be necessary. For whatever reason, route-guard.service.ts:32
  // does not detect state changes on AUTH.FAILURE, even setting ...initialState in reducer.
  // This effect has been added to mitigate the damage.
  handleLoginFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AUTH.FAILURE, AUTH_OEM.FAILURE),
        switchMap(() =>
          of([]).pipe(
            map(() => {
              this.router.navigate([RouteTypes.SIGNIN], {
                queryParamsHandling: 'preserve',
              })
            }),
            catchError((error) =>
              this.errorReportingService.notifyErrorObservable(error, [])
            )
          )
        )
      ),
    { dispatch: false }
  )


  handleRapLoginBypass$: Observable<
    | ReturnType<typeof authOemSuccess>
    | ReturnType<typeof notifyOemAuthFailure>
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(SET_RAP_AUTH),
      switchMap((action) =>
        of(action).pipe(
          switchMap((action: PayloadedAction) => {
            const oemSignInRequest = this._authService.rapVerification.bind(this._authService)
            let resolvePostSignInActions: (authResponse: AuthResponse) => any[]
            let resolvePostSignInActionsFailure: () => any[];

            resolvePostSignInActions = (data: AuthResponse) => [
                authOemSuccess({
                  payload: {
                    ...data,
                    isSecure: false,
                    method: AuthMethods.OEM
                  }
                }),
              ]
            resolvePostSignInActionsFailure = () => [
              notifyRapAuthFailure({ payload: RapAuthFailure.NOT_ELIGIBLE }),
            ]

            return from(oemSignInRequest(action.payload.access_token)).pipe(
              mergeMap((data: any) => {
                if (data.rapVerification.eligibility.eligible) {
                  const _action = 'RAP Login Bypass Validation Request - Token'
                  this.taggingService.setAutomatedEvent(_action, events.auth.PAGE_TYPE)
                  return resolvePostSignInActions({
                    ...data.rapVerification,
                    eligibility: data.rapVerification.eligibility,
                    method: data.method
                  })
                } else {
                  return resolvePostSignInActionsFailure()
                }
              }),
              catchError((error) => {
                this.taggingService.setAutomatedEvent('invalid RAP token', events.auth.PAGE_TYPE)

                handleAuthAnalytics(this.taggingService, {
                  method: action.payload.method,
                  isValid: false,
                })

                return this.errorReportingService.notifyErrorObservable(
                  error,
                  notifyOemAuthFailure
                )
              }),
            );
          }),
          catchError((error) => this.errorReportingService.notifyErrorObservable(
            error,
            notifyOemAuthFailure
          )
          )
        )
      )
    )
  )






  // OEM Effect
  handleOemAuth$: Observable<
    | ReturnType<typeof authOemSuccess>
    | ReturnType<typeof notifyAuthFailure>
    | ReturnType<typeof notifyOemAuthFailure>
  > = createEffect(() => this.actions$.pipe(
    ofType(AUTH_OEM.REQUEST),
    switchMap((action: PayloadedAction) => {
      const oemSignInRequest = this._authService.oemValidation.bind(this._authService)
      let resolvePostSignInActions: (authResponse: AuthResponse) => any[]
      let resolvePostSignInActionsFailure: () => any[];

      resolvePostSignInActions = (data: AuthResponse) => [
          authOemSuccess({
            payload: {
              ...data,
              isSecure: false,
              method: AuthMethods.OEM
            }
          }),
        ]
      resolvePostSignInActionsFailure = () => [
        notifyRapAuthFailure({ payload: RapAuthFailure.NOT_ELIGIBLE }),
      ]
      return from(oemSignInRequest(action.payload.captchaToken, action.payload.vin, action.payload.mileage, action.payload.manufacturer, action.payload.referenceId)).pipe(
        mergeMap((data: any) => {
          if (data.oemValidation.eligibility.eligible) {
            return resolvePostSignInActions({
              ...data.oemValidation,
              eligibility: data.oemValidation.eligibility,
              method: action.payload.method
            })
          } else {
            return resolvePostSignInActionsFailure()
          }
        }),
        catchError((error) => {
          this.taggingService.setAutomatedEvent('invalid vin number', events.auth.PAGE_TYPE)

          handleAuthAnalytics(this.taggingService, {
            method: action.payload.method,
            isValid: false,
          })
          return this.errorReportingService.notifyErrorObservable(
            error,
            notifyAuthFailure
          )
        }),
      );
    }),
    catchError((error) => this.errorReportingService.notifyErrorObservable(error, notifyAuthFailure))
  ))

  handleOemAuthSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(AUTH_OEM.SUCCESS),
    withLatestFrom(
      this.store$.pipe(select(selectAuthMethod)),
      this.store$.pipe(select(selectQueryParamsVehicleData)),
      this.store$.pipe(select(selectModeConfiguration)),
      this.store$.pipe(select(selectVehicleYears)),
    ),
    switchMap((action: any) => of(action).pipe(
      mergeMap(([action, authMethod, queryParamsVehicle, modeConfig, vehicleYears]) => {
        handleAuthAnalytics(this.taggingService, {
          method: authMethod as AuthMethods,
          isValid: action.payload.eligibility.eligible,
        })

        const actions: any[] = [
          callStatusRequest({
            payload: {
              retry: false,
              timeout: FIRST_CALL_STATUS_TIMEOUT,
              eligible: action.payload.eligibility.eligible,
            },
          }),
        ]

        let vehicle: Vehicle = action.payload.eligibility.contractIdentityData?.vehicle;
        const isAllowedParamsYears = vehicleYears.includes(queryParamsVehicle?.year);
        const isAllowedParamsMake = modeConfig?.makes && modeConfig?.makes
          .find(make => make?.name?.toLowerCase() === queryParamsVehicle?.make?.toLowerCase())
        if (!vehicle.id) {
          vehicle.id = uuidv4()
        }
        if (!vehicle && isAllowedParamsMake && isAllowedParamsYears) {
          vehicle = queryParamsVehicle
        }
        const payload = { vehicles: [vehicle] };
        if (vehicle) {
          actions.push(vehicleLoadSuccess({ payload }))
          actions.push(setEligibilityVehicle({ payload: vehicle }))
        }

        actions.push(startSessionTimeout())

        return actions;
      }),
      catchError((error) => this.errorReportingService.notifyErrorObservable(error, notifyOemAuthFailure))
    )))
  )

  // RENTAL Effect
  handleRentalAuth$: Observable<
    | ReturnType<typeof authRentalSuccess>
    | ReturnType<typeof notifyAuthFailure>
    | ReturnType<typeof notifyRentalAuthFailure>
  > = createEffect(() => this.actions$.pipe(
    ofType(AUTH_RENTAL.REQUEST),
    switchMap((action: PayloadedAction) => {
      const rentalSignInRequest = this._authService.rentalValidation.bind(this._authService)
      let resolvePostSignInActions: (authResponse: AuthResponse) => any[]
      let resolvePostSignInActionsFailure: () => any[];

      resolvePostSignInActions = (data: AuthResponse) => [
        authRentalSuccess({
          payload: {
            ...data,
            isSecure: false,
            method: AuthMethods.RENTAL
          }
        }),
      ]
      resolvePostSignInActionsFailure = () => [
        notifyAuthRentalFailure(),
      ]
      return from(rentalSignInRequest(action.payload.captchaToken, action.payload.refNumber, action.payload.referenceId)).pipe(
        mergeMap((data: any) => {
          if (data.rentalValidation.eligibility.eligible) {
            return resolvePostSignInActions({
              ...data.rentalValidation,
              eligibility: data.rentalValidation.eligibility,
              method: action.payload.method
            })
          } else {
            return resolvePostSignInActionsFailure()
          }
        }),
        catchError((error) => {
          this.taggingService.setAutomatedEvent('invalid rental number', events.auth.PAGE_TYPE)

          handleAuthAnalytics(this.taggingService, {
            method: action.payload.method,
            isValid: false,
          })
          return this.errorReportingService.notifyErrorObservable(
            error,
            notifyAuthFailure
          )
        }),
      );
    }),
    catchError((error) => this.errorReportingService.notifyErrorObservable(error, notifyAuthFailure))
  ))

  handleRentalAuthSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(AUTH_RENTAL.SUCCESS),
    withLatestFrom(
      this.store$.pipe(select(selectAuthMethod)),
    ),
    switchMap((action: any) => of(action).pipe(
      mergeMap(([action, authMethod]) => {
        handleAuthAnalytics(this.taggingService, {
          method: authMethod as AuthMethods,
          isValid: action.payload.eligibility.eligible,
        })

        return [
          callStatusRequest({
            payload: {
              retry: false,
              timeout: FIRST_CALL_STATUS_TIMEOUT,
              eligible: action.payload.eligibility.eligible,
            },
          }),
        ]
      }),
      catchError((error) => this.errorReportingService.notifyErrorObservable(error, notifyRentalAuthFailure))
    )))
  )

  // VAS Effect
  handleVasAuth$: Observable<
    | ReturnType<typeof authVasSuccess>
    | ReturnType<typeof notifyAuthFailure>
    | ReturnType<typeof notifyVasAuthFailure>
  > = createEffect(() => this.actions$.pipe(
    ofType(AUTH_VAS.REQUEST),
    switchMap((action: PayloadedAction) => {
      const vasSignInRequest = this._authService.vasValidation.bind(this._authService)
      let resolvePostSignInActions: (authResponse: AuthResponse) => any[]
      let resolvePostSignInActionsFailure: () => any[];

      resolvePostSignInActions = (data: AuthResponse) => [
        authVasSuccess({
          payload: {
            ...data,
            isSecure: false,
            method: AuthMethods.VAS
          }
        }),
      ]
      resolvePostSignInActionsFailure = () => [
        notifyAuthVasFailure(),
      ]
      return from(vasSignInRequest(action.payload.captchaToken, action.payload.subscriber, action.payload.referenceId)).pipe(
        mergeMap((data: any) => {
          if (data.vasValidation.eligibility.eligible) {
            return resolvePostSignInActions({
              ...data.vasValidation,
              eligibility: data.vasValidation.eligibility,
              method: action.payload.method
            })
          } else {
            return resolvePostSignInActionsFailure()
          }
        }),
        catchError((error) => {
          this.taggingService.setAutomatedEvent('invalid vas number', events.auth.PAGE_TYPE)

          handleAuthAnalytics(this.taggingService, {
            method: action.payload.method,
            isValid: false,
          })
          return this.errorReportingService.notifyErrorObservable(
            error,
            notifyAuthFailure
          )
        }),
      );
    }),
    catchError((error) => this.errorReportingService.notifyErrorObservable(error, notifyAuthFailure))
  ))

  handleVasAuthSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(AUTH_VAS.SUCCESS),
    withLatestFrom(
      this.store$.pipe(select(selectAuthMethod)),
    ),
    switchMap((action: any) => of(action).pipe(
      mergeMap(([action, authMethod]) => {
        handleAuthAnalytics(this.taggingService, {
          method: authMethod as AuthMethods,
          isValid: action.payload.eligibility.eligible,
        })

        return [
          callStatusRequest({
            payload: {
              retry: false,
              timeout: FIRST_CALL_STATUS_TIMEOUT,
              eligible: action.payload.eligibility.eligible,
            },
          }),
        ]
      }),
      catchError((error) => this.errorReportingService.notifyErrorObservable(error, notifyVasAuthFailure))
    )))
  )


}

export const __TEST__ = {
  FIRST_CALL_STATUS_TIMEOUT,
}
