import { createSelector, Selector } from '@ngrx/store'
import { AAAStore } from '../../../store/root-reducer'
import { LoadingState } from './loading.reducer'

export const selectLoading: Selector<AAAStore, Object> = (
  store: AAAStore
): LoadingState => store.loading
export const selectIsLoading = (feature: string) =>
  createSelector(
    selectLoading,
    (loading: object): boolean => Boolean(loading) && Boolean(loading[feature])
  )
export const selectIsAnyRequestLoading = createSelector(
  selectLoading,
  (loadingState): boolean =>
    Boolean(loadingState && Object.values(loadingState).some(Boolean))
)
