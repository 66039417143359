import {
  Dialog,
  Step,
  StepStatus,
  StepTypes,
  VehicleSections,
} from './ui.types'
import {
  DIALOG_OPEN,
  DIALOG_CLOSE,
  PENDING_STEP,
  EDIT_STEP,
  ACTIVE_STEP,
  DONE_STEP,
  HIDE_SPLASHSCREEN,
  SET_SPLASHSCREEN_STEP,
  SHOW_SPLASHSCREEN,
  ANIMATE_SPLASHSCREEN_HIDE,
  SET_SHOW_PWA,
  SET_SHOW_MENU,
  TRANSITION,
  SET_USER_DEFAULT_COORDS,
  SET_SKIP_ISSUE,
  RESET_SKIP_ISSUE,
  DIALOG_RESET,
  ADD_TOWING_STEP,
  SET_PREPROD_ENV,
  SET_USER_DEFAULT_ZIPCODE,
  SET_SHOW_EXPANDED_DIALOG,
  SET_SHOW_BACK,
  SET_ISSUE_SELECTION_REVAMP,
  SET_SHOW_BOTTOM_NAVIGATION,
  SET_TOW_REDESIGN,
  SET_HIDE_NOTES_ON_NEED_TOW,
  SET_MAP_DEBUG,
  SET_SIMPLE_CAPTCHA,
  SET_SHOW_AUTH_OPTION_BUTTON,
  SET_SHOW_KEEP_ME_INFORMED,
  SET_CALL_STATUS_INTERVAL,
  SET_APPLICATION_TEXT_UPDATES,
  SET_NAME_ZIP_INSTRUCTIONS,
  SET_TELEMATICS,
  SET_QUERY_PARAMS_VEHICLE_DATA,
  SET_FIRST_TIME_ACCESS,
} from './ui.actions'
import { PACE_SETTER_OPTION_TEMPLATES } from '../issue/issue.utils'
import { PayloadedAction, IndexedCollection } from '../../shared/types'
import { CANCEL_EDITING_REQUEST } from 'src/app/shared/actions/shared.actions'
import { DEFAULT_LAT, DEFAULT_LNG } from '../location/location.constants'
import { GoogleAddress } from '../location/google-geocode/types'
import { Vehicle } from '../member/member.types'

export interface SplashscreenState {
  visible: boolean
  step: 0 | 1 | 2 | 3
  animating: boolean
}

export interface UIState {
  steps: IndexedCollection<Step>
  dialog: {
    [type: string]: Dialog
  }
  vehicleDetailsRedirect: boolean
  initialLatLng: GoogleAddress
  splashscreen: SplashscreenState
  showPwa: boolean
  showBack: boolean
  showExpandedDialog: boolean
  issueSelectionRevamp: boolean
  showBottomNavigation: boolean
  applicationTextUpdates: boolean
  isTowRedesign: boolean
  hideNotesOnNeedTow: boolean
  showAuthOptionButton: boolean
  showMenu: boolean
  showSimpleCaptcha: boolean
  showKeepMeInformed: boolean
  transitioning: boolean
  defaultUserCoords?: GoogleAddress
  skipIssue?: boolean
  preProdEnv: boolean
  defaultZipCode?: string
  mapDebug: boolean
  callStatusInterval: number
  nameZipInstructions: boolean
  telematics: string[]
  queryParamsVehicle: Vehicle
  isFirstTimeAccess: boolean
}

export const initialSteps = {
  1: {
    id: 1,
    url: StepTypes.ISSUE,
    status: StepStatus.EDITING,
    title: 'Issue',
  },
  2: {
    id: 2,
    url: StepTypes.BREAKDOWN_LOCATION,
    status: StepStatus.PENDING,
    title: 'Location',
  },
  3: {
    id: 3,
    url: StepTypes.VEHICLE,
    status: StepStatus.PENDING,
    title: 'Vehicle',
    sections: [
      VehicleSections.MAKES,
      VehicleSections.MODELS,
      VehicleSections.DETAILS,
    ],
  },
  5: {
    id: 5,
    url: StepTypes.SUBMIT,
    status: StepStatus.PENDING,
    title: 'Submit',
  },
}

export const towingStep = {
  4: {
    id: 4,
    url: StepTypes.TOWING,
    status: StepStatus.PENDING,
    title: 'Tow To',
  },
}

export const initialState: UIState = {
  steps: initialSteps,
  dialog: {},
  vehicleDetailsRedirect: false,
  initialLatLng: {
    lat: DEFAULT_LAT,
    lng: DEFAULT_LNG,
    address: {},
  },
  splashscreen: {
    visible: false,
    step: 1,
    animating: false,
  },
  showPwa: true,
  showMenu: true,
  showBack: false,
  showExpandedDialog: false,
  issueSelectionRevamp: false,
  showBottomNavigation: false,
  applicationTextUpdates: false,
  isTowRedesign: false,
  hideNotesOnNeedTow: false,
  showAuthOptionButton: false,
  showSimpleCaptcha: false,
  showKeepMeInformed: false,
  transitioning: false,
  skipIssue: false,
  preProdEnv: false,
  defaultZipCode: null,
  mapDebug: false,
  defaultUserCoords: null,
  callStatusInterval: 60000,
  nameZipInstructions: false,
  telematics: [],
  queryParamsVehicle: null,
  isFirstTimeAccess: false,
}

const stepChangeMap = {
  [PENDING_STEP]: StepStatus.PENDING,
  [EDIT_STEP]: StepStatus.EDITING,
  [ACTIVE_STEP]: StepStatus.ACTIVE,
  [DONE_STEP]: StepStatus.DONE,
}

export function reducer(
  state: UIState = initialState,
  action: PayloadedAction
) {
  switch (action.type) {
    case PENDING_STEP:
    case EDIT_STEP:
    case ACTIVE_STEP:
    case DONE_STEP:
      return {
        ...state,
        steps: {
          ...state.steps,
          [action.payload.id]: {
            ...action.payload,
            status: stepChangeMap[action.type],
          },
        },
      }
    case DIALOG_OPEN.MESSAGE:
    case DIALOG_OPEN.ERROR:
    case DIALOG_OPEN.PROMPT:
      return {
        ...state,
        dialog: {
          ...state.dialog,
          [action.payload.type]: {
            ...action.payload,
            displayedAt: Date.now(),
          },
        },
      }
    case DIALOG_CLOSE: {
      delete state.dialog[action.payload.type]
      return {
        ...state,
        dialog: {
          ...state.dialog,
        },
      }
    }
    case DIALOG_RESET: {
      return {
        ...state,
        dialog: {
          ...initialState.dialog,
        },
      }
    }
    case CANCEL_EDITING_REQUEST:
      return {
        ...state,
        steps: initialState.steps,
      }
    case SHOW_SPLASHSCREEN:
    case HIDE_SPLASHSCREEN:
      if (state.splashscreen.step === 0) {
        return state
      }

      return {
        ...state,
        splashscreen: {
          visible: action.type === SHOW_SPLASHSCREEN,
          step: action.type === SHOW_SPLASHSCREEN ? 1 : state.splashscreen.step,
          animating: false,
        },
      }
    case ANIMATE_SPLASHSCREEN_HIDE:
      if (state.splashscreen.step === 0) {
        return state
      }

      return {
        ...state,
        splashscreen: {
          ...state.splashscreen,
          animating: true,
        },
      }

    case SET_SPLASHSCREEN_STEP:
      if (state.splashscreen.step === 0) {
        return state
      }

      return {
        ...state,
        splashscreen: {
          ...state.splashscreen,
          step: action.payload,
        },
      }
    case SET_SHOW_PWA:
      return {
        ...state,
        showPwa: action.payload,
      }
    case TRANSITION.REQUEST:
    case TRANSITION.SUCCESS:
      return {
        ...state,
        transitioning: action.payload,
      }
    case SET_SHOW_MENU:
      return {
        ...state,
        showMenu: action.payload,
      }
    case SET_SHOW_KEEP_ME_INFORMED:
      return {
        ...state,
        showKeepMeInformed: action.payload,
      }
    case SET_USER_DEFAULT_COORDS:
      return {
        ...state,
        defaultUserCoords: { ...action.payload, address: {} },
      }
    case SET_SKIP_ISSUE:
      return {
        ...state,
        skipIssue: action.payload,
      }
    case RESET_SKIP_ISSUE:
      return {
        ...state,
        skipIssue: false,
      }
    case SET_MAP_DEBUG:
      return {
        ...state,
        mapDebug: action.payload,
      }

    case ADD_TOWING_STEP:
      {
        // TODO refactor required. Move logic to an effect.

        // if towing is required reset steps and add the addtional step
        // if not towing step will be removed if is already set
        if (action.payload === true) {
          return {
            ...state,
            steps: {
              ...state.steps,
              ...towingStep,
              5: initialSteps[5],
            },
          }
        } else if (state.steps[4]) {
          delete state.steps[4]

          return {
            ...state,
            steps: {
              ...state.steps,
            },
          }
        }
      }
      return state

    case SET_PREPROD_ENV:
      return {
        ...state,
        preProdEnv: true,
      }
    case SET_USER_DEFAULT_ZIPCODE:
      return {
        ...state,
        defaultZipCode: action.payload,
      }
    case SET_SHOW_BACK:
      return {
        ...state,
        showBack: action.payload,
      }
    case SET_SHOW_EXPANDED_DIALOG:
      return {
        ...state,
        showExpandedDialog: action.payload,
      }
    case SET_ISSUE_SELECTION_REVAMP:
      return {
        ...state,
        issueSelectionRevamp: action.payload,
      }

    case SET_SHOW_BOTTOM_NAVIGATION:
      return {
        ...state,
        showBottomNavigation: action.payload,
      }
    case SET_APPLICATION_TEXT_UPDATES:
      return {
        ...state,
        applicationTextUpdates: action.payload
      }

    case SET_TOW_REDESIGN:
      return {
        ...state,
        isTowRedesign: action.payload,
      }
    case SET_NAME_ZIP_INSTRUCTIONS:
      return {
        ...state,
        nameZipInstructions: action.payload,
      }

    case SET_HIDE_NOTES_ON_NEED_TOW: {
      const paceSetter = { ...PACE_SETTER_OPTION_TEMPLATES }
      paceSetter['NEED A TOW'].detailGroups = []

      return {
        ...state,
        ...paceSetter,
        hideNotesOnNeedTow: action.payload,
      }
    }

    case SET_SHOW_AUTH_OPTION_BUTTON:
      return {
        ...state,
        showAuthOptionButton: action.payload,
      }
    case SET_SIMPLE_CAPTCHA:
      return {
        ...state,
        showSimpleCaptcha: action.payload,
      }
    case SET_CALL_STATUS_INTERVAL:
      return {
        ...state,
        callStatusInterval: action.payload,
      }
    case SET_TELEMATICS:
      return {
        ...state,
        telematics: action.payload ? action.payload.split(',') : [],
      }
    case SET_QUERY_PARAMS_VEHICLE_DATA:
      return {
        ...state,
        queryParamsVehicle: action.payload,
      }
    case SET_FIRST_TIME_ACCESS:
      return {
        ...state,
        isFirstTimeAccess: action.payload,
      }
    default:
      return state
  }
}
