<svg
  xmlns="http://www.w3.org/2000/svg"
  width="14"
  height="14"
  viewBox="0 0 4.233 4.233"
>
  <g fill="none" stroke="#d81e05" stroke-width=".32">
    <path d="M.183.183L4.05 4.05M4.05.183L.183 4.05" />
  </g>
</svg>
