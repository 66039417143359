import { Pipe, PipeTransform } from '@angular/core'
import { VehicleData } from 'src/app/modules/member/member.types'
import { TitleCasePipe } from '@angular/common'

export function formatVehicle(
  vehicle: VehicleData,
  ...additionalMembers: string[]
) {
  if (!vehicle) {
    return ''
  }

  const casePipe = new TitleCasePipe()
  const additional =
    additionalMembers &&
    additionalMembers
      .map((key) => ` (${casePipe.transform(vehicle[key])})`)
      .join('')

  const vehicleModel = vehicle?.driveType
  ? `${vehicle.model} (${vehicle.driveType})`
  : vehicle.model

  return `${vehicle.year} ${vehicle.make} ${vehicleModel} ${additional}`
}

@Pipe({ name: 'vehicle' })
export class VehiclePipe implements PipeTransform {
  transform(vehicle: VehicleData, ...args: any[]) {
    return formatVehicle(vehicle, ...args)
  }
}
