import { array, number, object, string } from 'yup'
import {
  Address,
  AddressState,
  Country,
  OperatingDay,
  Phone,
} from '../../../shared/types'
import { AARDetail, AARVehicle } from './aar.types'

const CountrySchema = object<Country>().shape({
  value: string(),
  code: string(),
})

const AddressStateSchema = object<AddressState>().shape({
  code: string(),
})

const AddressSchema = object<Address>().shape({
  addressLine: string(),
  cityName: string(),
  cityCode: string(),
  stateProv: AddressStateSchema,
  postalCode: string(),
  countryName: CountrySchema,
  type: string(),
})

const PhoneSchema = object<Phone>().shape({
  value: string(),
  type: string(),
})

const OperatingDaySchema = object<OperatingDay>().shape({
  day: string(),
  open: string(),
  close: string(),
})

const ServiceVehicleSchema = object<AARVehicle>().shape({
  type: string(),
  value: string(),
})

const ShopServiceSchema = object<AARVehicle>().shape({
  type: string(),
  value: string(),
})

const AARDataSchema = object<AARDetail>().shape({
  id: number(),
  type: string().required(),
  name: string().required(),
  serviceProviderCode: string(),
  latitude: number().required(),
  longitude: number().required(),
  distanceTo: number(),
  address: AddressSchema.required(),
  phone: PhoneSchema,
  emailAddress: string(),
  webAddress: string(),
  operatingDays: array().of(OperatingDaySchema),
  serviceOffers: array().of(string()),
  vehicles: array().of(ServiceVehicleSchema),
  services: array().of(ShopServiceSchema),
})

export const AARSchema = object({
  aars: array().of(AARDataSchema),
})

export const AARDetailSchema = object<AARDetail>().shape({
  id: number(),
  type: string().required(),
  name: string().required(),
  serviceProviderCode: string(),
  latitude: number().required(),
  longitude: number().required(),
  distanceTo: number(),
  address: AddressSchema.required(),
  phone: PhoneSchema,
  emailAddress: string(),
  webAddress: string(),
  operatingDays: array().of(OperatingDaySchema),
  serviceOffers: array().of(string()),
  vehicles: array().of(ServiceVehicleSchema),
  services: array().of(ShopServiceSchema),
  discount: object({
    offerDescription: string(),
    offerRestriction: string(),
  }),
})
