import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { MemoizedProjection, select, Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { AAAStore } from 'src/app/store/root-reducer'
import {
  selectRouterSection,
  selectRouterStep,
} from 'src/app/store/router.selectors'
import { paceSetterNext } from '../issue/issue.actions'
import events from '../tagging/events'
import { TaggingService } from '../tagging/tagging.service'
import {
  createGetPreviousStepByUrl,
  createGetStepByUrl,
} from '../ui/ui.selectors'
import { Section, Step, StepTypes, VehicleSections } from '../ui/ui.types'
import { resetEditStepLocation } from './wizard.actions'

@Injectable({
  providedIn: 'root',
})
export class WizardService {
  currentStepUrl: Step['url']
  currentSectionUrl: Section

  getPreviousStepByUrl: MemoizedProjection
  getStepByUrl: MemoizedProjection

  currentStepUrl$: Observable<Step['url']> = this.store$.pipe(
    select(selectRouterStep)
  )

  currentSectionUrl$: Observable<Section> = this.store$.pipe(
    select(selectRouterSection)
  )

  getPreviousStepByUrl$: Observable<MemoizedProjection> = this.store$.pipe(
    select(createGetPreviousStepByUrl)
  )

  getStepByUrl$: Observable<MemoizedProjection> = this.store$.pipe(
    select(createGetStepByUrl)
  )

  constructor(
    private router: Router,
    private store$: Store<AAAStore>,
    private taggingService: TaggingService
  ) {
    this.currentStepUrl$.subscribe(
      (currentStepUrl) => (this.currentStepUrl = currentStepUrl)
    )
    this.currentSectionUrl$.subscribe(
      (currentSectionUrl) => (this.currentSectionUrl = currentSectionUrl)
    )

    this.getPreviousStepByUrl$.subscribe(
      (getPreviousStepByUrl) =>
        (this.getPreviousStepByUrl = getPreviousStepByUrl)
    )
    this.getStepByUrl$.subscribe(
      (getStepByUrl) => (this.getStepByUrl = getStepByUrl)
    )
  }

  backToEditUrl(url: string, needsTow: boolean = false) {
    this.store$.dispatch(resetEditStepLocation())

    // go to next step instead of continue to tow-location
    // thish handle an edge case when the user change the issue typoe
    // from towing to no towing
    if (url.indexOf('tow-location') !== -1 && !needsTow) {
      this.store$.dispatch(paceSetterNext())
      return true
    }

    this.router.navigateByUrl(url)

    return false
  }

  goToPreviousStep() {
    const currentStep = this.getStepByUrl.memoized(this.currentStepUrl)
    const currentSectionIndex = currentStep?.sections?.indexOf(
      this.currentSectionUrl
    )
    const previousStep = this.getPreviousStepByUrl.memoized(this.currentStepUrl)

    if (currentSectionIndex === undefined && !previousStep) {
      const previousStep = currentStep
      this.tagPreviousStepInteraction(previousStep.url)

        return this.router.navigate(['steps'], {
          queryParams: { step: previousStep.url },
        })
    }

    switch (true) {
      case currentSectionIndex > 0:
        const previousSection = currentStep.sections[currentSectionIndex - 1]
        this.tagPreviousStepInteraction(this.currentStepUrl, previousSection)

        return this.router.navigate(['steps'], {
          queryParams: {
            step: this.currentStepUrl,
            section: previousSection,
          },
        })

      case currentSectionIndex === 0:
        this.tagPreviousStepInteraction(this.currentStepUrl)

        return this.router.navigate(['steps'], {
          queryParams: {
            step: this.currentStepUrl,
          },
        })

      default:
        this.tagPreviousStepInteraction(previousStep.url)

        return this.router.navigate(['steps'], {
          queryParams: { step: previousStep.url },
        })
    }
  }

  tagPreviousStepInteraction = (step: Step['url'], section?: Section) => {
    switch (step) {
      case StepTypes.ISSUE:
        this.taggingService.setClickEvent(
          events.shared.BACK_FROM_ISSUE_PAGE,
          events.shared.BACK_BUTTON_PAGE_TYPE
        )
        break

      case StepTypes.BREAKDOWN_LOCATION:
        this.taggingService.setClickEvent(
          events.shared.BACK_FROM_LOCATION_PAGE,
          events.shared.BACK_BUTTON_PAGE_TYPE
        )
        break

      case StepTypes.VEHICLE:
        this.taggingService.setClickEvent(
          events.shared.BACK_FROM_VEHICLE_PAGE,
          events.shared.BACK_BUTTON_PAGE_TYPE
        )
        break

      case StepTypes.TOWING:
        this.taggingService.setClickEvent(
          events.shared.BACK_FROM_TOWTO_PAGE,
          events.shared.BACK_BUTTON_PAGE_TYPE
        )
        break
    }

    switch (section) {
      case VehicleSections.MAKES:
        this.taggingService.setClickEvent(
          events.shared.BACK_FROM_VEHICLE_MAKES_PAGE,
          events.shared.BACK_BUTTON_PAGE_TYPE
        )
        break
      case VehicleSections.MODELS:
        this.taggingService.setClickEvent(
          events.shared.BACK_FROM_VEHICLE_MODELS_PAGE,
          events.shared.BACK_BUTTON_PAGE_TYPE
        )
        break
      case VehicleSections.DETAILS:
        this.taggingService.setClickEvent(
          events.shared.BACK_FROM_VEHICLE_DETAILS_PAGE,
          events.shared.BACK_BUTTON_PAGE_TYPE
        )
        break
    }
  }
}
