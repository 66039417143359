export const environment = {
  clientId: 'drr-web.prod',
  additionalCookieDomain: false,
  configTools: false,
  description: false,
  displayVersion: true,
  optimize: true,
  log: true,
  serviceWorker: true,
  baseURL: 'https://gatewayers.national.aaa.com',
  openAARsURL: 'https://tdr.aaa.com/tdrl/search.jsp',
  errorReportingID: 'APPLICATION-F50BE17DAD1656FB',
  errorReportingSource: 'DRR Web',
  errorReportingToken: 'uEsmJJ0YTnGChqK9cmyKO',
  errorReportingURL: 'https://gzz47282.live.dynatrace.com',
  ipInterrogateURL: 'https://services.aaa.com/RESTWS/aaa/services/geo/locate',
  aaaQuoteServicesUrl: 'https://services.aaa.com/RESTWS/aaa/services',
  aaaConfigurationServicesUrl:
    'https://services.aaa.com/RESTWS/aaa/services/configuration',
  rapConfigurationBaseUrl: 'https://www.aaa.com/AAA/Common/drr/rap/config',
  association: 'AAA',
  rapGoogleMapsApiKey: 'AIzaSyDaog2M_ukpxPjckXDZg84yC_LaBr2YxYE',
  googleMapsApiKey: 'AIzaSyBldHa1XtxzEh1-mIeaFZc8ZXleaCzXOBQ',
  googleCaptchaKey: '6LeiCZEUAAAAANRcZW9zAA5whvbRx47UQO9fYu0e',
  unsecureClub: 999,
}
