export interface PaceSetterCode {
  name: string
  paceSetterCode?: string
  requiresTowing?: boolean
  obs?: string
  comment?: string
  alternateLabel?: string
  tagLabel?: string
  overrideType?: string
}

export interface PaceSetterList {
  members: PaceSetterCode[]
}

export interface PaceSetterGroup {
  lists: PaceSetterList[]
}

export interface PaceSetterSituation {
  name: string
  icon: string
  paceSetterCodes?: Array<PaceSetterCode>
}

export interface PaceSetterDetails {
  name: string
  defaultPaceSetterCode?: PaceSetterCode
  /* Details are in groups, which are displayed as pages;
   * Each page has one or more sets of options, and there are one or more options in each list.
   * So this array is indexed by group -> list index -> option index
   * Example: Flat Tire:
   * Page 1:
   *   tires:
   *     Front Left
   *     Front Right
   *     Back Left
   *     Back Right
   * Page 2:
   *   Anything else?
   *     I need a spare
   *     My tires need air
   *     etc.
   */
  selectedGroups: Array<PaceSetterGroup>
  notes?: string
}

/* Pace setter categories and other information */

export enum PACE_SETTER_SITUATION_TYPES {
  ACCIDENT = 'ACCIDENT',
  BATTERY_ISSUE = 'BATTERY ISSUE',
  BATTERY_ISSUE_CAR_WONT_START = `CAR WON'T START \n BATTERY ISSUE `,
  CAR_WONT_START = `CAR WON'T START`,
  FLAT_TIRE = 'FLAT TIRE',
  LOCKED_OUT = 'LOCKED OUT',
  NEED_A_TOW = 'NEED A TOW',
  OUT_OF_FUEL = 'FUEL/EV CHARGE',
  STUCK = 'STUCK',
}

export enum PACE_SETTER_SITUATION_CODES {
  ACCIDENT = 'T906',
  STUCK = 'T931',
  BATTERY_ISSUE = 'L301',
  BATTERY_ISSUE_CAR_WONT_START = 'L301',
  TIRE_SPARE = 'L101',
  TIRE_NO_SPARE = 'T180',
  LOCK_KEY_INSIDE = 'L701',
  LOCK_KEY_LOST = 'L890',
  LOCK_KEY_NO_TURN = 'L801',
  LOCK_KEY_NO_WORK = 'L890',
  LOCK_UNSURE = 'L701',
  FUEL_GAS = 'L402',
  FUEL_DIESEL = 'L403',
  FUEL_ELECTRIC = 'T483',
  NEED_TOW = 'T480',
  WONT_START = 'L301',
  WONT_START_CLICK = 'L401',
  WONT_START_DIED = 'T680',
  WONT_START_BATT = 'L301',
  FLAT_TIRE_SPARE_TROUBLE_CODE = 'T1',
  FLAT_TIRE_NO_SPARE_TROUBLE_CODE = 'T2',
  BATTERY_TROUBLE_CODE = 'T3',
  CAR_WONT_START_TROUBLE_CODE = 'T4',
  FUEL_TROUBLE_CODE = 'T5',
  TOW_TROUBLE_CODE = 'T6',
  LOCKED_OUT_TROUBLE_CODE = 'T7',
  LOCKED_OUT_KEY_DELIVERY_TROUBLE_CODE = '7A',
  LOCKED_OUT_LOCKSMITH_TROUBLE_CODE = '7B',
  STUCK_TROUBLE_CODE = 'T8',
}

export enum IssueTypes {
  MECHANICAL_PROBLEM = '',
  VEHICLE_STUCK = 'STUCK',
  BATTERY = 'BATTERY_ISSUE',
  FLAT_TIRE = 'FLAT TIRE',
  LOCKOUT_LOCKSMITH = 'LOCKED_OUT',
  OUT_OF_FUEL = 'OUT_OF_FUEL',
}

export enum ENTITLEMENT_TYPES {
  TOW = 'TOW',
  BATTERY = 'BATTERY',
  TIRE = 'TIRE',
  GAS = 'GAS',
  LOCK = 'LOCK',
  WINCH = 'WINCH',
  TRIP = 'TRIP',
  TAXI = 'TAXI',
}

export const PACE_SETTER_SITUATION_CODE_ENTITLEMENT_TYPE = {
  [PACE_SETTER_SITUATION_CODES.ACCIDENT]: ENTITLEMENT_TYPES.TOW,
  [PACE_SETTER_SITUATION_CODES.STUCK]: ENTITLEMENT_TYPES.WINCH,
  [PACE_SETTER_SITUATION_CODES.BATTERY_ISSUE]: ENTITLEMENT_TYPES.BATTERY,
  [PACE_SETTER_SITUATION_CODES.TIRE_SPARE]: ENTITLEMENT_TYPES.TIRE,
  [PACE_SETTER_SITUATION_CODES.TIRE_NO_SPARE]: ENTITLEMENT_TYPES.TIRE,
  [PACE_SETTER_SITUATION_CODES.LOCK_KEY_INSIDE]: ENTITLEMENT_TYPES.LOCK,
  [PACE_SETTER_SITUATION_CODES.LOCK_KEY_LOST]: ENTITLEMENT_TYPES.LOCK,
  [PACE_SETTER_SITUATION_CODES.LOCK_KEY_NO_TURN]: ENTITLEMENT_TYPES.LOCK,
  [PACE_SETTER_SITUATION_CODES.LOCK_KEY_NO_WORK]: ENTITLEMENT_TYPES.LOCK,
  [PACE_SETTER_SITUATION_CODES.LOCK_UNSURE]: ENTITLEMENT_TYPES.LOCK,
  [PACE_SETTER_SITUATION_CODES.FUEL_GAS]: ENTITLEMENT_TYPES.GAS,
  [PACE_SETTER_SITUATION_CODES.FUEL_DIESEL]: ENTITLEMENT_TYPES.GAS,
  [PACE_SETTER_SITUATION_CODES.FUEL_ELECTRIC]: ENTITLEMENT_TYPES.GAS,
  [PACE_SETTER_SITUATION_CODES.NEED_TOW]: ENTITLEMENT_TYPES.TOW,
  [PACE_SETTER_SITUATION_CODES.WONT_START]: ENTITLEMENT_TYPES.BATTERY,
  [PACE_SETTER_SITUATION_CODES.WONT_START_CLICK]: ENTITLEMENT_TYPES.TOW,
  [PACE_SETTER_SITUATION_CODES.WONT_START_DIED]: ENTITLEMENT_TYPES.TOW,
  [PACE_SETTER_SITUATION_CODES.WONT_START_BATT]: ENTITLEMENT_TYPES.BATTERY,
}

export interface PaceSetterOptions {
  name: string
  defaultCode?: PaceSetterCode
  detailGroups: PaceSetterDetailGroup[]
  allowNotes?: boolean
}

export interface PaceSetterDetailGroup {
  heading?: string
  subHeading?: string
  isOptional?: boolean
  lists?: PaceSetterDetailCategory[]
  options?: PaceSetterDetailCategory[]
  allowNotes?: boolean
  noteInstructions?: string
}

export interface PaceSetterDetailCategory {
  name?: string
  heading?: string
  multiSelect?: boolean
  mandatory?: boolean
  values: PaceSetterCode[] | FlatTireOptions[] | DamagedPartOptions[]
}

export interface FlatTireOptions {
  active?: boolean
  section: string
  side: string
  label: string
}

export interface DamagedPartOptions {
  active?: boolean
  section: string
  side: string
  label: string
  comment?: string
}
