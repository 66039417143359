import { ofType, Actions, createEffect } from '@ngrx/effects'
import { Injectable } from '@angular/core'
import {
  map,
  filter,
  catchError,
  switchMap,
  tap
} from 'rxjs/operators';

import {
  RouterNavigatedAction,
  ROUTER_NAVIGATION,
} from '@ngrx/router-store'
import { addTowingStep, DIALOG_OPEN, openPromptDialog } from './ui.actions';
import { PromptDialogTypes, StepTypes, SubmitSections } from './ui.types';
import { Title } from '@angular/platform-browser'
import { buildTitle } from 'src/app/shared/utils/title'
import { ErrorReportingService } from '../../shared/services/error-reporting.service'
import { combineLatest, from, of } from 'rxjs'
import { selectNeedsTow } from '../issue/issue.selectors'
import { Store } from '@ngrx/store'
import { AAAStore } from 'src/app/store/root-reducer'
import { getParamsFromEvents } from './ui.utils'
import { TaggingService } from '../tagging/tagging.service';
import events from '../tagging/events';

@Injectable()
export class UIEffects {
  updateTitle$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<RouterNavigatedAction>(ROUTER_NAVIGATION),
        switchMap((action) =>
          of(action).pipe(
            filter((payloadedAction) => !!payloadedAction.payload.event),
            map((_) => {
              const nextStepUrl = getParamsFromEvents(action, 'step')
              const nextSectionUrl = getParamsFromEvents(action, 'section')
              const nextIndexUrl = getParamsFromEvents(action, 'index')

              switch (nextStepUrl) {
                case StepTypes.ISSUE:
                  if (!nextIndexUrl) {
                    this.titleService.setTitle(buildTitle('What Happened?'))
                  }
                  break
                case StepTypes.BREAKDOWN_LOCATION:
                  this.titleService.setTitle(
                    buildTitle('Set Breakdown Location')
                  )
                  break
                case StepTypes.VEHICLE:
                  this.titleService.setTitle(buildTitle('Select Vehicle'))
                  break
                case StepTypes.TOWING:
                  this.titleService.setTitle(buildTitle('Tow Location'))
                  break
                case StepTypes.SUBMIT:
                  switch (nextSectionUrl) {
                    case SubmitSections.PASSENGERS:
                      this.titleService.setTitle(buildTitle('Passengers'))
                      break
                    case SubmitSections.SUMMARY:
                      this.titleService.setTitle(
                        buildTitle('Review and Submit')
                      )
                      break
                  }
                  break
                default:
                  this.titleService.setTitle(buildTitle('Sign In'))
                  break
              }
            }),
            catchError((error) =>
              from(this.errorReportingService.notifyError(error))
            )
          )
        )
      ),
    { dispatch: false }
  )

  checkNeedTowing$ = createEffect(() =>
    combineLatest([this.store$.select(selectNeedsTow)]).pipe(
      map(([needsTow]: [boolean]) => addTowingStep({ payload: needsTow }))
    )
  )

  openBatteryQuotesPromptDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ReturnType<typeof openPromptDialog>>(DIALOG_OPEN.PROMPT),
        switchMap((action) =>
          of(action).pipe(
            filter((_) => action.payload.type === PromptDialogTypes.BATTERY_QUOTES),
            tap((_) => this.tagging.setClickEvent(
              events.dashboard.BATTERY_QUOTES_SHOW_CLICK,
              events.dashboard.SERVICE_TRACKING_PAGE_TYPE
            )),
          )
        )
      ),
    { dispatch: false }
  )

  constructor(
    private store$: Store<AAAStore>,
    private actions$: Actions,
    protected titleService: Title,
    private errorReportingService: ErrorReportingService,
    private tagging: TaggingService,
  ) {}
}
