<div class="situation-summary">
  <!-- Issue -->
  <ng-container>
    <div
      class="situation-piece"
      [ngClass]="{ disabled: !(activeSituationDetails$ | async) }"
    >
      <div class="issue-icon" *ngIf="(activeSituationDetails$ | async)?.icon">
        <app-img
          [src]="(activeSituationDetails$ | async)?.icon"
          classNames="icon"
          [alt]="(activeSituationDetails$ | async)?.name || 'What is the issue?'"
        ></app-img>
      </div>
      <div class="piece-title small-text bold uppercase">
        Issue:
      </div>
      <div class="piece-heading small-text issue-label">
        {{ (activeSituationDetails$ | async)?.name || 'What is the issue?' }}
      </div>

      <ng-container
        *ngIf="!(currentEditing$ | async) && (activeSituationDetails$ | async)"
      >
        <div class="piece-edit">
          <aria-link-button
            [tabIndex]="901"
            ariaLabel="Change Issue"
            (clickLink)="editService$.doEdit('issue', '')">
            <app-img
              src="assets/images/icons/edit small.svg"
              classNames="edit-icon"
              alt="Change issue">
            </app-img>
          </aria-link-button>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <!-- Location -->
  <ng-container>
    <div
      class="situation-piece"
      [ngClass]="{ disabled: !(selectBreakdownLocationAddress$ | async) }"
    >
      <div class="issue-icon">
        <app-img
          src="assets/images/icons/location-pin.svg"
          classNames="icon"
          alt="breakdown location"
        ></app-img>
      </div>
      <div class="piece-title small-text bold uppercase">
        Location:
      </div>
      <div class="piece-heading location-label">
        <p class="small-text" *ngIf="selectDisplayLandmark$ | async">
          {{ selectBreakdownLocationLandmark$ | async }}
        </p>
        <p class="small-text">
          {{
            (selectBreakdownLocationAddress$ | async) ||
              'Where is your vehicle?'
          }}
        </p>
        <p class="small-text exit-info" *ngIf="highWayExit$ | async">
          {{ highWayExit$ | async }}
        </p>
      </div>

      <ng-container
        *ngIf="
          !(currentEditing$ | async) &&
          (selectBreakdownLocationAddress$ | async)
        "
      >
        <div class="piece-edit">
          <aria-link-button
          [tabIndex]="902"
          ariaLabel="Edit breakdown location"
          (clickLink)="editService$.doEdit('location')">
            <app-img
              src="assets/images/icons/edit small.svg"
              classNames="edit-icon"
              alt="Edit breakdown location">
            </app-img>
          </aria-link-button>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <!-- Vehicle -->
  <ng-container>
    <div
      class="situation-piece"
      [ngClass]="{
        disabled: !(workingVehicle$ | async).year && !(activeVehicle$ | async)
      }"
    >
      <div class="issue-icon">
        <app-img
          src="assets/images/icons/car-small.svg"
          classNames="icon"
          alt="Vehicle info"
        ></app-img>
      </div>
      <div class="piece-title small-text bold uppercase">
        Vehicle:
      </div>
      <div class="flex-to-col" *ngIf="!(activeVehicle$ | async)">
        <div
          *ngIf="(workingVehicle$ | async).year"
          class="rounded-icon"
          (click)="
            editService$.editVehicleSection(true, _workingVehicle, 'years')
          "
        >
          {{ (workingVehicle$ | async).year }}
          <app-img
            src="assets/images/icons/edit small white.svg"
            classNames="edit-icon"
            alt="Edit vehicle year"
            [tabIndex]="903"
          ></app-img>
        </div>
        <div
          *ngIf="(workingVehicle$ | async).make"
          class="rounded-icon"
          (click)="
            editService$.editVehicleSection(true, _workingVehicle, 'makes')
          "
        >
          {{ (workingVehicle$ | async).make }}
          <app-img
            src="assets/images/icons/edit small white.svg"
            classNames="edit-icon"
            alt="Edit vehicle make"
            [tabIndex]="904"
          ></app-img>
        </div>
        <div
          *ngIf="(workingVehicle$ | async).model"
          class="rounded-icon"
          (click)="
            editService$.editVehicleSection(true, _workingVehicle, 'models')
          "
        >
          {{ (vehicleModelDescription$ | async) }}
          <app-img
            src="assets/images/icons/edit small white.svg"
            classNames="edit-icon"
            alt="Edit vehicle model"
            [tabIndex]="905"
          ></app-img>
        </div>
      </div>

      <div
        class="piece-heading small-text vehicle-label"
        *ngIf="!(workingVehicle$ | async).year || (activeVehicle$ | async)"
      >
        {{ (activeVehicle$ | async) || 'Which vehicle?' }}
      </div>

      <ng-container
        *ngIf="!(currentEditing$ | async) && (activeVehicle$ | async)"
      >
        <div class="piece-edit">
          <aria-link-button
            (clickLink)="editService$.doEdit('vehicle')"
            ariaLabel="Edit vehicle"
            [tabIndex]="906">
            <app-img
              src="assets/images/icons/edit small.svg"
              classNames="edit-icon"
              alt="Edit vehicle">
            </app-img>
          </aria-link-button>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <!-- Tow to -->
  <ng-container *ngIf="needsTow$ | async">
    <div
      class="situation-piece"
      [ngClass]="{ disabled: !(towLocation$ | async)?.address }"
    >
      <div class="issue-icon">
        <app-img
          src="assets/images/icons/tow-small.svg"
          classNames="icon"
          alt="Towing location"
        ></app-img>
      </div>
      <div class="piece-title small-text bold uppercase">
        Tow to:
      </div>
      <div class="piece-heading small-text tow-to-label">
        {{ (towLocation$ | async)?.address || 'Location' }}
      </div>
      <app-img
        *ngIf="(towLocation$ | async).isAar"
        classNames="aaa-aproved"
        src="assets/images/AAA Approved.svg"
        alt="AAA Approved"
      ></app-img>

      <ng-container
        *ngIf="!(currentEditing$ | async) && (towLocation$ | async)?.address"
      >
        <div class="piece-edit">
          <aria-link-button
            (clickLink)="editService$.doEdit('tow-location')"
            ariaLabel="Edit tow location"
            [tabIndex]="907">
            <app-img
              src="assets/images/icons/edit small.svg"
              classNames="edit-icon"
              alt="Edit tow location">
            </app-img>
          </aria-link-button>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>
