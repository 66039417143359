import { createSelector } from '@ngrx/store'
import { AAAStore } from 'src/app/store/root-reducer'
import { GenericCoordinates } from '../location/location.types'
import { RouteDistance, RouteDistancesState } from './route-distance.reducer'

export const selectRoutesDistanceState = (store: AAAStore): RouteDistancesState => store.routesDistance

export const selectRoutesDistance = createSelector(
  selectRoutesDistanceState,
  (state: RouteDistancesState): RouteDistance[] => state.routesDistance
)

export const getDistance = (routesDistance: RouteDistance[], origin: GenericCoordinates, destination: GenericCoordinates) => {
  const routeDistance = routesDistance.filter(routeDistance => filterRouteDistance(routeDistance, origin, destination))
  if (routeDistance.length) {
    return routeDistance[0].distanceInMiles
  }
  return null
}

function filterRouteDistance(routeDistance: RouteDistance, breakdown: GenericCoordinates, tow: GenericCoordinates): boolean {
  return routeDistance.origin.latitude === breakdown.latitude
    && routeDistance.origin.longitude === breakdown.longitude
    && routeDistance.destination.latitude === tow.latitude
    && routeDistance.destination.longitude === tow.longitude
}
