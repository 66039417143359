import { createAction, props } from '@ngrx/store'
import {
  AAALocation,
  GenericCoordinates,
  GoogleLocation,
  HighwayExits,
  LOCATION_STEPS,
  LocationWithMarker,
} from 'src/app/modules/location/location.types'
import { MemberInfo } from '../member/member.types'
import { createAsyncConstants } from 'src/app/shared/utils'
import { GeoLocationOptions, GoogleCoordinates } from './google-geocode/types'

export const ENSURE_LOCATION_SERVICES = 'ENSURE_LOCATION_SERVICES'

export const SET_LOCATION_SERVICES_AVAILABLE = 'SET_LOCATION_SERVICES_AVAILABLE'
export const SET_BREAKDOWN_LOCATION = createAsyncConstants(
  'SET_BREAKDOWN_LOCATION'
)
export const SET_HIGHWAY_EXITS = createAsyncConstants('SET_HIGHWAY_EXITS')
export const SET_LOCATION_CLUB = createAsyncConstants('SET_LOCATION_CLUB')
export const SKIP_SET_LOCATION_CLUB = 'SKIP_SET_LOCATION_CLUB'
export const SET_LOCATION_DETAILS = 'SET_LOCATION_DETAILS'
export const RESET_BREAKDOWN_LOCATION = 'RESET_BREAKDOWN_LOCATION'
export const LOCATION_NEXT = 'LOCATION_NEXT'
export const SET_LANDMARK = 'SET_LANDMARK'
export const SET_LOCATION_TYPE = 'SET_LOCATION_TYPE'
export const SET_IS_HIGHWAY = 'SET_IS_HIGHWAY'
export const RESET_HIGHWAY_EXITS = 'RESET_HIGHWAY_EXITS'
export const SET_BREAKDOWN_REFERENCES_MARKERS =
  'SET_BREAKDOWN_REFERENCES_MARKERS'
export const SET_LOCATION_STEP = 'SET_LOCATION_STEP'
export const SET_SHOW_MAP_OPTIONS = 'SET_SHOW_MAP_OPTIONS'

export enum LOCATION_TYPE {
  HOME = 'HOME',
  HOME_LINK = 'HOME_LINK',
  AAR = 'AAR',
  ADDRESS_INPUT = 'ADDRESS_INPUT',
  PIN_DROP = 'PIN_DROP',
  GPS_LOCATION = 'GPS_LOCATION',
}

export type LocationPayload =
  | string
  | GoogleCoordinates
  | { member: MemberInfo }
export interface LocationMeta extends GeoLocationOptions {
  locationType: LOCATION_TYPE
}

export const ensureLocationServices = createAction(ENSURE_LOCATION_SERVICES)

export const setLocationServicesAvailable = createAction(
  SET_LOCATION_SERVICES_AVAILABLE,
  props<{ payload: { available: boolean; coordinates: GoogleCoordinates } }>()
)

export const setLocationDetails = createAction(
  SET_LOCATION_DETAILS,
  // TODO add type
  props<{ payload: any }>()
)

// TODO REMOVE
export const locationNext = createAction(LOCATION_NEXT)

export const setBreakdownLocationRequest = createAction(
  SET_BREAKDOWN_LOCATION.REQUEST,
  props<{
    payload: LocationPayload
    meta: LocationMeta
  }>()
)

export const setBreakdownLocationSuccess = createAction(
  SET_BREAKDOWN_LOCATION.SUCCESS,
  props<{ payload: AAALocation }>()
)

export const notifySetBreakdownLocationFailure = createAction(
  SET_BREAKDOWN_LOCATION.FAILURE
)

export const resetBreakdownLocation = createAction(RESET_BREAKDOWN_LOCATION)

export const setLandMark = createAction(
  SET_LANDMARK,
  props<{ payload: string }>()
)

export const setLocationType = createAction(
  SET_LOCATION_TYPE,
  props<{ payload: GoogleLocation }>()
)

export const setLocationClubRequest = createAction(
  SET_LOCATION_CLUB.REQUEST,
  props<{ payload: GenericCoordinates & {
    zipcode: string
  } }>()
)

export const setLocationClubSuccess = createAction(
  SET_LOCATION_CLUB.SUCCESS,
  props<{ payload: {
    association: string,
    club: string,
    zipcode: string
  } }>()
)

export const setLocationClubError = createAction(SET_LOCATION_CLUB.FAILURE)

export const skipSetLocationClub = createAction(SKIP_SET_LOCATION_CLUB)

export const setHighwayExitsRequest = createAction(
  SET_HIGHWAY_EXITS.REQUEST,
  props<{ payload: GenericCoordinates }>()
)

export const setHighwayExitsSuccess = createAction(
  SET_HIGHWAY_EXITS.SUCCESS,
  props<{ payload: HighwayExits }>()
)

export const setHighwayExitsFailure = createAction(SET_HIGHWAY_EXITS.FAILURE)

export const setIsHighway = createAction(
  SET_IS_HIGHWAY,
  props<{ payload: boolean }>()
)

export const resetHighwayExits = createAction(RESET_HIGHWAY_EXITS)

export const setBreakdownReferencesMarkers = createAction(
  SET_BREAKDOWN_REFERENCES_MARKERS,
  props<{ payload: LocationWithMarker[] }>()
)

export const setLocationStep = createAction(
  SET_LOCATION_STEP,
  props<{ payload: LOCATION_STEPS }>()
)

export const setShowMapOptions = createAction(
  SET_SHOW_MAP_OPTIONS,
  props<{ payload: boolean }>()
)
