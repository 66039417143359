import { Component, EventEmitter, Input, Output } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { selectCallText, selectModeConfiguration } from '../../auth/auth.selectors'
import { VendorConfigurationMode } from '../../auth/mode-configuration.types'
import { selectDefaultPhoneNumber, selectServicingClubRoadServicePhoneNumber } from '../../servicing-club/servicing-club.selectors'
import events from '../../tagging/events'
import { TaggingService } from '../../tagging/tagging.service'

@Component({
  selector: 'app-call-aaa-button',
  templateUrl: './call-aaa-button.component.html',
  styleUrls: [
    '../dialog/shared-dialog.component.scss',
    './call-aaa-button.component.scss',
  ],
})
export class CallAaaButtonComponent {
  @Input() clickEventPage: string
  @Input() useDefault = false
  @Input() inverted = false
  @Input() contactPhone = ''
  @Output() buttonClick: EventEmitter<any> = new EventEmitter<any>()
  isRapUser = false

  constructor(private store$: Store, private tagging: TaggingService) {
    this.modeConfiguration$.subscribe((configData) => this.isRapUser = configData !== null)
  }

  default$: Observable<string> = this.store$.pipe(
    select(selectDefaultPhoneNumber)
  )

  callText$: Observable<string> = this.store$.pipe(
    select(selectCallText)
  )

  modeConfiguration$: Observable<VendorConfigurationMode> = this.store$.pipe(
    select(selectModeConfiguration)
  )

  roadServicePhoneNumber$: Observable<string> = this.store$.pipe(
    select(selectServicingClubRoadServicePhoneNumber)
  )

  onCallAAAClick() {
    if (this.clickEventPage && this.clickEventPage !== '') {
      this.tagging.setClickEvent(events.shared.CALL_AAA_CLICK, this.clickEventPage)
    }
    this.buttonClick.next()
  }
}
