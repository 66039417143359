import { MemberTypesFromMessage, MEMBERSHIP_ERRORS } from './member.types';

export function getAutomatedEventDetails(error) {
  const errorMessage = error.response?.data?.message || error.response?.data?.error_description

  const { action, reason } = getReasonTypeFromMessage(errorMessage)

  return {
    reason: reason || null,
    errorEventAction: action || null
  }
}

export function getReasonTypeFromMessage(message: string | null) {

  const msg = (message || '').toLowerCase()
  const entries = Object.entries(MemberTypesFromMessage)

  const match = entries.find((elements) => {
    const _section = elements[1]
    return _section === msg
  })

  if (!match) {
    return { action: null, reason: null }
  }

  return MEMBERSHIP_ERRORS[match[0]]
}
