import { AAAStore } from 'src/app/store/root-reducer'
import { createSelector, defaultMemoize } from '@ngrx/store'
import { TaggingState } from './tagging.reducer'
import { CALL_STATUS_CODES } from '../dashboard/calls.types'

export const selectTaggingState = (store: AAAStore): TaggingState =>
  store.tagging

export const createSelectTaggedCallStatus = createSelector(
  selectTaggingState,
  (state: TaggingState) => defaultMemoize((callId: string) => state[callId])
)

export const createSelectIsCallStatusTagged = createSelector(
  createSelectTaggedCallStatus,
  (selectTaggedCallStatus) =>
    (callId: string, callStatus: CALL_STATUS_CODES): boolean =>
      callStatus === selectTaggedCallStatus.memoized(callId)
)
