<div class="call-aaa-button">
  <app-link-button
    href="{{ useDefault ? (default$ | async) : (roadServicePhoneNumber$ | async) | phoneConverter: 'href' }}"
    target="_blank"
    tabIndex="0"
    [inverted]="inverted"
    class="show-mobile mobile-phone-button"
    title="{{ (callText$ | async) }}"
    (clickLink)="onCallAAAClick()"
    size="condensed"
  >
    <span class="phone-number alphanumeric-mobile">{{ (roadServicePhoneNumber$ | async) | phoneConverter: 'label' }}</span>
    <span class="phone-number numeric-mobile" *ngIf="!isRapUser">{{ (roadServicePhoneNumber$ | async) | phoneConverter: 'numeric' }}</span>
  </app-link-button>

  <div class="show-desktop text-center">
    <p class="phone-number alphanumeric-desktop">{{ (roadServicePhoneNumber$ | async) | phoneConverter: 'label' }}</p>
    <p class="phone-number numeric-desktop" *ngIf="!isRapUser">{{ (roadServicePhoneNumber$ | async) | phoneConverter: 'numeric' }}</p>
  </div>
</div>
