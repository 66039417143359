import { Injectable, isDevMode } from '@angular/core'

import { ModelType, ModelTypesResponse, VehicleMakes, VehicleModels, VehicleYears } from './vehicle.types'
import { RequestOptions, XHRService } from '../../shared/services/xhr.service'
import {
  VehicleMakesSchema,
  VehicleModelsSchema,
  VehicleYearsSchema,
} from './vehicle.model'
import { environment } from '../../../environments/environment'
import { generateVehicleSlug } from 'src/app/shared/utils'
import { IndexedCollection } from 'src/app/shared/types'

@Injectable({
  providedIn: 'root',
})
export class VehicleService {
  devOptions: RequestOptions = {}
  constructor(private xhr: XHRService) {
    if (isDevMode()) {
      this.devOptions = {
        overrideBaseURL: environment.baseURL.replace('dev', 'qa'),
      }
    }
  }

  async getYears() {
    const response = await this.xhr.request<VehicleYears>(
      'vehicles/years',
      this.devOptions
    )
    await VehicleYearsSchema.validate(response)
    return response
  }

  async getMakes(year: number) {
    const response = await this.xhr.request<VehicleMakes>(
      `vehicles/makes/${year}`,
      this.devOptions
    )
    await VehicleMakesSchema.validate(response)
    return response
  }

  async getModels(year: number, make: string) {
    const response = await this.xhr.request<VehicleModels>(
      `vehicles/models/${year}/${make}`,
      this.devOptions
    )
    await VehicleModelsSchema.validate(response)
    return response
  }

  async getModelTypes({ year, make, model }): Promise<IndexedCollection<ModelType[]>> {
    const response = await this.xhr.request<ModelTypesResponse>(
      `vehicles/types/${year}/${make}/${model}`,
      this.devOptions
    )
    // await VehicleModelTypesSchema.validate(response)
    const slug = generateVehicleSlug({ year, make, model })
    return {
      [slug]: response.types
    }
  }
}
