import { getSelectors } from '@ngrx/router-store'
import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
  Selector,
} from '@ngrx/store'
import { AAAStore } from 'src/app/store/root-reducer'
import { selectIsPaceSetterValid } from '../issue/issue.selectors'
import { selectIsBreakdownLocationValid } from '../location/location.selectors'
import { selectIsTowLocationValid } from '../location/tow-location/tow-location.selectors'
import { selectIsSubmitValid } from '../submit/submit.selectors'
import { StepTypes } from '../ui/ui.types'
import { selectIsVehicleValid } from '../vehicle/vehicle.selectors'
import { WizardState } from './wizard.reducer'

const selectRouter: any = createFeatureSelector('router')

const {
  selectQueryParams,
  selectQueryParam,
  selectRouteParams,
  selectRouteParam,
  selectRouteData,
  selectUrl,
} = getSelectors(selectRouter)

// This proxy selector is sadly required for testing purposes.
// The selectUrl function is a non-writtable, non-projectable; un-mockable function.
// An alternative by Todd Motto:
// https://github.com/ultimatecourses/ngrx-store-effects-app/
// blob/27-testing-effects/src/products/store/selectors/pizzas.selectors.spec.ts#L108
export const selectCurrentUrl = createSelector(selectUrl, (url) => url)

export const createSelectIsCurrentStepValid: MemoizedSelector<
  AAAStore,
  (step: string) => boolean
> = createSelector(
  selectIsPaceSetterValid,
  selectIsBreakdownLocationValid,
  selectIsVehicleValid,
  selectIsSubmitValid,
  selectIsTowLocationValid,
  (
      isIssueValid: boolean,
      isBreakdownLocationValid: boolean,
      isVehicleValid: boolean,
      isSubmitValid: boolean,
      isTowingValid: boolean
    ) =>
    (step: StepTypes): boolean => {
      const stepValidationMap = {
        [StepTypes.ISSUE]: isIssueValid,
        [StepTypes.BREAKDOWN_LOCATION]: isBreakdownLocationValid,
        [StepTypes.VEHICLE]: isVehicleValid,
        [StepTypes.SUBMIT]: isSubmitValid,
        [StepTypes.TOWING]: isTowingValid,
      }
      return stepValidationMap[step]
    }
)

export const selectWizardState: Selector<AAAStore, WizardState> = (store) =>
  store.wizard

export const currentEditStepLocation = createSelector(
  selectWizardState,
  (state: WizardState): any => (state ? state['editionStepLocation'] : null)
)
