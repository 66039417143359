import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import {
  NOTIFY_CLICK_ACTION,
  NOTIFY_NAVIGATION_ACTION,
  NOTIFY_AUTOMATED_ACTION,
  automatedEventAction,
  notifyEventActionFailure,
} from './tagging.actions'
import { filter, catchError, switchMap, withLatestFrom } from 'rxjs/operators'
import { ErrorReportingService } from 'src/app/shared/services/error-reporting.service'
import { Store } from '@ngrx/store'
import { AAAStore } from '../../store/root-reducer'
import { selectActivePaceSetterSituationName } from '../issue/issue.selectors'
import { awaitHoistedFunction } from 'src/app/shared/utils/awaitHoistedFunction'
import { environment } from '../../../environments/environment'
import { selectAuthMethod } from '../auth/auth.selectors'
import { AuthMethods } from '../auth/auth.types'
import { selectMembershipNumber } from '../member/member.selectors'
import { selectIsFirstTimeAccess } from '../ui/ui.selectors';
import { getOemBrandingId } from '../../shared/utils/branding'

const defaultAttrs = {
  eventType: 'DRR',
  category: 'Automotive',
  appId: 'DRR',
  subCategory: 'DRR',
  'Event Detail': null,
}

const mapAuthMethodValue = (method: AuthMethods) => {
  switch (method) {
    case AuthMethods.MEMBER_NAME:
      return 'Name'
    case AuthMethods.MEMBERSHIP_NUMBER:
      return 'Number'
    case AuthMethods.AAA_TOKEN:
      return 'Token'
    case AuthMethods.AAA_NATIONAL:
      return 'IdEts'
  }
}

@Injectable()
export class TaggingEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<AAAStore>,
    private errorReportingService: ErrorReportingService
  ) {}

  handleClickAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NOTIFY_CLICK_ACTION),
        withLatestFrom(
          this.store$.select(selectActivePaceSetterSituationName),
          this.store$.select(selectAuthMethod),
          this.store$.select(selectMembershipNumber),
          this.store$.select(selectIsFirstTimeAccess)
        ),
        switchMap(([action, issueName, authMethod, memberNumber, firstTimeAccess]) =>
          this.transmitEvent(
            'LINKCLICK',
            this.setExtraParamsOnEvent(
              issueName,
              authMethod,
              memberNumber,
              action,
              firstTimeAccess
            )
          )
        ),
        catchError((error) =>
          this.errorReportingService.notifyErrorObservable(
            error,
            notifyEventActionFailure
          )
        )
      ),
    { dispatch: false }
  )

  handleNavigationAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NOTIFY_NAVIGATION_ACTION),
        withLatestFrom(
          this.store$.select(selectActivePaceSetterSituationName),
          this.store$.select(selectAuthMethod),
          this.store$.select(selectMembershipNumber),
          this.store$.select(selectIsFirstTimeAccess)
        ),
        switchMap(([action, issueName, authMethod, memberNumber, firstTimeAccess]) =>
          this.transmitEvent(
            'ITEMVIEW',
            this.setExtraParamsOnEvent(
              issueName,
              authMethod,
              memberNumber,
              action,
              firstTimeAccess
            )
          )
        ),
        catchError((error) =>
          this.errorReportingService.notifyErrorObservable(
            error,
            notifyEventActionFailure
          )
        )
      ),
    { dispatch: false }
  )

  handleAutomatedAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NOTIFY_AUTOMATED_ACTION),
        filter(
          (action: ReturnType<typeof automatedEventAction>) =>
            !action.meta ||
            Boolean(action.meta.callIdentifier && action.meta.callStatus)
        ),
        withLatestFrom(
          this.store$.select(selectActivePaceSetterSituationName),
          this.store$.select(selectAuthMethod),
          this.store$.select(selectMembershipNumber),
          this.store$.select(selectIsFirstTimeAccess)
        ),
        switchMap(([action, issueName, authMethod, memberNumber, firstTimeAccess]) =>
          this.transmitEvent(
            'AUTOMATED',
            this.setExtraParamsOnEvent(
              issueName,
              authMethod,
              memberNumber,
              action,
              firstTimeAccess
            )
          )
        ),
        catchError((error) =>
          this.errorReportingService.notifyErrorObservable(
            error,
            notifyEventActionFailure
          )
        )
      ),
    { dispatch: false }
  )

  async transmitEvent(
    eventAction: string,
    {
      action,
      pageType,
      params = {},
    }: { action: string; pageType: string; params?: object }
  ): Promise<void> {
    const appId = getOemBrandingId(window.location.href)
    const isRapUser = appId ? true : false

    if (isRapUser) {
      defaultAttrs.appId = appId
      defaultAttrs.eventType = 'DRRRAP'
      defaultAttrs.subCategory = 'DRRRAP'
    }
    const data = {
      ...defaultAttrs,
      ...params,
      eventAction,
      action,
      pageType,
    }
    const error = new Error(
      `Malformed analytical event data: ${JSON.stringify(data)}`
    )
    if (!eventAction || !action || !pageType) {
      throw error
    }

    const zipcodeServicesError = new Error(
      `Unable to load Zipcode Services library! Got: (${typeof (window as any)
        .zs})`
    )
    const loggingCommonsError = new Error(
      `Unable to load Logging Commons library! Got: (${typeof (window as any)
        .lc_logEvent})`
    )

    await awaitHoistedFunction<Object, void>({
      ref: (window as any).zs,
      target: window,
      path: ['zs', 'cookieExists'],
      callback: () => {},
      error: zipcodeServicesError,
    })

    if (environment.configTools) {
      console.log('Analytics:', JSON.stringify(data))
    }

    return await awaitHoistedFunction<Object, Function>({
      ref: (window as any).lc_logEvent,
      target: window,
      path: ['lc_logEvent'],
      callback: () => (window as any).lc_logEvent(data),
      error: loggingCommonsError,
    })
  }

  private setExtraParamsOnEvent(
    issueName: string,
    authMethod: AuthMethods,
    membershipNumber: string,
    event,
    firstTimeAccess?: boolean
  ) {
    return {
      ...event.payload,
      params: {
        ...event.payload.params,
        ...(issueName ? { path: issueName } : {}),
        ...(authMethod
          ? { validationType: mapAuthMethodValue(authMethod) }
          : {}),
        ...(membershipNumber ? { memId: membershipNumber } : {}),
        ...(firstTimeAccess ? { firstTimeAccess: true } : {})
      },
    }
  }
}

export const __TEST__ = {
  defaultAttrs,
  mapAuthMethodValue,
}
