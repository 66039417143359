import { Component, Input } from '@angular/core'
import { ColorName } from 'src/app/shared/pipes'

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent {
  @Input() width = '140'
  @Input() height = '140'
  @Input() color = '#003893'
  @Input() colorName: ColorName
  constructor() {}
}
