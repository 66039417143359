import { createSelector, defaultMemoize } from '@ngrx/store'

import { AAAStore } from '../../store/root-reducer'
import { UIState } from './ui.reducer'
import { Step, StepStatus, Dialog } from './ui.types'
import { IndexedCollection } from '../../shared/types'
import { GoogleAddress } from '../location/google-geocode/types'
import { AuthState } from '../auth/auth.reducer'
import { AppId, AuthMethods } from '../auth/auth.types'
import { getCookie } from 'src/app/shared/utils/cookies'
import { selectAuthState } from '../auth/auth.selectors'
import { Vehicle } from '../member/member.types'

export const selectUIState = (store: AAAStore): UIState => store.ui

export const selectInitialMapLocation = createSelector(
  selectUIState,
  (state: UIState): GoogleAddress => state.initialLatLng
)

export const selectSteps = createSelector(
  selectUIState,
  (state: UIState): IndexedCollection<Step> => state.steps
)

export const selectIterableSteps = createSelector(
  selectSteps,
  (steps: IndexedCollection<Step>): Step[] => Object.values(steps)
)

export const selectEditingStep = createSelector(
  selectIterableSteps,
  (iterableSteps: Step[]): Step =>
    iterableSteps.find((step) => step.status === StepStatus.EDITING)
)

export const createGetStepByUrl = createSelector(
  selectIterableSteps,
  (iterableSteps: Step[]) =>
    defaultMemoize(
      (url: string): Step => iterableSteps.find((step) => step.url === url)
    )
)

export const createGetPreviousStepByUrl = createSelector(
  selectIterableSteps,
  (iterableSteps: Step[]) =>
    defaultMemoize((url: string): Step => {
      const currentStep = iterableSteps.find((step) => step.url === url)

      return iterableSteps[iterableSteps.indexOf(currentStep) - 1]
    })
)

export const selectSplashscreenIsVisible = createSelector(
  selectUIState,
  (state: UIState) => state.splashscreen.visible
)

export const selectSplashscreenStep = createSelector(
  selectUIState,
  (state: UIState) => state.splashscreen.step
)

export const selectSplashscreenIsAnimatingOut = createSelector(
  selectUIState,
  (state: UIState) => state.splashscreen.animating
)

// TODO: move into config reducer
export const selectShowPwa = createSelector(
  selectUIState,
  (state: UIState) => state.showPwa
)

export const selectShowMenu = createSelector(
  selectUIState,
  (state: UIState): boolean => (state ? state.showMenu : false)
)

export const selectMapDebug = createSelector(
  selectUIState,
  (state: UIState): boolean => (state ? state.mapDebug : false)
)

export const selectShowBack = createSelector(
  selectUIState,
  (state: UIState): boolean => (state ? state.showBack : false)
)

export const selectNameZipInstructions = createSelector(
  selectUIState,
  (state: UIState): boolean => (state ? state.nameZipInstructions : false)
)

export const selectShowExpandedDialog = createSelector(
  selectUIState,
  (state: UIState): boolean => (state ? state.showExpandedDialog : false)
)

export const selectIssueSelectionRevamp = createSelector(
  selectUIState,
  (state: UIState): boolean => (state ? state.issueSelectionRevamp : false)
)

export const selectShowBottomNavigation = createSelector(
  selectUIState,
  (state: UIState): boolean => (state ? state.showBottomNavigation : false)
)

export const selectApplicationTextUpdates = createSelector(
  selectUIState,
  (state: UIState): boolean => (state ? state.applicationTextUpdates: false)
)

export const selectTowRedesign = createSelector(
  selectUIState,
  (state: UIState): boolean => (state ? state.isTowRedesign : false)
)

export const selectShowAuthOptionButton = createSelector(
  selectUIState,
  (state: UIState): boolean => (state ? state.showAuthOptionButton : false)
)

export const selectShowKeepMeInformed = createSelector(
  selectUIState,
  (state: UIState): boolean => (state ? state.showKeepMeInformed : false)
)

export const selectShowSimpleCaptcha = createSelector(
  selectUIState,
  (state: UIState): boolean => (state ? state.showSimpleCaptcha : false)
)

export const selectIsCaptchaRequired = createSelector(
  selectAuthState,
  (state: AuthState): boolean =>
    !(
      state.isSecure ||
      (state.method === AuthMethods.MEMBERSHIP_NUMBER &&
        getCookie('AAA_AppId') === AppId.AGENT)
    )
)

export const selectIsTransitioning = createSelector(
  selectUIState,
  (state: UIState) => !!state.transitioning
)

export const selectUserDefaultCoords = createSelector(
  selectUIState,
  (state: UIState) => state.defaultUserCoords
)

export const selectHasUserDefaultCoords = createSelector(
  selectUserDefaultCoords,
  (userCoords: GoogleAddress) =>
    Boolean(userCoords) && Boolean(userCoords.lat) && Boolean(userCoords.lng)
)

export const selectActiveDialogs = createSelector(
  selectUIState,
  (state: UIState): IndexedCollection<Dialog> => state.dialog
)

export const selectActiveDialogsByTime = createSelector(
  selectActiveDialogs,
  (dialogs: IndexedCollection<Dialog>): Array<Dialog> =>
    Object.values(dialogs).sort((a, b) => Number(a.displayedAt > b.displayedAt))
)

export const selectIsAnyDialogActive = createSelector(
  selectActiveDialogs,
  (dialogs: IndexedCollection<Dialog>): boolean =>
    dialogs && Object.keys(dialogs).length > 0
)

export const selectSkipIssue = createSelector(
  selectUIState,
  (state: UIState) => state.skipIssue
)

export const selectPreProdEnv = createSelector(
  selectUIState,
  (state: UIState): boolean => state?.preProdEnv
)

export const selectDefaultZipCode = createSelector(
  selectUIState,
  (state: UIState): string => state?.defaultZipCode || null
)

export const selectCallStatusInterval = createSelector(
  selectUIState,
  (state: UIState): number => state.callStatusInterval
)

export const selectTelematics = createSelector(
  selectUIState,
  (state: UIState): string[] => (state ? state.telematics : [])
)

export const selectQueryParamsVehicleData = createSelector(
  selectUIState,
  (state: UIState): Vehicle => state?.queryParamsVehicle
)

export const selectIsFirstTimeAccess = createSelector(
  selectUIState,
  (state: UIState): boolean => state?.isFirstTimeAccess
)
