import { AARData, AARDetail, AARRequestParams } from './aar.types'
import { PayloadedAction, IndexedCollection } from 'src/app/shared/types'
import {
  AAR_LOAD,
  AAR_DETAIL,
  AAR_RESET,
  INCREMENT_AARS_SHOWN,
  SHOW_ALL_AARS,
  SHOW_OPEN_AARS_ONLY,
  NO_MAKE_FACILITIES,
} from './aar.actions'
import { CANCEL_EDITING_REQUEST } from 'src/app/shared/actions/shared.actions'

const RESULT_DISPLAY_BATCH_COUNT = 10

export interface AARState {
  data: IndexedCollection<AARData>
  maximumAarsShown: number
  aarParams?: AARRequestParams
  openOnly: boolean
  details: IndexedCollection<AARDetail>
  active: AARData
  noMakeFacilities: boolean
}

export const initialState: AARState = {
  data: null,
  maximumAarsShown: RESULT_DISPLAY_BATCH_COUNT,
  openOnly: false,
  details: {},
  active: null,
  noMakeFacilities: false,
}

export function reducer(
  state: AARState = initialState,
  action: PayloadedAction
): AARState {
  switch (action.type) {
    case AAR_LOAD.REQUEST:
      return {
        ...state,
        aarParams: action.payload,
        noMakeFacilities: false,
      }
    case AAR_LOAD.SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
        maximumAarsShown: RESULT_DISPLAY_BATCH_COUNT,
      }
    case INCREMENT_AARS_SHOWN:
      return {
        ...state,
        maximumAarsShown: state.maximumAarsShown + RESULT_DISPLAY_BATCH_COUNT,
      }
    case SHOW_ALL_AARS:
      return {
        ...state,
        openOnly: false,
      }
    case SHOW_OPEN_AARS_ONLY:
      return {
        ...state,
        openOnly: true,
      }
    case AAR_DETAIL.REQUEST:
      return {
        ...state,
        active:
          state.active && state.active.id === action.payload.id
            ? state.active
            : action.payload,
      }
    case AAR_DETAIL.SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          [action.payload.id]: {
            // Some data is only contained in the summary record!
            ...state.data[action.payload.id],
            ...action.payload,
          },
        },
      }
    case AAR_RESET:
    case CANCEL_EDITING_REQUEST: {
      return {
        ...initialState,
        details: { ...state.details },
        noMakeFacilities: false,
      }
    }
    case NO_MAKE_FACILITIES:
      return {
        ...initialState,
        aarParams: state.aarParams,
        noMakeFacilities: action.payload.active,
      }

    default:
      return state
  }
}
