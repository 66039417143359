<div class="dialog dialog-error not-a-member">
  <h1 mat-dialog-title>
    Notice
  </h1>

  <div id="dialog-content" mat-dialog-content>
    <p class="font-size-large">
      We are unable to verify your information. Please make sure you are using the name and zip you have on file for AAA. If the problem persists, please contact us:
    </p>
  </div>

  <div class="not-a-member_secondary-content">
    <app-call-aaa-button
      [clickEventPage]="pageType"
      [inverted]="true"
      (buttonClick)="onCallAAAClick()">
    </app-call-aaa-button>
  </div>
  <button mat-dialog-close class="mat-dialog-close" aria-label="dismiss this message">
    <icon-close></icon-close>
  </button>
</div>
