import { Component, Input } from '@angular/core'

import { DateTime } from 'luxon'

import {
  AUTO_STYLE,
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations'

// Types
import { ServiceItem } from 'src/app/modules/member/member.types'

// ARR:POC - The component displays individual service history item.
@Component({
  selector: 'app-service-history-item',
  templateUrl: './service-history-item.component.html',
  styleUrls: ['../service-history-panel.component.scss'],
  animations: [
    trigger('collapse', [
      state('false', style({ height: AUTO_STYLE, visibility: AUTO_STYLE })),
      state('true', style({ height: '0', visibility: 'hidden' })),
      transition('false => true', animate('300ms ease-in')),
      transition('true => false', animate('300ms ease-out')),
    ]),
  ],
})
export class ServiceHistoryItemComponent {
  collapsed = true

  @Input()
  item: ServiceItem

  Object = Object

  getDate(input) {
    return DateTime.fromISO(new Date(input).toISOString()).toFormat('hh:mm a')
  }

  constructor() {}
}
