<header role="banner">
  <div
    #container
    id="header-wrapper"
    class="app-header"
    [ngClass]="(showMenu$ | async) ? '' : 'no-menu'">
    <div class="full-logo"
      *ngIf="showMenu$ | async"
      [ngClass]="{'left-aligned-logo': canCancel$ | async}">
      <app-img
        *ngIf="showLogo"
        src="assets/images/{{appIdLogo.fileName}}"
        classNames="{{appIdLogo.className}}"
        alt="{{appIdLogo.alt}}"
        id="{{appIdLogo.className}}"
        width="{{appIdLogo.width}}"
        height="{{appIdLogo.height}}"
      ></app-img>
      <span class="roadside-request show-desktop">Roadside Request</span>
    </div>
    <app-button-cancel-request tabindex="{{ (canCancel$ | async) ? 2 : -1 }}" [attr.aria-hidden]="!(canCancel$ | async)"></app-button-cancel-request>
  </div>
</header>
