import { createSelector } from '@ngrx/store'

import { AAAStore } from '../../store/root-reducer'
import { SessionState } from './session.reducer'

export const selectSessionState = (store: AAAStore): SessionState =>
  store.session

export const selectLastInteraction = createSelector(
  selectSessionState,
  (state: SessionState): number => state.lastInteraction
)
