import { ofType, Actions, createEffect } from '@ngrx/effects'
import { Injectable } from '@angular/core'
import { Store, select, Action } from '@ngrx/store'
import {
  map,
  withLatestFrom,
  filter,
  catchError,
  switchMap,
  mergeMap,
} from 'rxjs/operators'

import { AAAStore } from '../../store/root-reducer'

import {
  SET_PACE_SETTER_SITUATION,
  PACE_SETTER_PREVIOUS,
  PACE_SETTER_NEXT,
  setPaceSetterStep,
  SET_PACE_SETTER_OPTIONS,
} from './issue.actions'
import {
  selectActivePaceSetterCode,
  selectActivePaceSetterSituation,
  selectActivePaceSetterStep,
  selectNeedsTow,
} from './issue.selectors'

import { PaceSetterCode, PaceSetterSituation, PACE_SETTER_SITUATION_CODES, PACE_SETTER_SITUATION_TYPES } from './issue.types'

import { Router } from '@angular/router'
import { ROUTER_NAVIGATED } from '@ngrx/router-store'
import { PayloadedAction } from 'src/app/shared/types'
import { openMessageDialog } from '../ui/ui.actions'
import { MessageDialogTypes, StepTypes } from '../ui/ui.types'
import { WizardService } from '../wizard/wizard.service'
import { currentEditStepLocation } from '../wizard/wizard.selectors'
import { setResetPassengers } from '../submit/submit.actions'
import { resetTowDestination } from '../location/tow-location/tow-location.actions'
import { ErrorReportingService } from '../../shared/services/error-reporting.service'
import { from, of } from 'rxjs'
import { selectActiveCallStatus } from '../dashboard/calls-statuses/call-status.selectors'
import { AAACallStatus } from '../dashboard/calls.types'
import { PACE_SETTER_OPTION_TEMPLATES } from './issue.utils'
import { LOCATION_STEPS } from '../location/location.types'
import { setLocationStep, setShowMapOptions } from '../location/location.actions'

@Injectable()
export class IssueEffects {
  handleSituationStepPrevious = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PACE_SETTER_PREVIOUS),
        switchMap((action) =>
          of(action).pipe(
            withLatestFrom(
              this.store$.pipe(select(selectActivePaceSetterStep))
            ),
            filter(([_, stepIndex]: [never, number]) => stepIndex > 0),
            map(([_, stepIndex]: [never, number]) => {
              const queryParams: any = { step: StepTypes.ISSUE }

              // Don't show index for landing screen in issues sequence
              if (stepIndex > 1) {
                queryParams.index = stepIndex - 1
              }

              return this.router.navigate(['steps'], { queryParams })
            }),
            catchError((error) =>
              from(this.errorReportingService.notifyError(error))
            )
          )
        )
      ),
    { dispatch: false }
  )

  // TODO refactor
  handleSituationStepNext = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SET_PACE_SETTER_SITUATION, PACE_SETTER_NEXT),
        switchMap((action) =>
          of(action).pipe(
            withLatestFrom(
              this.store$.pipe(select(selectActiveCallStatus)),
              this.store$.pipe(select(selectActivePaceSetterSituation)),
              this.store$.pipe(select(selectActivePaceSetterStep)),
              this.store$.pipe(select(currentEditStepLocation)),
              this.store$.pipe(select(selectNeedsTow))
            ),
            filter(([_, activeCallStatus]) => activeCallStatus === null),
            map(
              ([_, _2, situation, stepIndex, currentStepUrl, needsTow]: [
                Action,
                AAACallStatus,
                PaceSetterSituation,
                number,
                string | null,
                boolean
              ]) => {
                if (situation) {
                  const options =
                    PACE_SETTER_OPTION_TEMPLATES[situation.name.toUpperCase()]

                  // If tow is not needed we will reset the passengers form
                  if (!needsTow) {
                    this.store$.dispatch(resetTowDestination())
                    this.store$.dispatch(setResetPassengers())
                  }

                  if (options) {
                    // Once we've run through all the pages, move on
                    const maxGroups = options.detailGroups.length

                    if (stepIndex >= maxGroups && currentStepUrl && !needsTow) {
                      return this.wizardService.backToEditUrl(currentStepUrl)
                    }

                    if (stepIndex >= maxGroups) {
                      return this.router.navigate(['steps'], {
                        queryParams: { step: StepTypes.BREAKDOWN_LOCATION },
                      })
                    }
                  }
                }

                return this.router.navigate(['steps'], {
                  queryParams: {
                    step: StepTypes.ISSUE,
                    index: Number(stepIndex) + 1,
                  },
                })
              }
            ),
            catchError((error) =>
              from(this.errorReportingService.notifyError(error))
            )
          )
        )
      ),
    { dispatch: false }
  )

  handleNotifyPoliceDialog = createEffect(() =>
    this.actions$.pipe(
      ofType(SET_PACE_SETTER_SITUATION),
      withLatestFrom(this.store$.pipe(select(selectActiveCallStatus))),
      filter(
        ([action, activeCallStatus]: [PayloadedAction, AAACallStatus]) =>
          activeCallStatus === null &&
          PACE_SETTER_SITUATION_TYPES.ACCIDENT ===
            action.payload.name.toUpperCase()
      ),
      map((_) =>
        openMessageDialog({
          payload: {
            type: MessageDialogTypes.NOTIFY_POLICE,
          },
        })
      )
    )
  )

  handleNavigation = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      map(
        (action: PayloadedAction) => action.payload.routerState.root.queryParams
      ),
      filter((params) => params.step === 'issue'),
      map(({ index }) => setPaceSetterStep({ index: index || 0 }))
    )
  )

  handleBatteryIssueMapOptions = createEffect(() =>
    this.actions$.pipe(
      ofType(SET_PACE_SETTER_SITUATION, SET_PACE_SETTER_OPTIONS),
      withLatestFrom(this.store$.pipe(select(selectActivePaceSetterCode))),
      mergeMap(([_, activeCode]: [PayloadedAction, PaceSetterCode]) => {
        const batteryIssueOptionSelected = activeCode.paceSetterCode === PACE_SETTER_SITUATION_CODES.BATTERY_ISSUE

        if (batteryIssueOptionSelected) {
          return [setShowMapOptions({ payload: true }),
          setLocationStep({ payload: LOCATION_STEPS.OPTIONS })]
        }

        return [setShowMapOptions({ payload: false }),
        setLocationStep({ payload: LOCATION_STEPS.MAP_OPTIONS })]
      }),
    )
  )

  constructor(
    private actions$: Actions,
    private router: Router,
    private store$: Store<AAAStore>,
    private wizardService: WizardService,
    private errorReportingService: ErrorReportingService
  ) {}
}
