import { Component, Input } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { selectIterableSteps } from '../../modules/ui/ui.selectors'
import { Step } from '../../modules/ui/ui.types'
import { getOemBrandingId, OEMLogoData } from '../utils/branding'

@Component({
  selector: 'app-splashscreen',
  templateUrl: './splashscreen.component.html',
  styleUrls: ['./splashscreen.component.scss'],
})
export class SplashscreenComponent {
  @Input() step = 1
  @Input() hidden = false
  isRapUser = false
  appIdLogo;

  constructor(private store$: Store) {
    let oemBrandingAppId = getOemBrandingId(window.location.href)

    if (oemBrandingAppId) {
      this.appIdLogo = OEMLogoData[oemBrandingAppId];
      this.isRapUser = true
      require(`style-loader!../../../assets/styles/branding/${oemBrandingAppId.toLowerCase()}.css`);
    }
  }

  stepLoaded$: Observable<Step[]> = this.store$.pipe(
    select(selectIterableSteps)
  )
}
