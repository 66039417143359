import { Club, ClubState } from './clubs.reducer'
import { AAAStore } from 'src/app/store/root-reducer'
import { createSelector } from '@ngrx/store'
import { selectActiveCallStatus } from '../calls-statuses/call-status.selectors'

export const selectClubsState = (store: AAAStore) => store.clubs

export const clubSelectorFactory = createSelector(
  selectClubsState,
  (state: ClubState): ((name: string) => Club) =>
    (name: string) =>
      state.clubs[name]
)

export const selectActiveHasTracking = createSelector(
  clubSelectorFactory,
  selectActiveCallStatus,
  (selectClub, activeStatus): boolean => {
    // Default to false if nothing has come through yet
    if (!activeStatus) {
      return true
    }

    const club = selectClub(activeStatus.servicingClub)

    if (!club) {
      return true
    }

    return club.hasTrackingSupport
  }
)
