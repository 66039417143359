<div class="dialog">
  <h1 mat-dialog-title class="location-services_title">
    How to install AAA
  </h1>

  <div mat-dialog-content class="tall">
    <div class="intro-block">
      <p>
        To put an AAA shortcut on your Home screen, do the following:
      </p>
    </div>

    <div class="instruction-holder">
      <div class="instruction-header">Safari</div>
      <div>
        <ul>
          <li>Tap the <b>Share button</b> in Safari for the current page.</li>
          <li>Tap the icon labeled <b>Add to Home Screen </b>.</li>
          <li>Tap <b>Add</b> in the upper-right corner.</li>
        </ul>
      </div>
    </div>

    <div class="instruction-holder">
      <div class="instruction-header">Firefox</div>
      <div>
        <ul>
          <li>Tap the <b>Add</b> button in nav bar for the current page.</li>
          <li>Tap the <b>Add to Home Screen </b> button.</li>
          <li>Tap the <b>Add Automatically </b> button.</li>
        </ul>
      </div>
    </div>

    <div class="instruction-holder">
      <div class="instruction-header">Chrome</div>
      <div>
        <ul>
          <li>
            Tap the <b>Add</b> button in the nav bar for the current page.
          </li>
          <li>Tap the <b>Install </b> button.</li>
          <li>
            A new window will be opened containing the installed app
          </li>
        </ul>
      </div>
    </div>

    <p>
      You can now launch the AAA application from your Home screen by tapping
      its icon.
    </p>
  </div>
  <button mat-dialog-close class="mat-dialog-close" aria-label="dismiss this message">
    <icon-close></icon-close>
  </button>
</div>
