import { Injectable } from '@angular/core'
import { Adapter } from '../types'
import { AAACallData } from '../../modules/dashboard/calls.types'
import { getCookie } from '../utils/cookies'
import { CHARACTER_LIMIT_COMMENTS } from '../../modules/dashboard/calls.constants'

@Injectable({
  providedIn: 'root',
})
export class AAACallDataAdapter implements Adapter<AAACallData> {
  adapt(data: any): AAACallData {
    const appid = getCookie('AAA_AppId')
    const comments = `DRRWeb 2.0 ${appid} ${data.comments || ''}`.substring(
      0,
      CHARACTER_LIMIT_COMMENTS
    )

    const breakDownStAddress = `${
      data?.breakdownLocation?.streetNumber || ''
    } ${data?.breakdownLocation?.street || ''}`.trim()
    const towingStAddress = `${data?.towing?.streetNumber || ''} ${
      data?.towing?.streetName || ''
    }`.trim()

    if (data.towing) {
      data.towing.city = data.towing.city ? data.towing.city.toUpperCase() : ''
    }
    if (data.breakdownLocation) {
      data.breakdownLocation.city = data.breakdownLocation.city
        ? data.breakdownLocation.city.toUpperCase()
        : ''
    }

    return {
      ...data,
      breakdownLocation: {
        ...data.breakdownLocation,
        streetAddress: breakDownStAddress,
      },
      ...(data.towing && {
        towing: {
          ...data.towing,
          streetAddress: towingStAddress,
        },
      }),
      comments,
    }
  }
}
