import { Component, HostListener, Input } from '@angular/core'
import { AAAStore } from '../../../store/root-reducer'
import { cancelRequest } from '../../actions/shared.actions'
import { Store, select } from '@ngrx/store'
import { TaggingService } from 'src/app/modules/tagging/tagging.service'
import { Observable, combineLatest } from 'rxjs'
import { selectIsSignedIn } from 'src/app/modules/auth/auth.selectors'
import { selectRouterStep } from 'src/app/store/router.selectors'
import { map } from 'rxjs/operators'
import { CALL_CANCEL } from 'src/app/modules/dashboard/calls.actions'
import { selectIsLoading } from 'src/app/modules/ui/loading/loading.selectors'
import { selectServicingClubRoadServicePhoneNumber } from '../../../modules/servicing-club/servicing-club.selectors'
import { selectCanCancelActiveCall } from 'src/app/modules/dashboard/calls-statuses/call-status.selectors'
import events from 'src/app/modules/tagging/events'
import { selectCallText } from '../../../modules/auth/auth.selectors'
import { AbstractResponsiveComponent } from '../../abstract-responsive.component'

@Component({
  selector: 'app-button-cancel-request',
  templateUrl: './cancel-request.component.html',
  styleUrls: ['./cancel-request.component.scss'],
})
export class CancelRequestButtonComponent extends AbstractResponsiveComponent {
  constructor(
    private store$: Store<AAAStore>,
    private tagging: TaggingService
  ) {
    super()
    this.mobileWidth = 960;
  }

  @Input() bottom: Boolean = false
  @Input() tabIndex = 0
  @Input() defaultColor = false

  cancelling = false
  isSignedIn$: Observable<boolean> = this.store$.pipe(select(selectIsSignedIn))

  loading$: Observable<boolean> = this.store$.pipe(
    select(selectIsLoading(CALL_CANCEL.ACTION))
  )

  roadServicePhoneNumber$: Observable<string> = this.store$.pipe(
    select(selectServicingClubRoadServicePhoneNumber)
  )

  callText$ = this.store$.pipe(select(selectCallText));

  // TODO: Pending refactor.
  // Remove observable from header.ts, separate Call AAA button from cancel button,
  // create a control/options.ts component that wraps both. Only use cancel for header,
  // options with call AAA + cancel for mobile/tablet.
  canCancel$: Observable<boolean> = combineLatest([
    this.isSignedIn$,
    this.store$.select(selectRouterStep),
    this.store$.select(selectCanCancelActiveCall),
  ]).pipe(
    map(([isSignedIn, step, canCancelActiveCall]) => {
      if (!isSignedIn) {
        return false
      }
      if (step) {
        return step.toLowerCase() !== 'issue'
      } else {
        return canCancelActiveCall
      }
    })
  )
  // Trigger button click action
  cancelCall() {
    this.cancelling = true
  }

  confirmCancel() {
    this.store$.dispatch(cancelRequest())

    this.cancelling = false

    this.tagging.setClickEvent(
      events.dashboard.CALL_CANCEL_REQUEST,
      events.dashboard.CALL_STATUS_PAGE_TYPE
    )
  }

  cancelCancel() {
    this.cancelling = false
  }

  tagEvent() {
    this.tagging.setClickEvent(
      events.shared.CALL_AAA_CLICK,
      events.shared.MENU_PAGE_TYPE
    )
  }

  cancelRequestLabel() {
    return this.isMobile ? 'Cancel' : 'Cancel Request'
  }
}
