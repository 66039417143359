import { AAAStore } from 'src/app/store/root-reducer'
import { createSelector } from '@ngrx/store'
import {
  FormsState,
  MembershipNumberAuthFormState,
  NameAuthFormState,
  AdditionalLocationInfoFormState,
  VinMileageAuthFormState,
  CarRentalAuthFormState,
  SubscriberNumberAuthFormState,
} from './forms.reducer'
import { FormGroupState } from 'ngrx-forms'

export const selectForms = (state: AAAStore) => state.forms

export const selectAuthForm = createSelector(
  selectForms,
  (forms: FormsState): FormGroupState<MembershipNumberAuthFormState> =>
    forms.auth
)

export const selectNameAuthForm = createSelector(
  selectForms,
  (forms: FormsState): FormGroupState<NameAuthFormState> => forms.nameAuth
)

export const selectAdditionalLocationInfoForm = createSelector(
  selectForms,
  (forms: FormsState): FormGroupState<AdditionalLocationInfoFormState> =>
    forms.additionalLocationInfo
)

export const selectVinMileageAuthForm = createSelector(
  selectForms,
  (forms: FormsState): FormGroupState<VinMileageAuthFormState> => forms.vinMileageAuth
)

export const selectCarRentalAuthForm = createSelector(
  selectForms,
  (forms: FormsState): FormGroupState<CarRentalAuthFormState> => forms.carRentalAuth
)

export const selectSubscriberNumberAuthForm = createSelector(
  selectForms,
  (forms: FormsState): FormGroupState<SubscriberNumberAuthFormState> => forms.subscriberNumberAuth
)
