import { AuthMethods } from '../auth/auth.types'
import { TaggingService } from './tagging.service'
import events from './events'

export function handleAuthAnalytics(
  taggingService: TaggingService,
  {
    method,
    isValid,
    reason = null
  }: { method: AuthMethods; isValid: boolean; reason?: string }
) {

  function _setAutomatedEvent(action, reason) {
    if (reason) {
      taggingService.setAutomatedEvent(action, events.auth.PAGE_TYPE, null, {
        'Event Detail': reason
      })
    } else {
      taggingService.setAutomatedEvent(action, events.auth.PAGE_TYPE)
    }
}

  function _handleBypassAuthAnalytics() {
    const _action = isValid
      ? events.auth.AAA_MOBILE_AUTH_SUCCESS
      : events.auth.AAA_MOBILE_AUTH_FAILURE

    _setAutomatedEvent(_action, reason)
  }

  function _handleEncryptedAuthAnalytics() {
    const _action = isValid
      ? events.auth.AAA_NATIONAL_AUTH_SUCCESS
      : events.auth.AAA_NATIONAL_AUTH_FAILURE

    _setAutomatedEvent(_action, reason)
  }

  function _handleMembershipAuthAnalytics() {
    const _action = isValid
      ? events.auth.FORM_MEMBERSHIP_AUTH_SUCCESS
      : events.auth.FORM_MEMBERSHIP_AUTH_FAILURE

    _setAutomatedEvent(_action, reason)
  }

  function _handleNameAuthAnalytics() {
    const _action = isValid
      ? events.auth.FORM_NAME_AUTH_SUCCESS
      : events.auth.FORM_NAME_AUTH_FAILURE

    _setAutomatedEvent(_action, reason)
  }

  function _handleRapAuthAnalytics() {
    const _action = isValid
      ? events.auth.FORM_RAP_VALIDATION_SUCCESS + ' - ' + method
      : events.auth.FORM_RAP_VALIDATION_FAILURE + ' - ' + method

    _setAutomatedEvent(_action, reason)
  }

  switch (method) {
    case AuthMethods.AAA_TOKEN:
      _handleBypassAuthAnalytics()
      break
    case AuthMethods.AAA_NATIONAL:
      _handleEncryptedAuthAnalytics()
      break
    case AuthMethods.MEMBERSHIP_NUMBER:
      _handleMembershipAuthAnalytics()
      break
    case AuthMethods.MEMBER_NAME:
      _handleNameAuthAnalytics()
      break
    case AuthMethods.OEM:
    case AuthMethods.RENTAL:
    case AuthMethods.VAS:
      _handleRapAuthAnalytics()
      break
  }
}
