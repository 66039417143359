import { Eligibility } from './eligibility.types';

export interface ConfigAuthParams {
  isSecure?: boolean
  method?: AuthMethods
}

export interface BaseAuthRequestParams extends ConfigAuthParams {
  captchaToken?: string
}

export interface AuthBypassRequestParams {
  token: string
  club: string
}

export interface SecureAuthRequestParams {
  memId: string
  ets: string
  vendor?: string
}

export interface MemberNumberAuthRequestParams {
  memberNumber: string
  zipCode: string
}

export interface AgentAuthParams {
  isAgent: boolean
}

export interface VasAuthRequestParams {
  subscriber: string | number
  referenceId: string
}
export interface RentalAuthRequestParams {
  refNumber: string
  referenceId: string
}
export interface OemAuthRequestParams {
  vin: string
  mileage: number
  manufacturer: string
  referenceId: string
}

export type AuthRequestParams = BaseAuthRequestParams &
  (
    | MemberNumberAuthRequestParams
    | NameAuthRequestParams
    | SecureAuthRequestParams
    | AgentAuthParams
    | VasAuthRequestParams
    | RentalAuthRequestParams
    | OemAuthRequestParams
  )

export interface SetAuthParams {
  club: string
  access_token: string
}

export interface SetRapAuthParams extends ConfigAuthParams {
  access_token: string
}

export interface NameAuthRequestParams {
  firstName: string
  lastName: string
  zipCode: string
}

export interface AuthResponse extends ConfigAuthParams {
  club: string
  expires_in: number
  access_token: string
  scope: string
  eligibility?: Eligibility
}

export enum AuthMethods {
  AAA_NATIONAL = 'AAA_NATIONAL',
  AAA_TOKEN = 'AAA_TOKEN',
  MEMBERSHIP_NUMBER = 'MEMBERSHIP_NUMBER',
  MEMBER_NAME = 'MEMBER_NAME',
  OEM = 'OEM',
  RENTAL = 'RENTAL',
  VAS = 'VAS',
}

export interface SearchSigninParams {
  resultId: string
  searchId: string
}

export enum AppId {
  IOSMOBILE = 'IOSMOBILE',
  ANDROIDMOBILE = 'ANDROIDMOBILE',
  ACEAPP = 'ACEAPP',
  ACEAPP_IOS = 'ACEAPP-IOS',
  ACEAPP_ANDROID = 'ACEAPP-ANDROID',
  AGENT = 'AGENT',
  BQ = 'BQ',
  SEO = 'SEO',
  _800SMS = '800SMS',
  IVR = 'IVR',
  TTP = 'TTP',
  MONITOR = 'MONITOR',
}

// TODO change it to be an interface with Id and Domain
export enum AppWhiteLabelId {
  HYUNDAI = 'HYUNDAI',
  TOYOTA = 'TOYOTA',
  HERTZ = 'HERTZ',
  CUSTOM = 'CUSTOM',
}

export enum OEMBranding {
  HYUNDAI = 'HYUNDAI',
}

// ARR:POC - Agent Settings interface
export interface AgentSettingsParams {
  vehicleType?: string
  cashCall?: boolean
  priorityCode?: string
  agentName?: string
  agentUsername?: string
}

export enum RapAuthFailure {
  NOT_ELIGIBLE = 'NOT_ELIGIBLE',
  GENERIC = 'GENERIC'
}
