import {
  Component,
  ViewEncapsulation,
  OnInit,
  PLATFORM_ID,
  Inject,
} from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { PwaService } from './shared/services/pwa.service'
import { Store, select } from '@ngrx/store'
import { AAAStore } from './store/root-reducer'
import {
  selectSplashscreenIsVisible,
  selectSplashscreenStep,
  selectIsTransitioning,
} from './modules/ui/ui.selectors'
import { map, take, withLatestFrom, delay, concatMap, filter } from 'rxjs/operators'
import { showSplashscreen } from './modules/ui/ui.actions'
import { selectUrl } from './store/router.selectors'
import { of, ReplaySubject, Observable } from 'rxjs'
import { selectShowMenu } from 'src/app/modules/ui/ui.selectors'
import { environment } from '../environments/environment'
import { getCookie } from './shared/utils/cookies'
import { OEMBranding } from './modules/auth/auth.types'
import { NavigationEnd, Router } from '@angular/router'

declare let dT_

const DELAY_TRANSITION_OUT_ANIMATION = 350

const _loadedLibraries: { [url: string]: ReplaySubject<any> } = {}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  isTransitioning$ = this.store$.pipe(
    select(selectIsTransitioning),
    concatMap((transitioning) =>
      of(transitioning).pipe(
        delay(transitioning ? 0 : DELAY_TRANSITION_OUT_ANIMATION)
      )
    )
  )

  isSplashscreenHidden$ = this.store$.pipe(
    select(selectSplashscreenIsVisible),
    map((visible) => !visible)
  )

  step$ = this.store$.pipe(delay(10), select(selectSplashscreenStep))

  splashscreenIsHiddenOnBootstrap = this.store$.pipe(
    select(selectSplashscreenIsVisible),
    take(1),
    map((visible) => !visible),
    withLatestFrom(this.store$.pipe(select(selectUrl)))
  )

  showHeader$ = this.store$.pipe(select(selectShowMenu))

  private _selector = 'meaningful-paint'

  constructor(
    public pwaService: PwaService,
    private http: HttpClient,
    private store$: Store<AAAStore>,
    @Inject(PLATFORM_ID) private platformId,
    private router: Router
  ) {
    if (typeof dT_ !== 'undefined' && dT_.initAngularNg) {
      dT_.initAngularNg(http, HttpHeaders)
    }
  }

  ngOnInit() {
    // OEM Branding
    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    ).subscribe(() => {
      //OEM Branding after cookie is set with current appId
      const appId = getCookie('AAA_AppId');
      if (appId in OEMBranding) {
        require(`style-loader!../assets/styles/branding/${appId.toLowerCase()}.css`);
      }
    });

    this.pwaService.swUpdateListener()
    this.splashscreenIsHiddenOnBootstrap.subscribe(
      ([hidden, url]: [boolean, string]) => {
        this.hideMeaningfulPaint()
        if (hidden) {
          this.store$.dispatch(showSplashscreen())
        }
      }
    )
    // load js libraries
    this.loadScript(
      'https://www.googleoptimize.com/optimize.js?id=OPT-TGWZVN3'
    ).subscribe(() => {
      console.log('optimize.js loaded')
    })
    this.loadScript('//www.aaa.com/jsincludes/zipcodeServices.js').subscribe(
      () => {
        ;(<any>window).AAA_NA_DL = {
          eventType: 'DRR',
          category: 'Automotive',
          appId: 'DRR',
          subCategory: 'DRR',
        } as any
          ;(<any>window).zs.setZip2()
        console.log('zipcodeServices.js loaded')
      }
    )
    const loggerLibrary =
      'assets/js/logger' +
      (environment.clientId.endsWith('prod') ? '.prod.js' : '.js')
    this.loadScript(loggerLibrary).subscribe(() => {
      console.log(loggerLibrary + ' loaded')
    })
  }

  /**
   * Hide first meaningful paint
   */
  private hideMeaningfulPaint() {
    if (this.platformId === 'browser') {
      const elements = document.getElementById(this._selector)
      if (elements) {
        setTimeout(() => {
          elements.style['display'] = 'none'
        }, 200)
      }
    }
  }

  private loadScript(url: string): Observable<any> {
    if (_loadedLibraries[url]) {
      return _loadedLibraries[url].asObservable()
    }

    _loadedLibraries[url] = new ReplaySubject()

    const script = window.document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.src = url
    script.onload = () => {
      _loadedLibraries[url].next()
      _loadedLibraries[url].complete()
    }

    window.document.body.appendChild(script)

    return _loadedLibraries[url].asObservable()
  }
}
