import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { Subscription, timer } from 'rxjs'
import { AAAStore } from 'src/app/store/root-reducer'
import { checkSessionTimeout } from './session.actions'

export enum TimerStatus {
  Stopped = 0,
  Running = 1,
}

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  sessionTimeoutTimer = null
  timerStatus = TimerStatus.Stopped
  sessionTimeoutTimerSubscription: Subscription = null

  constructor(private store$: Store<AAAStore>) {}

  startSessionTimeout() {
    if (this.timerStatus === TimerStatus.Running) {
      return
    }

    this.sessionTimeoutTimer = timer(0, 30000)

    if (this.sessionTimeoutTimerSubscription) {
      this.sessionTimeoutTimerSubscription.unsubscribe()
    }

    this.sessionTimeoutTimerSubscription = this.sessionTimeoutTimer.subscribe(
      () => {
        this.store$.dispatch(checkSessionTimeout())
      }
    )

    this.timerStatus = TimerStatus.Running
  }

  stopSessionTimeout() {
    // Should be safe anyway, but don't repeat the stop operation twice.
    if (this.timerStatus === TimerStatus.Stopped) {
      return
    }

    if (this.sessionTimeoutTimerSubscription) {
      this.sessionTimeoutTimerSubscription.unsubscribe()
    }

    this.sessionTimeoutTimer = null

    this.timerStatus = TimerStatus.Stopped
  }
}
