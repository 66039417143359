import { Component, Input, Output, EventEmitter } from '@angular/core'
import { flyInOutAnimationLeft } from 'src/app/modules/ui/animations'

import { Store, select } from '@ngrx/store'
import { AAAStore } from 'src/app/store/root-reducer'
import { ServiceItem } from 'src/app/modules/member/member.types'
import { Observable } from 'rxjs'
import { selectServiceHistory } from 'src/app/modules/member/member.selectors'

// ARR:POC - The components controls rendering the service history panel.
@Component({
  selector: 'app-service-history-panel',
  templateUrl: './service-history-panel.component.html',
  styleUrls: ['./service-history-panel.component.scss'],
  animations: [flyInOutAnimationLeft],
})
export class ServiceHistoryPanelComponent {
  constructor(private store$: Store<AAAStore>) {
    this.isCollapsed = false
  }

  @Input()
  display = false

  @Output()
  isDisplayed = new EventEmitter<boolean>()

  isCollapsed: boolean

  serviceHistory$: Observable<Array<ServiceItem>> = this.store$.pipe(
    select(selectServiceHistory)
  )

  handleDisplay() {
    this.display = !this.display
    this.isDisplayed.emit(this.display)
  }
}
