import { createSelector, defaultMemoize, MemoizedProjection } from '@ngrx/store'

import { AAAStore } from '../../store/root-reducer'
import { selectLocationClub } from '../location/location.selectors'
import { selectMemberShipLevel } from '../member/member.selectors'
import {
  ClubMembershipTowing,
  ServiceConfigurationSetting,
  ServicingClubConfig,
  PACE_SETTER_OVERRIDE_OPTIONS,
  OverrideOptions,
  ServicesConfiguration,
} from './servicing-club.types'
import { ServicingClubState } from './servicing-club.reducer'
import { MembershipLevelsSettings } from '../member/member.types'
import { PaceSetterCode } from '../issue/issue.types'
import { SelectorFactory } from 'src/app/shared/types'
import { indexCollection } from 'src/app/shared/utils'
import { TOW_DISTANCE } from '../location/location.types'
import { selectModeConfiguration } from '../auth/auth.selectors'
import { VendorConfigurationMode } from '../auth/mode-configuration.types'

const AAA_DEFAULT_PHONE_NUMBER = '800-AAA-HELP'
export const CONFIG_IS_ENABLED = 'DRRENABLED'
export const DEFAULT_MEMBER_LEVELS = Object.values(TOW_DISTANCE)
  .filter((value) => typeof value === 'number')
  .map(Number)

export const selectServicingClubState = (store: AAAStore): ServicingClubState =>
  store.servicingClub

export const createSelectConfigByServicingClub = createSelector(
  selectServicingClubState,
  (state): SelectorFactory<ServicesConfiguration> =>
    defaultMemoize((club: string) => state.club[club])
)

export const selectIsEnabled = createSelector(
  createSelectConfigByServicingClub,
  selectLocationClub,
  (selectConfigByServicingClub, club): boolean => {
    const config =
      selectConfigByServicingClub.memoized(club)?.configurationGroup
        ?.configurationItem

    if (!config) {
      return true
    }

    const isEnabledConfig = config.find(
      (item) => item.name.toUpperCase() === CONFIG_IS_ENABLED
    )

    return typeof isEnabledConfig?.enabled === 'boolean'
      ? isEnabledConfig?.enabled
      : true
  }
)

export const selectTowClubSettings = createSelector(
  createSelectConfigByServicingClub,
  selectLocationClub,
  (
    selectConfigByServicingClub,
    club
  ): Array<ServiceConfigurationSetting> | null => {
    const configByClub = selectConfigByServicingClub.memoized(club)

    if (!configByClub) {
      return null
    }

    return configByClub.settings?.setting?.filter((setting) =>
      setting.name.startsWith('tow')
    )
  }
)

export const selectClubTowSettingLevels = createSelector(
  selectTowClubSettings,
  (towSettings): Array<number> =>
    towSettings
      ? Object.keys(
          indexCollection<ServiceConfigurationSetting, 'value'>(
            towSettings.filter((setting) => Number(setting.value)),
            'value'
          )
        ).map(Number)
      : Object.values(TOW_DISTANCE)
          .filter((value) => typeof value === 'number')
          .map(Number)
)

export const selectHasTowClubSettings = createSelector(
  selectTowClubSettings,
  selectClubTowSettingLevels,
  (towClubSettings,towClubSettingLevels): boolean =>
    Boolean(towClubSettings?.length)
    && Boolean(towClubSettingLevels?.length)
)

export const selectClubTowSettingByMemberLevel = createSelector(
  selectTowClubSettings,
  selectMemberShipLevel,
  (towClubSettings, memberShipLevel): ClubMembershipTowing | null => {
    if (!towClubSettings || !memberShipLevel) {
      return null
    }

    const level = memberShipLevel.replace(/\s/g, '').toUpperCase()
    const memberLevel: MembershipLevelsSettings =
      MembershipLevelsSettings[level]
    const settingsByLevel = towClubSettings
      .filter((settings: ServiceConfigurationSetting) =>
        settings.name.match(
          new RegExp(`^tow(Mileage|Description)${memberLevel}$`, 'g')
        )
      )
      .reduce((towingLimit, setting) => {
        if (setting.name === `towMileage${memberLevel}` && setting.value) {
          return {
            ...towingLimit,
            distance: Number(setting.value),
          }
        }

        if (setting.name === `towDescription${memberLevel}` && setting.value) {
          return {
            ...towingLimit,
            message: setting.value,
          }
        }

        return towingLimit
      }, {})

    return Object.keys(settingsByLevel).length ? settingsByLevel : null
  }
)

export const selectServicingClubHighwayPriorityCode = createSelector(
  selectServicingClubState,
  selectLocationClub,
  (state: ServicingClubState, club: string): string | null => {
    if (!club) {
      return null
    }
    const configs = state.club[club]?.settings?.setting?.filter(
      (settings: ServiceConfigurationSetting) =>
        settings.name === ServicingClubConfig.HIGHWAY_PRIORITY_CODE_NAME
    )
    return configs && configs[0]?.value
  }
)

export const selectDefaultPhoneNumber = createSelector(
  selectModeConfiguration,
  (configMode: VendorConfigurationMode): string => configMode ? configMode.contactPhone : AAA_DEFAULT_PHONE_NUMBER)

export const selectServicingClubRoadServicePhoneNumber = createSelector(
  selectServicingClubState,
  selectLocationClub,
  selectDefaultPhoneNumber,
  selectModeConfiguration,
  (state: ServicingClubState, club: string, defaultPhoneNumber: string, config: VendorConfigurationMode): string => {
    if (!club || config) {
      return defaultPhoneNumber
    }
    const configs = state.club[club]?.settings?.setting?.filter(
      (settings: ServiceConfigurationSetting) =>
        settings.name === ServicingClubConfig.ROAD_SERVICE_PHONE_NUMBER
    )
    return (configs && configs[0]?.value) || defaultPhoneNumber
  }
)

export const selectIsServicingClubConfigsSet = createSelector(
  createSelectConfigByServicingClub,
  selectLocationClub,
  (selectConfigByServicingClub: MemoizedProjection, club: string): boolean =>
    Boolean(selectConfigByServicingClub.memoized(club))
)

export const selectIsBatteryQuoteEnabled = createSelector(
  selectServicingClubState,
  selectLocationClub,
  (state: ServicingClubState, club: string): boolean => {
    if (!club) {
      return null
    }

    const config = state.club[club]?.settings?.setting?.find(
      (settings: ServiceConfigurationSetting) =>
        settings.name === ServicingClubConfig.ROAD_SERVICE_BATTERY_QUOTE
    )

    return config && config?.value.toLowerCase() === 'y'
  }
)

export const selectClubOverridePaceSetterCode = createSelector(
  selectServicingClubState,
  selectLocationClub,
  (state: ServicingClubState, club: string) =>
    defaultMemoize((paceSetterCode: PaceSetterCode): OverrideOptions => {
      if (!club || !paceSetterCode) {
        return null
      }

      const activePaceSetterCode = paceSetterCode.paceSetterCode
      const clubSettings = state.club[club]?.settings?.setting || []
      const paceSetterOverrideKeys = Object.keys(PACE_SETTER_OVERRIDE_OPTIONS)

      const activePaceSetterCodeOverrideKey = paceSetterOverrideKeys.find(
        (key) => {
          const setting = PACE_SETTER_OVERRIDE_OPTIONS[key]
          return (
            setting?.parentCode?.toLowerCase() ===
            activePaceSetterCode?.toLowerCase()
          )
        }
      )

      if (!activePaceSetterCodeOverrideKey) {
        return null
      }

      const activePaceSetterCodeOverride = clubSettings.find(
        (setting) =>
          activePaceSetterCodeOverrideKey.toLowerCase() ===
          setting.name.toLowerCase()
      )

      if (!activePaceSetterCodeOverride) {
        return null
      }

      const validIndicators = ['yes', 'y']
      const paceSetterOverride =
        PACE_SETTER_OVERRIDE_OPTIONS[activePaceSetterCodeOverrideKey]

      const towingSetting = clubSettings.find(
        (setting) =>
          paceSetterOverride.towIndicator.toLowerCase() ===
          setting.name.toLowerCase()
      )
      const towingEnabled =
        validIndicators.indexOf(towingSetting?.value?.toLowerCase()) !== -1

      return {
        requiresTowing: towingEnabled,
        paceSetterCode: activePaceSetterCodeOverride.value,
        overrideType: activePaceSetterCodeOverrideKey,
      }
    })
)
