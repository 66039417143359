import { createAction, props } from '@ngrx/store'
import { ResetAction } from 'ngrx-forms'

export const PASSENGERS_FORM = 'form.passengers'
export const CONTACT_FORM = 'form.contact'

export const SET_SUBMIT_STEP = 'SET_SUBMIT_STEP'
export const RESET_PASSENGERS = 'RESET_PASSENGERS'

export const setSubmitStep = createAction(
  SET_SUBMIT_STEP,
  props<{ payload: { step: string } }>()
)

export const setResetPassengers = createAction(RESET_PASSENGERS)

export const resetPassengers = () => new ResetAction(PASSENGERS_FORM)
