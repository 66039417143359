import { Injectable } from '@angular/core'

import {
  MemberNumberAuthRequestParams,
  SecureAuthRequestParams,
  BaseAuthRequestParams,
  AuthResponse,
  SearchSigninParams,
} from './auth.types'
import { XHRService } from '../../shared/services/xhr.service'
import { TokenSchema } from './auth.model'
import { ErrorReportingService } from 'src/app/shared/services/error-reporting.service'



export const DEFAULT_VENDOR = 'RSOWeb'

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private xhr: XHRService) { }

  async secureSignin({
    captchaToken,
    memId,
    ets,
    vendor = DEFAULT_VENDOR,
  }: BaseAuthRequestParams & SecureAuthRequestParams) {
    try {
      const response = await this.xhr.request<AuthResponse>({
        url: 'v2/member/verification',
        data: {
          captchaToken,
          memberNumber: memId,
          ets,
          vendor,
        },
        method: 'POST',
      })
      await TokenSchema.validate(response)
      return response
    } catch (error) {
      throw error
    }
  }

  // start ARR:POC - The validation service that posts member sign in data to a ARR POC endpoint
  async agentSignIn({
    captchaToken,
    memberNumber,
    zipCode,
  }: BaseAuthRequestParams & MemberNumberAuthRequestParams) {
    try {
      const response = await this.xhr.request<AuthResponse>({
        url: 'v2/member/agent/validation',
        data: {
          captchaToken,
          memberNumber,
          zipCode,
        },
        method: 'POST',
      })
      await TokenSchema.validate(response)
      return response
    } catch (error) {
      throw error
    }
  }
  // end ARR:POC

  async unsecureSignin({
    captchaToken,
    memberNumber,
    zipCode,
  }: BaseAuthRequestParams & MemberNumberAuthRequestParams) {
    try {
      const response = await this.xhr.request<AuthResponse>({
        url: 'v2/member/validation',
        data: {
          captchaToken,
          memberNumber,
          zipCode,
        },
        method: 'POST',
      })
      await TokenSchema.validate(response)
      return response
    } catch (error) {
      throw error
    }
  }

  async searchSignin({ searchId, resultId }: SearchSigninParams) {
    try {
      const response = await this.xhr.request<AuthResponse>({
        method: 'POST',
        url: `v2/member/search/${searchId}/${resultId}`,
        data: {
          searchId,
          resultId,
        },
      })
      await TokenSchema.validate(response)
      return response
    } catch (error) {
      throw error
    }
  }

  async oemValidation(
    captchaToken,
    vin: string,
    mileage: number,
    manufacturer: string,
    referenceId: string
  ) {
    try {

      const oemValidation = await this.xhr.request<AuthResponse>({
        url: `/rap/validation/oem`,
        data: {
          captchaToken,
          vin,
          odometer: mileage,
          manufacturer,
          referenceId
        },
        method: 'POST',
      })
      return { oemValidation, captchaToken }
    } catch (error) {
      throw error
    }
  }

  async rentalValidation(
    captchaToken,
    rentalAgreement: string,
    referenceId: string
  ) {
    try {
      const rentalValidation = await this.xhr.request<AuthResponse>({
        url: `/rap/validation/rental`,
        data: {
          captchaToken,
          rentalAgreement,
          referenceId
        },
        method: 'POST',
      })
      return { rentalValidation, captchaToken }
    } catch (error) {
      throw error
    }
  }

  async vasValidation(
    captchaToken,
    subscriberId: string,
    referenceId: string
  ) {
    try {
      const vasValidation = await this.xhr.request<AuthResponse>({
        url: `/rap/validation/vas`,
        data: {
          captchaToken,
          subscriberId,
          referenceId
        },
        method: 'POST',
      })
      return { vasValidation, captchaToken }
    } catch (error) {
      throw error
    }
  }

  async rapVerification(
    access_token,
  ) {
    try {
      const rapVerification = await this.xhr.request<AuthResponse>({
        url: `/rap/verification`,
        data: {},
        method: 'POST',
        headers: {
          Authorization: `Bearer ${access_token}`
        },
      })
      return { rapVerification, access_token }
    } catch (error) {
      throw error
    }
  }


}
