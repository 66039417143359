import { environment } from 'src/environments/environment'
import { PayloadedAction } from 'src/app/shared/types'

export interface ConfigState {
  baseURL: string
}

export const initialState = {
  baseURL: environment.baseURL,
}

export function reducer(state = initialState, action: PayloadedAction) {
  switch (action.type) {
    default:
      return state
  }
}
