import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core'
import { PwaService } from 'src/app/shared/services/pwa.service'
import { Store, select } from '@ngrx/store'
import { AAAStore } from 'src/app/store/root-reducer'
import { openMessageDialog } from '../ui.actions'
import { MessageDialogTypes } from '../ui.types'
import { isDesktopSize } from 'src/app/shared/utils/windowSize'
import { takeUntil, filter } from 'rxjs/operators'
import { Subject, Observable } from 'rxjs'
import { selectShowPwa } from '../ui.selectors'
import {
  trigger,
  state,
  style,
  animate,
  transition,
  group,
} from '@angular/animations'
import { selectActivePaceSetterSituation } from '../../issue/issue.selectors'
import { TaggingService } from '../../tagging/tagging.service'
import events from '../../tagging/events'
import { selectIsRapUser } from '../../auth/auth.selectors'
import { getOemBrandingId, OEMLogoData } from 'src/app/shared/utils/branding'

@Component({
  selector: 'app-pwa-quick-install',
  templateUrl: './pwa-quick-install.component.html',
  styleUrls: ['./pwa-quick-install.component.scss'],
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          display: 'block',
        })
      ),
      state(
        'closed',
        style({
          display: 'none',
        })
      ),
      transition('open => closed', [
        style({ height: '*', opacity: 1 }),
        group([
          animate(300, style({ height: 0 })),
          animate('200ms ease-in-out', style({ opacity: '0' })),
        ]),
      ]),
      transition('closed => open', [
        style({ height: '0', opacity: 0 }),
        group([
          animate(600, style({ height: '*' })),
          animate('300ms ease-in-out', style({ opacity: '1' })),
        ]),
      ]),
    ]),
  ],
})
export class PwaQuickInstallComponent implements OnInit {
  isSafari = false
  isChrome = false
  isFirefox = false
  closed = false
  isDesktop = false
  isPwa = false
  prompt: any

  suppressPWA$: Observable<boolean> = this.store$.pipe(select(selectShowPwa))

  activePaceSetterSituation$: Observable<any> = this.store$.pipe(
    select(selectActivePaceSetterSituation)
  )

  isRapUser = false

  private unsubscribe$ = new Subject<void>()

  get displayQuickAccess() {
    return (
      (!this.isChrome && !this.isDesktop) ||
      (this.isChrome && this.isDesktop) ||
      (this.prompt && !this.isChrome)
    )
  }

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private pwaService: PwaService,
    private store$: Store<AAAStore>,
    private taggingService: TaggingService
  ) {}

  ngOnInit() {
    let oemBrandingAppId = getOemBrandingId(window.location.href)

    if (oemBrandingAppId) {
      this.isRapUser = true
    }

    this.isPwa = window.matchMedia('(display-mode: standalone)').matches

    if (this.platformId === 'browser') {
      this.isDesktop = isDesktopSize()
      this.isSafari = navigator.userAgent.indexOf('Safari') !== -1
      this.isChrome = navigator.userAgent.indexOf('Chrome') !== -1
      this.isFirefox =
        navigator.userAgent.indexOf('Firefox') !== -1 ||
        navigator.userAgent.indexOf('Mozilla') !== -1

      this.pwaService.promptEvent
        .pipe(
          filter((prompt) => prompt),
          takeUntil(this.unsubscribe$) // unsubscribe to prevent memory leak
        )
        .subscribe((promptEvent) => {
          this.prompt = promptEvent
          this.unsubscribe$.next()
          this.unsubscribe$.complete()
        })

      this.activePaceSetterSituation$.subscribe((situation) => {
        if (!!situation) {
          this.closed = true
        }
      })
    }
  }

  close() {
    this.closed = true
  }

  install(prompt) {
    this.taggingService.setClickEvent(
      events.shared.APP_INSTALL_CLICK,
      events.issue.ISSUE_PAGE_TYPE
    )

    // Trigger instructions of how to install
    if ((this.isSafari || this.isFirefox) && !prompt) {
      return this.openInstructions()
    }

    // Trigger action for chrome
    if (this.isChrome && prompt) {
      return this.chromeInstall(prompt)
    }
  }

  private openInstructions() {
    const modal = openMessageDialog({
      payload: {
        type: MessageDialogTypes.PWA_INSTALL,
        title: 'PWA Install',
      },
    })
    this.store$.dispatch(modal)
  }

  private chromeInstall(prompt) {
    prompt.prompt()
    prompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the A2HS prompt')
      } else {
        console.log('User dismissed the A2HS prompt')
      }
    })
  }
}
