import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'
import { from, Observable } from 'rxjs'
import {
  catchError,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators'
import { ErrorReportingService } from 'src/app/shared/services/error-reporting.service'
import { PayloadedAction } from 'src/app/shared/types'
import { generateVehicleSlug } from 'src/app/shared/utils/generateVehicleSlug'
import { AAAStore } from 'src/app/store/root-reducer'
import { selectEligibility } from '../../auth/auth.selectors'
import { selectActiveCallStatus } from '../../dashboard/calls-statuses/call-status.selectors'
import {
  selectActivePaceSetterCode,
  selectNeedsTow,
} from '../../issue/issue.selectors'
import {
  ASSIGN_EXISTING_VEHICLE,
  SET_VEHICLE,
} from '../../member/member.actions'
import { selectMemberActiveVehicle } from '../../member/member.selectors'
import { RapService } from '../../rap/rap.service'
import { requestRouteDistance } from '../../route-distance/route-distance.actions'
import { SET_BREAKDOWN_LOCATION } from '../location.actions'
import { selectBreakdownLocation } from '../location.selectors'
import {
  AAR_DETAIL,
  AAR_LOAD,
  completeAarDetail,
  completeAarLoad,
  notifyAarDetailFailure,
  notifyAarLoadFailure,
  requestAars,
} from './aar.actions'
import {
  createAARDetailSelector,
  selectExistsAarForVehicle,
} from './aar.selectors'
import { AarService } from './aar.service'
import { AARData, AARDetail } from './aar.types'
import { filterAarByServiceOffers } from './aar.utils'

@Injectable()
export class AarEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<AAAStore>,
    private _aarService: AarService,
    private errorReportingService: ErrorReportingService,
    private rapService: RapService,
  ) {}

  refreshAars$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SET_BREAKDOWN_LOCATION.SUCCESS,
        ASSIGN_EXISTING_VEHICLE,
        SET_VEHICLE
      ),
      withLatestFrom(
        this.store$.pipe(select(selectBreakdownLocation)),
        this.store$.pipe(select(selectMemberActiveVehicle)),
        this.store$.pipe(select(selectExistsAarForVehicle)),
        this.store$.pipe(select(selectNeedsTow)),
        this.store$.pipe(select(selectActiveCallStatus))
      ),
      filter(
        ([
          _,
          breakdownLocation,
          activeVehicle,
          hasAarForVehicle,
          needsTow,
          activeCall,
        ]) =>
          breakdownLocation &&
          needsTow &&
          Boolean(activeVehicle) &&
          !hasAarForVehicle &&
          !activeCall
      ),
      map(([_, breakdownLocation, activeVehicle]) =>
        requestAars({
          payload: {
            latitude: breakdownLocation.latitude,
            longitude: breakdownLocation.longitude,
            make: activeVehicle.make,
            vehicleSlug: generateVehicleSlug(
              activeVehicle,
              breakdownLocation['zip']
            ),
          },
        })
      )
    )
  )

  loadAars$ = createEffect(
    (): Observable<
      | ReturnType<typeof completeAarLoad>
      | ReturnType<typeof notifyAarLoadFailure>
    > =>
      this.actions$.pipe(
        ofType<ReturnType<typeof requestAars>>(AAR_LOAD.REQUEST),
        filter((action) => Boolean(action.payload)),
        withLatestFrom(this.store$.pipe(select(selectActivePaceSetterCode))),
        switchMap(([action, activePaceCode]) =>
          from(this._aarService.getAARs(action.payload)).pipe(
            map((aars) => filterAarByServiceOffers(activePaceCode, aars, this.rapService.isRapUser())),
            map((aars) =>
              completeAarLoad({
                payload: {
                  [action.payload.vehicleSlug]: aars,
                },
              })
            )
          )
        ),
        catchError((error) =>
          this.errorReportingService.notifyErrorObservable(
            error,
            notifyAarLoadFailure
          )
        )
      )
  )

  loadAarDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AAR_DETAIL.REQUEST),
      filter((action: PayloadedAction) => Boolean(action.payload)),
      withLatestFrom(
        this.store$.pipe(select(createAARDetailSelector)),
        this.store$.pipe(select(selectEligibility)),
        this.store$.pipe(select(selectBreakdownLocation)),
        this.store$.pipe(select(selectMemberActiveVehicle)),
      ),
      switchMap(
        ([action, detailSelector, eligibility, breakdownLocation, activeVehicle]) => {
          const id = action.payload
          const storeAarDetail = detailSelector(id) as AARDetail

          if (eligibility) {
            const vehicleSlug = generateVehicleSlug(activeVehicle, breakdownLocation['zip'])
            return this.createActions(detailSelector(vehicleSlug)[id], breakdownLocation)
          }

          return storeAarDetail?.webAddress // note: webAddress is present just on the AAR Details response
            ? this.createActions(storeAarDetail, breakdownLocation)
            : from(this._aarService.getAARDetails(id)).pipe(
              switchMap((aarDetail: AARDetail) =>
                this.createActions(aarDetail, breakdownLocation)
              )
            )
        }
      ),
      catchError((error) =>
        this.errorReportingService.notifyErrorObservable(
          error,
          notifyAarDetailFailure
        )
      )
    )
  )

  createActions(aarDetailPayload, breakdownLocation) {
    return [
      completeAarDetail({ payload: aarDetailPayload }),
      requestRouteDistance({
        payload: {
          origin: breakdownLocation,
          destination: {
            latitude: aarDetailPayload.latitude,
            longitude: aarDetailPayload.longitude
          }
        }
      })
    ]
  }
}
